import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Script from 'react-load-script'
import { Elements, StripeProvider } from 'react-stripe-elements'

import * as actions from '../../../actions'

const url = 'https://js.stripe.com/v3/'

class StripeLoader extends Component {
  state = {
    stripe: null
  }

  componentDidMount () {
    this.props.getOptions()
  }

  _scriptLoaded () {
    return this.state.stripe && Script.loadedScripts[url]
  }

  _renderStripeLibrary () {
    const { createAccount: { options } } = this.props
    if (this._scriptLoaded() || !options) { return }
    const { stripePublishableKey } = options
    return <Script
      url={url}
      onLoad={() => this.setState({ stripe: window.Stripe(stripePublishableKey) })}
    />
  }

  _renderStripeProvider () {
    const { stripe } = this.state
    if (!stripe || !this._scriptLoaded()) { return }

    return (
      <StripeProvider stripe={stripe}>
        <Elements>
          {this.props.children}
        </Elements>
      </StripeProvider>
    )
  }

  render () {
    return (
      <React.Fragment>
        {this._renderStripeLibrary()}
        {this._renderStripeProvider()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { createAccount } = state
  return { createAccount }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.createAccount },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeLoader)

StripeLoader.propTypes = {
  children: PropTypes.node,
  createAccount: PropTypes.object.isRequired,
  getOptions: PropTypes.func.isRequired
}