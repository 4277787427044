import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CardElement, injectStripe } from 'react-stripe-elements'

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faExclamationTriangle, faLock } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import ModalConfirmation from '../common/modalConfirmation'
import HelpTooltip from '../common/helpTooltip'

import * as actions from '../../actions'

class EditSubscription extends Component {
  state = {
    show: true,
    showConfirmCancelSubscription: null
  }

  componentDidMount () {
    this.props.setAccountEdited()
    this.props.setError()
  }

  componentDidUpdate () {
    const { account: { accountEdited } } = this.props
    if (accountEdited) {
      this.props.done()
    }
  }

  async _handleOnSubmit () {
    const { stripe } = this.props
    if (!stripe) { return }

    this.props.setError()
    this.props.setLoading(true)

    // try to create a stripe payment method now
    const result = await stripe.createPaymentMethod({
      type: 'card', card: this.props.elements.getElement('card')
    })
    if (result && result.error) {
      this.props.setError(result.error.message)
      this.props.setLoading(false)
      return
    }

    // try to update the account's payment method now
    const paymentMethod = result ? result.paymentMethod : null
    this.props.updateSubscription({ paymentMethod })
  }

  _renderConfirmCancelSubscription () {
    const { account: { loading, error } } = this.props
    const { showConfirmCancelSubscription } = this.state
    if (!showConfirmCancelSubscription) { return }

    return (
      <ModalConfirmation
        show={true}
        header='Cancel subscription'
        text='Are you sure you want to cancel your subscription renewal to Drivers Clearinghouse? You will remain covered through the end of your current coverage period but your subscription will not be renewed.'
        loading={loading}
        error={error}
        acceptText='Yes, cancel subscription'
        onAccept={() => this.props.cancelSubscription()}
        cancelText='No, don&apos;t cancel subscription'
        onCancel={() => this.setState({ show: true, showConfirmCancelSubscription: false })}
      />
    )
  }

  _renderButtonContents () {
    const { account: { loading } } = this.props
    if (!loading) { return 'Save' }
    return (
      <React.Fragment>
        <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Saving
      </React.Fragment>
    )
  }

  _renderMessage () {
    const { account: { error } } = this.props
    if (!error) { return }
    return (
      <Row>
        <Col xs={12} className='alert alert-danger'>
          {error}
        </Col>
      </Row>
    )
  }

  _renderPaymentInformation () {
    const { stripe } = this.props
    if (!stripe) { return }

    return (
      <Form.Group>
        <Form.Label>Payment information</Form.Label>
        <CardElement
          className='form-control'
          style={{ base: { fontSize: '16px' } }}
        />
      </Form.Group>
    )
  }

  render () {
    const { account: { loading, driver, company, dotNumber }, done } = this.props
    if (!driver || !company || !dotNumber) { return }

    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={() => done()}
          animation={false}
          size='lg'
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
              Edit subscription
            </Modal.Title>
            <div>
              <Button
                size='sm'
                variant='outline-secondary'
                title='Close'
                className='ml-2'
                onClick={() => done()}
              ><FontAwesomeIcon icon={faTimes}/>
              </Button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {this._renderMessage()}
              <Row>
                <Col xs={12}>
                  <p>If needed, update your subscription payment information now.</p>
                </Col>
                <Col xs={12} className='mt-2'>
                  <div className='d-flex flex-column bg-light3 p-3'>
                    {this._renderPaymentInformation()}
                    <div>
                      <FontAwesomeIcon icon={faLock}/>
                      <span className='ml-2'>Drivers Clearinghouse does not store your payment information.
                        <HelpTooltip
                          content={<span>Transactions are processed and information is stored by a PCI-compliant vendor.</span>}
                        />
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-between'>
              <Button
                variant='outline-danger'
                size={'sm'}
                className='btn-semi-wide'
                onClick={() => this.setState({ show: false, showConfirmCancelSubscription: true })}
              ><FontAwesomeIcon className='mr-2' icon={faExclamationTriangle}/>Cancel subscription</Button>
              <div>
                <Button
                  variant='secondary'
                  size={'sm'}
                  className='btn-semi-wide mr-2'
                  onClick={() => done()}
                >Cancel</Button>
                <Button
                  variant='primary'
                  size={'sm'}
                  disabled={loading}
                  className='btn-wide'
                  onClick={async () => await this._handleOnSubmit()}
                >{this._renderButtonContents()}</Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {this._renderConfirmCancelSubscription()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { account } = state
  return { account }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.account },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(EditSubscription))

EditSubscription.propTypes = {
  done: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  updateSubscription: PropTypes.func.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  setAccountEdited: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
