import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row
} from 'react-bootstrap'
import Select from 'react-select'
import { selectCustomStyles } from '../common/selectCustomStyles'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { displayQueryStatus } from '../../utils'
import * as actions from '../../actions'

class UpdateQueryStatuses extends Component {
  state = {
    dateCompleted: new Date(),
    queryStatus: 'none'
  }

  _renderQueryStatus () {
    const { queryStatus } = this.state
    if (!queryStatus) { return }
    return `Query statuses set to ${queryStatus}`
  }

  render () {
    const queryStatus = this.state.queryStatus
    const queryStatuses = ['none', 'awaiting', 'pending', 'complete-notprohibited', 'incomplete/cancelled', 'driverprohibited','recordsfound-fullqueryrequested','driver-notverified','query balance- 0']
    const { done, updateQueryStatuses } = this.props

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo' />
            Update query statuses
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>New status</Col>
              <Col xs={8} md={6}>
                <Select
                  styles={selectCustomStyles}
                  options={queryStatuses.map((status) => ({ value: status, label: displayQueryStatus(status) }))}
                  value={{ value: queryStatus, label: displayQueryStatus(queryStatus) }}
                  onChange={(e) => this.setState({ queryStatus: e.value })}
                ></Select>
              </Col>
            </Row>
            <br></br>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'></Col>
              <Col xs={8} md={6}>Query statuses for the selected drivers will be updated to the value chosen above. Do you want to proceed?
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => done()}
          >Cancel</Button>
          <Button
            variant='primary'
            size={'sm'}
            className='btn-wide'
            onClick={() => {
              updateQueryStatuses(queryStatus)
              done()
            }}
          >{'Continue'}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { driversBulkActions } = state
  return { driversBulkActions }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driversBulkActions },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateQueryStatuses)

UpdateQueryStatuses.propTypes = {
  done: PropTypes.func.isRequired,
  driversBulkActions: PropTypes.object.isRequired,
  updateQueryStatuses: PropTypes.func.isRequired
}