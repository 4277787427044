import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row
} from 'react-bootstrap'
import Select from 'react-select'
import { selectCustomStyles } from '../common/selectCustomStyles'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import * as selectors from '../../selectors'

class CreateConsentContents extends Component {
  state = {
    DOTNumbers: this.props.DOTNumbers,
    consentContents: this.props.consentContents,
    showEditConsentContent: false
  }

  _setDefaultConsentContents () {
    const defaultConsentContents = [{
      language: 'en',
      dotNumber: null
    }]
    defaultConsentContents[0].content = selectors.driverConsent.getDefaultConsentContents(defaultConsentContents[0].language)
    this.setState({
      consentContents: defaultConsentContents
    })
    return defaultConsentContents
  }

  _handleAddConsent (consentContents) {
    consentContents.push({
      language: null,
      dotNumber: null,
      content: null
    })
    this.setState({ consentContents: consentContents })
  }

  _handleRemoveConsent (consentContents, index) {
    consentContents.splice(index, 1)
    this.setState({ consentContents: consentContents })
  }

  _handleChangeLanguage (consentContents, index, event) {
    consentContents[index].language = event.value
    if (!consentContents[index].content) {
      consentContents[index].content = selectors.driverConsent.getDefaultConsentContents(consentContents[index].language)
    }
    this.setState({ consentContents: consentContents })
  }

  _handleChangeDOTNumber (consentContents, index, event) {
    consentContents[index].dotNumber = event === null ? null : event.value
    this.setState({ consentContents: consentContents })
  }

  _handleUpdateContents (selectedConsent, event) {
    selectedConsent.content = event.target.value
    this.setState({ selectedConsent: selectedConsent })
  }

  _handleSaveContents (consentContents, selectedConsent) {
    consentContents[selectedConsent.index].content = selectedConsent.content
    this.setState({
      consentContents: consentContents,
      showEditConsentContent: false
    })
  }

  _showEditContentArea (selectedConsent, index) {
    this.setState({
      showEditConsentContent: true,
      selectedConsent: { ...selectedConsent, index: index }
    })
  }

  _renderConsentContentArea () {
    return !this.state.showEditConsentContent ? '' :
      <Container>
        <hr className='mb-3'/>
        <Row className='pb-1 mx-3'
          style={{ margin: '10px 0' }}>
          <Col xs={12} style={{ fontWeight: 'bold' }}>
            Editing Entry #{this.state.selectedConsent.index + 1}
          </Col>
        </Row>
        <Row className='pb-1 mx-3'
          style={{ margin: '10px 0' }}>
          <Col xs={12}>
            <Form.Control
              as='textarea'
              rows={10}
              value={this.state.selectedConsent.content}
              placeholder='Type/Paste HTML Content here and click "Update Content" to save...'
              onChange={(e) => this._handleUpdateContents(this.state.selectedConsent, e)}
            ></Form.Control>
          </Col>
        </Row>
        <Row className='pb-1 mx-3'
          style={{ margin: '10px 0' }}>
          <Col xs={2}>
            <Button
              size='sm'
              variant='primary'
              title='Save consent configuration'
              className='btn-wide'
              onClick={() => this._handleSaveContents(this.state.consentContents, this.state.selectedConsent)}
            >Update Content</Button>
          </Col>
        </Row>
      </Container>
  }

  render () {
    const { hide, done, back } = this.props
    const languages = selectors.languages.getAll()
    let consentContents = this.state.consentContents

    if (consentContents[0] === null) {
      consentContents = this._setDefaultConsentContents()
    }

    return (
      <Modal
        show={true}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo' />
            Add Consent Contents
          </Modal.Title>
          <Modal.Title>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => hide()}
            ><FontAwesomeIcon icon={faTimes} />
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='pb-1 mx-3 align-items-center'
              style={{ margin: '10px 0' }}>
              <Col xs={2} style={{ fontWeight: 'bold' }}>#</Col>
              <Col xs={3} style={{ fontWeight: 'bold' }}>Language</Col>
              <Col xs={3} style={{ fontWeight: 'bold' }}>DOT Number</Col>
              <Col xs={4}></Col>
            </Row>
            {consentContents.map((consent, index) => {
              return <Row className='pb-1 mx-3 align-items-center'
                key = {index}
                style={{ marginBottom: '5px' }}>
                <Col xs={2} style={{ fontWeight: 'bold' }}>
                  <Button
                    variant='danger'
                    size={'sm'}
                    disabled={consentContents.length < 2}
                    onClick={() => this._handleRemoveConsent(consentContents, index)}>
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </Button>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  {index + 1}:
                </Col>
                <Col xs={3}>
                  <Select
                    styles={selectCustomStyles}
                    isClearable={false}
                    isMulti={false}
                    options={languages.map((l) => ({ value: l.value, label: l.display }))}
                    value={{
                      value: consentContents[index].language,
                      label: (languages.find(l => l.value === consentContents[index].language) || { display: null }).display
                    }}
                    onChange={(event) => this._handleChangeLanguage(consentContents, index, event)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  {(this.state.DOTNumbers.length < 2 && this.state.DOTNumbers[0] === null) ? 'N/A' :
                    <Select
                      styles={selectCustomStyles}
                      isClearable={true}
                      isMulti={false}
                      options={this.state.DOTNumbers.map((d) => ({ value: d, label: d }))}
                      value={{ value: consentContents[index].dotNumber, label: consentContents[index].dotNumber }}
                      onChange={(event) => this._handleChangeDOTNumber(consentContents, index, event)}
                    ></Select>
                  }
                </Col>
                <Col xs={4}>
                  <Button
                    size='sm'
                    variant={!consentContents[index].content ? 'danger' : 'primary'}
                    title='Edit contents of this consent configuration'
                    className='btn-wide'
                    onClick={() => this._showEditContentArea(consentContents[index], index)}
                  >Edit</Button>
                </Col>
              </Row>
            })}
            <Row className='pb-1 mx-3 align-items-center'
              style={{ margin: '10px 0' }}>
              <Col xs={3} md={4}>
                <Button
                  variant='primary'
                  size={'sm'}
                  onClick={() => this._handleAddConsent(consentContents)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
              </Col>
              <Col xs={6} md={4}></Col>
              <Col xs={3} md={4}></Col>
            </Row>
          </Container>
          {this._renderConsentContentArea()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => hide()}
          >Cancel</Button>
          <Button
            variant='outline-secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => back(consentContents)}
          >Back</Button>
          <Button
            variant='primary'
            size={'sm'}
            className='btn-wide'
            disabled={
              consentContents.length < 1 ||
              consentContents.filter((c) => {
                return !c.language || !c.content
              }).length > 0}
            onClick={() => done(this.state.consentContents)}
          >Save</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect()(CreateConsentContents)

CreateConsentContents.propTypes = {
  hide: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  DOTNumbers: PropTypes.array,
  consentContents: PropTypes.array
}