import { actionTypes } from '../actions/actionTypes'

const initialState = {
  consent: null,
  pdf: null,
  loading: false,
  error: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.CONSENT.SET_CONSENT:
    return { ...state, ...action.value }
  case actionTypes.CONSENT.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.CONSENT.SET_ERROR:
    return { ...state, error: action.value }
  default:
    return state
  }
}