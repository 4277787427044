import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Form,
  Spinner
} from 'react-bootstrap'
import Select from 'react-select'
import { selectCustomStyles } from '../../common/selectCustomStyles'
import ModalBackdropForm from '../../common/modalBackdropForm'
import ProgressBar from './common/progressBar'
import HelpTooltip from '../../common/helpTooltip'

import * as selectors from '../../../selectors'
import * as actions from '../../../actions'

class UserInfo extends Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    driversLicenseNumber: '',
    state: '',
    dotNumber: '',
    legalEntityName: '',
    dob: ''
  }

  componentDidMount () {
    this.props.getOptions()
    if (this.props.createAccount.userInfo) {
      this.setState(this.props.createAccount.userInfo)
    }
  }

  _handleOnSubmit () {
    this.props.validateUserInfo(this.state)
  }

  _renderButtonContents () {
    const { createAccount: { loading } } = this.props

    if (loading) {
      return (
        <React.Fragment>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Validating
        </React.Fragment>
      )
    }
    return 'Continue'
  }

  _renderMessage () {
    const { createAccount: { error } } = this.props

    if (!error) { return '' }
    return (
      <Col xs={12} className='mb-3 alert alert-danger'>
        <span>{error}</span>
      </Col>
    )
  }

  _renderForm () {
    const { createAccount: { loading } } = this.props

    return (
      <React.Fragment>
        <Col xs={12}>
          <ProgressBar
            title='Create account'
            subtitle='Step 1: please enter the information required to start creating your account.'
            percentDone={25}
          />
        </Col>
        <Col xs={12}>
          <Form.Group controlId='email'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type='email'
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId='firstName'>
            <Form.Label>First name</Form.Label>
            <Form.Control
              type='text'
              value={this.state.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId='lastName'>
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type='text'
              value={this.state.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId='driversLicenseNumber'>
            <Form.Label>Drivers license number</Form.Label>
            <Form.Control
              type='text'
              value={this.state.driversLicenseNumber}
              onChange={(e) => this.setState({ driversLicenseNumber: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId='state'>
            <Form.Label>Drivers license state</Form.Label>
            <Select
              id='state'
              styles={selectCustomStyles}
              isClearable={false}
              isSearchable={true}
              options={selectors.states.getAll().map((s) => ({ value: s, label: s }))}
              value={{ value: this.state.state, label: this.state.state }}
              onChange={(v) => this.setState({ state: v.value })}
            ></Select>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId='dotNumber'>
            <Form.Label>
              <span>DOT number</span>
              <HelpTooltip
                content='Your USADOT number, as required by the FMCSA.'
              />
            </Form.Label>
            <Form.Control
              type='text'
              value={this.state.dotNumber}
              onChange={(e) => this.setState({ dotNumber: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId='legalEntityName'>
            <Form.Label>
              <span>Legal entity name</span>
              <HelpTooltip
                content='An association, corporation, partnership, proprietorship, trust, or individual that has legal standing in the eyes of law. This is your company name, or your full name if you are a sole proprietor.'
              />
            </Form.Label>
            <Form.Control
              type='text'
              value={this.state.legalEntityName}
              onChange={(e) => this.setState({ legalEntityName: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId='dob'>
            <Form.Label>Date of birth</Form.Label>
            <Form.Control
              type='date'
              placeholder='1980-01-30'
              value={this.state.dob}
              onChange={(e) => this.setState({ dob: e.target.value })}
            ></Form.Control>
          </Form.Group>
        </Col>
        {this._renderMessage()}
        <Col xs={12} className='my-3'>
          <Button
            variant='primary'
            block
            type='submit'
            disabled={loading}
          >{this._renderButtonContents()}</Button>
        </Col>
      </React.Fragment>
    )
  }

  render () {
    return (
      <ModalBackdropForm
        sm={11}
        md={10}
        lg={9}
        onSubmit={() => this._handleOnSubmit()}
        displayLoginLink2={true}
        displayHelpLink={true}
      >{this._renderForm()}</ModalBackdropForm>
    )
  }
}

const mapStateToProps = (state) => {
  const { createAccount } = state
  return { createAccount }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.createAccount },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)

UserInfo.propTypes = {
  createAccount: PropTypes.object.isRequired,
  getOptions: PropTypes.func.isRequired,
  validateUserInfo: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
