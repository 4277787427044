import { actionTypes } from './actionTypes'
import { driver } from './driver'
import {
  requestAccount,
  requestUpdateAccount,
  requestCreateAccountPaymentMethod,
  requestCancelSubscription
} from '../services/apiService'

const account = {
  setAccount: (value) => ({
    type: actionTypes.ACCOUNT.SET_ACCOUNT,
    value
  }),

  setAccountEdited: (value) => ({
    type: actionTypes.ACCOUNT.SET_ACCOUNT_EDITED,
    value
  }),

  setError: (value) => ({
    type: actionTypes.ACCOUNT.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.ACCOUNT.SET_LOADING,
    value
  }),

  loadAccount: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(account.setLoading(true))
      dispatch(account.setError())
      const data = await requestAccount(token)
      if(data && data.account) {
        dispatch(account.setAccount(data.account))
        if (data.account.driver) {
          dispatch(driver.getDriver(data.account.driver._id))
        }
      } else if (data && data.error){
        dispatch(account.setError(data.error))
      }
      dispatch(account.setLoading(false))
    }
  },

  updateAccount: (params) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(account.setLoading(true))
      dispatch(account.setError())
      const data = await requestUpdateAccount(token, params)
      if(data && data.account) {
        dispatch(account.setAccount(data.account))
        dispatch(account.setAccountEdited(true))
      } else if (data && data.error){
        dispatch(account.setError(data.error))
      }
      dispatch(account.setLoading(false))
    }
  },

  updateSubscription: (params) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(account.setLoading(true))
      dispatch(account.setError())
      const data = await requestCreateAccountPaymentMethod(token, params)
      if(data && data.account) {
        dispatch(account.setAccount(data.account))
        dispatch(account.setAccountEdited(true))
      } else if (data && data.error){
        dispatch(account.setError(data.error))
      }
      dispatch(account.setLoading(false))
    }
  },

  cancelSubscription: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(account.setLoading(true))
      dispatch(account.setError())
      const data = await requestCancelSubscription(token)
      if(data && data.account) {
        dispatch(account.setAccount(data.account))
        dispatch(account.setAccountEdited(true))
      } else if (data && data.error){
        dispatch(account.setError(data.error))
      }
      dispatch(account.setLoading(false))
    }
  }
}

export { account }
