import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import { isEmpty, sortBy, last } from 'lodash'
import { fullName, displayDate, displayDateTime, displayQueryType, displayQueryStatus } from '../../../utils'

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import logo from '../../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import PdfDocument from '../pdfDocument'

import * as actions from '../../../actions'
import * as selectors from '../../../selectors'

class ViewDriver extends Component {
  state = {
    selectedFileName: ''
  }

  componentDidMount () {
    this.props.setConsent({ consent: null, pdf: null, error: null })
    this.props.setDocument()
    const { driver: { driver } } = this.props
    if (!driver) {
      return
    }
    this.props.getDriver(driver._id)
  }

  _renderViewDriver () {
    const { driver: { driver }, dotNumbers: { dotNumbers } } = this.props
    if (!driver || !dotNumbers) {
      return ''
    }

    const { emid, driversLicenseNumber, active, email, phoneNumber, dob, state, consentStatus, note, externalNoteView, queries } = driver

    const dotNumber = dotNumbers.find((d) => d._id === driver.dotNumber)
    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)

    const lastRequestedQuery = last(sortBy(queries || [], 'createdAt'))
    const lastCompletedQuery = last(sortBy((queries || []).filter(q => !!q.completed), 'completed'))

    return (
      <Fragment>
        <Row>
          <Col xs={12} lg={7}>
            {isConcordeAdmin && <Row>
              <Col xs={4} className='mb-1 text-right'>
                Employment ID
              </Col>
              <Col xs={8}>
                {emid}
              </Col>
            </Row>}
            <Row>
              <Col xs={4} className='mb-1 text-right'>
                Drivers license number
              </Col>
              <Col xs={8}>
                {driversLicenseNumber}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className='mb-1 text-right'>
                State
              </Col>
              <Col xs={8}>
                {state}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className='mb-1 text-right'>
                DOT
              </Col>
              <Col xs={8}>
                {dotNumber ? dotNumber.name : ''}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className='mb-1 text-right'>
                Active
              </Col>
              <Col xs={8}>
                {active ? '✓' : '✘'}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className='mb-1 text-right'>
                Email address
              </Col>
              <Col xs={8}>
                {email}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className='mb-1 text-right'>
                Phone Number
              </Col>
              <Col xs={8}>
                {phoneNumber}
              </Col>
            </Row>

            <Row>
              <Col xs={4} className='mb-1 text-right'>
                DOB
              </Col>
              <Col xs={8}>
                {displayDate(dob)}
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={5}>
            {lastRequestedQuery &&
              <Fragment>
                <Row>
                  <Col xs={4} lg={6} className='mb-1 text-right'>
                    Query request type
                  </Col>
                  <Col xs={8} lg={6}>
                    {displayQueryType(lastRequestedQuery.queryType)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} lg={6} className='mb-1 text-right'>
                    Query requested
                  </Col>
                  <Col xs={8} lg={6}>
                    {displayDateTime(lastRequestedQuery.createdAt, true)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} lg={6} className='mb-1 text-right'>
                    Query requested by
                  </Col>
                  <Col xs={8} lg={6}>
                    {(lastRequestedQuery.requestedBy) ? `${lastRequestedQuery.requestedBy.firstName} ${lastRequestedQuery.requestedBy.lastName}` : ''}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} lg={6} className='mb-1 text-right'>
                    Query status
                  </Col>
                  <Col xs={8} lg={6}>
                    {displayQueryStatus(lastRequestedQuery.status)}
                  </Col>
                </Row>
              </Fragment>
            }
            <Row>
              <Col xs={4} lg={6} className='mb-1 text-right'>
                Last completed query
              </Col>
              <Col xs={8} lg={6}>
                {lastCompletedQuery ? `${displayDate(lastCompletedQuery.completed, true)} (${displayQueryType(lastCompletedQuery.queryType)})` : ''}
              </Col>
            </Row>
            <Row>
              <Col xs={4} lg={6} className='mb-1 text-right'>
                Last consented
              </Col>
              <Col xs={8} lg={6}>
                {(consentStatus && consentStatus.lastCompleted) ? displayDate(consentStatus.lastCompleted, true) : ''}
              </Col>
            </Row>
            <Row>
              <Col xs={4} lg={6} className='mb-1 text-right'>
                Awaiting consent
              </Col>
              <Col xs={8} lg={6}>
                {(consentStatus && consentStatus.pending) ? 'Yes' : 'No'}
              </Col>
            </Row>
            <Row>
              <Col xs={4} lg={6} className='mb-1 text-right'>
                Last notified
              </Col>
              <Col xs={8} lg={6}>
                {(consentStatus && consentStatus.lastNotified) ? displayDate(consentStatus.lastNotified, true) : ''}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={7}>
            <Row>
              <Col xs={4} className='mb-1 text-right'>
                Consents and Documents
              </Col>
              {this._renderConsentSelect()}
            </Row>
            {(isConcordeAdmin || externalNoteView) &&
              <Row>
                <Col xs={4} className='mb-1 text-right'>
                  Note
                </Col>
                <Col xs={8} lg={6}>
                  {note}
                </Col>
              </Row>
            }
            {isConcordeAdmin &&
              <Row>
                <Col xs={4} className='mb-1 text-right'>
                  External View Note
                </Col>
                <Col xs={8}>
                  {externalNoteView ? '✓' : '✘'}
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Fragment>
    )
  }

  _renderConsentSelect () {
    const { driver: { driver, loading, error } } = this.props
    if (!driver) {
      return ''
    }
    if (loading) {
      return (
        <Col xs={8}>
          <Spinner animation='border' size='sm' className='mr-2' />
          Loading
        </Col>
      )
    }
    if (error) {
      return (
        <Col xs={8} className='alert alert-danger'>
          {error}
        </Col>
      )
    }
    const completedConsents = selectors.driver.getCompletedConsents(this.props)
    const driverDocuments = selectors.driver.getDriverDocuments(this.props)
    if (isEmpty(completedConsents) && isEmpty(driverDocuments)) {
      return (
        <Col xs={8}>
          <em>None</em>
        </Col>
      )
    }

    const options = [
      <option key='default' value=''>Select a consent/document</option>,
      completedConsents.map((c) => (
        <option
          key={c._id}
          value={'CONSENT%%'+ c._id}
        >Consent: {moment.utc(c.completed).local().format('DD MMMM YYYY')}</option>
      )),
      <option key='separator' value=''>--------------------------------</option>,
      driverDocuments.map((d) => (
        <option
          key={d._id}
          value={'DOCUMENT%%' + d.serverFileName}
        >{d.documentType}: {moment.utc(d.updatedAt).local().format('DD MMMM YYYY')}</option>
      ))
    ]

    return (
      <Col xs={8}>
        <Form.Control
          as='select'
          size='sm'
          value={this.state.selectedFileName}
          onChange={(e) => this._handleConsentSelect(e.target.value)}
        >{options.map((o) => o)}
        </Form.Control>
      </Col>
    )
  }

  _handleConsentSelect (value) {
    const { driver: { driver } } = this.props
    this.setState({ selectedFileName: value })
    if (value === '') {
      this.props.setConsent({ consent: null, pdf: null, error: null })
      this.props.setDocument()
      this.props.setError(null)
      return
    }
    if (value.includes('CONSENT%%')) {
      this.props.setDocument()
      this.props.getConsent(value.replace('CONSENT%%', ''))
    }
    else {
      this.props.setConsent({ consent: null, pdf: null, error: null })
      this.props.getDocument(driver._id, value.replace('DOCUMENT%%', ''))
    }
  }

  _renderConsent () {
    const { consent, driverDocuments } = this.props
    if (consent.loading || driverDocuments.loadingDocument) {
      return <Col xs={12} className='d-flex justify-content-around'>
        <div>
          <Spinner animation='border' size='sm' className='mr-2' />
          Loading
        </div>
      </Col>
    }
    if (consent.error) {
      return <Col xs={12} className='alert alert-danger'>{consent.error}</Col>
    }
    if (driverDocuments.error) {
      return <Col xs={12} className='alert alert-danger'>{driverDocuments.error}</Col>
    }
    if (!consent.consent && !driverDocuments.document) {
      return ''
    }
    if (!consent.pdf && !driverDocuments.document) {
      return <Col xs={12} className='alert alert-danger'>Error: no pdf found for the consent.</Col>
    }
    return <Container>
      <PdfDocument
        consent={consent.consent ? consent.consent : {}}
        pdf={consent.pdf ? consent.pdf : driverDocuments.document}
        emailButton=''
      />
    </Container>
  }

  render () {
    const { driver: { driver }, done } = this.props

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo' />
            {fullName(driver)}
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Edit'
              onClick={() => done({ showEditDriver: true })}
            ><FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderViewDriver()}
            <hr className='mb-3' />
            <Row>
              {this._renderConsent()}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => done()}
          >Done</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, dotNumbers, driver, consent, driverDocuments } = state
  return { auth, dotNumbers, driver, consent, driverDocuments }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver, ...actions.consent, ...actions.driverDocuments },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewDriver)

ViewDriver.propTypes = {
  done: PropTypes.func.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  driver: PropTypes.object.isRequired,
  consent: PropTypes.object.isRequired,
  driverDocuments: PropTypes.object.isRequired,
  getDriver: PropTypes.func.isRequired,
  setConsent: PropTypes.func.isRequired,
  setDocument: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  getConsent: PropTypes.func.isRequired,
  getDocument: PropTypes.func.isRequired
}
