import {
  Button,
  Row,
  Col,
  Form,
  Container,
  Image,
  Modal,
  Spinner
} from 'react-bootstrap'
import logo from '../../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import { fullName } from '../../../utils'

import * as actions from '../../../actions'

class CreateConsent extends Component {
  state = {
    buttonText: 'Send Email',
    isFirstChecked: true
  }

  componentDidMount () {
    this.props.setCreatedConsent()
    this.props.setMarkedConsentOnFile()
    this.props.setError()
  }

  componentDidUpdate () {
    const { driver: { createdConsent, markedConsentOnFile } } = this.props
    if (createdConsent || markedConsentOnFile) {
      this.props.done()
    }
  }

  _onRadioButtonChange (checkedId) {
    if (checkedId && checkedId === 'custom-radio-consent-filed')
    {
      this.setState({
        buttonText: 'Confirm',
        isFirstChecked: false
      })
    }
    else
    {
      this.setState({
        buttonText: 'Send Email',
        isFirstChecked: true
      })
    }
  }

  _renderButtonContents () {
    const { driver: { loading } } = this.props
    if (!loading)
    {
      return this.state.buttonText
    }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Saving
    </React.Fragment>
  }

  _consentEmailOptionText (driver) {
    if (!driver) {return ''}

    const emailText = (driver.email) ? `A notification email will be sent to ${driver.email}.` : 'This driver doesn\'t have an email address, so they won\'t be notified.'
    return `Create a new consent request for ${fullName(driver)}.  ` + emailText
  }

  _renderError (error) {
    if (!error) { return '' }
    return <Row>
      <Col className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  _processRequest (driver) {
    if (this.state.isFirstChecked === true)
    {
      this.props.createConsent(driver._id)
    }
    else
    {
      this.props.markConsentOnFile(driver._id)
    }
  }

  render () {
    const { driver: { driver, loading, error }, done } = this.props
    if (!driver) { return '' }

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Create a new consent request
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError(error)}
            <b>Select an option below:</b>
            <Form.Check
              custom
              label={this._consentEmailOptionText(driver)}
              type='radio'
              id='custom-radio-email-consent'
              name='consentType'
              checked={this.state.isFirstChecked}
              onClick={(rb) => this._onRadioButtonChange(rb.target.id)}
            />
            <br></br>
            <Form.Check
              custom
              label={`A consent request is already on file for ${fullName(driver)}.  Limited queries can be run for this driver.`}
              type='radio'
              id='custom-radio-consent-filed'
              name='consentType'
              checked={!this.state.isFirstChecked}
              onClick={(rb) => this._onRadioButtonChange(rb.target.id)}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className={'btn-semi-wide'}
            onClick={() => this.props.done()}
          >Cancel
          </Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={loading}
            className='btn-wide'
            onClick={() => this._processRequest(driver)}
          >{this._renderButtonContents()}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { driver } = state
  return { driver }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateConsent)

CreateConsent.propTypes = {
  done: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  setCreatedConsent: PropTypes.func.isRequired,
  setMarkedConsentOnFile: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  createConsent: PropTypes.func.isRequired,
  markConsentOnFile: PropTypes.func.isRequired
}
