import axios from 'axios'
import qs from 'qs'
import FormData from 'form-data'
import { isTestEnvironment } from '../utils'

export const requestDriverConsentExists = async ({ uuid }) => {
  try {
    const response = await axios.request({
      url: `/api/v1/driver/consents/uuid/${uuid}/exists`,
      method: 'get'
    })
    return response.data
  } catch (error) {
    logError('requestDriverConsent', error)
    return error.response.data
  }
}

export const requestDriverConsent = async ({ uuid, driversLicenseNumber, dob }) => {
  try {
    const response = await axios.request({
      url: `/api/v1/driver/consents/uuid/${uuid}`,
      method: 'get',
      params: {
        driversLicenseNumber,
        dob
      }
    })
    return response.data
  } catch (error) {
    logError('requestDriverConsent', error)
    return error.response.data
  }
}

export const requestCompleteDriverConsent = async (data) => {
  try {
    const response = await axios.request({
      url: `/api/v1/driver/consents/uuid/${data.uuid}/complete`,
      method: 'post',
      data
    })
    return response.data
  } catch (error) {
    logError('completeDriverConsent', error)
    return error.response.data
  }
}

export const requestEmailDriverConsent = async (data) => {
  try {
    const response = await axios.request({
      url: `/api/v1/driver/consents/uuid/${data.uuid}/email`,
      method: 'post',
      data
    })
    return response.data
  } catch (error) {
    logError('emailDriverConsent', error)
    return error.response.data
  }
}

export const sendEmail = async (message) => {
  try {
    const response = await axios.request({
      url: '/api/v1/contact',
      method: 'post',
      data: message
    })
    return response.data
  } catch (error) {
    logError('sendEmail', error)
    return error.response.data
  }
}

export const requestAuthUser = async (data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/auth',
      method: 'post',
      data
    })
    return response.data
  } catch (error) {
    logError('requestAuthUser', error)
    return error.response.data
  }
}

export const requestForgot = async (data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/auth/forgot',
      method: 'post',
      data
    })
    return response.data
  } catch (error) {
    logError('requestForgot', error)
    return error.response.data
  }
}

export const requestReset = async (data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/auth/reset',
      method: 'post',
      data
    })
    return response.data
  } catch (error) {
    logError('requestReset', error)
    return error.response.data
  }
}

export const requestDrivers = async (token, params) => {
  try {
    const response = await axios.request({
      url: '/api/v1/admin/drivers',
      method: 'get',
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestDrivers', error)
    return error.response.data
  }
}

export const requestDotNumbers = async (token, params) => {
  try {
    const response = await axios.request({
      url: '/api/v1/admin/dotNumbers',
      method: 'get',
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestDotNumbers', error)
    return error.response.data
  }
}

export const requestGetDOTNumber = async (token, dotNumberId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/dotNumbers/${dotNumberId}`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetDOTNumber', error)
    return error.response.data
  }
}

export const requestUpdateDOTNumber = async (token, dotNumberId, data) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/dotNumbers/${dotNumberId}`,
      method: 'put',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestUpdateDOTNumber', error)
    return error.response.data
  }
}




export const requestCreateConsent = async (token, driverId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/createConsent`,
      method: 'post',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateConsent', error)
    return error.response.data
  }
}

export const requestMarkConsentOnFile = async (token, driverId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/markConsentOnFile`,
      method: 'post',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestMarkConsentOnFile', error)
    return error.response.data
  }
}

export const requestCreateConsents = async (token, data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/admin/drivers/createConsents',
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateConsents', error)
    return error.response.data
  }
}

export const requestSendNotification = async (token, driverId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/sendNotification`,
      method: 'post',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestSendNotification', error)
    return error.response.data
  }
}

export const requestSendNotifications = async (token, data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/admin/drivers/sendNotifications',
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestSendNotifications', error)
    return error.response.data
  }
}

export const requestCreateQuery = async (token, driverId, queryType, isConcordeAdmin) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/createQuery`,
      method: 'post',
      data: { queryType, isConcordeAdmin },
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateQuery', error)
    return error.response.data
  }
}

export const requestCreateQueries = async (token, data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/admin/drivers/createQueries',
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateQueries', error)
    return error.response.data
  }
}

export const requestCreateDriverExport = async (token, data) => {
  try {

    const response = await axios.request({
      url: '/api/v1/admin/drivers/createDriverExport',
      method: 'post',
      data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      headers: getHeaders(token)
    })

    return response.data
  } catch (error) {
    logError('requestDrivers', error)
    return error.response.data
  }
}


export const requestConsentUrl = async (token, driverId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/getConsentUrl`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestConsentUrl', error)
    return error.response.data
  }
}

export const requestGetDocumentListSparcMissing = async (token) => {
  try {
    const response = await axios.request({
      url: '/api/v1/admin/driverDocuments/sparcMissingDocuments',
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetDocumentListSparcMissing', error)
    return error.response.data
  }
}
export const requestGetDocumentList = async (token, driverId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/documents`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetDocumentList', error)
    return error.response.data
  }
}

export const requestGetDocument = async (token, driverId, documentId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/documents/${documentId}`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetDocument', error)
    return error.response.data
  }
}

export const requestDeleteDocument = async (token, driverId, documentId, userName) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/documents/${documentId}`,
      method: 'delete',
      data: { userName },
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestDeleteDocument', error)
    return error.response.data
  }
}

export const requestGetDriver = async (token, driverId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetDriver', error)
    return error.response.data
  }
}

export const requestCreateDriver = async (token, data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/admin/drivers',
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateDriver', error)
    return error.response.data
  }
}

export const requestUpdateDriver = async (token, driverId, data) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}`,
      method: 'put',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestUpdateDriver', error)
    return error.response.data
  }
}

export const requestUploadDriverDocument = async (token, driverId, data, isConcordeAdmin, userName) => {
  try {
    const formData = new FormData()
    formData.append('documentType', data.documentType)
    formData.append('upload', data.file)
    formData.append('isConcordeAdmin', isConcordeAdmin)
    formData.append('userName', userName)
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/documents`,
      method: 'post',
      data: formData,
      headers: getHeaders(token, { 'content-type': `multipart/form-data; boundary=${formData._boundary}` })
    })
    return response.data
  } catch (error) {
    logError('requestUploadDriverDocument', error)
    return error.response.data
  }
}

export const requestUploadDriverDocumentToSparc = async (token, driverId, documentId, data, isConcordeAdmin, userName) => {
  try {
    const formData = new FormData()
    formData.append('documentType', data.documentType)
    formData.append('upload', data.file)
    formData.append('isConcordeAdmin', isConcordeAdmin)
    formData.append('userName', userName.upload)
    const response = await axios.request({
      url: `/api/v1/admin/drivers/${driverId}/documentsToSparc/${documentId}`,
      method: 'post',
      data: formData,
      headers: getHeaders(token, { 'content-type': `multipart/form-data; boundary=${formData._boundary}` })
    })
    return response.data
  } catch (error) {
    logError('requestUploadDriverDocumentToSparc', error)
    return error.response.data
  }
}

export const requestGetConsent = async (token, consentId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/admin/consents/${consentId}`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetConsent', error)
    return error.response.data
  }
}

export const requestConsentContent = async (token) => {
  try {
    const response = await axios.request({
      url: '/api/v1/companyAdmin/consentContent',
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestConsentContent', error)
    return error.response.data
  }
}

export const requestUploadRosters = async (token, file) => {
  try {
    const form = new FormData()
    form.append('rosters', file)

    const response = await axios.request({
      url: '/api/v1/admin/rosters/upload',
      method: 'post',
      data: form,
      headers: getHeaders(token, { 'content-type': `multipart/form-data; boundary=${form._boundary}` })
    })
    return response.data
  } catch (error) {
    logError('requestUploadRosters', error)
    return error.response.data
  }
}

export const requestProcessFMCSAList = async (token, file) => {
  try {
    const form = new FormData()
    form.append('records', file)

    const response = await axios.request({
      url: '/api/v1/admin/drivers/processFMCSAList',
      method: 'post',
      data: form,
      headers: getHeaders(token, { 'content-type': `multipart/form-data; boundary=${form._boundary}` })
    })
    return response.data
  } catch (error) {
    logError('requestProcessFMCSAList', error)
    return error.response.data
  }
}

export const requestPerformQuery = async (token, driverId, data) => {
  try {
    const response = await axios.request({
      url: `/api/v1/concordeAdmin/drivers/${driverId}/performQuery`,
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestPerformQuery', error)
    return error.response.data
  }
}

export const requestUpdateQueryStatuses = async (token, data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/concordeAdmin/drivers/updateQueryStatuses',
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestUpdateQueryStatuses', error)
    return error.response.data
  }
}

export const requestCompanies = async (token) => {
  try {
    const response = await axios.request({
      url: '/api/v1/concordeAdmin/companies',
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCompanies', error)
    return error.response.data
  }
}

export const requestCreateCompany = async (token, data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/concordeAdmin/companies',
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateCompany', error)
    return error.response.data
  }
}

export const requestUpdateCompany = async (token, companyId, data) => {
  try {
    const response = await axios.request({
      url: `/api/v1/concordeAdmin/companies/${companyId}`,
      method: 'put',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestUpdateCompany', error)
    return error.response.data
  }
}

export const requestGetCompany = async (token, companyId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/concordeAdmin/companies/${companyId}`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetCompany', error)
    return error.response.data
  }
}

export const requestCreateUser = async (token, companyId, data) => {
  try {
    const response = await axios.request({
      url: `/api/v1/concordeAdmin/companies/${companyId}/createUser`,
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateUser', error)
    return error.response.data
  }
}

export const requestCreateAccountOptions = async () => {
  try {
    const response = await axios.request({
      url: '/api/v1/ownerOperator/accounts/options',
      method: 'get'
    })
    return response.data
  } catch (error) {
    logError('requestCreateAccountOptions', error)
    return error.response.data
  }
}

export const requestCreateAccountValidateUserInfo = async (data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/ownerOperator/accounts/validateUserInfo',
      method: 'post',
      data
    })
    return response.data
  } catch (error) {
    logError('requestCreateAccountValidateUserInfo', error)
    return error.response.data
  }
}

export const requestCreateAccount = async (data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/ownerOperator/accounts',
      method: 'post',
      data
    })
    return response.data
  } catch (error) {
    logError('requestCreateAccount', error)
    return error.response.data
  }
}

export const requestAccount = async (token) => {
  try {
    const response = await axios.request({
      url: '/api/v1/ownerOperator/accounts',
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateAccount', error)
    return error.response.data
  }
}

export const requestUpdateAccount = async (token, data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/ownerOperator/accounts',
      method: 'put',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestUpdateAccount', error)
    return error.response.data
  }
}

export const requestCreateAccountPaymentMethod = async (token, data) => {
  try {
    const response = await axios.request({
      url: '/api/v1/ownerOperator/accounts/paymentMethod',
      method: 'post',
      data,
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCreateAccountPaymentMethod', error)
    return error.response.data
  }
}

export const requestCancelSubscription = async (token) => {
  try {
    const response = await axios.request({
      url: '/api/v1/ownerOperator/accounts/subscription',
      method: 'delete',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestCancelSubscription', error)
    return error.response.data
  }
}

export const requestGetOwnerOperatorDriver = async (token, driverId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/ownerOperator/drivers/${driverId}`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetOwnerOperatorDriver', error)
    return error.response.data
  }
}

export const requestGetOwnerOperatorConsent = async (token, consentId) => {
  try {
    const response = await axios.request({
      url: `/api/v1/ownerOperator/consents/${consentId}`,
      method: 'get',
      headers: getHeaders(token)
    })
    return response.data
  } catch (error) {
    logError('requestGetOwnerOperatorConsent', error)
    return error.response.data
  }
}

const getHeaders = (token, headerProps = {}) => {
  return { Authorization: `Bearer ${token}`, ...headerProps }
}

const logError = (msg, error) => {
  if (isTestEnvironment()) { return }
  if (error.response.status && error.response.data) {
    console.error(`Error in ${msg}`, error.response.status, error.response.data)
  }
}