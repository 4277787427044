import { actionTypes } from './actionTypes'
import { requestUploadRosters } from '../services/apiService'
import { drivers } from './drivers'

const rosters = {
  setError: (value) => ({
    type: actionTypes.ROSTERS.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.ROSTERS.SET_LOADING,
    value
  }),

  setUploaded: (value) => ({
    type: actionTypes.ROSTERS.SET_UPLOADED,
    value
  }),

  upload: (file) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(rosters.setLoading(true))
      dispatch(rosters.setError())
      const data = await requestUploadRosters(token, file)
      if (data && data.uploaded) {
        dispatch(rosters.setUploaded(data))
        dispatch(drivers.reloadDrivers())
      } else if (data && data.error) {
        dispatch(rosters.setError(data.error))
      }
      dispatch(rosters.setLoading(false))
    }
  }
}

export { rosters }
