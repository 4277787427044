import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CardElement, injectStripe } from 'react-stripe-elements'

import { displayCurrency } from '../../../utils'

import {
  Button,
  Col,
  Form,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import HelpTooltip from '../../common/helpTooltip'

import * as actions from '../../../actions'
import * as selectors from '../../../selectors'

class PaymentForm extends Component {
  async _handleOnSubmit () {
    const { stripe } = this.props
    if (!stripe) { return }

    this.props.setError()
    this.props.setLoading(true)

    // try to create a stripe payment method now
    const result = await stripe.createPaymentMethod({
      type: 'card', card: this.props.elements.getElement('card')
    })
    if (result && result.error) {
      this.props.setError(result.error.message)
      this.props.setLoading(false)
      return
    }
    // try to create the account now
    const paymentMethod = result ? result.paymentMethod : null
    this.props.create({ ...this.props.createAccount, paymentMethod })
  }

  _renderMessage () {
    const { createAccount: { error } } = this.props
    if (!error) { return }
    return (
      <Col xs={12} className='mb-3 alert alert-danger'>
        <span>{error}</span>
      </Col>
    )
  }

  _renderButtonContents () {
    const { createAccount: { loading } } = this.props

    if (loading) {
      return (
        <React.Fragment>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Processing
        </React.Fragment>
      )
    }
    return 'Pay and continue'
  }

  _renderPaymentInformation () {
    const { stripe } = this.props
    if (!stripe) { return }

    return (
      <Form.Group>
        <Form.Label>Payment information</Form.Label>
        <CardElement
          className='form-control'
          style={{ base: { fontSize: '16px' } }}
        />
      </Form.Group>
    )
  }

  render () {
    const { stripe, createAccount: { loading } } = this.props

    return (
      <React.Fragment>
        <Col xs={12} className='mt-4'>
          <div className='d-flex flex-column bg-light3 p-3'>
            {this._renderPaymentInformation()}
            <div>
              <FontAwesomeIcon icon={faLock}/>
              <span className='ml-2'>Drivers Clearinghouse does not store your payment information.
                <HelpTooltip
                  content={<span>Transactions are processed and information is stored by a PCI-compliant vendor.</span>}
                />
              </span>
            </div>
          </div>
        </Col>
        <Col xs={12} className='mt-4'>
          <p>By continuing, you confirm that the above information you have entered is correct and that you agree to pay <strong>{displayCurrency(selectors.createAccount.getTotalPrice(this.props))} USD</strong> now, and <strong>{displayCurrency(selectors.createAccount.getSubscriptionPrice(this.props))} USD</strong> annually thereafter while your subscription is active (plus applicable taxes).</p>
          <p>You can cancel your annual subscription renewal at any time.
            <HelpTooltip
              content='If you cancel your subscription, you&apos;ll still remain covered by the plan until the subscription renewal date.'
            />
          </p>
        </Col>
        {this._renderMessage()}
        <Col xs={4} className='mt-2 mb-3'>
          <Button
            variant='secondary'
            block
            onClick={() => this.props.navigateToOwnerOperatorCreateAccountSignConsent()}
          >Back</Button>
        </Col>
        <Col xs={8} className='mt-2 mb-3'>
          <Button
            variant='primary'
            block
            disabled={!stripe || loading}
            onClick={async () => await this._handleOnSubmit()}
          >{this._renderButtonContents()}</Button>
        </Col>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { createAccount } = state
  return { createAccount }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.createAccount, ...actions.navigation },
    dispatch
  )
}

PaymentForm.propTypes = {
  createAccount: PropTypes.object.isRequired,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  navigateToOwnerOperatorCreateAccountSignConsent: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(PaymentForm))
