import { actionTypes } from '../actions/actionTypes'

const initialState = {
  createdConsents: null,
  processedFMCSAList: null,
  sentNotifications: null,
  createdQueries: null,
  createdDriverExport: null,
  loading: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.DRIVERS_BULK_ACTIONS.SET_CREATED_CONSENTS:
    return { ...state, createdConsents: action.value }
  case actionTypes.DRIVERS_BULK_ACTIONS.SET_PROCESSED_FMCSA_LIST:
    return { ...state, processedFMCSAList: action.value }
  case actionTypes.DRIVERS_BULK_ACTIONS.SET_SENT_NOTIFICATIONS:
    return { ...state, sentNotifications: action.value }
  case actionTypes.DRIVERS_BULK_ACTIONS.SET_CREATED_QUERIES:
    return { ...state, createdQueries: action.value }
  case actionTypes.DRIVERS_BULK_ACTIONS.SET_CREATED_DRIVER_EXPORT:
    return { ...state, createdDriverExport: action.value }
  case actionTypes.DRIVERS_BULK_ACTIONS.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.DRIVERS_BULK_ACTIONS.SET_ERROR:
    return { ...state, error: action.value }
  default:
    return state
  }
}