import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import AuthenticatedRoute from './components/common/authenticatedRoute'

// driver routes
import ConsentValidate from './components/driver/consentValidate'
import ConsentReview from './components/driver/consentReview'
import ConsentPrint from './components/driver/consentPrint'

// company admin routes
import CompanyAdminHome from './components/companyAdmin/home'
import CompanyAdminDriverRosters from './components/companyAdmin/driverRosters'
import ConsentContent from './components/companyAdmin/consentContent'
import CompanyAdminDOTRosters from './components/companyAdmin/dotRosters'

// owner operator routes
import OwnerOperatorCreateAccount from './components/ownerOperator/createAccount'
import OwnerOperatorAccount from './components/ownerOperator/account'

// concorde admin routes
import ConcordeAdminHome from './components/concordeAdmin/home'
import Companies from './components/concordeAdmin/companies'
import ConcordeAdminDriverRosters from './components/concordeAdmin/driverRosters'
import ConcordeAdminDOTRosters from './components/concordeAdmin/dotRosters'
import ConcordeAdminTasksErrors from './components/concordeAdmin/tasksErrors'

// other routes
import Contact from './components/contact'
import Login from './components/login'
import ForgotPassword from './components/forgotPassword'
import PasswordReset from './components/passwordReset'
import PrivacyPolicy from './components/privacyPolicy'
import TermsOfUse from './components/termsOfUse'
import NotFound from './components/notFound'
import CacheBuster from './cacheBuster'

class App extends Component {
  render () {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload()
          }

          return (
            <div className='App'>
              <Switch>
                <Route exact path='/driver-consent/:uuid' component={ConsentValidate}/>
                <Route exact path='/driver-consent/:uuid/review' component={ConsentReview}/>
                <Route exact path='/driver-consent/:uuid/print' component={ConsentPrint}/>

                <Route exact path='/company-admin/home'>
                  <AuthenticatedRoute>
                    <CompanyAdminHome/>
                  </AuthenticatedRoute>
                </Route>
                <Route exact path='/company-admin/driver-roster'>
                  <AuthenticatedRoute>
                    <CompanyAdminDriverRosters/>
                  </AuthenticatedRoute>
                </Route>
                <Route exact path='/company-admin/consent-content'>
                  <AuthenticatedRoute>
                    <ConsentContent/>
                  </AuthenticatedRoute>
                </Route>

                <Route exact path='/company-admin/dot-roster'>
                  <AuthenticatedRoute>
                    <CompanyAdminDOTRosters/>
                  </AuthenticatedRoute>
                </Route>
                <Route exact path='/owner-operator/create-account/user-info' component={OwnerOperatorCreateAccount.UserInfo} />
                <Route exact path='/owner-operator/create-account/service-questions' component={OwnerOperatorCreateAccount.ServiceQuestions} />
                <Route exact path='/owner-operator/create-account/sign-consent' component={OwnerOperatorCreateAccount.SignConsent} />
                <Route exact path='/owner-operator/create-account/payment' component={OwnerOperatorCreateAccount.Payment} />
                <Route exact path='/owner-operator/account'>
                  <AuthenticatedRoute>
                    <OwnerOperatorAccount/>
                  </AuthenticatedRoute>
                </Route>
                <Route path='/owner-operator'>
                  <Redirect to='/owner-operator/create-account/user-info'/>
                </Route>

                <Route exact path='/concorde-admin/home'>
                  <AuthenticatedRoute>
                    <ConcordeAdminHome/>
                  </AuthenticatedRoute>
                </Route>
                <Route exact path='/concorde-admin/companies'>
                  <AuthenticatedRoute>
                    <Companies/>
                  </AuthenticatedRoute>
                </Route>
                <Route exact path='/concorde-admin/driver-roster'>
                  <AuthenticatedRoute>
                    <ConcordeAdminDriverRosters/>
                  </AuthenticatedRoute>
                </Route>
                <Route exact path='/concorde-admin/dot-roster'>
                  <AuthenticatedRoute>
                    <ConcordeAdminDOTRosters/>
                  </AuthenticatedRoute>
                </Route>
                <Route exact path='/concorde-admin/tasks-errors'>
                  <AuthenticatedRoute>
                    <ConcordeAdminTasksErrors/>
                  </AuthenticatedRoute>
                </Route>
                <Route exact path='/'>
                  <Redirect to='/login'/>
                </Route>
                <Route exact path='/contact' component={Contact}/>
                <Route exact path='/login' component={Login}/>
                <Route exact path='/forgot-password' component={ForgotPassword}/>
                <Route exact path='/password-reset/:passwordResetToken' component={PasswordReset}/>
                <Route exact path='/privacy-policy' component={PrivacyPolicy}/>
                <Route exact path='/terms-of-use' component={TermsOfUse}/>
                <Route component={NotFound} />
              </Switch>
            </div>
          )
        }}
      </CacheBuster>
    )
  }
}

const mapStateToProps = (state) => {
  const { router } = state
  return { router }
}

export default connect(mapStateToProps)(App)

App.propTypes = {
  router: PropTypes.object.isRequired
}
