import { actionTypes } from './actionTypes'
import { navigation } from './navigation'

import {
  requestCreateAccountOptions,
  requestCreateAccountValidateUserInfo,
  requestCreateAccount
} from '../services/apiService'

const createAccount = {
  setUserInfo: (value) => ({
    type: actionTypes.CREATE_ACCOUNT.SET_USER_INFO,
    value
  }),

  setServiceQuestions: (value) => ({
    type: actionTypes.CREATE_ACCOUNT.SET_SERVICE_QUESTIONS,
    value
  }),

  setConsent: (value) => ({
    type: actionTypes.CREATE_ACCOUNT.SET_CONSENT,
    value
  }),

  setOptions: (value) => ({
    type: actionTypes.CREATE_ACCOUNT.SET_OPTIONS,
    value
  }),

  setAccountCreated: (value) => ({
    type: actionTypes.CREATE_ACCOUNT.SET_ACCOUNT_CREATED,
    value
  }),

  setError: (value) => ({
    type: actionTypes.CREATE_ACCOUNT.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.CREATE_ACCOUNT.SET_LOADING,
    value
  }),

  getOptions: () => {
    return async (dispatch) => {
      dispatch(createAccount.setLoading(true))
      dispatch(createAccount.setError())
      const data = await requestCreateAccountOptions()
      if (data && !data.error) {
        dispatch(createAccount.setOptions(data))
      } else if (data && data.error) {
        dispatch(createAccount.setError(data.error))
      }
      dispatch(createAccount.setLoading(false))
    }
  },

  validateUserInfo: (userInfo) => {
    return async (dispatch) => {
      dispatch(createAccount.setLoading(true))
      dispatch(createAccount.setError())
      const data = await requestCreateAccountValidateUserInfo(userInfo)
      if (data && !data.error) {
        dispatch(createAccount.setUserInfo(data))
        dispatch(navigation.navigateToOwnerOperatorCreateAccountServiceQuestions())
      } else if (data && data.error) {
        dispatch(createAccount.setError(data.error))
      }
      dispatch(createAccount.setLoading(false))
    }
  },

  create: (params) => {
    return async (dispatch) => {
      dispatch(createAccount.setLoading(true))
      dispatch(createAccount.setError())
      const data = await requestCreateAccount(params)
      if (data && !data.error && data.created) {
        dispatch(createAccount.setAccountCreated(data.created))
        if (data.user) {
          dispatch(navigation.navigateToPasswordReset(data.user.passwordResetToken))
        }
      } else if (data && data.error) {
        dispatch(createAccount.setError(data.error))
      }
      dispatch(createAccount.setLoading(false))
    }
  }
}

export { createAccount }
