import React, { Component } from 'react'
import NavHeader from './common/navHeader'
import DOTSTable from '../common/dotsTable/dotsTable'


class DOTRosters extends Component {
  render () {
    return (
      <div className='d-flex flex-column w-100 h-100'>
        <NavHeader/>
        <DOTSTable/>
      </div>
    )
  }
}

export default DOTRosters