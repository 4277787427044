import moment from 'moment'

const account = {
  isAccountActive (state) {
    return state.account.subscription && ['active', 'past_due'].includes(state.account.subscription.status)
  },

  hasSubscriptionBeenCanceled (state) {
    const { account: { subscription } } = state
    if (!subscription) { return false }
    return !!subscription.cancel_at_period_end
  },

  /**
   * Coverage starts at the start date of the subscription.
   * Coverage ends at the end of the quarter containing the subscription end,
   * or the end date if the subscription has ended
   */
  getSubscriptionCoveragePeriod (state) {
    const { account: { subscription } } = state
    if (!subscription) { return {} }
    const { current_period_start, current_period_end, ended_at } = subscription
    const end = ended_at ? moment.utc(ended_at * 1000).toJSON() : moment.utc(current_period_end * 1000).endOf('quarter').toJSON()
    return {
      start: moment.utc(current_period_start * 1000).toJSON(),
      end
    }
  }
}

export { account }