import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import ModalBackdropForm from '../common/modalBackdropForm'
import * as actions from '../../actions'

class ConsentValidate extends Component {

  state = {
    uuid: null,
    driversLicenseNumber: '',
    dob: ''
  }

  componentDidMount () {
    const { driverConsent: { loading } } = this.props
    if (loading) { return }

    // if there is no uuid, return
    const { match: { params: { uuid } } } = this.props
    if (!uuid) {
      this.setExists(false)
      return
    }

    // make a request to see if the uuid exists in the db
    this.setState({ uuid })
    this.props.driverConsentExists({ uuid })
  }

  _renderLoadingForm () {
    return (
      <ModalBackdropForm displayHelpButton={true}>
        <div className='col-12 mb-3'>
          <div className='text-center'>
            <span className='spinner-border spinner-border-md align-middle'></span>
          </div>
        </div>
      </ModalBackdropForm>
    )
  }

  _renderNotFoundForm () {
    return (
      <ModalBackdropForm displayHelpButton={true}>
        <div className='col-12 mb-3'>
          There was no matching consent request found. Please contact your employer.
        </div>
      </ModalBackdropForm>
    )
  }

  _renderButtonContents = () => {
    const { driverConsent: { loading } } = this.props

    if (loading) {
      return (
        <React.Fragment>
          <span className='spinner-border spinner-border-sm align-middle mr-2'>
          </span>
          Loading
        </React.Fragment>
      )
    }
    return 'Continue'
  }

  _renderMessage = () => {
    const { driverConsent: { validationError } } = this.props
    if (validationError) {
      return (
        <div className='col-12 mb-3 alert alert-danger' id='problem-verifying'>
          <span>There was a problem verifying your information. Please check that you&apos;ve entered it correctly and try again.</span>
        </div>
      )
    }
    return (
      <div className='col-12 mb-3'>
        <span>Please enter your information</span>
      </div>
    )
  }

  _handleOnSubmit = () => {
    this.props.setLoading(true)
    this.props.getDriverConsent(this.state)
  }

  _renderVerifyForm () {
    const { driverConsent: { loading } } = this.props

    return (
      <ModalBackdropForm
        onSubmit={() => this._handleOnSubmit()}
        displayHelpLink={true}
      >
        {this._renderMessage()}
        <div className='col-12'>
          <div className='form-group'>
            <label htmlFor='drivers-license-number'>Drivers license number</label>
            <input type='text'
              className='form-control'
              id='drivers-license-number'
              value={this.state.driversLicenseNumber}
              onChange={(e) => this.setState({ driversLicenseNumber: e.target.value })}
            />
          </div>
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label htmlFor='date-of-birth'>Date of birth</label>
            <DatePicker
              selected={this.state.dob}
              maxDate={moment()}
              dateFormat='M/d/yyyy'
              placeholderText='mm/dd/yyyy'
              onChange={(date) => this.setState({ dob: date })}
              name='dob'
              className='form-control'
              id='date-of-birth' dropdownMode={'scroll'}/>
          </div>
        </div>
        <div className='col-12 my-3'>
          <button
            type='submit'
            disabled={loading}
            className='btn btn-primary btn-block'
          >{this._renderButtonContents()}</button>
        </div>
      </ModalBackdropForm>
    )
  }

  render () {
    const { driverConsent: { exists, loading } } = this.props

    // if loading, render the loading view
    if (loading) {
      return this._renderLoadingForm()
    }

    // if the uuid doesn't exist, render the not found form
    if (!exists) {
      return this._renderNotFoundForm()
    }

    // otherwise, render the verify form
    return this._renderVerifyForm()
  }
}

const mapStateToProps = (state, ownProps) => {
  const { driverConsent } = state
  const { match } = ownProps
  return { match, driverConsent }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.driverConsent, ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentValidate)

ConsentValidate.propTypes = {
  match: PropTypes.object.isRequired,
  driverConsent: PropTypes.object.isRequired,
  driverConsentExists: PropTypes.func.isRequired,
  getDriverConsent: PropTypes.func.isRequired,
  setExists: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}