import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { displayCurrency } from '../../../utils'

import {
  Col
} from 'react-bootstrap'
import ModalBackdropForm from '../../common/modalBackdropForm'
import ProgressBar from './common/progressBar'
import StripeLoader from '../common/stripeLoader'
import PaymentForm from './paymentForm'

import * as actions from '../../../actions'
import * as selectors from '../../../selectors'

class Payment extends Component {
  componentDidMount () {
    this.props.setError()
    const { userInfo, consent } = this.props.createAccount
    if (!userInfo) {
      this.props.navigateToOwnerOperatorCreateAccountUserInfo()
    }
    if (!consent) {
      this.props.navigateToOwnerOperatorCreateAccountSignConsent()
    }
  }

  _renderLineItems () {
    const { createAccount: { serviceQuestions } } = this.props
    if (!serviceQuestions) { return '' }

    const { fullQuery, initialTest } = serviceQuestions

    const lineItems = [(
      <div key='subscription' className='d-flex border-bottom py-2'>
        <div className='d-flex flex-grow-1 justify-content-start mr-5'>
          Annual limited query and continuous enrollment in a random drug and alcohol testing program.
        </div>
        <div className='d-flex flex-shrink-0 justify-content-start align-items-center' style={{ width: 150 }}>
          {`${displayCurrency(selectors.createAccount.getSubscriptionPrice(this.props))} / year`}
        </div>
      </div>
    )]

    if (fullQuery) {
      lineItems.push(
        <div key='fullQuery' className='d-flex border-bottom py-2'>
          <div className='d-flex flex-grow-1 justify-content-start mr-5'>
            One-time FMCSA full query.
          </div>
          <div className='d-flex flex-shrink-0 justify-content-start align-items-center' style={{ width: 150 }}>
            {displayCurrency(selectors.createAccount.getFullQueryPrice(this.props))}
          </div>
        </div>
      )
    }

    if (initialTest) {
      lineItems.push(
        <div key='initialTest' className='d-flex border-bottom py-2'>
          <div className='d-flex flex-grow-1 justify-content-start mr-5'>
            One-time initial drug test.
          </div>
          <div className='d-flex flex-shrink-0 justify-content-start align-items-center' style={{ width: 150 }}>
            {displayCurrency(selectors.createAccount.getInitialTestPrice(this.props))}
          </div>
        </div>
      )
    }

    return (
      <div className='d-flex flex-column px-3'>
        {lineItems.map((li) => li)}
        <div key='total' className='d-flex pt-2'>
          <div className='d-flex flex-grow-1 justify-content-end mr-5'>
            <strong>Total</strong>
          </div>
          <div className='d-flex flex-shrink-0 justify-content-start align-items-center' style={{ width: 150 }}>
            <strong>{displayCurrency(selectors.createAccount.getTotalPrice(this.props))}</strong>
          </div>
        </div>
      </div>
    )
  }

  _renderForm () {
    const { createAccount: { userInfo } } = this.props
    if (!userInfo) { return '' }

    return (
      <React.Fragment>
        <Col xs={12}>
          <ProgressBar
            title='Create account'
            subtitle='Step 4: please enter your payment information so we can complete creating your account.'
            percentDone={100}
          />
        </Col>
        <Col xs={12}>
          <div className='border-top pt-4 mb-3'>
            <strong>You are purchasing:</strong>
          </div>
          {this._renderLineItems()}
        </Col>
        <StripeLoader>
          <PaymentForm/>
        </StripeLoader>
      </React.Fragment>
    )
  }

  render () {
    return (
      <ModalBackdropForm
        sm={11}
        md={10}
        lg={9}
        displayHelpLink={true}
      >{this._renderForm()}</ModalBackdropForm>
    )
  }
}

const mapStateToProps = (state) => {
  const { createAccount } = state
  return { createAccount }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.createAccount, ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)

Payment.propTypes = {
  createAccount: PropTypes.object.isRequired,
  navigateToOwnerOperatorCreateAccountUserInfo: PropTypes.func.isRequired,
  navigateToOwnerOperatorCreateAccountSignConsent: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
