import { isEqual, isEmpty, pick } from 'lodash'
import { actionTypes } from './actionTypes'
import { dotNumbers } from './dotNumbers'

const dotNumbersFilters = {


  setPage: (value) => ({
    type: actionTypes.DOT_NUMBERS_FILTERS.SET_PAGE,
    value
  }),

  setSearch: (value) => ({
    type: actionTypes.DOT_NUMBERS_FILTERS.SET_SEARCH,
    value
  }),

  setSort: (value) => ({
    type: actionTypes.DOT_NUMBERS_FILTERS.SET_SORT,
    value
  }),
  setCompanies: (value) => ({
    type: actionTypes.DOT_NUMBERS_FILTERS.SET_COMPANIES,
    value
  }),
  setDOTNumbers: (value) => ({
    type: actionTypes.DOT_NUMBERS_FILTERS.SET_DOT_NUMBERS,
    value
  }),
  setSelectedDOTNumbers: (value) => ({
    type: actionTypes.DOT_NUMBERS_FILTERS.SET_SELECTED_DOT_NUMBERS,
    value
  }),


  updateSearch: (searchString = '') => {
    return async (dispatch, getState) => {
      const { dotNumbersFilters: { search: prevSearch } } = getState()
      const search = !searchString ? undefined : searchString.split(' ')
      dispatch(dotNumbersFilters.setSearch(search))
      if (!isEqual(search, prevSearch)) {
        dispatch(dotNumbersFilters.setPage(1))
        dispatch(dotNumbers.debouncedReloadDOTNumbers())
      }
    }
  },

  updateSort: (sorted = []) => {
    return (dispatch, getState) => {
      const { dotNumbersFilters: { sort: prevSort } } = getState()
      const sort = isEmpty(sorted) ? undefined : sorted.reduce((prev, curr) => {
        prev[curr.id] = curr.desc ? -1 : 1
        return prev
      }, {})
      dispatch(dotNumbersFilters.setSort(sort))
      if (!isEqual(sort, prevSort)) {
        dispatch(dotNumbersFilters.setPage(1))
        dispatch(dotNumbers.reloadDOTNumbers())
      }
    }
  },

  updatePage: (page = 1) => {
    return (dispatch, getState) => {
      const { dotNumbersFilters: { page: prevPage } } = getState()
      dispatch(dotNumbersFilters.setPage(page))
      if (!isEqual(page, prevPage)) {
        dispatch(dotNumbers.debouncedReloadDOTNumbers())
      }
    }
  },

  updateCompanies: (companies) => {
    return (dispatch, getState) => {
      const { dotNumbersFilters: { companies: prevCompanies } } = getState()
      dispatch(dotNumbersFilters.setCompanies(companies))
      if (!isEqual(companies, prevCompanies)) {
        dispatch(dotNumbersFilters.setPage(1))
        dispatch(dotNumbers.reloadDOTNumbers())
      }
    }
  },


  updateDotNumbers: (dotNumbers) => {
    return (dispatch, getState) => {
      const { dotNumbersFilters: { dotNumbers: prevDotNumbers } } = getState()
      dispatch(dotNumbersFilters.setDOTNumbers(dotNumbers))
      if (!isEqual(dotNumbers, prevDotNumbers)) {
        dispatch(dotNumbersFilters.setPage(1))
        console.log(`bbbbbbb updateDotNumbers ${JSON.stringify(dotNumbers) } `)
        dispatch(dotNumbers.reloadDOTNumbers())
      }
    }
  },

  updateFilters: (filters) => {
    return (dispatch, getState) => {
      const { dotNumbersFilters: prevFilters } = getState()
      dispatch(dotNumbersFilters.setCompanies(filters.companies))
      dispatch(dotNumbersFilters.setDOTNumbers(filters.dotNumbers))
      if (!isEqual(
        filters,
        pick(prevFilters, [
          'companies','dotNumbers'
        ])
      )) {
        dispatch(dotNumbersFilters.setPage(1))
        dispatch(dotNumbers.reloadDOTNumbers())
      }
    }
  },

  clearFilters: () => {
    return (dispatch) => {
      dispatch(dotNumbersFilters.updateFilters({
        companies: [], dotNumbers: []
      }))
    }
  }
}

export { dotNumbersFilters }