import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row
} from 'react-bootstrap'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

class CreateCompany extends Component {
  state = { companyName: this.props.companyName }

  handleChange = event =>
    this.setState({ companyName: event.target.value })

  render () {
    const { hide, next } = this.props
    return (
      <Modal
        show={true}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo' />
            Add a new company
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => hide()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Company Name<span style={{ color: 'red' }}> *</span></Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  defaultValue={this.state.companyName}
                  onChange={this.handleChange}
                ></Form.Control>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => hide()}
          >Cancel</Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={!this.state.companyName || this.state.companyName.trim() === ''}
            className='btn-wide'
            onClick={() => next(this.state.companyName.trim())}
          >Next</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect()(CreateCompany)

CreateCompany.propTypes = {
  hide: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  companyName: PropTypes.string
}
