import React, { Component } from 'react'

import NavHeader from './common/navHeader'
import DriversTable from '../common/driversTable/driversTable'

class DriverRosters extends Component {
  render () {
    return (
      <div className='d-flex flex-column w-100 h-100'>
        <NavHeader/>
        <DriversTable/>
      </div>
    )
  }
}

export default DriverRosters
