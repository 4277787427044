import { actionTypes } from '../actions/actionTypes'

const initialState = {
  driver: null,
  company: null,
  dotNumber: null,
  accountEdited: null,
  loading: null,
  error: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.ACCOUNT.SET_ACCOUNT:
    return { ...state, ...action.value }
  case actionTypes.ACCOUNT.SET_ACCOUNT_EDITED:
    return { ...state, accountEdited: action.value }
  case actionTypes.ACCOUNT.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.ACCOUNT.SET_ERROR:
    return { ...state, error: action.value }
  default:
    return state
  }
}