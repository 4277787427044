import React, { Component } from 'react'

import { Col } from 'react-bootstrap'
import ModalBackdropForm from './common/modalBackdropForm'
import dompurify from 'dompurify'

const policy = `<div class="info_level_1">
<ol class="info_list">
  <li><span><strong>General Information.</strong></span>
    <ol>
      <li>
		  <p><strong>Scope of the Policy.</strong> Concorde, Inc. (“Concorde”, “we” or “us”), d.b.a. Driversclearinghouse.com is in the business of occupational health and employment screening. Concorde furnishes various services to the public and private business sectors, including the administration of medical examinations, drug and alcohol testing, and various types of employment screening. Concorde's services and products may be explained in greater detail on this website.</p>
		  <p>This Privacy Policy describes the types of information we may collect from you or that you may provide and how Concorde collects, protects, uses and shares information it obtains and collects, including information accessed via the Internet. In order to provide its services, Concorde gathers individuals’ Personal Information. “Personal Information” means information that relates to you as an individually identifiable person, such as your name, e-mail address, and mobile number. Personal Information can be gathered in a number of different ways. It may be provided to Concorde by a customer, a customer’s employee or a customer’s applicant for employment. Information may be provided to Concorde in written or electronic form. Information may be obtained when a person visits our public website at www.concorde2000.com (the “Public Website”) or one of our secure password protected websites (the “Private Websites”) which are utilized by our customers and their employees and applicants to access Concorde’s services (collectively the “Websites”).</p>
		  <p>Please read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Websites. By accessing or using our Websites, you agree to this Privacy Policy.</p>
		  </li>
		  <li>
		  <p><strong>Commitment to privacy.</strong> Concorde respects the privacy of its customers, their employees and applicants, and the public. Concorde recognizes the need for appropriate protections and management of the information that Concorde collects and uses, including information received from visitors to its Websites. Concorde has adopted, implemented and conducts its business pursuant to this Privacy Policy to assist you in understanding what types of Personal Information Concorde may collect, how that information may be used, and with whom the information may be shared.</p>
      </li>
    </ol>
  </li>
  <li><span><strong>Information Concorde Collects.</strong></span>
	<p>Concorde has various forms posted on the Public Website where, if you choose to provide it, we may gather your name, company, title, email address, and phone number.</p>
	<p>In addition, information collected on the Public Website includes:</p>
	<ul>
		<li><strong>Your IP address at the time of connecting to the Website.</strong></li>
		<li><strong>The type of browser that you are using (e.g., Safari, Microsoft Internet Explorer)</strong></li>
		<li><strong>The domain from which you come to our Website (e.g., bing.com, google.com)</strong></li>
		<li><strong>Statistics that show the numbers and frequencies that users visit our Website</strong></li>
	</ul>
	<p></p>
	<p>Concorde utilizes cookies to help track users when they are logged into our Private Websites. Cookies may contain information such as the URL you came from, your computer's IP address (i.e., the Internet address of your computer) and domain type (e.g., .com or .org), your browser type, the country, state and telephone area code where your server is located, the pages of our Website that were viewed during a visit, and any search terms that you entered on our Website.</p>
	<p>While an IP address may reveal your ISP or geographic area, we cannot determine your identity solely based upon your IP address, the type of browser you use, domain type, or statistics on user usage. We do not link this information to personal information.</p>
	<p>Personal information collected on the Private Website, depending on the nature of the service Concorde is rendering, may include:</p>
	<ul>
		<li><strong>Name</strong></li>
		<li><strong>Addresses (home and email)</strong></li>
		<li><strong>Current and prior employment</strong></li>
		<li><strong>Date of Birth</strong></li>
		<li><strong>Medical informaton</strong></li>
		<li><strong>Drug/alcohol violations</strong></li>
	</ul>
	<p></p>
  </li>
  <li><span><strong>How Concorde Uses Personal Information.</strong></span>
	<ol>
		<li>
			<p><strong>Aggregate Information.</strong> To measure the visitors’ interest in, and use of, various areas of the Website and the various programs that Concorde administers, Concorde will rely upon aggregate information, which is information that does not identify you specifically, such as statistical and navigational information. With this aggregate information, Concorde may undertake statistical and other summary analyses of the visitors' behaviors and characteristics. Concorde may use third party suppliers to collect and analyze this information. Although Concorde may share this aggregate information with third parties, none of this information will allow anyone to identify an individual person.</p>
		</li>
		<li>
			<p><strong>Personal Information.</strong></p>
			<ol>
				<li>
					<p><strong>Obtained from Public Website.</strong> Concorde has various forms posted on its Public Website where, if you choose to provide it, we may gather your name, company, title email address and phone number. This data is used for marketing and information request purposes.</p>
					<p>Concorde does not share your Personal Information it collects on the Public Website with third parties.</p>
				</li>
				<li>
					<p><strong>Obtained from Concorde's Customers in Writing or from one of its Private Websites.</strong> When Concorde obtains Personal Information about you either from you or one of its customers, Concorde utilizes that information to process and deliver a contracted service to a customer. Those services include performing pre-employment background checks, obtaining driving records, providing drug and alcohol testing services, facilitating use of the government drug and alcohol clearinghouse, and administering medical examination services by physicians and other medical personnel. As a result of performing these services, Concorde may obtain additional Personal Information such as health or credit information. Concorde shares the results of these services with its customer that contracted for the service and with third parties at the direction of that customer. These services are provided with your consent. If you previously provided Concorde with such consent but no longer want your Personal Information to be shared, please contact the Concorde Privacy Officer at <a href="mailto:privacyofficer@concorde2000.com">privacyofficer@concorde2000.com</a>. Concorde’s Private Websites provide ongoing on-line access to Concorde’s customer that contracted the service which may contain the Personal Information described in the preceding paragraph.</p>
				</li>
				<li>
					<p><strong>Your ‘Do Not Track’ Browser Setting.</strong> We do not support the Do Not Track (DNT) browser setting. DNT is a preference you can set in your browser’s settings to let the websites you visit know that you do not want the websites collecting your personal data.</p>
					<p>We do not track your online activity over time and across third-party websites or online services. Third-parties do not collect Personal Information about your online activities over time and across different websites when you use the Websites.</p>
				</li>
			</ol>
		</li>
	</ol>
  </li>
  <li><span><strong>Retention of Personal Information.</strong></span>
	<p>Concorde will retain your Personal Information for as long as required or permitted by applicable laws. Some of the services rendered by Concorde (for example Driver Qualification Files which are mandated by the United States Department of Transportation and the Federal Motor Carrier Safety Administration) require the retention of your information. Your consent to the use of your Personal Information for such purposes(s) remains valid after termination of your relationship with a former employer.</p>
  </li>
  <li><span><strong>Safety Measures.</strong></span>
	<p>Concorde takes measures to maintain the confidentiality of your Personal Information and protect it from unlawful disclosure. Concorde will not knowingly make your Personal Information available to the general public. Concorde safeguards your Personal Information on the Internet by using industry-standard and commercially reasonable practices. Although guaranteed security does not exist either on or off the Internet, Concorde makes commercially reasonable efforts to make the collection and security of such information consistent with this Privacy Policy and all applicable laws and regulations. Concorde uses commercially reasonable efforts to ensure that Personal Information is safeguarded against loss, access, use, modification, disclosure, or misuse. All commercially reasonable steps are taken to prevent the unauthorized use or disclosure of your Personal Information. For your convenience, Concorde may include on the Website links to other websites. Concorde provides these links as a convenience only and Concorde does not endorse the content or services offered by, or the privacy policies in place at, these other websites. This Privacy Policy covers only Personal Information that Concorde collects through the Website and websites not operated by or on behalf of Concorde to which links are included on the Website may have privacy policies that differ substantially from this Privacy Policy. Concorde has structured the Website to make it reasonably clear when you leave the Website and enter another website.</p>
	<p>In some instances (such as through the use of Private Websites), the safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Private Websites, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
	<p>Unfortunately, any transmission of your Personal Information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on our Websites.</p>
  </li>
  <li><span><strong>Storage and Disclosure of Personal Information.</strong></span>
	<ol>
		<li>
			<p><strong>Storage of personal Information.</strong> Personally identifiable information collected by Concorde is normally stored and processed in the United States.</p>
			<p>Personally identifiable information collected by Concorde is normally stored and processed in the United States.that have taken place outside of the United States utilizing information provided by you. These types of checks will typically be about your criminal records in foreign jurisdictions other than the United States, educational institutions you attended outside of the United States and/or your employment outside of the United States. These background checks are performed in accordance with your written consent and the requirements of the Fair Credit Reporting Act (FCRA). By executing the FCRA consent form, you consent to the transfer of your Personal Information outside of the United States to enable the foreign pre-employment background checks described above to be performed. Concorde does not utilize any off-shore resources and does not send your Personal Information off-shore to perform any pre-employment background checks regarding your activities in the United States. NOTE: The United States does not have the same data protection laws as Germany and the countries of the European Community and the European Economic Area. When Concorde transfers your Personal Information to other countries, Concorde will protect that Personal Information as described in this Privacy Policy.</p>
		</li>
		<li>
			<p><strong>Disclosure of Personal Information.</strong> Concorde will not disclose your Personal Information to a third party (including prospective or current employers) unless you request or otherwise consent to such disclosure or such disclosure is required or permitted by applicable law or, if in Concorde’s good-faith belief, Concorde determines that such disclosure is necessary to (a) comply with any law applicable to Concorde or its subsidiaries and/or affiliates or comply with legal process or investigation involving Concorde, its subsidiaries and/or affiliates or the Website or (b) protect or defend the rights or property of Concorde.</p>
		</li>
	</ol>
  </li>
  <li><span><strong>Children and Data Collection.</strong></span>
	<p>Concorde adheres to the federal privacy protection standards as stated in the Children's Online Privacy Protection Act (“COPPA”). Concorde cares about the safety of children. Concorde will not knowingly allow anyone under thirteen (13) years of age to provide Concorde with any Personal Information. Children under thirteen (13) years of age are required to obtain the express permission of a parent or guardian before submitting any Personal Information about themselves over the Internet. If a child under thirteen (13) years of age has provided Concorde with Personal Information without the consent of a parent or guardian, the parent or guardian of that child should contact the Privacy Officer immediately at <a href="mailto:privacyofficer@concorde2000.com">privacyofficer@concorde2000.com</a>. Concorde will use commercially reasonable efforts to promptly delete such child's information from its records and servers (if in electronic form).</p>
  </li>
  <li><span><strong>Contact Information.</strong></span>
	<p>If you have any questions or complaints, or you wish to access, correct or delete your Personal Information, please contact the Concorde Privacy Officer. The Concorde Privacy Officer can be reached by telephone at: 1-215-563-5555, by facsimile at: (215)600-2595, by email at 
    <a href="mailto:privacyofficer@concorde2000.com">privacyofficer@concorde2000.com</a>, or by mail at Concorde, Inc., 701 Market St., Suite 3400, Philadelphia, PA 19106, Attention: Privacy Officer. If necessary, the Privacy Officer will contact another employee to assist in completing your requested task.</p>
  </li>
  <li><span><strong>Changes to this Privacy Policy.</strong></span>
	<p>If we change this Privacy Policy, we will post those changes on this page and update the Privacy Policy modification date. If we materially change this Privacy Policy in a way that affects how we use or disclose your personal data, we will provide a prominent notice of such changes and the effective date of the changes before making them.</p>
  </li>
</ol>
<p>Revised 2.4.2020</p>
</div>`

class PrivacyPolicy extends Component {
  render () {
    return (
      <ModalBackdropForm sm={12} md={11} lg={10} displayTermsOfUseLink={false} displayPrivacyPolicyLink={false}>
        <Col xs={12} className='text-center mb-3'>
          <h3>Privacy Policy</h3>
        </Col>
        <Col xs={12}><div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(policy) }} />
        </Col>
      </ModalBackdropForm>
    )
  }
}

export default PrivacyPolicy
