import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { sortBy } from 'lodash'
import { getLabelForLanguage, renderConsentContent } from '../../utils'

import {
  Button,
  Col,
  ListGroup,
  Spinner
} from 'react-bootstrap'
import NavHeader from './common/navHeader'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

class ViewConsentContent extends Component {
  state = {
    consentContent: null
  }

  componentDidMount () {
    this.props.loadConsentContent()
    this.props.loadDotNumbers()
  }

  _renderError () {
    const {
      consentContent: { error: error1 },
      dotNumbers: { error: error2 }
    } = this.props
    if (!error1 && !error2) { return '' }
    return (
      <div className='d-flex justify-content-around align-items-center'>
        {`There was a problem loading the data: ${error1 || error2}`}
      </div>
    )
  }

  _renderLoading () {
    const {
      consentContent: { consentContent, loading: loading1 },
      dotNumbers: { dotNumbers, loading: loading2 }
    } = this.props
    if (!loading1 && !loading2 && consentContent && dotNumbers) { return '' }
    return (
      <div className='d-flex justify-content-around align-items-center'>
        <div>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Loading consent content
        </div>
      </div>
    )
  }

  _renderListGroupItem (consentContent) {
    const dotMap = selectors.dotNumbers.getDotNumberMap(this.props)

    const language = getLabelForLanguage(consentContent.language)
    const title = consentContent.company ?
      `Company default – ${language}` :
      `DOT number ${dotMap[consentContent.dotNumber].name} – ${language}`
    return <Button
      key={consentContent._id}
      id={`consentContent-${consentContent._id}`}
      size='sm'
      variant='outline-primary'
      className='my-2'
      onClick={() => this.setState({ consentContent })}
    >{title}</Button>
  }

  _renderConsentContentControls () {
    const { consentContent: { consentContent }, dotNumbers: { dotNumbers } } = this.props
    if (!consentContent || !dotNumbers) { return '' }

    const dotNumberMap = selectors.dotNumbers.getDotNumberMap(this.props)
    const sorted = sortBy(consentContent, ['company', (c) => c.dotNumber && dotNumberMap[c.dotNumber].name, 'language'])

    return (
      <ListGroup>
        {sorted.map((c) => this._renderListGroupItem(c))}
      </ListGroup>
    )
  }

  _renderConsentContent () {
    const { consentContent } = this.state
    const { auth: { user } } = this.props
    if (!consentContent || !user) { return '' }

    // create a dummy consent object that will populate the templated fields
    const content = renderConsentContent(consentContent, {
      driver: {
        firstName: 'Firstname',
        lastName: 'Lastname',
        dotNumber: {
          company: user.company
        }
      }
    })

    return <div
      className='pb-5'
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  }

  render () {
    return (
      <div className='d-flex flex-column w-100 h-100'>
        <NavHeader/>
        <div className='d-flex align-items-center justify-content-center'>
          {this._renderError()}
          {this._renderLoading()}
        </div>
        <div className='flex-grow-1 d-flex flex-row overflow-hidden'>
          <Col xs={4} lg={3} xl={2} className='bg-light p-3'>
            {this._renderConsentContentControls()}
          </Col>
          <div className='p-3 overflow-auto'>
            {this._renderConsentContent()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, consentContent, dotNumbers } = state
  return { auth, consentContent, dotNumbers }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.consentContent, ...actions.dotNumbers },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewConsentContent)

ViewConsentContent.propTypes = {
  auth: PropTypes.object.isRequired,
  consentContent: PropTypes.object.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  loadConsentContent: PropTypes.func.isRequired,
  loadDotNumbers: PropTypes.func.isRequired
}
