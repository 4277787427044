import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Form,
  Spinner
} from 'react-bootstrap'
import ModalBackdropForm from './common/modalBackdropForm'
import * as actions from '../actions'

class ForgotPassword extends Component {

    state = {
      email: ''
    }

    componentDidMount () {
      this.props.setError()
      this.props.setForgotSent()
    }

    _handleOnSubmit () {
      this.props.forgot(this.state)
    }

    _renderMessage () {
      const { auth: { error, forgotSent } } = this.props
      if (error) {
        return (
          <Col xs={12} className='mb-3 alert alert-danger'>
            {error}
          </Col>
        )
      }
      if (forgotSent) {
        return (
          <Col xs={12} className='mb-3 alert alert-success'>
            Please check your email, including your spam folder, for instructions about how to reset your password.
          </Col>
        )
      }
      return (
        <Col xs={12} className='mb-3'>
          Reset your password.
        </Col>
      )
    }

    _renderButtonContents () {
      const { auth: { loading } } = this.props

      if (loading) {
        return (
          <React.Fragment>
            <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
            Sending instructions
          </React.Fragment>
        )
      }
      return 'Send instructions'
    }

    _renderForm () {
      const { auth: { loading, forgotSent } } = this.props
      if (forgotSent) { return '' }
      return (
        <React.Fragment>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                autoComplete='email'
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                required
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={12} className='my-3'>
            <Button
              variant='primary'
              block
              type='submit'
              disabled={loading}
            >{this._renderButtonContents()}</Button>
          </Col>
        </React.Fragment>
      )
    }

    render () {
      return (
        <ModalBackdropForm
          onSubmit={() => this._handleOnSubmit()}
          displayLoginLink={true}
          displayHelpLink={true}
        >
          {this._renderMessage()}
          {this._renderForm()}
        </ModalBackdropForm>
      )
    }
}

const mapStateToProps = (state) => {
  const { auth } = state
  return { auth }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.auth, ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  forgot: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setForgotSent: PropTypes.func.isRequired
}