import { renderConsentContent } from '../utils'

const driverConsent = {
  getRenderedConsentContent: (state) => {
    const { driverConsent: { consent, consentContent } } = state
    return consentContent.map((content) => renderConsentContent(content, consent))
  },

  getSupportedLanguages: (state) => {
    const { driverConsent: { consentContent } } = state
    return consentContent.map((content) => content.language)
  },

  getDefaultConsentContents: (language) => {
    return defaultConsentContents.find(d => d.language === language).content
  }
}

export { driverConsent }

const defaultConsentContents = [
  {
    language: 'en',
    content: `<h3><strong>{{driver.dotNumber.company.name}}</strong> is requesting a General Consent for Limited Queries of the Federal Motor Carrier Safety Administration (FMCSA) Drug and Alcohol Clearinghouse</h3>
    <p>I, {{driver.firstName}} {{driver.lastName}}, hereby provide consent to {{driver.dotNumber.company.name}} to conduct a limited query of the FMCSA Commercial Driver’s License Drug and Alcohol Clearinghouse (Clearinghouse) to determine whether drug or alcohol violation information about me exists in the Clearinghouse.</p>
    <p>Employers and employees may also wish to include the terms of the consent. For example, is the driver consenting to a single limited query or multiple limited queries? If the driver consents to multiple limited queries, will those queries be conducted over a fixed period of time or for the duration of employment? Is the number of limited queries specific or unlimited? The scope of this consent would be determined by the employer and the employee.</p>
    <p>I understand that if the limited query conducted by {{driver.dotNumber.company.name}} indicates that drug or alcohol violation information about me exists in the Clearinghouse, FMCSA will not disclose that information to {{driver.dotNumber.company.name}} without first obtaining additional specific consent from me.</p>
    <p>I further understand that if I refuse to provide consent for {{driver.dotNumber.company.name}} to conduct a limited query of the Clearinghouse, {{driver.dotNumber.company.name}} must prohibit me from performing safety-sensitive functions, including driving a commercial motor vehicle, as required by FMCSA’s drug and alcohol program regulations.<br/>
    <br>
    <br></p>
    <div style="margin-bottom: 20px;"><div style="width:100px;display:inline-block;text-align:right;">Name</div><div style="width:300px;border-bottom:1px solid black;margin-left:20px;display:inline-block;">&nbsp;&nbsp;{{driver.firstName}} {{driver.lastName}}</div></div>      
    <div>
    <div style="width:100px;display:inline-block;text-align:right;">Date</div><div style="width:300px;border-bottom:1px solid black;margin-left:20px;text-align:left;display:inline-block;">&nbsp;&nbsp;{{date}}</div></div>
    `
  },
  {
    language: 'fr-ca',
    content: `<h3><strong>{{driver.dotNumber.company.name}}</strong> demande un consentement général pour les requêtes limitées de la centrale de drogue et d'alcool du Federal Motor Carrier Safety Administration (FMCSA)</h3>
    <p>Je, {{driver.firstName}} {{driver.lastName}}, donne par la présente son consentement à {{driver.dotNumber.company.name}} pour effectuer une interrogation limitée sur le centre de documentation sur l'alcool et les drogues de la FMCSA. pour déterminer s'il existe des informations sur les violations de drogue ou d'alcool à mon sujet dans le Clearinghouse.</p>
    <p>Les employeurs et les employés peuvent également souhaiter inclure les conditions du consentement. Par exemple, le pilote consent-il à une seule requête limitée ou à plusieurs requêtes limitées? Si le conducteur consent à plusieurs requêtes limitées, celles-ci seront-elles effectuées sur une période de temps déterminée ou pour la durée de l'emploi? Le nombre de requêtes limitées est-il spécifique ou illimité? La portée de ce consentement serait déterminée par l'employeur et l'employé.</p>
    <p>Je comprends que si la requête limitée effectuée par {{driver.dotNumber.company.name}} indique que des informations relatives à la violation de drogue ou d’alcool me concernant existent dans la centrale, FMCSA ne les communiquera pas à {{driver.dotNumber.company.name}} sans avoir au préalable obtenu un consentement spécifique supplémentaire de ma part.</p>
    <p>Je comprends également que si je refuse de donner mon consentement à ce que {{driver.dotNumber.company.name}} effectue une requête limitée dans le Clearinghouse, {{driver.dotNumber.company.name}} doit m'empêcher de procéder à des opérations de sécurité. fonctions, y compris la conduite d’un véhicule utilitaire, conformément au règlement de la FMCSA sur les programmes de lutte contre la drogue et l’alcool.</p>
    <p><br>
    <br></p>
    <div style="margin-bottom: 20px;"><div style="width:100px;display:inline-block;text-align:right;">Nom</div><div style="width:300px;border-bottom:1px solid black;margin-left:20px;display:inline-block;">&nbsp;&nbsp;{{driver.firstName}} {{driver.lastName}}</div></div>      
    <div>
    <div style="width:100px;display:inline-block;text-align:right;">Date</div><div style="width:300px;border-bottom:1px solid black;margin-left:20px;text-align:left;display:inline-block;">&nbsp;&nbsp;{{date}}</div></div>
    `
  },
  {
    language: 'es',
    content: `<h3><strong>{{driver.dotNumber.company.name}}</strong> Consentimiento general para consultas limitadas de la Administración Federal de Seguridad de Autotransportes (FMCSA) Centro de Información sobre Drogas y Alcohol</h3>
    <p>Yo, {{driver.firstName}} {{driver.lastName}}, por la presente doy mi consentimiento a {{driver.dotNumber.company.name}} para realizar una consulta limitada del Centro de intercambio de drogas y alcohol de la licencia de conducir comercial de FMCSA ( Clearinghouse) para determinar si existe información de violación de drogas o alcohol sobre mí en el Clearinghouse.</p>
    <p>Los empleadores y los empleados también pueden incluir los términos del consentimiento. Por ejemplo, ¿el conductor está dando su consentimiento para una única consulta limitada o múltiples consultas limitadas? Si el conductor acepta múltiples consultas limitadas, ¿se realizarán esas consultas durante un período de tiempo fijo o durante la duración del empleo? ¿El número de consultas limitadas es específico o ilimitado? El alcance de este consentimiento será determinado por el empleador y el empleado.</p>
    <p>Entiendo que si la consulta limitada realizada por {{driver.dotNumber.company.name}} indica que existe información de violación de drogas o alcohol sobre mí en el Centro de Información, FMCSA no revelará esa información a {{driver.dotNumber.company.name }} sin obtener primero mi consentimiento específico adicional.</p>
    <p>Además, entiendo que si me niego a dar mi consentimiento para que {{driver.dotNumber.company.name}} lleve a cabo una consulta limitada del Centro de Información, {{driver.dotNumber.company.name}} debe prohibirme que sea sensible a la seguridad funciones, incluida la conducción de un vehículo motorizado comercial, según lo exigen las reglamentaciones del programa de drogas y alcohol de la FMCSA.</p>
    <p><br>
    <br></p>
    <div style="margin-bottom: 20px;"><div style="width:100px;display:inline-block;text-align:right;">Firma</div><div style="width:300px;border-bottom:1px solid black;margin-left:20px;display:inline-block;">&nbsp;&nbsp;{{driver.firstName}} {{driver.lastName}}</div></div>
    <div>
    <div style="width:100px;display:inline-block;text-align:right;">Fecha</div><div style="width:300px;border-bottom:1px solid black;margin-left:20px;text-align:left;display:inline-block;">&nbsp;&nbsp;{{date}}</div></div>
    `
  }
]