import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import { fullName } from '../../../utils'
import ModalConfirmation from '../modalConfirmation'

import * as actions from '../../../actions'

class PerformConsent extends Component {
  componentDidMount () {
    const { driver: { driver } } = this.props
    if (!driver) { return }
    this.props.setError()
    this.props.setConsentUrl()
    this.props.getConsentUrl(driver._id)
  }

  render () {
    const { driver: { driver, loading, error, consentUrl } } = this.props
    if (!driver) { return '' }

    const text = !consentUrl ? 'Loading...' : (<React.Fragment>
      {`To obtain consent from ${fullName(driver)}, click the link below or copy and paste it into a new browser tab.`}
      <br></br><br></br>
      <a href={consentUrl} target='_blank' rel='noopener noreferrer'>{consentUrl}</a>
    </React.Fragment>)

    return <ModalConfirmation
      show={true}
      header='Perform consent'
      text={text}
      showAccept={false}
      onCancel={() => this.props.done()}
      cancelText='Done'
      loading={loading}
      error={error}
    />
  }
}

const mapStateToProps = (state) => {
  const { driver } = state
  return { driver }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformConsent)

PerformConsent.propTypes = {
  done: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  getConsentUrl: PropTypes.func.isRequired,
  setConsentUrl: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}