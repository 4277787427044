import { actionTypes } from './actionTypes'
import {
  requestGetConsent
} from '../services/apiService'

const consent = {
  setConsent: (value) => ({
    type: actionTypes.CONSENT.SET_CONSENT,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.CONSENT.SET_LOADING,
    value
  }),

  setError: (value) => ({
    type: actionTypes.CONSENT.SET_ERROR,
    value
  }),

  getConsent: (consentId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(consent.setLoading(true))
      dispatch(consent.setError())
      const data = await requestGetConsent(token, consentId)
      if(data && data.consent){
        dispatch(consent.setConsent(data))
      } else if (data && data.error){
        dispatch(consent.setError(data.error))
      }
      dispatch(consent.setLoading(false))
    }
  }
}

export { consent }
