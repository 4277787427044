import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Container,
  Col,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import DriverFields from '../common/driversTable/driverFields'

import * as actions from '../../actions'

class CreateDriver extends Component {
  state = {
    driverFields: null
  }

  componentDidMount () {
    this.props.setCreated()
    this.props.setError()
  }

  componentDidUpdate () {
    const { driver: { created } } = this.props
    if (created) {
      this.props.done()
    }
  }

  _renderButtonContents () {
    const { driver: { loading } } = this.props
    if (!loading) { return 'Create' }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Saving
    </React.Fragment>
  }

  _renderError  () {
    const { driver: { error } } = this.props
    if (!error) { return '' }
    return <Row>
      <Col className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  render () {
    const { driver: { loading }, dotNumbers: { dotNumbers }, done } = this.props
    if (!dotNumbers) { return '' }

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Create new driver
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            <DriverFields
              dotNumbers={dotNumbers}
              onUpdate={(state) => this.setState({ driverFields: state })}
              isConcordeAdmin={false}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => done()}
          >Cancel</Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={loading}
            className='btn-wide'
            onClick={() => this.props.createDriver(this.state.driverFields)}
          >{this._renderButtonContents()}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { dotNumbers, driver } = state
  return { dotNumbers, driver }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDriver)

CreateDriver.propTypes = {
  done: PropTypes.func.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  driver: PropTypes.object.isRequired,
  createDriver: PropTypes.func.isRequired,
  setCreated: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
