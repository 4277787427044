import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Container,
  Modal,
  Row
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class CreateCompanyError extends Component {
  render () {
    const { hide, title, message } = this.props
    return (
      <Modal
        show={true}
        animation={false}
        size='md'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <FontAwesomeIcon icon={faExclamationTriangle} color='red' style={{ marginRight: '10px' }}/>
            {title}
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => hide()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col className='text-center'>{message}</Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => hide()}
          >Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect()(CreateCompanyError)

CreateCompanyError.propTypes = {
  hide: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string
}