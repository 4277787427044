import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import { startCase } from 'lodash'
import { fullName, displayDate, displayCurrency } from '../../utils'

import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import NavHeader from './common/navHeader'
import EditAccount from './editAccount'
import EditSubscription from './editSubscription'
import ContactForm from '../common/contactForm'
import StripeLoader from './common/stripeLoader'
import PdfDocument from '../common/pdfDocument'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

class Account extends Component {
  state = {
    showEditAccount: null,
    showEditSubscription: null,
    showContactForm: null
  }

  componentDidMount () {
    this.props.loadAccount()
  }

  _renderEditAccount () {
    const { showEditAccount } = this.state
    if (!showEditAccount) { return }
    return (
      <EditAccount
        done={() => this.setState({ showEditAccount: false })}
      />
    )
  }

  _renderEditSubscription () {
    const { showEditSubscription } = this.state
    if (!showEditSubscription) { return }
    return (
      <StripeLoader>
        <EditSubscription
          done={() => this.setState({ showEditSubscription: false })}
        />
      </StripeLoader>
    )
  }

  _renderContactForm () {
    const { showContactForm } = this.state
    if (!showContactForm) { return }
    return (
      <ContactForm
        done={() => this.setState({ showContactForm: false })}
      />
    )
  }

  _renderAccount () {
    const { account: { driver, company, dotNumber, loading } } = this.props

    if (loading || !driver || !company || !dotNumber) { return (
      <div className='d-flex justify-content-center align-items-center'>
        <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Loading
      </div>
    ) }

    return (
      <React.Fragment>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Name</Col>
          <Col xs={6}>{fullName(driver)}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Drivers license number</Col>
          <Col xs={6}>{driver.driversLicenseNumber}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>State</Col>
          <Col xs={6}>{driver.state}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>DOT number</Col>
          <Col xs={6}>{dotNumber.name}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Email address</Col>
          <Col xs={6}>{driver.email}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Date of birth</Col>
          <Col xs={6}>{displayDate(driver.dob)}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Legal entity name</Col>
          <Col xs={6}>{company.name}</Col>
        </Row>
        <Row className='mt-y mb-3'>
          <Col xs={12} className='d-flex justify-content-end'>
            <Button
              onClick={() => this.setState({ showEditAccount: true })}
            >Edit account</Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  _renderSubscriptionRenewalDate () {
    const { account: { subscription } } = this.props
    if (!subscription) { return }

    if (!selectors.account.isAccountActive(this.props) || selectors.account.hasSubscriptionBeenCanceled(this.props)) {
      return <em className='text-secondary'>Subscription canceled</em>
    }

    const { current_period_end } = subscription
    const renewalDate = moment.utc(current_period_end * 1000).toJSON()
    return displayDate(renewalDate, true)
  }

  _renderSubscriptionStatus () {
    const { account: { subscription } } = this.props
    if (!subscription) { return }
    const { status } = subscription

    if (status === 'active') {
      return <div><span className='px-2 text-center rounded bg-success text-light'>Active</span></div>
    }
    if (status === 'past_due') {
      return <div><span className='px-2 text-center rounded bg-warning'>Past due</span></div>
    }
    return <div><span className='px-2 text-center rounded bg-light3'>Inactive</span></div>
  }

  _renderSubscriptionCoveragePeriod () {
    const { account: { subscription } } = this.props
    if (!subscription) { return }

    if (!selectors.account.isAccountActive(this.props)) {
      return <strong className='text-danger'>Not currently covered</strong>
    }

    const { start, end } = selectors.account.getSubscriptionCoveragePeriod(this.props)
    return `${displayDate(start, true)} – ${displayDate(end, true)}`
  }

  _renderSubscriptionPaymentMethod () {
    const { account: { paymentMethod } } = this.props
    if (!paymentMethod || !paymentMethod.card) { return }

    if (!selectors.account.isAccountActive(this.props) || selectors.account.hasSubscriptionBeenCanceled(this.props)) { return }

    const { brand, last4 } = paymentMethod.card
    return <span>{startCase(brand)}&nbsp;&nbsp;●●●●{last4}</span>
  }

  _renderSubscription () {
    const { account: { customer, product, subscription, loading } } = this.props

    if (loading || !customer || !product || !subscription) { return (
      <div className='d-flex justify-content-center align-items-center'>
        <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Loading
      </div>
    )}

    return (
      <React.Fragment>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Status</Col>
          <Col xs={6}>{this._renderSubscriptionStatus()}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Current coverage period</Col>
          <Col xs={6}>{this._renderSubscriptionCoveragePeriod()}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Subscription plan</Col>
          <Col xs={6}>
            <span>{`${product.name}:`}</span>
            <strong>&nbsp;&nbsp;{`${displayCurrency(Number(product.metadata.amount))} / year`}</strong>
          </Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Subscription renewal date</Col>
          <Col xs={6}>{this._renderSubscriptionRenewalDate()}</Col>
        </Row>
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'>Payment method</Col>
          <Col xs={6}>{this._renderSubscriptionPaymentMethod()}</Col>
        </Row>
        <Row className='mt-y mb-3'>
          <Col xs={12} className='d-flex justify-content-end'>
            {selectors.account.isAccountActive(this.props) && !selectors.account.hasSubscriptionBeenCanceled(this.props) ?
              <Button
                onClick={() => this.setState({ showEditSubscription: true })}
              >Edit subscription</Button> :
              <Button
                onClick={() => this.setState({ showContactForm: true })}
              >Contact us to reactivate your subscription</Button>
            }
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  _renderQuery (query, i) {
    const { createdAt, status, queryType, completed } = query
    return (
      <Row className='my-1' key={i}>
        <Col xs={6} md={4} className='text-right'><strong>Query created</strong></Col>
        <Col xs={6} md={8}>{displayDate(createdAt, true)}</Col>
        <Col xs={6} md={4} className='text-right'>Query type</Col>
        <Col xs={6} md={8}>{queryType}</Col>
        {!completed ?
          <React.Fragment>
            <Col xs={6} md={4} className='text-right'>Completed on</Col>
            <Col xs={6} md={8}>{displayDate(completed, true)}</Col>
          </React.Fragment> : <React.Fragment>
            <Col xs={6} md={4} className='text-right'>Status</Col>
            <Col xs={6} md={8}>{status}</Col>
          </React.Fragment>
        }
        <Col xs={12} className='d-flex justify-content-center'>
          <div className='w-75 border-bottom'></div>
        </Col>
      </Row>
    )
  }

  _renderConsent (consent, i) {
    const { _id: consentId, createdAt, status, completed } = consent
    return (
      <Row className='my-1' key={i}>
        <Col xs={6} md={4} className='text-right'><strong>Consent created</strong></Col>
        <Col xs={6} md={8}>{displayDate(createdAt, true)}</Col>
        {completed ?
          <React.Fragment>
            <Col xs={6} md={4} className='text-right'>Completed on</Col>
            <Col xs={6} md={8}>{displayDate(completed, true)}</Col>
            <Col xs={6} md={4} className='text-right'></Col>
            <Col xs={6} md={8}>
              <Button
                variant='link'
                className='p-0'
                onClick={() => this.props.getConsent(consentId) }
              >View consent</Button></Col>
          </React.Fragment> : <React.Fragment>
            <Col xs={6} md={4} className='text-right'>Status</Col>
            <Col xs={6} md={8}>{status}</Col>
          </React.Fragment>
        }
        <Col xs={12} className='d-flex justify-content-center'>
          <div className='w-75 border-bottom'></div>
        </Col>
      </Row>
    )
  }

  _renderActivity () {
    const { driver: { driver, loading } } = this.props

    if (!driver || loading) { return (
      <div className='d-flex justify-content-center align-items-center'>
        <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Loading
      </div>
    )}

    const events = selectors.driver.getSortedQueriesAndConsents(this.props)
    const { start: subscriptionStart, end: subscriptionEnd } = selectors.account.getSubscriptionCoveragePeriod(this.props)
    const isAccountActive = selectors.account.isAccountActive(this.props)

    return (
      <React.Fragment>
        {isAccountActive ? '' :
          <Row className='my-1'>
            <Col xs={6} md={4} className='text-right'><strong>Coverage ended</strong></Col>
            <Col xs={6}>{displayDate(subscriptionEnd, true)}</Col>
            <Col xs={12} className='d-flex justify-content-center'>
              <div className='w-75 border-bottom'></div>
            </Col>
          </Row>
        }
        {events.map((event, i) => event.type === 'query' ? this._renderQuery(event, i) : this._renderConsent(event, i))}
        <Row className='my-1'>
          <Col xs={6} md={4} className='text-right'><strong>Coverage started</strong></Col>
          <Col xs={6}>{displayDate(subscriptionStart, true)}</Col>
        </Row>
      </React.Fragment>
    )
  }

  _closePdf () {
    this.props.setConsent({ consent: null, pdf: null })
  }

  _renderPdf () {
    const { consent: { consent, pdf } } = this.props
    if (!pdf || !consent) { return }

    return (
      <Modal
        show={true}
        size='xl'
        onHide={() => this._closePdf()}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            View consent
          </Modal.Title>
          <Button
            size='sm'
            variant='outline-secondary'
            title='Close'
            onClick={() => this._closePdf()}
          ><FontAwesomeIcon icon={faTimes}/>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <PdfDocument
              consent={consent}
              pdf={pdf}
              emailButton=''
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => this._closePdf()}
          >Done</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render () {
    return (
      <div className='d-flex flex-column w-100 h-100'>
        <NavHeader/>
        <Container className='mt-3'>
          <Row className='mb-3'>
            <Col>
              <h3 className='text-callout subheading'>Account</h3>
            </Col>
          </Row>
          {this._renderAccount()}
          <Row className='mb-3 pt-3 border-top'>
            <Col>
              <h3 className='text-callout subheading'>Subscription</h3>
            </Col>
          </Row>
          {this._renderSubscription()}
          <Row className='mb-3 pt-3 border-top'>
            <Col>
              <h3 className='text-callout subheading'>Activity</h3>
            </Col>
          </Row>
          {this._renderActivity()}
          <Row className='mb-5'></Row>
        </Container>
        {this._renderEditAccount()}
        {this._renderEditSubscription()}
        {this._renderContactForm()}
        {this._renderPdf()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { account, driver, consent } = state
  return { account, driver, consent }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.account, ...actions.consent },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)

Account.propTypes = {
  account: PropTypes.object.isRequired,
  driver: PropTypes.object.isRequired,
  consent: PropTypes.object.isRequired,
  loadAccount: PropTypes.func.isRequired,
  getConsent: PropTypes.func.isRequired,
  setConsent: PropTypes.func.isRequired
}
