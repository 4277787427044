import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Image,
  Nav
} from 'react-bootstrap'
import styles from './navHeader.module.scss'
import logo from '../../../images/drivers-clearinghouse-logo-small-white.png'

class NavHeader extends Component {

  render () {
    const { driverConsent: { consent } } = this.props
    if (!consent) { return '' }
    const { driver: { firstName, lastName } } = consent

    return (
      <Nav className={`${styles.nav} nav d-flex justify-content-between align-items-center`}>
        <Image className={`${styles.logo} pl-4`} src={logo} alt='logo'/>
        <div className='mr-4 text-white'>{`${firstName} ${lastName}`}</div>
      </Nav>
    )
  }
}

const mapStateToProps = (state) => {
  const { driverConsent } = state
  return { driverConsent }
}

export default connect(mapStateToProps)(NavHeader)

NavHeader.propTypes = {
  driverConsent: PropTypes.object.isRequired
}
