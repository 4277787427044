import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  Button,
  Container,
  Col,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'

import * as actions from '../../actions'

class ViewCompany extends Component {
  state = {
    showUserList: false
  }


  componentDidMount () {
    console.log('modal opened')
    const { company: { company } } = this.props
    if (!company) { return }
    this.props.getCompany(company._id)
  }

  _updateUserList (showUsers) {
    this.setState({
      showUserList: showUsers
    })
  }

  _renderUserList (users) {
    let resultHtml = null
    let showUsers = false
    if (this.state.showUserList) {
      let userNameList = users.map(x => x.email)
      resultHtml = userNameList.join('<br>')
      showUsers = false
    }
    else {
      resultHtml = users.length
      showUsers = true
    }
    return <React.Fragment>
      <Col xs={(this.state.showUserList ? 3 : 1)}>
        <div dangerouslySetInnerHTML={{ __html: resultHtml }} />
      </Col>
      <Col xs={(this.state.showUserList ? 3 : 5)} style={{ marginTop: '-6px' }}>
        {(users.length === 0) ? '' :
          <Button
            variant='link'
            onClick={() => this._updateUserList(showUsers)}
          >{(this.state.showUserList ? 'Hide' : 'Show') + ' Users...'}
          </Button>}
      </Col>
    </React.Fragment>
  }

  _renderViewCompany () {
    const { company: { company, loading } } = this.props
    if (loading) { return (
      <Container>
        <Row className='align-items-top text-center'>
          <Col xs={12}>
            <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
            Loading
          </Col>
        </Row>
      </Container>
    )}
    return (
      <Container>
        <Row className='pb-1 mx-3 align-items-top'>
          <Col xs={4} className='text-right'>Name</Col>
          <Col xs={8} md={6}>
            {company.name}
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-top'>
          <Col xs={4} className='text-right'>Date Created</Col>
          <Col xs={8} md={6}>
            {moment(company.createdAt.toString()).local().format('M/D/YYYY')}
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-top'>
          <Col xs={4} className='text-right'>Active</Col>
          <Col xs={8} md={6}>
            {company.active ? 'Yes' : 'No'}
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-top'>
          <Col xs={4} className='text-right'>{(!this.state.showUserList) ? 'Number of ' : '' }Users</Col>
          {this._renderUserList(company.users)}
        </Row>
        <Row className='pb-1 mx-3 align-items-top'>
          <Col xs={4} className='text-right'>Number of Drivers</Col>
          <Col xs={8} md={6}>
            {company.driverCount}
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-top'>
          <Col xs={4} className='text-right'>Date of Last Query</Col>
          <Col xs={8} md={6}>
            {(company.lastQueryCreatedDT === 'None Created') ? company.lastQueryCreatedDT : moment(company.lastQueryCreatedDT.toString()).local().format('M/D/YYYY')}
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-top'>
          <Col xs={4} className='text-right'>DOT Numbers</Col>
          <Col xs={8} md={6}>
            <div dangerouslySetInnerHTML={{ __html: (company.dotNumbers.length === 0) ? 'No DOT Numbers' : company.dotNumbers.join('<br>') }} />
          </Col>
        </Row>
      </Container>
    )
  }

  render () {
    const { company: { company }, done } = this.props
    if (!company.lastQueryCreatedDT)
      company.lastQueryCreatedDT = 'None Created'
    if (!company.dotNumbers)
      company.dotNumbers = []
    if (!company.driverCount)
      company.driverCount = 0
    if (!company.users)
      company.users = []
    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            View company
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Edit'
              className='ml-2'
              onClick={() => done({ showEditCompany: true })}
            ><FontAwesomeIcon icon={faPencilAlt}/>
            </Button>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this._renderViewCompany()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => done()}
          >Done</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { company } = state
  return { company }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.company },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCompany)

ViewCompany.propTypes = {
  done: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired
}
