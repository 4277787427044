import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  Col,
  Container,
  Image,
  Row
} from 'react-bootstrap'
import NavHeader from './common/navHeader'
import logo from '../../images/drivers-clearinghouse-logo.png'

class Home extends Component {
  render () {
    return (
      <div className='d-flex flex-column w-100 h-100'>
        <NavHeader/>
        <Container className='d-flex flex-grow-1 flex-column align-items-center justify-content-center'>
          <Row className='w-100 mt-4 mb-2'>
            <Col xs={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className='text-center'>
              <Image style={{ height: '50px', width: '214px' }} src={logo} alt='logo'/>
            </Col>
          </Row>
          <Row className='w-100 mt-2 mb-2'>
            <Col className='text-center' xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
              <h3>Concorde admin home</h3>
            </Col>
          </Row>
          <Row className='w-100 my-4 justify-content-center'>
            <Col className='m-1' xs={12} md={4} lg={3}>
              <Link className='no-decoration' to='/concorde-admin/driver-roster'>
                <Button className='btn-block py-md-4 py-lg-5' size='lg' variant='outline-primary'>Driver roster</Button>
              </Link>
            </Col>
            <Col className='m-1' xs={12} md={4} lg={3}>
              <Link className='no-decoration' to='/concorde-admin/companies'>
                <Button className='btn-block py-md-4 py-lg-5' size='lg' variant='outline-primary'>Companies</Button>
              </Link>
            </Col>
            <Col className='m-1' xs={12} md={4} lg={3}>
              <Link className='no-decoration' to='/concorde-admin/dot-roster'>
                <Button className='btn-block py-md-4 py-lg-5' size='lg' variant='outline-primary'>DOT roster</Button>
              </Link>
            </Col>
            <Col className='m-1' xs={12} md={4} lg={3}>
              <Link className='no-decoration' to='/concorde-admin/tasks-errors'>
                <Button className='btn-block py-md-4 py-lg-5' size='lg' variant='outline-primary'>Tasks/Errors</Button>
              </Link>
            </Col>
          </Row>
          <Row className='my-4'></Row>
        </Container>
      </div>
    )
  }
}

export default Home
