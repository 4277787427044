import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { renderConsentContent } from '../../../utils'

import {
  Button,
  Col,
  Form
} from 'react-bootstrap'
import ModalBackdropForm from '../../common/modalBackdropForm'
import ProgressBar from './common/progressBar'

import * as actions from '../../../actions'

class SignConsent extends Component {
  state = {
    accepted: false,
    htmlContent: null
  }

  componentDidMount () {
    this.props.setError()
    const { userInfo, serviceQuestions } = this.props.createAccount
    if (!userInfo) {
      this.props.navigateToOwnerOperatorCreateAccountUserInfo()
    }
    if (!serviceQuestions) {
      this.props.navigateToOwnerOperatorCreateAccountServiceQuestions()
    }
    const htmlContent = renderConsentContent({ content: consentContent }, userInfo)
    this.setState({ htmlContent })
  }

  _handleOnSubmit () {
    this.props.setConsent(this.state)
    this.props.navigateToOwnerOperatorCreateAccountPayment()
  }

  _renderForm () {
    const { createAccount: { userInfo } } = this.props
    if (!userInfo) { return '' }
    const { accepted, htmlContent } = this.state

    return (
      <React.Fragment>
        <Col xs={12}>
          <ProgressBar
            title='Create account'
            subtitle='Step 3: please provide us with your consent to perform an FMCSA limited query.'
            percentDone={75}
          />
        </Col>
        <Col xs={12}>
          <div
            className='border-top py-3 mb-3'
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        </Col>
        <Col xs={12}>
          <div className='border-top pt-3'>
            <Form.Check
              name='consent'
              id='consentCheck'
              label={<span className='ml-1'>I consent to this request.</span>}
              selected={accepted}
              onChange={() => this.setState({ accepted: !this.state.accepted  })}
            />
          </div>
        </Col>
        <Col xs={4} className='mt-4 mb-3'>
          <Button
            variant='secondary'
            block
            onClick={() => this.props.navigateToOwnerOperatorCreateAccountServiceQuestions()}
          >Back</Button>
        </Col>
        <Col xs={8} className='mt-4 mb-3'>
          <Button
            variant='primary'
            block
            type='submit'
            disabled={!accepted}
          >Continue</Button>
        </Col>
      </React.Fragment>
    )
  }

  render () {
    return (
      <ModalBackdropForm
        sm={11}
        md={10}
        lg={9}
        onSubmit={() => this._handleOnSubmit()}
        displayHelpLink={true}
      >{this._renderForm()}</ModalBackdropForm>
    )
  }
}

const mapStateToProps = (state) => {
  const { createAccount } = state
  return { createAccount }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.createAccount, ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SignConsent)

SignConsent.propTypes = {
  createAccount: PropTypes.object.isRequired,
  navigateToOwnerOperatorCreateAccountUserInfo: PropTypes.func.isRequired,
  navigateToOwnerOperatorCreateAccountServiceQuestions: PropTypes.func.isRequired,
  navigateToOwnerOperatorCreateAccountPayment: PropTypes.func.isRequired,
  setConsent: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}

const consentContent = `
  <h4><strong>Drivers Clearinghouse</strong> is requesting a General Consent for Limited Queries of the Federal Motor Carrier Safety Administration (FMCSA) Drug and Alcohol Clearinghouse</h4>
  <br>
  <p>I, {{firstName}} {{lastName}}, hereby provide consent to Drivers Clearinghouse to conduct a limited query of the FMCSA Commercial Driver’s License Drug and Alcohol Clearinghouse (Clearinghouse) to determine whether drug or alcohol violation information about me exists in the Clearinghouse.</p>
  <p>I understand that if the limited query conducted by Drivers Clearinghouse indicates that drug or alcohol violation information about me exists in the Clearinghouse, FMCSA will not disclose that information to Drivers Clearinghouse without first obtaining additional specific consent from me.</p>
  <div style="margin-bottom: 20px;"><div style="width:100px;display:inline-block;text-align:right;">Name</div><div style="width:300px;border-bottom:1px solid black;margin-left:20px;display:inline-block;">&nbsp;&nbsp;{{firstName}} {{lastName}}</div></div>
  <div><div style="width:100px;display:inline-block;text-align:right;">Date</div><div style="width:300px;border-bottom:1px solid black;margin-left:20px;text-align:left;display:inline-block;">&nbsp;&nbsp;{{date}}</div></div>
`