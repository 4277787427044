import { actionTypes } from '../actions/actionTypes'

const initialState = {
  company: null,
  edited: null,
  loading: null,
  error: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.COMPANY.SET_COMPANY:
    return { ...state, company: action.value }
  case actionTypes.COMPANY.SET_EDITED:
    return { ...state, edited: action.value }
  case actionTypes.COMPANY.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.COMPANY.SET_ERROR:
    return { ...state, error: action.value }
  default:
    return state
  }
}