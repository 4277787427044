import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { isEmpty } from 'lodash'

import {
  Button,
  Col,
  Form,
  Image
} from 'react-bootstrap'
import styles from './modalBackdropForm.module.scss'
import logo from '../../images/drivers-clearinghouse-logo.png'

import * as actions from '../../actions'

class ModalBackdropForm extends Component {

  _handleSubmitForm (e) {
    e.preventDefault()
    const { onSubmit } = this.props
    if (!onSubmit) { return }
    onSubmit()
  }

  _handleForgotPasswordLinkClick (e) {
    e.preventDefault()
    this.props.navigateToForgotPassword()
  }

  _handleLoginLinkClick (e) {
    e.preventDefault()
    this.props.navigateToLogin()
  }

  _handleHelpLinkClick (e) {
    e.preventDefault()
    this.props.navigateToContact()
  }

  _renderForgotPasswordLink () {
    const { displayForgotPasswordLink } = this.props
    if (!displayForgotPasswordLink) { return '' }
    return (
      <Col xs={12} className='pl-0'>
        <Button
          variant='link'
          onClick={(e) => this._handleForgotPasswordLinkClick(e)}
        >Forgot your password?</Button>
      </Col>
    )
  }

  _renderLoginLink () {
    const { displayLoginLink } = this.props
    if (!displayLoginLink) { return '' }
    return (
      <Col xs={12} className='pl-0'>
        <Button
          variant='link'
          onClick={(e) => this._handleLoginLinkClick(e)}
        >Know your password? Sign in.</Button>
      </Col>
    )
  }

  _renderLoginLink2 () {
    const { displayLoginLink2 } = this.props
    if (!displayLoginLink2) { return '' }
    return (
      <Col xs={12} className='pl-0'>
        <Button
          variant='link'
          onClick={(e) => this._handleLoginLinkClick(e)}
        >Already have an account? Sign in.</Button>
      </Col>
    )
  }

  _renderHelpLink () {
    const { displayHelpLink } = this.props
    if (!displayHelpLink) { return '' }
    return (
      <Col xs={12} className='pl-0'>
        <Button
          variant='link'
          onClick={(e) => this._handleHelpLinkClick(e)}
        >Having trouble? Contact us</Button>
      </Col>
    )
  }

  _renderTermsOfUseAndPrivacyPolicyLinks () {
    const { displayTermsOfUseLink = true, displayPrivacyPolicyLink = true } = this.props
    const links = []
    if (displayTermsOfUseLink) {
      links.push(<a key='tou' className='mr-2' style={{ fontSize: 10 }} href='/terms-of-use' target='_blank' rel='noopener noreferrer'>Terms of use</a>)
    }
    if (displayTermsOfUseLink && displayPrivacyPolicyLink) {
      links.push(<span key='sep' className='text-primary mr-2' style={{ fontSize: 12 }}>|</span>)
    }
    if (displayPrivacyPolicyLink) {
      links.push(<a key='pp' style={{ fontSize: 10 }} href='/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy policy</a>)
    }
    if (isEmpty(links)) { return }
    return (
      <Col xs={12} className='mt-1'>
        {links.map((l) => l)}
      </Col>
    )
  }

  render () {
    const { children, sm, md, lg } = this.props

    return (
      <React.Fragment>
        <div className={styles.background}></div>
        <div className={styles['background-cover']}></div>
        <div className='d-flex flex-column overflow-auto h-100 w-100'>
          <div className='flex-grow-1'></div>
          <div className='d-flex justify-content-center'>
            <Col xs={12} sm={sm || 8} md={md || 7} lg={lg || 5}>
              <Form
                className='row py-5 px-sm-2 px-md-5 m-0 rounded bg-white'
                onSubmit={(e) => this._handleSubmitForm(e)}
              >
                <Col xs={12} className='mb-3 d-flex justify-content-center'>
                  <Image style={{ height: '50px', width: '214px' }} src={logo} alt='logo'/>
                </Col>
                {children}
                {this._renderForgotPasswordLink()}
                {this._renderLoginLink()}
                {this._renderLoginLink2()}
                {this._renderHelpLink()}
                {this._renderTermsOfUseAndPrivacyPolicyLinks()}
              </Form>
            </Col>
          </div>
          <div className='flex-grow-1'></div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { router } = state
  return { router }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBackdropForm)

ModalBackdropForm.propTypes = {
  children: PropTypes.node,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  onSubmit: PropTypes.func,
  displayForgotPasswordLink: PropTypes.bool,
  displayLoginLink: PropTypes.bool,
  displayLoginLink2: PropTypes.bool,
  displayHelpLink: PropTypes.bool,
  displayTermsOfUseLink: PropTypes.bool,
  displayPrivacyPolicyLink: PropTypes.bool,
  navigateToForgotPassword: PropTypes.func.isRequired,
  navigateToLogin: PropTypes.func.isRequired,
  navigateToContact: PropTypes.func.isRequired
}
