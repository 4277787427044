import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  Dropdown,
  Image,
  Nav
} from 'react-bootstrap'
import styles from './navHeader.module.scss'
import logo from '../../../images/drivers-clearinghouse-logo-small-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import ContactForm from '../../common/contactForm'

import * as actions from '../../../actions'

class NavHeader extends Component {
  state = {
    showContactForm: null
  }

  render () {
    const { auth: { user } } = this.props
    if (!user) { return '' }
    const { firstName, lastName } = user

    return (
      <Nav className={`${styles.nav} nav d-flex justify-content-between align-items-center`}>
        <div className='d-flex align-items-center'>
          <Link to='/owner-operator/account'>
            <Image className={`${styles.navImage} ml-3`} src={logo} alt='logo'/>
          </Link>
        </div>
        <div className='d-flex align-items-center'>
          <span
            className={`${styles.userName} text-light mr-4`}
          >{`${firstName} ${lastName}`}</span>
          <Dropdown className='mr-3'>
            <Dropdown.Toggle
              className={styles.menuButton}
              variant='outline-light'
              size='sm'
              id='navSelect'
            ><FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item
                as='button'
                onSelect={() => this.setState({ showContactForm: true })}
                key='1'
                className='btn btn-secondary'
                id='contact'
              >Contact</Dropdown.Item>
              <a className='no-decoration' href='/terms-of-use' target='_blank' rel='noopener noreferrer'>
                <Dropdown.Item
                  as='button'
                  key='2'
                  className='btn btn-secondary'
                >Terms of use</Dropdown.Item>
              </a>
              <a className='no-decoration' href='/privacy-policy' target='_blank' rel='noopener noreferrer'>
                <Dropdown.Item
                  as='button'
                  key='3'
                  className='btn btn-secondary'
                >Privacy policy</Dropdown.Item>
              </a>
              <Dropdown.Item
                as='button'
                onSelect={() => this.props.logout()}
                key='4'
                className='btn btn-secondary'
                id='logout'
              >Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {this.state.showContactForm ? <ContactForm done={() => this.setState({ showContactForm: false })}/> : ''}
      </Nav>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth } = state
  return { auth }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.auth },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader)

NavHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}
