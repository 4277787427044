import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import account from './account'
import auth from './auth'
import companies from './companies'
import company from './company'
import consent from './consent'
import consentContent from './consentContent'
import contact from './contact'
import createAccount from './createAccount'
import dotNumbers from './dotNumbers'
import dotNumbersFilters from './dotNumbersFilters'
import driver from './driver'
import driverDocuments from './driverDocuments'
import driverConsent from './driverConsent'
import drivers from './drivers'
import driversBulkActions from './driversBulkActions'
import driversFilters from './driversFilters'
import rosters from './rosters'
import user from './user'

export default (history) => combineReducers({
  router: connectRouter(history),
  account,
  auth,
  companies,
  company,
  consent,
  consentContent,
  contact,
  createAccount,
  dotNumbers,
  dotNumbersFilters,
  driver,
  driverDocuments,
  driverConsent,
  drivers,
  driversBulkActions,
  driversFilters,
  rosters,
  user
})