import { clone } from 'lodash'

const companies = {
  updateCompaniesWithCompany: (companies, company) => {
    if (!companies || !company) { return companies }
    const index = companies.findIndex((c) => c._id === company._id)
    if (index < 0) { return companies }
    const newCompanies = clone(companies)
    newCompanies[index] = company
    return newCompanies
  }
}

export { companies }