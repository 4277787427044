import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Form,
  Spinner
} from 'react-bootstrap'
import ModalBackdropForm from './common/modalBackdropForm'

import * as actions from '../actions'

class Contact extends Component {
  state = {
    name: '',
    message: '',
    subject: '',
    phone: '',
    email: ''
  }

  componentDidMount () {
    this.props.setError()
    this.props.setSent()
  }

  _handleOnSubmit () {
    this.props.sendEmail(this.state)
  }

  _renderButtonContents () {
    const { contact: { loading } } = this.props

    if (loading) {
      return (
        <React.Fragment>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Sending
        </React.Fragment>
      )
    }
    return 'Send'
  }

  _renderMessage () {
    const { contact: { error, sent } } = this.props

    if (error) {
      return (
        <Col xs={12} className='mb-3 alert alert-danger'>
          <span>{`There was a problem sending your message: ${error}`}</span>
        </Col>
      )
    }
    if (sent) {
      return (
        <Col xs={12} className='mb-3 alert alert-success'>
          <span>Your message has been sent. We&apos;ll be in touch shortly.</span>
        </Col>
      )
    }
    return ''
  }

  _renderForm () {
    const { contact: { loading, sent } } = this.props
    if (sent) { return '' }
    return (
      <React.Fragment>
        <Col xs={12}>
          <Form.Group controlId='message'>
            <Form.Label>Message</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              value={this.state.message}
              onChange={(e) => this.setState({ message: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId='subject'>
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type='text'
              value={this.state.subject}
              onChange={(e) => this.setState({ subject: e.target.value })}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId='phone'>
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type='tel'
              value={this.state.phone}
              onChange={(e) => this.setState({ phone: e.target.value })}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId='email'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type='email'
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} className='my-3'>
          <Button
            variant='primary'
            block
            type='submit'
            disabled={loading}
          >{this._renderButtonContents()}</Button>
        </Col>
      </React.Fragment>
    )
  }

  render () {
    return (
      <ModalBackdropForm
        onSubmit={() => this._handleOnSubmit()}
      >
        {this._renderMessage()}
        {this._renderForm()}
      </ModalBackdropForm>
    )
  }
}

const mapStateToProps = (state) => {
  const { contact } = state
  return { contact }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.navigation, ...actions.contact },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)

Contact.propTypes = {
  contact: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  setSent: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired
}
