import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fullName } from '../../utils'

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'

import * as actions from '../../actions'

class ContactForm extends Component {
  state = {
    message: '',
    subject: '',
    phone: ''
  }

  componentDidMount () {
    this.props.setError()
    this.props.setSent()
  }

  _handleOnSubmit () {
    const { auth: { user } } = this.props
    this.props.sendEmail({ ...this.state, email: user.email, name: fullName(user), support: true })
  }

  _renderButtonContents () {
    const { contact: { loading } } = this.props

    if (loading) {
      return (
        <React.Fragment>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Sending
        </React.Fragment>
      )
    }
    return 'Send'
  }

  _renderMessage () {
    const { contact: { error, sent } } = this.props

    if (error) {
      return (
        <Col xs={12} className='mb-3 alert alert-danger'>
          <span>{`There was a problem sending your message: ${error}`}</span>
        </Col>
      )
    }
    if (sent) {
      return (
        <Col xs={12} className='mb-3 alert alert-success'>
          <span>Your message has been sent. We&apos;ll be in touch shortly.</span>
        </Col>
      )
    }
    return ''
  }

  _renderForm () {
    const { contact: { sent } } = this.props
    if (sent) { return '' }
    return (
      <React.Fragment>
        <Col xs={12}>
          <Form.Group controlId='message'>
            <Form.Label>Message</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              value={this.state.message}
              onChange={(e) => this.setState({ message: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId='subject'>
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type='text'
              value={this.state.subject}
              onChange={(e) => this.setState({ subject: e.target.value })}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId='phone'>
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type='tel'
              value={this.state.phone}
              onChange={(e) => this.setState({ phone: e.target.value })}
            />
          </Form.Group>
        </Col>
      </React.Fragment>
    )
  }

  render () {
    const { contact: { loading, sent } } = this.props

    return (
      <Modal
        show={true}
        onHide={() => this.props.done()}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Contact support
          </Modal.Title>
          <Button
            size='sm'
            variant='outline-secondary'
            title='Close'
            onClick={() => this.props.done()}
          ><FontAwesomeIcon icon={faTimes}/>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {this._renderMessage()}
              {this._renderForm()}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className={sent ? 'btn-wide' : 'btn-semi-wide'}
            onClick={() => this.props.done()}
          >{sent ? 'Done' : 'Cancel'}</Button>
          {sent ? '' :
            <Button
              variant='primary'
              size={'sm'}
              disabled={loading}
              className='btn-wide'
              onClick={() => this._handleOnSubmit() }
            >{this._renderButtonContents()}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, contact } = state
  return { auth, contact }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.navigation, ...actions.contact },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)

ContactForm.propTypes = {
  done: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  setSent: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired
}
