import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../actions'
import { CompanyCell } from '../driversTable/driversTableCustomCells'
import * as selectors from '../../../selectors'


import ReactTable from 'react-table'
import {
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Container,
  Form,
  InputGroup
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faFile, faFileUpload, faTimes, faSync } from '@fortawesome/free-solid-svg-icons'
import styles from '../driversTable/driversTableCustomCells.module.scss'
import PdfDocument from '../pdfDocument'
import { displayNumber } from '../../../utils'

class DriversTableMissingSparcDoumentLink extends Component {

    state = {
      selectedDocument: null,
      showConfirmUploadToSparc: false,
      selectedDocumentOriginalName: null,
      showDocument: false,
      selectedServerFileName: null,
      selectedDocumentDriverId: null,
      selectedDocumentType:null,
      viewDocumentOnly:false
    }

    constructor () {
      super()
    }

    componentDidMount () {
      this.props.getDocumentListSparcMissing()
      this.props.loadDotNumbers()
      this.props.loadCompanies()
      this.props.setUploadedDocument()
      this.props.setDriverError()
    }

    render () {
      return (
        <React.Fragment>
          {this._renderTopControls()}
          {this._renderTable()}
          {this._renderBottomControls()}
          {this._renderTransfer()}
        </React.Fragment>
      )
    }

    _renderTableError () {
      const { driverDocuments: { error } } = this.props
      return (
        <div className='d-flex justify-content-around align-items-center'>
          {`There was a problem loading the data: ${error}`}
        </div>
      )
    }

    _renderTableLoading () {
      return (
        <div className='d-flex justify-content-around align-items-center'>
          <div>
            <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
                    Loading issues
          </div>
        </div>
      )
    }

    _renderTopControls () {
      const { driverDocuments: { loading } } = this.props

      return (
        <Row className='flex-shrink-0 mx-3 mt-2 mb-1' noGutters>
          <Col xs={4} md={3} lg={2}>
            {/* Space reserved for Search Box */}
          </Col>
          <Col xs={{ span: 4, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 8, offset: 1 }}>
            {/* Space reserved for filter pop-up buttons */}
          </Col>
          <Col xs={3} md={2} lg={1}>
            <Form.Group className='d-flex flex-column align-items-end'>
              <Form.Label>Actions</Form.Label>
              <InputGroup className='d-flex justify-content-end'>
                <Button
                  variant='outline-primary'
                  size='sm'
                  className='mr-2'
                  disabled={loading}
                  title='Reload data'
                  onClick={() => this.props.getDocumentListSparcMissing()}
                ><FontAwesomeIcon icon={faSync} /></Button>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      )
    }

    _renderTable () {
      const {
        companies: { companies },
        driverDocuments: { documentList, loading, error },
        dotNumbers: { dotNumbers }
      } = this.props

      if (error) { return this._renderTableError() }

      if (!documentList || !companies || !dotNumbers) { return this._renderTableLoading() }

      if (!this.companyMap) {
        this.companyMap = selectors.companies.getCompanyMap(this.props)
      }
      if (!this.dotMap) {
        this.dotMap = selectors.dotNumbers.getDotNumberMap(this.props)
      }


      return (
        <React.Fragment>
          <ReactTable
            className='flex-grow mx-3'
            columns={[
              {
                Header: 'Issue Type',
                id: 'type',
                Cell: 'Document not uploaded to Sparc',
                headerClassName: 'left',
                sortable: true
              },
              {
                Header: 'Driver',
                id: 'driver',
                Cell: (row) => `${row.value.firstName} ${row.value.lastName}`,
                accessor: 'driverInfo',
                headerClassName: 'left',
                minWidth: 150,
                sortable: true
              },
              {
                Header: 'Company',
                id: 'company',
                Cell: (row) => <CompanyCell value={row.value} dotMap={this.dotMap} companyMap={this.companyMap} />,
                accessor: 'driverInfo.dotNumber',
                headerClassName: 'left',
                minWidth: 150,
                sortable: true
              },

              { Header: 'Document Type', accessor: 'documentType', headerClassName: 'left' },
              { Header: 'File Name', accessor: 'originalName', headerClassName: 'left' },
              {
                Header: 'Actions',
                id: 'sub_col_actions',
                accessor: (d) => ({
                  id: d._id,
                  createdAt: d.createdAt,
                  serverFileName: d.serverFileName,
                  originalName: d.originalName,
                  driver: d.driver,
                  documentType: d.documentType
                }),

                Cell: (row) => {
                  return (
                    <React.Fragment>
                      <Button
                        variant='light'
                        size='sm'
                        title='View Document'
                        className='mr-1 action-button text-secondary'
                        onClick={() => {
                          this.setState({
                            selectedDocument: row.value.id,
                            showConfirmUploadToSparc: true,
                            selectedDocumentOriginalName: row.value.originalName,
                            selectedServerFileName: row.value.serverFileName,
                            selectedDocumentDriverId: row.value.driver,
                            selectedDocumentType: row.value.documentType,
                            viewDocumentOnly: true
                          }, () => this.props.getDocument(row.value.driver, row.value.serverFileName))
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button
                        variant='light'
                        size='sm'
                        title='Upload Document To Sparc'
                        className='mr-1 action-button text-secondary'
                        onClick={() => {
                          this.setState({
                            selectedDocument: row.value.id,
                            showConfirmUploadToSparc: true,
                            selectedDocumentOriginalName: row.value.originalName,
                            selectedServerFileName: row.value.serverFileName,
                            selectedDocumentDriverId: row.value.driver,
                            selectedDocumentType: row.value.documentType,
                            viewDocumentOnly: false
                          }, () => this.props.getDocument(row.value.driver, row.value.serverFileName))
                        }}
                      >
                        <FontAwesomeIcon icon={faFileUpload} />
                      </Button>
                    </React.Fragment>
                  )
                },
                className: 'left',
                headerClassName: 'left'
              }
            ]}
            data={documentList}
            loading={loading}
            loadingText='Loading'
            manual
            minRows={5}
            showPaginationBottom={false}
          ></ReactTable>
        </React.Fragment>
      )
    }

    _renderBottomControls () {
      const { driverDocuments: { documentList } } = this.props
      if (!documentList) { return '' }
      let message = `Showing ${displayNumber(documentList.length)} total issues`

      return (
        <Row className='my-2 d-flex justify-content-betweeen align-items-center' noGutters>
          <div className='ml-3'>{message}</div>
          <div className='mr-3 d-flex align-items-center'>
            {/* Space reserved for paging functionality */}
          </div>
        </Row>
      )
    }

    _renderTransfer () {

      if (!this.state.showConfirmUploadToSparc) { return '' }

      const { driverDocuments, driver } = this.props

      if (driverDocuments.document) {
        if (this.state.showConfirmUploadToSparc) {
          return (
            <Modal
              show={true}
              onHide={() => {
                this.setState({
                  showConfirmUploadToSparc: false,
                  selectedDocument: null,
                  selectedDocumentType: null,
                  selectedDocumentOriginalName: null
                })
                this.props.setUploadedDocument()
                this.props.setDriverError()
                this.props.getDocumentListSparcMissing()
              }}
              animation={false}
              size='lg'
              centered
            >
              <Modal.Header>
                <Modal.Title>
                  <FontAwesomeIcon icon={faFile} className={`mr-2 ${styles.menuITemIcon}`} />
                  { !this.state.viewDocumentOnly ?
                      `Are you sure you want to upload ${this.state.selectedDocumentOriginalName} to SPARC?`
                    :
                      `${this.state.selectedDocumentOriginalName}`
                  }
                </Modal.Title>
                <div>
                  <Button
                    size='sm'
                    variant='outline-secondary'
                    onClick={() => {
                      this.setState({
                        showConfirmUploadToSparc: false,
                        selectedDocument: null,
                        selectedDocumentType: null,
                        selectedDocumentOriginalName: null
                      })
                      this.props.setUploadedDocument()
                      this.props.setDriverError()
                      this.props.getDocumentListSparcMissing()
                    }}
                  ><FontAwesomeIcon icon={faTimes} />
                  </Button>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  {this._renderPDFView()}
                  {this._renderUploaded()}
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='secondary'
                  size={'sm'}
                  className='btn-semi-wide ml-2'
                  onClick={() => {
                    this.setState({
                      showConfirmUploadToSparc: false,
                      selectedDocument: null,
                      selectedDocumentType: null,
                      selectedDocumentOriginalName: null
                    })
                    this.props.setUploadedDocument()
                    this.props.setDriverError()
                    this.props.getDocumentListSparcMissing()
                  }}
                >{driver.uploadedDriverDocument || driver.error ? 'Close' : 'Cancel'}</Button>
                { !this.state.viewDocumentOnly ?
                  <Button
                    variant='primary'
                    size={'sm'}
                    disabled={driver.loading || driver.uploadedDriverDocument || driver.error}
                    className='btn-semi-wide ml-2'
                    onClick={ () => {
                      this.props.uploadDriverDocumentToSparc(this.state.selectedDocumentDriverId, this.state.selectedDocument, true)
                    }}
                  >Upload to Sparc</Button>
                  : ''
                }
              </Modal.Footer>
            </Modal>
          )
        }
      }
    }

    _renderPDFView () {
      const { driverDocuments, driver } = this.props
      if (driverDocuments.document && !driver.uploadedDriverDocument && !driver.error) {
        return <PdfDocument
          consent={{}}
          pdf={driverDocuments.document}
          emailButton=''
        />
      }
    }

    _renderUploaded () {
      const { driver: { uploadedDriverDocument, error } } = this.props

      if (!uploadedDriverDocument && !error) { return '' }

      if (error) {
        return (
          <React.Fragment>
            <Row>
              <Col xs={12} className='alert alert-danger'>
                Document was not uploaded successfully to Sparc.  Error information is below.
                <br></br>
                {error}
              </Col>
            </Row>
          </React.Fragment>
        )
      }
      else {
        return (
          <React.Fragment>
            <Row>
              <Col xs={12} className='alert alert-success'>
                Successfully uploaded document to Sparc.
              </Col>
            </Row>
          </React.Fragment>
        )
      }
    }
}

const mapStateToProps = (state) => {
  const { auth, companies, dotNumbers, driver, drivers, driversFilters, driverDocuments } = state
  return { auth, companies, dotNumbers, driver, drivers, driversFilters, driverDocuments }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.dotNumbers, ...actions.companies, ...actions.drivers, ...actions.driversFilters, ...actions.driver, ...actions.driverDocuments },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DriversTableMissingSparcDoumentLink)

DriversTableMissingSparcDoumentLink.propTypes = {
  driver: PropTypes.object.isRequired,
  drivers: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  loadDotNumbers: PropTypes.func.isRequired,
  loadCompanies: PropTypes.func.isRequired,
  reloadDrivers: PropTypes.func.isRequired,
  driversFilters: PropTypes.object.isRequired,
  driverDocuments: PropTypes.object.isRequired,
  getDocument: PropTypes.func.isRequired,
  setDocument: PropTypes.func.isRequired,
  uploadDriverDocument: PropTypes.func.isRequired,
  uploadDriverDocumentToSparc: PropTypes.func.isRequired,
  getDocumentListSparcMissing: PropTypes.func.isRequired,
  setDriverError: PropTypes.func.isRequired,
  setUploadedDocument: PropTypes.func.isRequired
}