import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import moment from 'moment'
import { fullName, displayDateTime, displayQueryStatus } from '../../utils'

import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import * as actions from '../../actions'

class PerformQuery extends Component {
  state = {
    dateCompleted: new Date(),
    queryStatus: null
  }

  componentDidMount () {
    this.props.setPerformedQuery()
    this.props.setError()

    const { driver: { driver } } = this.props
    if (!driver) { return }
    this.setState({ queryStatus: driver.queryStatus })
  }

  componentDidUpdate () {
    const { driver: { performedQuery } } = this.props
    if (performedQuery) {
      this.props.done()
    }
  }

  _renderButtonContents () {
    const { driver: { loading } } = this.props
    if (!loading) { return 'Save' }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
      Saving
    </React.Fragment>
  }

  _renderError () {
    const { driver: { error } } = this.props
    if (!error) { return '' }
    return <Row>
      <Col className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  _renderQueryStatus () {
    const { queryStatus } = this.state
    if (!queryStatus) { return }
    return `${displayQueryStatus(queryStatus.queryType)} query requested on ${displayDateTime(queryStatus.lastCreated, true)}`
  }

  _renderQueryDateTime () {
    return <DatePicker
      showTimeInput
      dateFormat='M/d/yyyy h:mm a'
      timeFormat='h:mm a'
      placeholderText='Enter date when query was performed'
      selected={this.state.dateCompleted}
      maxDate={new Date()}
      onChange={(date) => this.setState({ dateCompleted: date })}
      dropdownMode='scroll'
    />
  }

  render () {
    const { driver: { driver, loading }, done } = this.props
    if (!driver) { return }

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Perform a query
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Name</Col>
              <Col xs={8} md={6}>{fullName(driver)}</Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Drivers license number</Col>
              <Col xs={8} md={6}>{driver.driversLicenseNumber}</Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>State</Col>
              <Col xs={8} md={6}>{driver.state}</Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Query status</Col>
              <Col xs={8} md={6}>{this._renderQueryStatus()}</Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Query performed on</Col>
              <Col xs={8} md={6}>{this._renderQueryDateTime()}</Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => done()}
          >Cancel</Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={loading || !this.state.dateCompleted}
            className='btn-wide'
            onClick={() => {
              const completed = moment(this.state.dateCompleted).utc().toJSON()
              this.props.performQuery(driver._id, { queryType: this.state.queryStatus.queryType, completed })
            }}
          >{this._renderButtonContents()}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { driver } = state
  return { driver }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformQuery)

PerformQuery.propTypes = {
  done: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  performQuery: PropTypes.func.isRequired,
  setPerformedQuery: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}