const driversFilters = {
  /**
   * Sets the selected state of the given driver and returns a new set
   * of selected drivers
   */
  updateSelectedDriversWithDriver: (selectedDrivers, driverId, isSelected) => {
    const result = new Set(selectedDrivers)
    if (isSelected) {
      result.add(driverId)
    } else {
      result.delete(driverId)
    }
    return result
  },

  /**
   * Toggles the selected state of the given driver and returns a new set
   * of selected drivers
   */
  updateSelectedDriversWithToggledDriver: (selectedDrivers, driverId) => {
    const isSelected = selectedDrivers.has(driverId)
    return driversFilters.updateSelectedDriversWithDriver(selectedDrivers, driverId, !isSelected)
  },

  /**
   * Returns a new set of selected drivers given the list of drivers to be selected
   */
  replaceAllSelectedDrivers: (drivers) => {
    return new Set((drivers || []).map((d) => d._id))
  }
}

export { driversFilters }