import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Form,
  Spinner
} from 'react-bootstrap'
import ModalBackdropForm from './common/modalBackdropForm'
import * as actions from '../actions'

class PasswordReset extends Component {

  state = {
    password: '',
    passwordConfirmation: ''
  }

  componentDidMount () {
    this.props.setError()
    this.props.setPasswordReset()
  }

  _handleOnSubmit () {
    const { match: { params: { passwordResetToken } } } = this.props
    this.props.reset({ ...this.state, passwordResetToken })
  }

  _renderThankYouMessage () {
    const { createAccount: { accountCreated } } = this.props
    if (!accountCreated) { return }
    return (
      <Col xs={12} className='mb-3'>
        <h3 className='text-callout subheading'>Account creation successful. Thank you!</h3>
      </Col>
    )
  }

  _renderMessage () {
    const { auth: { error, passwordReset }, createAccount: { accountCreated } } = this.props
    if (error) {
      return (
        <Col xs={12} className='mb-3 alert alert-danger'>
          {error}
        </Col>
      )
    }
    if (passwordReset) {
      return (
        <Col xs={12} className='mb-3 alert alert-success'>
          {accountCreated ? 'Your account password has been successfully set. Log in using the link below.' : 'Your password has been successfully reset. Log in using the link below.' }
        </Col>
      )
    }
    return (
      <Col xs={12} className='mb-3'>
        <p>{accountCreated ? 'Choose a password for your account.' : 'Choose a new password.'}</p>
        <p>Passwords must be at least 10 characters long and contain at least three of the four types of characters: lowercase letters, uppercase letters, numbers, symbols.</p>
      </Col>
    )
  }

  _renderButtonContents () {
    const { auth: { loading }, createAccount: { accountCreated } } = this.props

    if (loading) {
      return (
        <React.Fragment>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          {accountCreated ? 'Setting account password' : 'Resetting password' }
        </React.Fragment>
      )
    }
    return accountCreated ? 'Set account password' : 'Reset password'
  }

  _renderForm () {
    const { auth: { loading, passwordReset }, createAccount: { accountCreated } } = this.props
    if (passwordReset) { return '' }
    return (
      <React.Fragment>
        <Col xs={12}>
          <Form.Group>
            <Form.Label>{accountCreated ? 'Password' : 'New password'}</Form.Label>
            <Form.Control
              type='password'
              autoComplete='new-password'
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              required
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group>
            <Form.Label>{accountCreated ? 'Confirm password' : 'Confirm new password' }</Form.Label>
            <Form.Control
              type='password'
              autoComplete='new-password'
              value={this.state.passwordConfirmation}
              onChange={(e) => this.setState({ passwordConfirmation: e.target.value })}
              required
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} className='my-3'>
          <Button
            variant='primary'
            block
            type='submit'
            disabled={loading}
          >{this._renderButtonContents()}</Button>
        </Col>
      </React.Fragment>
    )
  }

  render () {
    return (
      <ModalBackdropForm
        onSubmit={() => this._handleOnSubmit()}
        displayLoginLink={true}
        displayHelpLink={true}
      >
        {this._renderThankYouMessage()}
        {this._renderMessage()}
        {this._renderForm()}
      </ModalBackdropForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { auth, createAccount } = state
  const { match } = ownProps
  return { auth, createAccount, match }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.auth, ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)

PasswordReset.propTypes = {
  auth: PropTypes.object.isRequired,
  createAccount: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setPasswordReset: PropTypes.func.isRequired
}