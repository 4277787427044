import { actionTypes } from '../actions/actionTypes'

const initialState = {
  userInfo: null,
  serviceQuestions: null,
  consent: null,
  options: null,
  accountCreated: null,
  loading: null,
  error: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.CREATE_ACCOUNT.SET_USER_INFO:
    return { ...state, userInfo: action.value }
  case actionTypes.CREATE_ACCOUNT.SET_SERVICE_QUESTIONS:
    return { ...state, serviceQuestions: action.value }
  case actionTypes.CREATE_ACCOUNT.SET_CONSENT:
    return { ...state, consent: action.value }
  case actionTypes.CREATE_ACCOUNT.SET_OPTIONS:
    return { ...state, options: action.value }
  case actionTypes.CREATE_ACCOUNT.SET_ACCOUNT_CREATED:
    return { ...state, accountCreated: action.value }
  case actionTypes.CREATE_ACCOUNT.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.CREATE_ACCOUNT.SET_ERROR:
    return { ...state, error: action.value }
  default:
    return state
  }
}