const actionTypes = {
  ACCOUNT: {
    SET_ACCOUNT: 'ACCOUNT/SET_ACCOUNT',
    SET_ACCOUNT_EDITED: 'ACCOUNT/SET_ACCOUNT_EDITED',
    SET_ERROR: 'ACCOUNT/SET_ERROR',
    SET_LOADING: 'ACCOUNT/SET_LOADING'
  },
  AUTH: {
    SET_USER: 'AUTH/SET_USER',
    SET_ERROR: 'AUTH/SET_ERROR',
    SET_FORGOT_SENT: 'AUTH/SET_FORGOT_SENT',
    SET_PASSWORD_RESET: 'AUTH/SET_PASSWORD_RESET',
    SET_LOADING: 'AUTH/SET_LOADING',
    SET_TOKEN: 'AUTH/SET_TOKEN'
  },
  COMPANY: {
    SET_COMPANY: 'COMPANY/SET_COMPANY',
    SET_EDITED: 'COMPANY/SET_EDITED',
    SET_LOADING: 'COMPANY/SET_LOADING',
    SET_ERROR: 'COMPANY/SET_ERROR'
  },
  COMPANIES: {
    SET_COMPANIES: 'COMPANIES/SET_COMPANIES',
    SET_LOADING: 'COMPANIES/SET_LOADING',
    SET_ERROR: 'COMPANIES/SET_ERROR'
  },
  CONSENT: {
    SET_CONSENT: 'CONSENT/SET_CONSENT',
    SET_LOADING: 'CONSENT/SET_LOADING',
    SET_ERROR: 'CONSENT/SET_ERROR'
  },
  CONSENT_CONTENT: {
    SET_CONSENT_CONTENT: 'CONSENT_CONTENT/SET_CONSENT_CONTENT',
    SET_LOADING: 'CONSENT_CONTENT/SET_LOADING',
    SET_ERROR: 'CONSENT_CONTENT/SET_ERROR'
  },
  CONTACT: {
    SET_ERROR: 'CONTACT/SET_ERROR',
    SET_LOADING: 'CONTACT/SET_LOADING',
    SET_SENT: 'CONTACT/SET_SENT'
  },
  CREATE_ACCOUNT: {
    SET_USER_INFO: 'CREATE_ACCOUNT/SET_USER_INFO',
    SET_SERVICE_QUESTIONS: 'CREATE_ACCOUNT/SET_SERVICE_QUESTIONS',
    SET_CONSENT: 'CREATE_ACCOUNT/SET_CONSENT',
    SET_OPTIONS: 'CREATE_ACCOUNT/SET_OPTIONS',
    SET_ACCOUNT_CREATED: 'CREATE_ACCOUNT/ACCOUNT_CREATED',
    SET_ERROR: 'CREATE_ACCOUNT/SET_ERROR',
    SET_LOADING: 'CREATE_ACCOUNT/SET_LOADING'
  },
  DOT_NUMBERS: {
    SET_DOT_NUMBERS: 'DOT_NUMBERS/SET_DOT_NUMBERS',
    SET_LOADING: 'DOT_NUMBERS/SET_LOADING',
    SET_ERROR: 'DOT_NUMBERS/SET_ERROR'
  },
  DOT_NUMBERS_FILTERS: {
    SET_PAGE: 'DOT_NUMBERS_FILTERS/SET_PAGE',
    SET_SEARCH: 'DOT_NUMBERS_FILTERS/SET_SEARCH',
    SET_SORT: 'DOT_NUMBERS_FILTERS/SET_SORT',
    SET_COMPANIES: 'DOT_NUMBERS_FILTERS/SET_COMPANIES',
    SET_DOT_NUMBERS: 'DOT_NUMBERS_FILTERS/SET_DOT_NUMBERS',
    SET_SELECTED_DOT_NUMBERS: 'DOT_NUMBERS_FILTERS/SET_SELECTED_DOT_NUMBERS',
    CLEAR: 'DOT_NUMBERS_FILTERS/CLEAR'
  },
  DRIVER_CONSENT: {
    SET_CONSENT: 'DRIVER_CONSENT/SET_CONSENT',
    SET_EXISTS: 'DRIVER_CONSENT/SET_EXISTS',
    SET_LOADING: 'DRIVER_CONSENT/SET_LOADING',
    SET_VALIDATION_ERROR: 'DRIVER_CONSENT/SET_VALIDATION_ERROR',
    SET_COMPLETION_ERROR: 'DRIVER_CONSENT/SET_COMPLETION_ERROR',
    SET_EMAIL_ERROR: 'DRIVER_CONSENT/SET_EMAIL_ERROR',
    SET_EMAIL_SUCCESS: 'DRIVER_CONSENT/SET_EMAIL_SUCCESS'
  },
  DRIVER_DOCUMENTS: {
    SET_DOCUMENT_LIST: 'DRIVER_DOCUMENTS/SET_DOCUMENT_LIST',
    SET_DOCUMENT: 'DRIVER_DOCUMENTS/SET_DOCUMENT',
    SET_LOADING: 'DRIVER_DOCUMENTS/SET_LOADING',
    SET_LOADING_DOCUMENT: 'DRIVER_DOCUMENTS/SET_LOADING_DOCUMENT',
    SET_ERROR: 'DRIVER_DOCUMENTS/SET_ERROR'
  },
  DRIVER: {
    SET_DRIVER: 'DRIVER/SET_DRIVER',
    SET_LOADING: 'DRIVER/SET_LOADING',
    SET_ERROR: 'DRIVER/SET_ERROR',
    SET_CREATED_CONSENT: 'DRIVER/SET_CREATED_CONSENT',
    SET_MARKED_CONSENT_ON_FILE: 'DRIVER/SET_MARKED_CONSENT_ON_FILE',
    SET_PERFORMED_QUERY: 'DRIVER/SET_PERFORMED_QUERY',
    SET_SENT_NOTIFICATION: 'DRIVER/SET_SENT_NOTIFICATION',
    SET_CREATED_QUERY: 'DRIVER/SET_CREATED_QUERY',
    SET_CONSENT_URL: 'DRIVER/SET_CONSENT_URL',
    SET_CREATED: 'DRIVER/SET_CREATED',
    SET_EDITED: 'DRIVER/SET_EDITED',
    SET_UPLOADED_DOCUMENT: 'DRIVER/SET_UPLOADED_DOCUMENT'
  },
  DRIVERS: {
    SET_DRIVERS: 'DRIVERS/SET_DRIVERS',
    SET_LOADING: 'DRIVERS/SET_LOADING',
    SET_ERROR: 'DRIVERS/SET_ERROR'
  },
  DRIVERS_BULK_ACTIONS: {
    SET_DRIVERS: 'DRIVERS_BULK_ACTIONS/SET_DRIVERS',
    SET_CREATED_CONSENTS: 'DRIVERS_BULK_ACTIONS/SET_CREATED_CONSENTS',
    SET_SENT_NOTIFICATIONS: 'DRIVERS_BULK_ACTIONS/SET_SENT_NOTIFICATIONS',
    SET_CREATED_QUERIES: 'DRIVERS_BULK_ACTIONS/SET_CREATED_QUERIES',
    SET_UPDATED_QUERY_STATUSES: 'DRIVERS_BULK_ACTIONS/SET_UPDATED_QUERY_STATUSES',
    SET_CREATED_DRIVER_EXPORT: 'DRIVERS_BULK_ACTIONS/SET_CREATED_DRIVER_EXPORT',
    SET_LOADING: 'DRIVERS_BULK_ACTIONS/SET_LOADING',
    SET_ERROR: 'DRIVERS_BULK_ACTIONS/SET_ERROR',
    SET_PROCESSED_FMCSA_LIST: 'DRIVERS_BULK_ACTIONS/SET_PROCESSED_FMCSA_LIST'
  },
  DRIVERS_FILTERS: {
    SET_PAGE: 'DRIVERS_FILTERS/SET_PAGE',
    SET_SEARCH: 'DRIVERS_FILTERS/SET_SEARCH',
    SET_SORT: 'DRIVERS_FILTERS/SET_SORT',
    SET_COMPANIES: 'DRIVERS_FILTERS/SET_COMPANIES',
    SET_DOT_NUMBERS: 'DRIVERS_FILTERS/SET_DOT_NUMBERS',
    SET_ACTIVE: 'DRIVERS_FILTERS/SET_ACTIVE',
    SET_EMAIL: 'DRIVERS_FILTERS/SET_EMAIL',
    SET_STATES: 'DRIVERS_FILTERS/SET_STATES',
    SET_LAST_CONSENTED: 'DRIVERS_FILTERS/SET_LAST_CONSENTED',
    SET_PENDING_CONSENT: 'DRIVERS_FILTERS/SET_PENDING_CONSENT',
    SET_LAST_NOTIFIED: 'DRIVERS_FILTERS/SET_LAST_NOTIFIED',
    SET_PENDING_QUERY: 'DRIVERS_FILTERS/SET_PENDING_QUERY',
    SET_LAST_QUERIED: 'DRIVERS_FILTERS/SET_LAST_QUERIED',
    SET_SELECTED_DRIVERS: 'DRIVERS_FILTERS/SET_SELECTED_DRIVERS',
    CLEAR: 'DRIVERS_FILTERS/CLEAR',
    SET_QUERY_TYPES: 'DRIVERS_FILTERS/SET_QUERY_TYPES',
    SET_QUERY_STATUS: 'DRIVERS_FILTERS/SET_QUERY_STATUS',
    SET_QUERY_LAST_QUERIED_FROM: 'DRIVERS_FILTERS/SET_LAST_QUERIED_FROM',
    SET_QUERY_LAST_QUERIED_TO: 'DRIVERS_FILTERS/SET_LAST_QUERIED_TO' ,
    SET_LAST_QUERIED_NE:   'DRIVERS_FILTERS/SET_LAST_QUERIED_NE',
    SET_LAST_QUERIED_BETWEEN:   'DRIVERS_FILTERS/SET_LAST_QUERIED_BETWEEN'
  },
  ROSTERS: {
    SET_ERROR: 'ROSTERS/SET_ERROR',
    SET_LOADING: 'ROSTERS/SET_LOADING',
    SET_UPLOADED: 'ROSTERS/SET_UPLOADED'
  },
  USER: {
    SET_USER: 'USER/SET_USER',
    SET_ERROR: 'USER/SET_ERROR',
    SET_LOADING: 'USER/SET_LOADING'
  }
}

export { actionTypes }