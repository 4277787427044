import {
  Button,
  Row,
  Col,
  Container,
  Image,
  Modal,
  Spinner
} from 'react-bootstrap'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import { selectCustomStyles } from '../selectCustomStyles'
import logo from '../../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from '../../../actions'
import * as selectors from '../../../selectors'

class UploadDriverDocument extends Component {
  state = {
    documentType: null,
    file: null,
    formValid: false
  }

  componentDidMount () {
    this.props.setUploadedDocument()
    this.props.setError()
  }

  componentDidUpdate () {
    const { driver: { uploadedDriverDocument } } = this.props
    if (uploadedDriverDocument) {
      this.props.reloadDrivers()
      this.props.done()
    }
  }

  _renderError (error) {
    if (!error) { return '' }
    return <Row>
      <Col className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  _renderButtonContents () {
    const { driver: { loading } } = this.props
    if (!loading)
    {
      return 'Upload Document'
    }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
            Uploading
    </React.Fragment>
  }

  _onFileSelect = (acceptedFiles) => {
    const file = (!acceptedFiles || acceptedFiles.length !== 1) ? null : acceptedFiles[0]
    this.setState({ file: file }, () => {
      this._enableUploadButton()
    })
  }

  _getDropZoneClass (isDragActive) {
    if (isDragActive)
      return 'dropzone--isActive'
    else
      return 'dropzone'
  }

  _handleModalState (value) {
    this.setState({ documentType: value }, () => {
      this._enableUploadButton()
    })
  }

  _enableUploadButton () {
    if (this.state.documentType !== null && this.state.file !== null)
      this.setState({ formValid: true })
    else
      this.setState({ formValid: false })
  }

  _getFileInfo () {
    if (this.state.file === null)
      return
    return `${this.state.file.name} (${this.state.file.size} bytes)`
  }

  render () {
    const { driver: { driver, loading, error }, done, isConcordeAdmin } = this.props
    if (!driver) {return ''}

    const fileInfo = this._getFileInfo()

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Upload driver document
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError(error)}
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs='auto' sm={2} className='text-right'>Document Type</Col>
              <Col xs={8}>
                <Select
                  styles={selectCustomStyles}
                  isSearchable={false}
                  options={selectors.driverDocumentTypes.getAll().map((t) => ({ value: t, label: t }))}
                  onChange={(v) => this._handleModalState(v.value)}
                ></Select>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs='auto' sm={2} className='text-right'></Col>
              <Col xs={8} className='d-flex justify-content-center align-items-center'>
                <Dropzone
                  onDrop={this._onFileSelect}
                  accept={'application/pdf, image/*'}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return <div
                      style={{
                        width: '100%',
                        height: '100px'
                      }}
                    >
                      <div {...getRootProps()}
                        className={this._getDropZoneClass(isDragActive)}
                        style={{ width: '100%', height: '100%' }}
                      >
                        <input {...getInputProps()} />
                        {isDragActive
                          ? <div className={'text-center mt-2'}>Drop file here...</div>
                          :
                          <div className={'text-center mt-2'}>Drag a PDF/Image file and drop it here, or click here to select a
                              file</div>
                        }
                      </div>
                    </div>
                  }}
                </Dropzone>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs='auto' sm={2} className='text-right'>File Selected</Col>
              <Col xs={8}>
                {fileInfo}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className={'btn-semi-wide'}
            onClick={() => this.props.done()}
          >Cancel
          </Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={loading || !this.state.formValid}
            className='btn-wide'
            onClick={() => this.props.uploadDriverDocument(driver._id, this.state, isConcordeAdmin)}
          >{this._renderButtonContents()}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { driver, drivers } = state
  return { driver, drivers }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver, ...actions.drivers },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDriverDocument)

UploadDriverDocument.propTypes = {
  done: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  drivers: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  setUploadedDocument: PropTypes.func.isRequired,
  uploadDriverDocument: PropTypes.func.isRequired,
  reloadDrivers: PropTypes.func.isRequired,
  isConcordeAdmin: PropTypes.bool
}