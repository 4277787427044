import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row
} from 'react-bootstrap'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CreateCompanyUser extends Component {
  state = {
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    email: this.props.email,
    isConcordeAdmin: this.props.isConcordeAdmin,
    password: null,
    confirmPassword: null
  }

  handleFirstName = event =>
    this.setState({ firstName: event.target.value })

  handleLastName = event =>
    this.setState({ lastName: event.target.value })

  handleEmail = event =>
    this.setState({ email: event.target.value })

  handlePassword = event =>
    this.setState({ password: event.target.value })

  handleConfirmPassword = event =>
    this.setState({ confirmPassword: event.target.value })

  handleisConcordeAdmin = event =>
    this.setState({ isConcordeAdmin: event.target.checked })

  render () {
    const { hide, back, next, companyName } = this.props
    return (
      <Modal
        show={true}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo' />
            Add user for company
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => hide()}
            ><FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='pb-1 mx-3 align-items-center' style={{ marginBottom: '5px' }}>
              <Col xs={4} className='text-right'>Company Name<span style={{ color: 'white' }}> *</span></Col>
              <Col xs={8} md={6} style={{ fontWeight: 'bold' }}>
                {companyName}
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center' style={{ marginBottom: '5px' }}>
              <Col xs={4} className='text-right'>First Name<span style={{ color: 'red' }}> *</span></Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  defaultValue={this.state.firstName}
                  onChange={this.handleFirstName}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center' style={{ marginBottom: '5px' }}>
              <Col xs={4} className='text-right'>Last Name<span style={{ color: 'red' }}> *</span></Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  defaultValue={this.state.lastName}
                  onChange={this.handleLastName}
                ></Form.Control>
              </Col>
            </Row><Row className='pb-1 mx-3 align-items-center' style={{ marginBottom: '5px' }}>
              <Col xs={4} className='text-right'>Email<span style={{ color: 'red' }}> *</span></Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  defaultValue={this.state.email}
                  onChange={this.handleEmail}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center' style={{ marginBottom: '5px' }}>
              <Col xs={4} className='text-right'>Password<span style={{ color: 'red' }}> *</span></Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='password'
                  defaultValue={this.state.password}
                  onChange={this.handlePassword}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center' style={{ marginBottom: '5px' }}>
              <Col xs={4} className='text-right'>Confirm Password<span style={{ color: 'red' }}> *</span></Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='password'
                  defaultValue={this.state.confirmPassword}
                  onChange={this.handleConfirmPassword}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'></Col>
              <Col xs={8} md={6}>
                <Form.Check
                  inline
                  label='This user is an administrator'
                  type='checkbox'
                  id='inline-checkbox-active'
                  defaultChecked={this.state.isConcordeAdmin}
                  onChange={this.handleisConcordeAdmin}
                  className='mr-3'
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => hide()}
          >Cancel</Button>
          <Button
            variant='outline-secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => back(
              this.state.firstName,
              this.state.lastName,
              this.state.email,
              this.state.isConcordeAdmin)}
          >Back</Button>
          <Button
            variant='primary'
            size={'sm'}
            className='btn-wide'
            onClick={() => next(
              this.state.firstName,
              this.state.lastName,
              this.state.email,
              this.state.password,
              this.state.confirmPassword,
              this.state.isConcordeAdmin)}
            disabled={!this.state.firstName || this.state.firstName.trim() === ''
              || !this.state.lastName || this.state.lastName.trim() === ''
              || !this.state.email || this.state.email.trim() === ''
              || !this.state.password || this.state.password.trim() === ''
              || !this.state.confirmPassword || this.state.confirmPassword.trim() === ''
            }
          >Next</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect()(CreateCompanyUser)

CreateCompanyUser.propTypes = {
  hide: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  isConcordeAdmin: PropTypes.bool
}
