import { actionTypes } from '../actions/actionTypes'

const initialState = {
  driver: null,
  createdConsent: null,
  markedConsentOnFile: null,
  sentNotification: null,
  uploadedDriverDocument: null,
  createdQuery: null,
  performedQuery: null,
  consentUrl: null,
  created: null,
  edited: null,
  loading: null,
  error: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.DRIVER.SET_DRIVER:
    return { ...state, driver: action.value }
  case actionTypes.DRIVER.SET_CREATED_CONSENT:
    return { ...state, createdConsent: action.value }
  case actionTypes.DRIVER.SET_MARKED_CONSENT_ON_FILE:
    return { ...state, markedConsentOnFile: action.value }
  case actionTypes.DRIVER.SET_SENT_NOTIFICATION:
    return { ...state, sentNotification: action.value }
  case actionTypes.DRIVER.SET_CREATED_QUERY:
    return { ...state, createdQuery: action.value }
  case actionTypes.DRIVER.SET_PERFORMED_QUERY:
    return { ...state, performedQuery: action.value }
  case actionTypes.DRIVER.SET_CONSENT_URL:
    return { ...state, consentUrl: action.value }
  case actionTypes.DRIVER.SET_CREATED:
    return { ...state, created: action.value }
  case actionTypes.DRIVER.SET_EDITED:
    return { ...state, edited: action.value }
  case actionTypes.DRIVER.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.DRIVER.SET_ERROR:
    return { ...state, error: action.value }
  case actionTypes.DRIVER.SET_UPLOADED_DOCUMENT:
    return { ...state, uploadedDriverDocument: action.value }
  default:
    return state
  }
}