import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'

const renderError = ({ error }) => {
  if (!error) { return '' }
  return <Row>
    <Col className='alert alert-danger'>
      {error}
    </Col>
  </Row>
}
renderError.propTypes = {
  error: PropTypes.string
}

const renderAcceptButtonContents = ({ loading, acceptText, acceptLoadingText }) => {
  if (!loading) { return acceptText }
  return  <React.Fragment>
    <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
    {acceptLoadingText}
  </React.Fragment>
}
renderAcceptButtonContents.propTypes = {
  loading: PropTypes.bool,
  acceptText: PropTypes.string,
  acceptLoadingText: PropTypes.string
}

const ModalConfirmation = ({
  show,
  header,
  text,
  cancelText = 'Cancel',
  onCancel,
  showCancel = true,
  acceptText = 'Continue',
  acceptLoadingText = 'Processing',
  onAccept,
  showAccept = true,
  loading = false,
  error
}) => {
  return (
    <Modal
      show={show}
      onHide={() => onCancel()}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
          {header}
        </Modal.Title>
        <Button
          size='sm'
          variant='outline-secondary'
          title='Close'
          onClick={() => onCancel()}
        ><FontAwesomeIcon icon={faTimes}/>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {renderError({ error })}
          <Row>
            <Col>
              {text}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {!showCancel ? '' :
          <Button
            variant='secondary'
            size={'sm'}
            className={showAccept ? 'btn-semi-wide' : 'btn-wide'}
            onClick={() => onCancel()}
          >{cancelText}
          </Button>
        }
        {!showAccept ? '' :
          <Button
            variant='primary'
            size={'sm'}
            disabled={loading}
            className='btn-wide'
            onClick={() => onAccept ? onAccept() : null }
          >{renderAcceptButtonContents({ loading, acceptText, acceptLoadingText })}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirmation

ModalConfirmation.displayName = 'ModalConfirmation'
ModalConfirmation.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes
    .oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  showCancel: PropTypes.bool,
  acceptText: PropTypes.string,
  acceptLoadingText: PropTypes.string,
  onAccept: PropTypes.func,
  showAccept: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string
}
