import { actionTypes } from '../actions/actionTypes'

const initialState = {
  error: null,
  loading: null,
  uploaded: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.ROSTERS.SET_ERROR:
    return { ...state, error: action.value }
  case actionTypes.ROSTERS.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.ROSTERS.SET_UPLOADED:
    return { ...state, uploaded: action.value }
  default:
    return state
  }
}