import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const renderTooltip = ({ content, show, ...rest }) => {
  return (
    <Tooltip {...rest} show={show.toString()}>{content}</Tooltip>
  )
}
renderTooltip.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  show: PropTypes.bool
}

const HelpTooltip = ({ content }) => {
  return (
    <OverlayTrigger
      placement='auto'
      trigger={['focus']}
      overlay={(props) => renderTooltip({ content, ...props })}
    >
      <Button
        size='sm'
        variant='white'
        style={{ margin: '-3px 0 -3px 8px', padding: '0 0 2px' }}
        tabIndex='-1'
      ><FontAwesomeIcon
          icon={faQuestionCircle}
          className='text-callout'
        /></Button>
    </OverlayTrigger>
  )
}

export default HelpTooltip

HelpTooltip.displayName = 'HelpTooltip'
HelpTooltip.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]).isRequired
}
