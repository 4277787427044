import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({
  title,
  subtitle,
  percentDone
}) => {
  return (
    <div className='mt-2 mb-4'>
      <div>
        <h3 className='text-callout subheading'>{title}</h3>
      </div>
      <div className='w-100 rounded bg-light3 my-2' style={{ height: '25px' }}>
        <div className='h-100 rounded-lg bg-callout' style={{ width: `${percentDone}%` }} />
      </div>
      <div>
        <p className='text-callout'>{subtitle}</p>
      </div>
    </div>
  )
}

export default ProgressBar

ProgressBar.displayName = 'ProgressBar'
ProgressBar.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  percentDone: PropTypes.number.isRequired
}
