import { actionTypes } from './actionTypes'
import {  requestDotNumbers } from '../services/apiService'
import * as selectors from '../selectors'


const dotNumbers = {
  // setDOTNumbers: (dotNumbers) => ({
  //  type: actionTypes.DOT_NUMBERS.SET_DOT_NUMBERS,
  //  value: dotNumbers
  // }),

  // setDOTNumbers: (drivers) => ({
  //   type: actionTypes.DOT_NUMBERS.SET_DOT_NUMBERS,
  //   value: drivers
  // }),

  setDOTNumbers: (value) => ({
    type: actionTypes.DOT_NUMBERS.SET_DOT_NUMBERS,
    value: value
  }),

  setError: (error) => ({
    type: actionTypes.DOT_NUMBERS.SET_ERROR,
    value: error
  }),

  setLoading: (loading) => ({
    type: actionTypes.DOT_NUMBERS.SET_LOADING,
    value: loading
  }),

  reloadDOTNumbers: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      const params = selectors.dotNumbersFilters.getFilterParams(getState())
      dispatch(dotNumbers.setLoading(true))
      dispatch(dotNumbers.setError())
      const data = await requestDotNumbers(token, params)
      if(data && data.dotNumbers){
        dispatch(dotNumbers.setDOTNumbers(data))
      } else if (data && data.error){
        dispatch(dotNumbers.setError(data.error))
      }
      dispatch(dotNumbers.setLoading(false))
    }
  },

  debouncedReloadDOTNumbers: () => {
    const thunk = (dispatch) => {
      dispatch(dotNumbers.reloadDOTNumbers())
    }
    thunk.meta = {
      debounce: {
        time: 500,
        key: 'DEBOUNCED_RELOAD_DOTNUMBERS'
      }
    }
    return thunk
  },

  loadDotNumbers: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(dotNumbers.setLoading(true))
      dispatch(dotNumbers.setError())
      const data = await requestDotNumbers(token)
      if(data && data.dotNumbers){
        dispatch(dotNumbers.setDOTNumbers(data))
      } else if (data && data.error) {
        dispatch(dotNumbers.setError(data.error))
      }
      dispatch(dotNumbers.setLoading(false))
    }
  },

  clear: () => {
    return (dispatch) => {
      dispatch(dotNumbers.setDOTNumbers({ dotNumbers: null, pages: {} }))
    }
  }
}

export { dotNumbers }
