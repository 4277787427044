import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { displayQueryStatus, displayQueryType } from '../../../utils'
import moment from 'moment'
import { omit, groupBy, isEmpty } from 'lodash'

import {
  Button,
  Col,
  Form,
  Image,
  Modal,
  Row
} from 'react-bootstrap'
import Select from 'react-select'
import { selectCustomStyles } from '../selectCustomStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../../images/drivers-clearinghouse-logo-small.png'

import * as actions from '../../../actions'
import * as selectors from '../../../selectors'

class DriversTableFilters extends Component {
  state = {
    lastConsentedOn: moment().subtract(1, 'y').format('YYYY-MM-DD'),
    lastNotifiedOn: moment().subtract(1, 'w').format('YYYY-MM-DD')
  }

  componentDidMount () {
    this.setState(this.props.driversFilters)
    const { driversFilters: { lastConsented, lastNotified } } = this.props
    if (lastConsented) { this.setState({ lastConsentedOn: lastConsented }) }
    if (lastNotified) { this.setState({ lastNotifiedOn: lastNotified }) }
  }

  // the options to display in the DOT number select
  _dotNumberOptions () {
    const { dotNumbers: { dotNumbers }, companies: { companies: allCompanies } } = this.props
    const { companies } = this.state
    const companyMap = selectors.companies.getCompanyMap(this.props)

    // for concorde admins who are viewing a list of companies, group the dot numbers by company
    if (!isEmpty(allCompanies)) {
      return Object.entries(groupBy(dotNumbers, 'company'))
        .filter(([companyId]) => isEmpty(companies) || companies.includes(companyId))
        .map(([companyId, dotNumbers]) => ({
          label: companyMap[companyId].name,
          options: dotNumbers.map((d) => ({ value: d._id, label: d.name }))
        }))
    }

    // otherwise, don't worry about grouping them
    return dotNumbers.map((d) => ({ value: d._id, label: d.name }))
  }

  _renderFilters () {
    const { dotNumbers: { dotNumbers: allDotNumbers }, companies: { companies: allCompanies } } = this.props
    if (!allDotNumbers) { return }

    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
    const dotNumberMap = selectors.dotNumbers.getDotNumberMap(this.props)
    const companyMap = selectors.companies.getCompanyMap(this.props)

    const {
      companies, dotNumbers, active, email, states = [], queryTypes = [], queryStatuses = [],
      lastConsented, lastConsentedOn, pendingConsent,
      lastNotified, lastNotifiedOn,
      pendingQuery, lastQueriedNE, lastQueriedBetween, lastQueriedFrom, lastQueriedTo
    } = this.state

    return (
      <React.Fragment>
        {!isConcordeAdmin ? '' :
          <Row className='pb-3 mx-3 border-bottom align-items-center'>
            <Col xs={4} className='d-flex justify-content-end'>Companies</Col>
            <Col xs={8} md={6}>
              <Select
                styles={selectCustomStyles}
                isClearable={true}
                isSearchable={true}
                isMulti={true}
                options={allCompanies.map((d) => ({ value: d._id, label: d.name }))}
                value={(companies || []).map((d) => ({ value: d, label: companyMap[d].name }))}
                onChange={(value) => this.setState({ companies: (value || []).map((v) => v.value), dotNumbers: [] })}
                filterOption={(option, inputValue) => (option.label.toString().toUpperCase().match(inputValue.toUpperCase()) || []).length > 0}
              ></Select>
            </Col>
          </Row>
        }
        <Row className='py-3 mx-3 border-bottom align-items-center'>
          <Col xs={4} className='d-flex justify-content-end'>DOT numbers</Col>
          <Col xs={8} md={6}>
            <Select
              styles={selectCustomStyles}
              isClearable={true}
              isSearchable={true}
              isMulti={true}
              options={this._dotNumberOptions()}
              value={(dotNumbers || []).map((d) => ({ value: d, label: dotNumberMap[d].name }))}
              onChange={(value) => this.setState({ dotNumbers: (value || []).map((v) => v.value) })}
              filterOption={(option, inputValue) => (option.label.toString().match(inputValue) || []).length > 0}
            ></Select>
          </Col>
        </Row>
        <Row className='py-3 mx-3 border-bottom align-items-center'>
          <Col xs={4} className='d-flex justify-content-end'>States</Col>
          <Col xs={8} md={6}>
            <Select
              styles={selectCustomStyles}
              isClearable={true}
              isSearchable={true}
              isMulti={true}
              options={selectors.states.getAll().map((s) => ({ value: s, label: s }))}
              value={states.map((s) => ({ value: s, label: s }))}
              onChange={(value) => this.setState({ states: value.map((v) => v.value) })}
            ></Select>
          </Col>
        </Row>
        <Row className='pt-3 pb-2 mx-3 border-bottom'>
          <Col xs={12} lg={2}><strong>Drivers</strong></Col>
          <Col xs={4} lg={2} className='d-flex justify-content-end'>Active</Col>
          <Col xs={8} md={6} className='pb-3 mb-3 border-bottom'>
            <Form.Check
              inline
              label='Yes'
              type='checkbox'
              id='inline-checkbox-active-yes'
              checked={active === true}
              onChange={(e) => this.setState({ active: e.target.checked ? true : null })}
              className='mr-3'
            />
            <Form.Check
              inline
              label='No'
              type='checkbox'
              id='inline-checkbox-active-no'
              checked={active === false}
              onChange={(e) => this.setState({ active: e.target.checked ? false : null })}
            />
          </Col>
          <Col xs={4} className='d-flex justify-content-end'>Email</Col>
          <Col xs={8} md={6}>
            <Form.Check
              inline
              label='Yes'
              type='checkbox'
              id='inline-checkbox-email-yes'
              checked={email === true}
              onChange={(e) => this.setState({ email: e.target.checked ? true : null })}
              className='mr-3'
            />
            <Form.Check
              inline
              label='No'
              type='checkbox'
              id='inline-checkbox-email-no'
              checked={email === false}
              onChange={(e) => this.setState({ email: e.target.checked ? false : null })}
            />
          </Col>
        </Row>
        <Row className='pt-3 pb-2 mx-3'>
          <Col xs={12} lg={2}><strong>Queries</strong></Col>
          <Col xs={4} lg={2} className='d-flex justify-content-end text-nowrap'>Query types</Col>
          <Col xs={8} md={6} className='pb-3 mb-0 border-bottom'>

            <Select
              styles={selectCustomStyles}
              isClearable={true}
              isSearchable={true}
              isMulti={true}
              options={selectors.queries.getTypes().map((qt) => ({ value: qt, label: displayQueryType(qt) }))}
              value={(queryTypes || []).map((d) => ({ value: d, label: displayQueryType(d) }))}
              onChange={(value) => {
                this.setState({ queryTypes: (value || []).map((v) => v.value) })
              }}
            ></Select>
          </Col>
        </Row>

        <Row className='pt-3 pb-2 mx-3'>
          <Col xs={12} lg={2}></Col>
          <Col xs={4} lg={2} className='d-flex justify-content-end text-nowrap'>Query status</Col>
          <Col xs={8} md={6} className='pb-3 mb-0 border-bottom'>

            <Select
              styles={selectCustomStyles}
              isClearable={true}
              isSearchable={true}
              isMulti={true}
              options={selectors.queries.getStatuses().map((qt) => ({ value: qt, label: displayQueryStatus(qt) }))}
              value={(queryStatuses || []).map((d) => ({ value: d, label: displayQueryStatus(d) }))}
              onChange={(value) => {
                this.setState({ queryStatuses: (value || []).map((v) => v.value) })
              }}
            ></Select>

          </Col>
        </Row>
        <Row className='pt-3 pb-2 mx-3'>
          <Col xs={12} lg={2}></Col>
          <Col xs={4} lg={2} className='d-flex justify-content-end text-nowrap'>Last queried</Col>
          <Col xs={8} md={6} className='pb-3 mb-0 border-bottom'>
            <Form.Check
              label='not ever queried'
              type='checkbox'
              id='inline-checkbox-last-queried-not-ever'
              checked={lastQueriedNE === true}
              onChange={(e) => {
                this.setState({ lastQueriedNE: e.target.checked ? true : null })
                this.setState({ lastQueriedBetween: e.target.checked ? false : null })
              }
              }
              className='mr-3'
            />
            <div className='d-flex mt-1 align-items-center'>
              <Form.Check
                label='between'
                type='checkbox'
                id='inline-checkbox-last-queried-between'
                checked={lastQueriedBetween === true}
                onChange={(e) => {
                  this.setState({ lastQueriedBetween: e.target.checked ? true : null })
                  this.setState({ lastQueriedNE: e.target.checked ? false : null })
                }
                }
                className='flex-shrink-0 mr-2'
              />
              <Form.Control
                type='date'
                size='sm'
                value={lastQueriedFrom}
                onChange={(e) => this.setState({
                  lastQueriedFrom: e.target.value
                })}
                className='flex-grow-1'
              />
              <Form.Control
                type='date'
                size='sm'
                value={lastQueriedTo}
                onChange={(e) => this.setState({
                  lastQueriedTo: e.target.value
                })}
                className='flex-grow-1'
              />
            </div>
          </Col>
        </Row>
        <Row className='py-2 mx-3 border-bottom'>
          <Col xs={4} className='d-flex justify-content-end text-nowrap'>Awaiting query</Col>
          <Col xs={8} md={6}>
            <Form.Check
              inline
              label='Yes'
              type='checkbox'
              id='inline-checkbox-query-pending-yes'
              checked={pendingQuery === true}
              onChange={(e) => this.setState({ pendingQuery: e.target.checked ? true : null })}
              className='mr-3'
            />
            <Form.Check
              inline
              label='No'
              type='checkbox'
              id='inline-checkbox-query-pending-no'
              checked={pendingQuery === false}
              onChange={(e) => this.setState({ pendingQuery: e.target.checked ? false : null })}
            />
          </Col>
        </Row>
        <Row className='pt-3 pb-2 mx-3'>
          <Col xs={12} lg={2}><strong>Consents</strong></Col>
          <Col xs={4} lg={2} className='d-flex justify-content-end text-nowrap'>Last consented</Col>
          <Col xs={8} md={6} className='pb-3 mb-0 border-bottom'>
            <Form.Check
              label='not ever consented'
              type='checkbox'
              id='inline-checkbox-last-consented-no'
              checked={lastConsented === false}
              onChange={(e) => this.setState({ lastConsented: e.target.checked ? false : null })}
              className='mr-3'
            />
            <div className='d-flex mt-1 align-items-center'>
              <Form.Check
                label='prior to'
                type='checkbox'
                id='inline-checkbox-last-consented-yes'
                checked={!!lastConsented}
                onChange={(e) => this.setState({ lastConsented: e.target.checked ? lastConsentedOn : null })}
                className='flex-shrink-0 mr-2'
              />
              <Form.Control
                type='date'
                size='sm'
                value={lastConsentedOn}
                onChange={(e) => this.setState({
                  lastConsentedOn: e.target.value,
                  lastConsented: e.target.value ? e.target.value : null
                })}
                className='flex-grow-1'
              />
            </div>
          </Col>
        </Row>
        <Row className='py-2 mx-3'>
          <Col xs={4} className='d-flex justify-content-end text-nowrap'>Awaiting consent</Col>
          <Col xs={8} md={6} className='pb-3 mb-0 border-bottom'>
            <Form.Check
              inline
              label='Yes'
              type='checkbox'
              id='inline-checkbox-pending-yes'
              checked={pendingConsent === true}
              onChange={(e) => this.setState({ pendingConsent: e.target.checked ? true : null })}
              className='mr-3'
            />
            <Form.Check
              inline
              label='No'
              type='checkbox'
              id='inline-checkbox-pending-no'
              checked={pendingConsent === false}
              onChange={(e) => this.setState({ pendingConsent: e.target.checked ? false : null })}
            />
          </Col>
        </Row>
        <Row className='py-2 mx-3'>
          <Col xs={4} className='d-flex justify-content-end text-nowrap'>Last notified</Col>
          <Col xs={8} md={6}>
            <Form.Check
              label='not ever notified'
              type='checkbox'
              id='inline-checkbox-last-notified-no'
              checked={lastNotified === false}
              onChange={(e) => this.setState({ lastNotified: e.target.checked ? false : null })}
              className='mr-3'
            />
            <div className='d-flex mt-1 align-items-center'>
              <Form.Check
                label='prior to'
                type='checkbox'
                id='inline-checkbox-last-notified-yes'
                checked={!!lastNotified}
                onChange={(e) => this.setState({ lastNotified: e.target.checked ? lastNotifiedOn : null })}
                className='flex-shrink-0 mr-2'
              />
              <Form.Control
                type='date'
                size='sm'
                value={lastNotifiedOn}
                onChange={(e) => this.setState({
                  lastNotifiedOn: e.target.value,
                  lastNotified: e.target.value ? e.target.value : null
                })}
                className='flex-grow-1'
              />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  render () {
    return (
      <Modal
        show={true}
        size='lg'
        onHide={() => this.props.done()}
        animation={false}
        centered
        scrollable={true}
      >
        <Modal.Header className='shadow-sm'>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo' />
            Edit filters
          </Modal.Title>
          <Button
            size='sm'
            variant='outline-secondary'
            title='Close'
            onClick={() => this.props.done()}
          ><FontAwesomeIcon icon={faTimes} />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {this._renderFilters()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => this.props.done()}
          >Cancel</Button>
          <Button
            variant='primary'
            size={'sm'}
            className='btn-wide'
            onClick={() => {
              this.props.updateFilters(omit(this.state, ['lastConsentedOn', 'lastNotifiedOn']))
              this.props.done()
            }}
          >Continue</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, companies, dotNumbers, driversFilters } = state
  return { auth, companies, dotNumbers, driversFilters }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driversFilters },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DriversTableFilters)

DriversTableFilters.propTypes = {
  done: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  driversFilters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired
}
