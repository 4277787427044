import { actionTypes } from '../actions/actionTypes'

const initialState = {
  documentList: [],
  document: null,
  loading: null,
  loadingDocument: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.DRIVER_DOCUMENTS.SET_DOCUMENT_LIST:
    return { ...state, documentList: action.value }
  case actionTypes.DRIVER_DOCUMENTS.SET_DOCUMENT:
    return { ...state, document: action.value }
  case actionTypes.DRIVER_DOCUMENTS.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.DRIVER_DOCUMENTS.SET_LOADING_DOCUMENT:
    return { ...state, loadingDocument: action.value }
  case actionTypes.DRIVER_DOCUMENTS.SET_ERROR:
    return { ...state, error: action.value }
  default:
    return state
  }
}