import { actionTypes } from './actionTypes'
//import * as dotNumbersActions from './dotNumbers'
import {
  requestGetDOTNumber,
  requestUpdateDOTNumber
} from '../services/apiService'

const dotNumber = {
  setDOTNumber: (value) => ({
    type: actionTypes.DOTNumber.SET_DOTNumber,
    value
  }),


  setCreated: (value) => ({
    type: actionTypes.DOTNumber.SET_CREATED,
    value
  }),

  setEdited: (value) => ({
    type: actionTypes.DOTNumber.SET_EDITED,
    value
  }),

  setError: (value) => ({
    type: actionTypes.DOTNumber.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.DOTNumber.SET_LOADING,
    value
  }),
  getDOTNumber: (dotNumberId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(dotNumber.setLoading(true))
      dispatch(dotNumber.setError())
      const data = await requestGetDOTNumber(token, dotNumberId)
      if (data && data.dotNumber) {
        dispatch(dotNumber.setDOTNumber(data.dotNumber))
      } else if (data && data.error) {
        dispatch(dotNumber.setError(data.error))
      }
      dispatch(dotNumber.setLoading(false))
    }
  },



  updateDOTNumber: (dotNumberId, params) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(dotNumber.setEdited())
      dispatch(dotNumber.setLoading(true))
      dispatch(dotNumber.setError())
      const data = await requestUpdateDOTNumber(token, dotNumberId, params)
      if (data && data.driver) {
        dispatch(dotNumber.setEdited(true))
        //dispatch(dotNumbersActions.dotNumbers.updateWithDriver(data.dotNumber))
      } else if (data && data.error) {
        dispatch(dotNumber.setError(data.error))
      }
      dispatch(dotNumber.setLoading(false))
    }
  }
}

export { dotNumber }
