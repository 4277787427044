import { actionTypes } from './actionTypes'
import {
  requestCompleteDriverConsent,
  requestEmailDriverConsent,
  requestDriverConsent,
  requestDriverConsentExists
} from '../services/apiService'
import { navigation } from './navigation'

const driverConsent = {
  setConsent: (consent) => ({
    type: actionTypes.DRIVER_CONSENT.SET_CONSENT,
    value: consent
  }),

  setExists: (exists) => ({
    type: actionTypes.DRIVER_CONSENT.SET_EXISTS,
    value: exists
  }),

  setLoading: (loading) => ({
    type: actionTypes.DRIVER_CONSENT.SET_LOADING,
    value: loading
  }),

  setValidationError: (error) => ({
    type: actionTypes.DRIVER_CONSENT.SET_VALIDATION_ERROR,
    value: error
  }),

  setCompletionError: (error) => ({
    type: actionTypes.DRIVER_CONSENT.SET_COMPLETION_ERROR,
    value: error
  }),

  setEmailError: (error) => ({
    type: actionTypes.DRIVER_CONSENT.SET_EMAIL_ERROR,
    value: error
  }),

  setEmailSuccess: (success) => ({
    type: actionTypes.DRIVER_CONSENT.SET_EMAIL_SUCCESS,
    value: success
  }),

  completeDriverConsent: (params) => {
    return async (dispatch) => {
      dispatch(driverConsent.setLoading(true))
      dispatch(driverConsent.setCompletionError())
      const data = await requestCompleteDriverConsent(params)
      if (data && data.consent) {
        dispatch(driverConsent.setConsent(data))
        dispatch(navigation.navigateToDriverConsentPrint(data.consent))
      } else if (data && data.error) {
        dispatch(driverConsent.setCompletionError(data.error))
      }
      dispatch(driverConsent.setLoading(false))
    }
  },

  emailDriverConsent: (params) => {
    return async (dispatch) => {
      dispatch(driverConsent.setLoading(true))
      dispatch(driverConsent.setEmailSuccess())
      dispatch(driverConsent.setEmailError())
      const data = await requestEmailDriverConsent(params)
      if (data && data.sent) {
        dispatch(driverConsent.setEmailSuccess(true))
      } else if (data && data.error) {
        dispatch(driverConsent.setEmailError(data.error))
      }
      dispatch(driverConsent.setLoading(false))
    }
  },

  getDriverConsent: (params) => {
    return async (dispatch) => {
      dispatch(driverConsent.setLoading(true))
      dispatch(driverConsent.setValidationError(false))
      const data = await requestDriverConsent(params)
      if (data && data.consent) {
        dispatch(driverConsent.setConsent(data))
        dispatch(navigation.navigateToDriverConsentReview(params))
      } else {
        dispatch(driverConsent.setValidationError(true))
      }
      dispatch(driverConsent.setLoading(false))
    }
  },

  driverConsentExists: ({ uuid }) => {
    return async (dispatch) => {
      dispatch(driverConsent.setLoading(true))
      const data = await requestDriverConsentExists({ uuid })
      dispatch(driverConsent.setExists(data && data.exists))
      dispatch(driverConsent.setLoading(false))
    }
  }
}

export { driverConsent }
