import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Form
} from 'react-bootstrap'
import ModalBackdropForm from '../../common/modalBackdropForm'
import ProgressBar from './common/progressBar'
import HelpTooltip from '../../common/helpTooltip'
import ModalConfirmation from '../../common/modalConfirmation'

import * as actions from '../../../actions'

class ServiceQuestions extends Component {
  state = {
    ownerOperator: null,
    fullQuery: null,
    initialTest: null
  }

  componentDidMount () {
    this.props.setError()
    if (!this.props.createAccount.userInfo) {
      this.props.navigateToOwnerOperatorCreateAccountUserInfo()
    }
    if (this.props.createAccount.serviceQuestions) {
      this.setState(this.props.createAccount.serviceQuestions)
    }
  }

  _handleOnSubmit () {
    this.props.setServiceQuestions(this.state)
    this.props.navigateToOwnerOperatorCreateAccountSignConsent()
  }

  _renderNotOwnerOperatorModal () {
    const { ownerOperator } = this.state
    if (ownerOperator !== false) { return '' }
    return <ModalConfirmation
      show={true}
      header='Not an owner/operator'
      text={<span>Not an owner/operator? We also have corporate plans! <a href='/contact'>Contact us</a> to learn more about our pricing.</span>}
      showAccept={false}
      cancelText='Done'
      onCancel={() => this.setState({ ownerOperator: null })}
    />
  }

  _renderForm () {
    const { createAccount: { userInfo } } = this.props
    const { ownerOperator, fullQuery, initialTest } = this.state

    if (!userInfo) { return }
    const { legalEntityName } = userInfo

    return (
      <React.Fragment>
        <Col xs={12}>
          <ProgressBar
            title='Create account'
            subtitle='Step 2: please answer the following questions so we know which services we can provide you with.'
            percentDone={50}
          />
        </Col>
        <Col xs={12}>
          <div className='border-top pt-4 mb-3'>
            <strong>Are you an owner/operator?</strong>
            <HelpTooltip
              content='An owner/operator is someone who acts as both an employer and a driver.'
            />
          </div>
          <div className='px-3'>
            <Form.Check
              label={<span className='ml-1'>Yes. I confirm that I am an owner/operator.</span>}
              type='checkbox'
              id='inline-checkbox-oo-yes'
              checked={ownerOperator === true}
              onChange={(e) => this.setState({ ownerOperator: e.target.checked ? true : null })}
              className='mb-1'
            />
            <Form.Check
              label={<span className='ml-1'>No. I am not an owner/operator.</span>}
              type='checkbox'
              id='inline-checkbox-oo-no'
              checked={ownerOperator === false}
              onChange={(e) => this.setState({ ownerOperator: e.target.checked ? false : null })}
            />
          </div>
        </Col>
        <Col xs={12} className='mt-4'>
          <div className='border-top pt-4 mb-3'>
            <strong>{`Have you had an FMCSA Drivers Clearinghouse full query previously run on you as an owner/operator under the "${legalEntityName}" entity?`}</strong>
            <HelpTooltip
              content='FMCSA Drivers Clearinghouse full queries include all pre-employment queries.'
            />
          </div>
          <div className='px-3'>
            <Form.Check
              label={<span className='ml-1'>{`Yes. I confirm that a FMCSA Drivers Clearinghouse full query has been run on me previously under the "${legalEntityName}" entity.`}</span>}
              type='checkbox'
              id='inline-checkbox-fq-yes'
              checked={fullQuery === false}
              onChange={(e) => this.setState({ fullQuery: e.target.checked ? false : null })}
              className='mb-1'
            />
            <Form.Check
              label={<span className='ml-1'>No. I need a full query run at this time.</span>}
              type='checkbox'
              id='inline-checkbox-fq-no'
              checked={fullQuery === true}
              onChange={(e) => this.setState({ fullQuery: e.target.checked ? true : null })}
            />
          </div>
        </Col>
        <Col xs={12} className='mt-4 mb-2'>
          <div className='border-top pt-4 mb-3'>
            <strong>Have you been enrolled in a random drug and alcohol testing program in the past 30 days?</strong>
          </div>
          <div className='border-bottom px-3 pb-4'>
            <Form.Check
              label={<span className='ml-1'>Yes. I confirm that I have been enrolled in a random drug and alcohol testing program in the past 30 days.</span>}
              type='checkbox'
              id='inline-checkbox-it-yes'
              checked={initialTest === false}
              onChange={(e) => this.setState({ initialTest: e.target.checked ? false : null })}
              className='mb-1'
            />
            <Form.Check
              label={<span className='ml-1'>No. I need an initial drug test performed at this time.</span>}
              type='checkbox'
              id='inline-checkbox-it-no'
              checked={initialTest === true}
              onChange={(e) => this.setState({ initialTest: e.target.checked ? true : null })}
            />
          </div>
        </Col>
        <Col xs={4} className='mt-4 mb-3'>
          <Button
            variant='secondary'
            block
            onClick={() => this.props.navigateToOwnerOperatorCreateAccountUserInfo()}
          >Back</Button>
        </Col>
        <Col xs={8} className='mt-4 mb-3'>
          <Button
            variant='primary'
            block
            type='submit'
            disabled={!ownerOperator || fullQuery === null || initialTest === null}
          >Continue</Button>
        </Col>
        {this._renderNotOwnerOperatorModal()}
      </React.Fragment>
    )
  }

  render () {
    return (
      <ModalBackdropForm
        sm={11}
        md={10}
        lg={9}
        onSubmit={() => this._handleOnSubmit()}
        displayHelpLink={true}
      >{this._renderForm()}</ModalBackdropForm>
    )
  }
}

const mapStateToProps = (state) => {
  const { createAccount } = state
  return { createAccount }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.createAccount, ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceQuestions)

ServiceQuestions.propTypes = {
  createAccount: PropTypes.object.isRequired,
  navigateToOwnerOperatorCreateAccountUserInfo: PropTypes.func.isRequired,
  navigateToOwnerOperatorCreateAccountSignConsent: PropTypes.func.isRequired,
  setServiceQuestions: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
