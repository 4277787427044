import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  Dropdown,
  Image,
  Nav
} from 'react-bootstrap'
import styles from './navHeader.module.scss'
import logo from '../../../images/drivers-clearinghouse-logo-small-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import ContactForm from '../../common/contactForm'

import * as actions from '../../../actions'

class NavHeader extends Component {
  state = {
    showContactForm: null
  }

  _currentLocationConfig () {
    const { router: { location: { pathname } } } = this.props

    const config = {
      home: {
        name: 'Home',
        showDriverRostersLink: true,
        showConsentContentLink: true,
        showDOTRosterLink:true
      },
      driverRosters: {
        name: 'Driver roster',
        showHomeLink: true,
        showConsentContentLink: true,
        showDOTRosterLink:true
      },
      consentContent: {
        name: 'Consent forms',
        showHomeLink: true,
        showDriverRostersLink: true,
        showDOTRosterLink:true
      },
      dotRosters: {
        name: 'DOT roster',
        showHomeLink: true,
        showDriverRostersLink: true
      }
    }

    let current
    if (/home/.test(pathname)) { current = config.home }
    if (/driver-roster/.test(pathname)) { current = config.driverRosters }
    if (/consent-content/.test(pathname)) { current = config.consentContent }
    if (/dot-roster/.test(pathname)) { current = config.dotRosters }
    Object.assign(config, { current })

    return config
  }

  _renderViewSelect () {
    const config = this._currentLocationConfig()

    return (
      <Dropdown className='ml-3'>
        <Dropdown.Toggle
          className={`${styles.menuButton} px-3`}
          variant='outline-light'
          size='sm'
          id='viewSelect'
        >{config.current.name}<FontAwesomeIcon icon={faChevronDown} className='ml-3'/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {!config.current.showHomeLink ? '' :
            <Dropdown.Item
              as='button'
              key='1'
              id='navHomeButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToCompanyAdminHome()}
            >{config.home.name}</Dropdown.Item>
          }
          {!config.current.showDriverRostersLink ? '' :
            <Dropdown.Item
              as='button'
              key='2'
              id='navDriversTableButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToCompanyAdminDriversTable()}
            >{config.driverRosters.name}</Dropdown.Item>
          }
          {!config.current.showConsentContentLink ? '' :
            <Dropdown.Item
              as='button'
              key='3'
              id='navConsentContentButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToCompanyAdminConsentContent()}
            >{config.consentContent.name}</Dropdown.Item>
          }

          {!config.current.showDOTRosterLink ? '' :
            <Dropdown.Item
              as='button'
              key='3'
              id='navDotRostersButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToCompanyAdminDOTRoster()}
            >{config.dotRosters.name}</Dropdown.Item>
          }

        </Dropdown.Menu>
      </Dropdown>
    )
  }

  render () {
    const { auth: { user } } = this.props
    if (!user) { return '' }
    const { firstName, lastName } = user

    return (
      <Nav className={`${styles.nav} nav d-flex justify-content-between align-items-center`}>
        <div className='d-flex align-items-center'>
          <Link to='/company-admin/home'>
            <Image className={`${styles.navImage} ml-3`} src={logo} alt='logo'/>
          </Link>
          {this._renderViewSelect()}
        </div>
        <div className='d-flex align-items-center'>
          <span
            className={`${styles.userName} text-light mr-4`}
          >{`${firstName} ${lastName}`}</span>
          <Dropdown className='mr-3'>
            <Dropdown.Toggle
              className={styles.menuButton}
              variant='outline-light'
              size='sm'
              id='navSelect'
            ><FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item
                as='button'
                onSelect={() => this.setState({ showContactForm: true })}
                key='1'
                className='btn btn-secondary'
                id='contact'
              >Contact</Dropdown.Item>
              <a className='no-decoration' href='/terms-of-use' target='_blank' rel='noopener noreferrer'>
                <Dropdown.Item
                  as='button'
                  key='2'
                  className='btn btn-secondary'
                >Terms of use</Dropdown.Item>
              </a>
              <a className='no-decoration' href='/privacy-policy' target='_blank' rel='noopener noreferrer'>
                <Dropdown.Item
                  as='button'
                  key='3'
                  className='btn btn-secondary'
                >Privacy policy</Dropdown.Item>
              </a>
              <Dropdown.Item
                as='button'
                onSelect={() => this.props.logout()}
                key='4'
                className='btn btn-secondary'
                id='logout'
              >Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {this.state.showContactForm ? <ContactForm done={() => this.setState({ showContactForm: false })}/> : ''}
      </Nav>
    )
  }
}

const mapStateToProps = (state) => {
  const { router, auth } = state
  return { router, auth }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.auth, ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader)

NavHeader.propTypes = {
  router: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  navigateToCompanyAdminHome: PropTypes.func.isRequired,
  navigateToCompanyAdminDriversTable: PropTypes.func.isRequired,
  navigateToCompanyAdminConsentContent: PropTypes.func.isRequired,
  navigateToCompanyAdminDOTRoster:PropTypes.func.isRequired
}
