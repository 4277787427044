import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { without } from 'lodash'
import moment from 'moment'

import { Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import * as selectors from '../../../selectors'
import * as actions from '../../../actions'

const FilterBadge = ({ title, onClick }) => {
  return (
    <Badge
      as='button'
      onClick={() => onClick()}
      className='btn p-1 m-half btn-dark d-flex align-items-center'
      data-toggle='button'
    >{title}<FontAwesomeIcon className='align-middle ml-1' icon={faTimes}/>
    </Badge>
  )
}
FilterBadge.displayName = 'FilterBadge'
FilterBadge.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

class FilterBadges extends Component {
  _renderCompanyBadges () {
    const { dotNumbersFilters: { companies } } = this.props
    return companies.map((company) => (
      <FilterBadge
        key={company}
        title={`Company ${this.companyMap[company].name}`}
        onClick={() => {
          const { dotNumbersFilters: { companies } } = this.props
          this.props.updateCompanies(without(companies, company))
        }}
      />
    ))
  }

  _renderDotBadges () {
    const { dotNumbersFilters: { dotNumbers } } = this.props
    return dotNumbers.map((dotNumber) => (
      <FilterBadge
        key={dotNumber}
        title={`DOT ${this.dotMap[dotNumber].name}`}
        onClick={() => {
          const { dotNumbersFilters: { dotNumbers } } = this.props
          this.props.updateDotNumbers(without(dotNumbers, dotNumber))
        }}
      />
    ))
  }

  _renderActiveBadge () {
    const { driversFilters: { active } } = this.props

    if (active === true) {
      return (
        <FilterBadge
          key='active'
          title='Active'
          onClick={() => this.props.updateActive()}
        />
      )
    }
    if (active === false) {
      return (
        <FilterBadge
          key='active'
          title='Not active'
          onClick={() => this.props.updateActive()}
        />
      )
    }
    return ''
  }

  _renderEmailBadge () {
    const { driversFilters: { email } } = this.props

    if (email === true) {
      return (
        <FilterBadge
          key='email'
          title='Email present'
          onClick={() => this.props.updateEmail()}
        />
      )
    }
    if (email === false) {
      return (
        <FilterBadge
          key='email'
          title='No email present'
          onClick={() => this.props.updateEmail()}
        />
      )
    }
    return ''
  }

  _renderStateBadges () {
    const { driversFilters: { states } } = this.props

    return states.map((state) => (
      <FilterBadge
        key={state}
        title={`${state} state`}
        onClick={() => {
          const { driversFilters: { states } } = this.props
          this.props.updateStates(without(states, state))
        }}
      />
    ))
  }

  _renderLastConsentedBadge () {
    const { driversFilters: { lastConsented } } = this.props
    if (lastConsented === false) {
      return (
        <FilterBadge
          key='lastConsented'
          title='Not ever consented'
          onClick={() => this.props.updateLastConsented()}
        />
      )
    }
    if (lastConsented) {
      return (
        <FilterBadge
          key='lastConsented'
          title={`Last consented prior to ${moment(lastConsented, 'YYYY-MM-DD').format('M/D/YYYY')}`}
          onClick={() => this.props.updateLastConsented()}
        />
      )
    }
    return ''
  }

  _renderPendingConsentBadge () {
    const { driversFilters: { pendingConsent } } = this.props

    if (pendingConsent === true) {
      return (
        <FilterBadge
          key='pendingConsent'
          title='Awaiting consent'
          onClick={() => this.props.updatePendingConsent()}
        />
      )
    }
    if (pendingConsent === false) {
      return (
        <FilterBadge
          key='pendingConsent'
          title='Not awaiting consent'
          onClick={() => this.props.updatePendingConsent()}
        />
      )
    }
    return ''
  }

  _renderLastNotifiedBadge () {
    const { driversFilters: { lastNotified } } = this.props
    if (lastNotified === false) {
      return (
        <FilterBadge
          key='lastNotified'
          title='Not ever notified'
          onClick={() => this.props.updateLastNotified()}
        />
      )
    }
    if (lastNotified) {
      return (
        <FilterBadge
          key='lastNotified'
          title={`Last notified prior to ${moment(lastNotified, 'YYYY-MM-DD').format('M/D/YYYY')}`}
          onClick={() => this.props.updateLastNotified()}
        />
      )
    }
    return ''
  }

  _renderPendingQueryBadge () {
    const { driversFilters: { pendingQuery } } = this.props

    if (pendingQuery === true) {
      return (
        <FilterBadge
          key='pendingQuery'
          title='Pending query'
          onClick={() => this.props.updatePendingQuery()}
        />
      )
    }
    if (pendingQuery === false) {
      return (
        <FilterBadge
          key='pendingQuery'
          title='No query pending'
          onClick={() => this.props.updatePendingQuery()}
        />
      )
    }
    return ''
  }


  render () {
    const { dotNumbers: { dotNumbers }, companies: { companies } } = this.props
    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)

    if (!dotNumbers || (isConcordeAdmin && !companies)) { return '' }

    if (!this.dotMap) {
      this.dotMap = selectors.dotNumbers.getDotNumberMap(this.props)
    }
    if (!this.companyMap) {
      this.companyMap = selectors.companies.getCompanyMap(this.props)
    }

    const badges = [
      ...this._renderCompanyBadges(),
      ...this._renderDotBadges()
    ]

    return (
      <React.Fragment>
        {badges.map(b => b)}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, companies, dotNumbers, dotNumbersFilters } = state
  return { auth, companies, dotNumbers, dotNumbersFilters }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.dotNumbersFilters },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBadges)

FilterBadges.propTypes = {
  companies: PropTypes.object.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  dotNumbersFilters: PropTypes.object.isRequired,
  driversFilters: PropTypes.object.isRequired,
  updateCompanies: PropTypes.func.isRequired,
  updateDotNumbers: PropTypes.func.isRequired,
  updateActive: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  updateStates: PropTypes.func.isRequired,
  updateLastConsented: PropTypes.func.isRequired,
  updatePendingConsent: PropTypes.func.isRequired,
  updateLastNotified: PropTypes.func.isRequired,
  updatePendingQuery: PropTypes.func.isRequired,
  updateLastQueried: PropTypes.func.isRequired
}
