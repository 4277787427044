import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { head } from 'lodash'

import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../../images/drivers-clearinghouse-logo-small.png'

import * as actions from '../../../actions'

class GeneratePDFExcel extends Component {
  state = {
    files: []
  }

  componentDidMount () {
    this.props.setProcessedFMCSAList()
    this.props.setError()
  }

  _renderButtonContents () {
    const { driversBulkActions: { loading } } = this.props
    if (!loading) { return 'Upload' }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
      Uploading
    </React.Fragment>
  }

  _renderError () {
    const { driversBulkActions: { error } } = this.props
    if (!error) { return '' }
    return <Row>
      <Col xs={12} className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  _renderInstructions () {
    const { driversBulkActions: { processedFMCSAList } } = this.props
    if (processedFMCSAList) { return '' }
    return (
      <Row>
        <Col xs={12}>
          <p>Please ensure the uploaded Excel document is the FMCSA query results document.  Otherwise, you will experience errors with this process.</p>
        </Col>
      </Row>
    )

  }

  _renderDropzone () {
    const { driversBulkActions: { processedFMCSAList } } = this.props
    if (processedFMCSAList) { return '' }

    const { files } = this.state
    let text = <span>Drag and drop an <strong>Excel</strong> file here or click to select a file.</span>
    if (files.length === 1) {
      text = <span>
        File to be uploaded: <strong>{files[0].name}</strong><br></br>
        Click upload to continue, or choose a different file.
      </span>
    }

    return (
      <Row>
        <Col xs={12} className='d-flex justify-content-center align-items-center'>
          <Dropzone
            multiple={false}
            onDrop={(files) => this.setState({ files })}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps({ className: `dropzone ${isDragActive ? 'active' : ''} p-3 d-flex justify-content-center align-items-center` })} style={{ width: '100%' }}>
                <input {...getInputProps()} />
                {text}
              </div>
            )}
          </Dropzone>
        </Col>
      </Row>
    )
  }

  _renderProcessed () {
    const { driversBulkActions: { processedFMCSAList } } = this.props
    if (!processedFMCSAList) { return '' }
    let errorInfoText = ''

    if (processedFMCSAList.rowsFailed > 0) {

      let errSummary = []

      processedFMCSAList.errorInfo.forEach((error) => {
        let key = ''
        if (error.errorMessage && error.errorMessage.Message) {
          if (error.errorMessage.Message.toString().includes('No matching Sparc record')) {
            key = 'No matching Sparc records'
          }
          if (error.errorMessage.Message.toString().includes('No employees found for DL')) {
            key = 'No employees found for DL#s'
          }
          if (error.errorMessage.Message.toString().includes('No applicable service')) {
            key = 'No applicable service found'
          }
          if (error.errorMessage.Message.toString().includes('not able to retrieve the record associated with')) {
            key = 'Not able to retrieve the record associated with employment ids'
          }

          //if not exists
          if (!errSummary.some(o => o.keyName === key)) {
            errSummary.push({ keyName: key, value: 0 })
          }
          var item = errSummary.find(x => x.keyName === key)
          if (item) {
            item.value += 1
          }
        }
      })

      for (var i = 0; i < errSummary.length; i++) {
        errorInfoText = `${errorInfoText}${errSummary[i].keyName}: ${errSummary[i].value} <br>`
      }

    }

    return <React.Fragment>{processedFMCSAList.rowsProcessed === 0 ? '' :
      <Row>
        <Col xs={12} className='alert alert-success'>
          Successfully processed {processedFMCSAList.rowsProcessed} {processedFMCSAList.rowsProcessed === 1 ? 'driver.' : 'drivers.'}
        </Col>
      </Row>}
    {processedFMCSAList.rowsFailed === 0 ? '' :
      <Row>
        <Col xs={12} className='alert alert-danger'>
          {processedFMCSAList.rowsFailed === 0 ? '' : `${processedFMCSAList.rowsFailed} ${processedFMCSAList.rowsFailed === 1 ? 'driver was' : 'drivers were'} not processed.  Error Information is below.`}
          <br></br>Summary of Errors:
          <br></br>
          <div dangerouslySetInnerHTML={{ __html: errorInfoText }} />
        </Col>
      </Row>}
    {!processedFMCSAList.processedFileData ? '' :
      <Row>
        <Col xs={12}>
          <p><a href={'data:application/zip;base64,' + processedFMCSAList.processedFileData} className='' download='ProcessedFMCSAPDFs.zip'>Download the generated PDFs here.</a></p>
        </Col>
      </Row>}
    </React.Fragment>
  }

  render () {
    const { driversBulkActions: { loading, processedFMCSAList }, done } = this.props
    const { files } = this.state

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo' />
            Generate PDFs from Excel file
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            {this._renderInstructions()}
            {this._renderDropzone()}
            {this._renderProcessed()}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className={processedFMCSAList ? 'btn-wide' : 'btn-semi-wide'}
            onClick={() => done()}
          >{processedFMCSAList ? 'Done' : 'Cancel'}</Button>
          {processedFMCSAList ? '' :
            <Button
              variant='primary'
              size={'sm'}
              disabled={loading || files.length !== 1}
              className='btn-wide'
              onClick={() => this.props.processFMCSAList(head(this.state.files))}
            >{this._renderButtonContents()}</Button>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, driversBulkActions } = state
  return { auth, driversBulkActions }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driversBulkActions },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePDFExcel)

GeneratePDFExcel.propTypes = {
  done: PropTypes.func.isRequired,
  driversBulkActions: PropTypes.object.isRequired,
  setProcessedFMCSAList: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  processFMCSAList: PropTypes.func.isRequired
}
