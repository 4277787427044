import Payment from './payment'
import ServiceQuestions from './serviceQuestions'
import SignConsent from './signConsent'
import UserInfo from './userInfo'

export default {
  Payment,
  ServiceQuestions,
  SignConsent,
  UserInfo
}