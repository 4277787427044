import { actionTypes } from '../actions/actionTypes'

const initialState = {
  page: 1,
  search: undefined,
  sort: undefined,
  companies: [],
  dotNumbers: [],
  active: undefined,
  email: undefined,
  states: [],
  lastConsented: undefined,
  pendingConsent: undefined,
  lastNotified: undefined,
  pendingQuery: undefined,
  lastQueried: undefined,
  selectedDrivers: new Set(),
  queryTypes:[],
  queryStatuses:[],
  lastQueriedFrom: undefined,
  lastQueriedTo: undefined,
  lastQueriedNE: undefined,
  lastQueriedBetween: undefined
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.DRIVERS_FILTERS.SET_PAGE:
    return { ...state, page: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_SEARCH:
    return { ...state, search: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_SORT:
    return { ...state, sort: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_COMPANIES:
    return { ...state, companies: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_DOT_NUMBERS:
    return { ...state, dotNumbers: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_ACTIVE:
    return { ...state, active: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_EMAIL:
    return { ...state, email: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_STATES:
    return { ...state, states: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_LAST_CONSENTED:
    return { ...state, lastConsented: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_PENDING_CONSENT:
    return { ...state, pendingConsent: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_LAST_NOTIFIED:
    return { ...state, lastNotified: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_PENDING_QUERY:
    return { ...state, pendingQuery: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_LAST_QUERIED:
    return { ...state, lastQueried: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_QUERY_TYPES:
    return { ...state, queryTypes: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_QUERY_STATUS:
    return { ...state, queryStatuses: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_QUERY_LAST_QUERIED_FROM:
    return { ...state, lastQueriedFrom: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_QUERY_LAST_QUERIED_TO:
    return { ...state, lastQueriedTo: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_LAST_QUERIED_NE:
    return { ...state, lastQueriedNE: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_LAST_QUERIED_BETWEEN:
    return { ...state, lastQueriedBetween: action.value }
  case actionTypes.DRIVERS_FILTERS.SET_SELECTED_DRIVERS:
    return { ...state, selectedDrivers: action.value }
  case actionTypes.DRIVERS_FILTERS.CLEAR:
    return { ...state, ...initialState }
  // when drivers are set, clear out the selected drivers
  case actionTypes.DRIVERS.SET_DRIVERS:
    return { ...state, selectedDrivers: new Set() }
  default:
    return state
  }
}