import React, { Component } from 'react'
import NavHeader from './common/navHeader'
import DriversTableMissingSparcDocumentLink from '../common/tasksErrorsTable/driversTableMissingSparcDocumentLink'

class TasksErrors extends Component {
  render () {
    return (
      <div className='d-flex flex-column w-100 h-100'>
        <NavHeader />
        <DriversTableMissingSparcDocumentLink/>
      </div>
    )
  }
}

export default TasksErrors