import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { pick } from 'lodash'

import {
  Button,
  Container,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEye } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'

import * as actions from '../../actions'

class EditCompany extends Component {
  state = {
    name: null,
    active: null
  }

  componentDidMount () {
    this.props.setEdited()
    this.props.setError()
    const { company: { company } } = this.props
    if (!company) { return }
    this.setState(pick(company, ['name', 'active']))
  }

  componentDidUpdate () {
    const { company: { edited } } = this.props
    if (edited) {
      this.props.done()
    }
  }

  _renderButtonContents () {
    const { company: { loading } } = this.props
    if (!loading) { return 'Save' }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Saving
    </React.Fragment>
  }

  _renderError () {
    const { company: { error } } = this.props
    if (!error) { return '' }
    return <Row>
      <Col className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  render () {
    const { company: { company }, done } = this.props
    if (!company) { return }

    const { name, active } = this.state

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Edit company
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='View'
              className='ml-2'
              onClick={() => done({ showViewCompany: true })}
            ><FontAwesomeIcon icon={faEye}/>
            </Button>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Name</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Active</Col>
              <Col xs={8} md={6}>
                <Form.Check
                  inline
                  label='Yes'
                  type='checkbox'
                  id='inline-checkbox-active-yes'
                  checked={!!active}
                  onChange={(e) => this.setState({ active: e.target.checked ? true : false })}
                  className='mr-3'
                />
                <Form.Check
                  inline
                  label='No'
                  type='checkbox'
                  id='inline-checkbox-active-no'
                  checked={!active}
                  onChange={(e) => this.setState({ active: e.target.checked ? false : true })}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => done()}
          >Done</Button>
          <Button
            variant='primary'
            size={'sm'}
            className='btn-wide'
            onClick={() => this.props.updateCompany(company._id, this.state)}
          >{this._renderButtonContents()}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { company } = state
  return { company }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.company },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany)

EditCompany.propTypes = {
  done: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  setEdited: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired
}
