import { actionTypes } from './actionTypes'
import * as driversActions from './drivers'
import {
  requestCreateConsent,
  requestSendNotification,
  requestCreateQuery,
  requestConsentUrl,
  requestGetDriver,
  requestCreateDriver,
  requestUpdateDriver,
  requestPerformQuery,
  requestMarkConsentOnFile,
  requestUploadDriverDocument,
  requestUploadDriverDocumentToSparc
} from '../services/apiService'

const driver = {
  setDriver: (value) => ({
    type: actionTypes.DRIVER.SET_DRIVER,
    value
  }),

  setCreatedConsent: (value) => ({
    type: actionTypes.DRIVER.SET_CREATED_CONSENT,
    value
  }),

  setMarkedConsentOnFile: (value) => ({
    type: actionTypes.DRIVER.SET_MARKED_CONSENT_ON_FILE,
    value
  }),

  setPerformedQuery: (value) => ({
    type: actionTypes.DRIVER.SET_PERFORMED_QUERY,
    value
  }),

  setSentNotification: (value) => ({
    type: actionTypes.DRIVER.SET_SENT_NOTIFICATION,
    value
  }),

  setUploadedDocument: (value) => ({
    type: actionTypes.DRIVER.SET_UPLOADED_DOCUMENT,
    value
  }),

  setCreatedQuery: (value) => ({
    type: actionTypes.DRIVER.SET_CREATED_QUERY,
    value
  }),

  setConsentUrl: (value) => ({
    type: actionTypes.DRIVER.SET_CONSENT_URL,
    value
  }),

  setCreated: (value) => ({
    type: actionTypes.DRIVER.SET_CREATED,
    value
  }),

  setEdited: (value) => ({
    type: actionTypes.DRIVER.SET_EDITED,
    value
  }),

  setError: (value) => ({
    type: actionTypes.DRIVER.SET_ERROR,
    value
  }),

  setDriverError: (value) => ({
    type: actionTypes.DRIVER.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.DRIVER.SET_LOADING,
    value
  }),

  createConsent: (driverId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setCreatedConsent())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestCreateConsent(token, driverId)
      if (data && data.driver) {
        dispatch(driver.setCreatedConsent(true))
        dispatch(driversActions.drivers.updateWithDriver(data.driver))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  markConsentOnFile: (driverId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setMarkedConsentOnFile())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestMarkConsentOnFile(token, driverId)
      if (data && data.driver) {
        dispatch(driver.setMarkedConsentOnFile(true))
        dispatch(driversActions.drivers.updateWithDriver(data.driver))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  sendNotification: (driverId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setSentNotification())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestSendNotification(token, driverId)
      if (data && data.driver) {
        dispatch(driver.setSentNotification(true))
        dispatch(driversActions.drivers.updateWithDriver(data.driver))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  createQuery: (driverId, queryType, isConcordeAdmin) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setCreatedQuery())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestCreateQuery(token, driverId, queryType, isConcordeAdmin)
      if (data && data.driver) {
        dispatch(driver.setCreatedQuery(true))
        dispatch(driversActions.drivers.updateWithDriver(data.driver))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  getConsentUrl: (driverId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setConsentUrl())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestConsentUrl(token, driverId)
      if (data && data.url) {
        dispatch(driver.setConsentUrl(data.url))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  getDriver: (driverId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestGetDriver(token, driverId)
      if (data && data.driver) {
        dispatch(driver.setDriver(data.driver))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  createDriver: (params) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setCreated())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestCreateDriver(token, params)
      if (data && data.driver) {
        dispatch(driver.setCreated(true))
        dispatch(driversActions.drivers.prependDriver(data.driver))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  updateDriver: (driverId, params) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setEdited())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestUpdateDriver(token, driverId, params)
      if (data && data.driver) {
        dispatch(driver.setEdited(true))
        dispatch(driversActions.drivers.updateWithDriver(data.driver))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  uploadDriverDocument: (driverId, params, isConcordeAdmin) => {
    return async (dispatch, getState) => {
      const { auth: { token, user } } = getState()
      let userName = `${user.firstName} ${user.lastName}`
      if (!token) { return }
      dispatch(driver.setUploadedDocument())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestUploadDriverDocument(token, driverId, params, isConcordeAdmin, userName)
      if (data && data.uploaded) {
        dispatch(driver.setUploadedDocument(true))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  uploadDriverDocumentToSparc: (driverId, documentId, params, isConcordeAdmin) => {
    return async (dispatch, getState) => {
      const { auth: { token, user } } = getState()
      let userName = `${user.firstName} ${user.lastName}`
      if (!token) { return }
      dispatch(driver.setUploadedDocument())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestUploadDriverDocumentToSparc(token, driverId, documentId, params, isConcordeAdmin, userName)
      if (data && data.uploaded) {
        dispatch(driver.setUploadedDocument(true))
        //dispatch(driverDocumentsActions.driverDocuments.getDocumentListSparcMissing())
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  },

  performQuery: (driverId, params) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driver.setPerformedQuery())
      dispatch(driver.setLoading(true))
      dispatch(driver.setError())
      const data = await requestPerformQuery(token, driverId, params)
      if (data && data.driver) {
        dispatch(driver.setPerformedQuery(true))
        dispatch(driversActions.drivers.updateWithDriver(data.driver))
      } else if (data && data.error) {
        dispatch(driver.setError(data.error))
      }
      dispatch(driver.setLoading(false))
    }
  }
}

export { driver }
