import { isEqual, isEmpty, pick } from 'lodash'
import { actionTypes } from './actionTypes'
import { drivers } from './drivers'
//import driversTableFilters from '../components/common/driversTable/driversTableFilters'

const driversFilters = {
  setPage: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_PAGE,
    value
  }),

  setSearch: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_SEARCH,
    value
  }),

  setSort: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_SORT,
    value
  }),

  setCompanies: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_COMPANIES,
    value
  }),

  setDotNumbers: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_DOT_NUMBERS,
    value
  }),

  setActive: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_ACTIVE,
    value
  }),

  setEmail: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_EMAIL,
    value
  }),

  setStates: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_STATES,
    value
  }),

  setLastConsented: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_LAST_CONSENTED,
    value
  }),

  setPendingConsent: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_PENDING_CONSENT,
    value
  }),

  setLastNotified: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_LAST_NOTIFIED,
    value
  }),

  setPendingQuery: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_PENDING_QUERY,
    value
  }),

  setLastQueried: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_LAST_QUERIED,
    value
  }),

  setSelectedDrivers: (value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_SELECTED_DRIVERS,
    value
  }),

  setLastQueriedFrom: (value) => ({
    type:actionTypes.DRIVERS_FILTERS.SET_QUERY_LAST_QUERIED_FROM,
    value
  }),

  setLastQueriedTo: (value) => ({
    type:actionTypes.DRIVERS_FILTERS.SET_QUERY_LAST_QUERIED_TO,
    value
  }),

  setQueryTypes:(value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_QUERY_TYPES,
    value
  }),

  setQueryStatuses:(value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_QUERY_STATUS,
    value
  }),

  setLastQueriedNE:(value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_LAST_QUERIED_NE,
    value
  }),

  setLastQueriedBetween:(value) => ({
    type: actionTypes.DRIVERS_FILTERS.SET_LAST_QUERIED_BETWEEN,
    value
  }),

  updateSearch: (searchString = '') => {
    return async (dispatch, getState) => {
      const { driversFilters: { search: prevSearch } } = getState()
      const search = !searchString ? undefined : searchString.split(' ')
      dispatch(driversFilters.setSearch(search))
      if (!isEqual(search, prevSearch)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.debouncedReloadDrivers())
      }
    }
  },

  updateSort: (sorted = []) => {
    return (dispatch, getState) => {
      const { driversFilters: { sort: prevSort } } = getState()
      const sort = isEmpty(sorted) ? undefined : sorted.reduce((prev, curr) => {
        prev[curr.id] = curr.desc ? -1 : 1
        return prev
      }, {})
      dispatch(driversFilters.setSort(sort))
      if (!isEqual(sort, prevSort)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updatePage: (page = 1) => {
    return (dispatch, getState) => {
      const { driversFilters: { page: prevPage } } = getState()
      dispatch(driversFilters.setPage(page))
      if (!isEqual(page, prevPage)) {
        dispatch(drivers.debouncedReloadDrivers())
      }
    }
  },

  updateCompanies: (companies) => {
    return (dispatch, getState) => {
      const { driversFilters: { companies: prevCompanies } } = getState()
      dispatch(driversFilters.setCompanies(companies))
      if (!isEqual(companies, prevCompanies)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateDotNumbers: (dotNumbers) => {
    return (dispatch, getState) => {
      const { driversFilters: { dotNumbers: prevDotNumbers } } = getState()
      dispatch(driversFilters.setDotNumbers(dotNumbers))
      if (!isEqual(dotNumbers, prevDotNumbers)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateActive: (active) => {
    return (dispatch, getState) => {
      const { driversFilters: { active: prevActive } } = getState()
      dispatch(driversFilters.setActive(active))
      if (!isEqual(active, prevActive)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateEmail: (email) => {
    return (dispatch, getState) => {
      const { driversFilters: { email: prevEmail } } = getState()
      dispatch(driversFilters.setEmail(email))
      if (!isEqual(email, prevEmail)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateStates: (states) => {
    return (dispatch, getState) => {
      const { driversFilters: { states: prevStates } } = getState()
      dispatch(driversFilters.setStates(states))
      if (!isEqual(states, prevStates)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateQueryTypes: (queryTypes) => {
    return (dispatch, getState) => {
      const { driversFilters: { queryTypes: prevqueryTypes } } = getState()
      dispatch(driversFilters.setQueryTypes(queryTypes))
      if (!isEqual(queryTypes, prevqueryTypes)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateQueryStatuses: (queryStatuses) => {
    return (dispatch, getState) => {
      const { driversFilters: { queryStatuses: prevqueryStatuses } } = getState()
      dispatch(driversFilters.setQueryStatuses(queryStatuses))
      if (!isEqual(queryStatuses, prevqueryStatuses)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },


  updateLastQueriedNE:(lastQueriedNE) => {
    return (dispatch, getState) => {
      const { driversFilters: { lastQueriedNE: prevlastQueriedNE } } = getState()
      dispatch(driversFilters.setLastQueriedNE(lastQueriedNE))
      if (!isEqual(lastQueriedNE, prevlastQueriedNE)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateLastQueriedBetween:(lastQueriedBetween, lastQueriedFrom, lastQueriedTo) => {
    return (dispatch, getState) => {
      const { driversFilters: { lastQueriedBetween: prevlastQueriedBetween,lastQueriedFrom: prevLastQueriedFrom, lastQueriedTo: prevLastQueriedTo  } } = getState()
      dispatch(driversFilters.setLastQueriedBetween(lastQueriedBetween))
      dispatch(driversFilters.setLastQueriedFrom(lastQueriedFrom))
      dispatch(driversFilters.setLastQueriedTo(lastQueriedTo))
      if (!isEqual(lastQueriedBetween, prevlastQueriedBetween) || !isEqual(lastQueriedFrom, prevLastQueriedFrom) || !isEqual(lastQueriedTo,prevLastQueriedTo) ) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateLastConsented: (lastConsented) => {
    return (dispatch, getState) => {
      const { driversFilters: { lastConsented: prevLastConsented } } = getState()
      dispatch(driversFilters.setLastConsented(lastConsented))
      if (!isEqual(lastConsented, prevLastConsented)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updatePendingConsent: (pendingConsent) => {
    return (dispatch, getState) => {
      const { driversFilters: { pendingConsent: prevPendingConsent } } = getState()
      dispatch(driversFilters.setPendingConsent(pendingConsent))
      if (!isEqual(pendingConsent, prevPendingConsent)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateLastNotified: (lastNotified) => {
    return (dispatch, getState) => {
      const { driversFilters: { lastNotified: prevLastNotified } } = getState()
      dispatch(driversFilters.setLastNotified(lastNotified))
      if (!isEqual(lastNotified, prevLastNotified)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updatePendingQuery: (pendingQuery) => {
    return (dispatch, getState) => {
      const { driversFilters: { pendingQuery: prevPendingQuery } } = getState()
      dispatch(driversFilters.setPendingQuery(pendingQuery))
      if (!isEqual(pendingQuery, prevPendingQuery)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateLastQueried: (lastQueried) => {
    return (dispatch, getState) => {
      const { driversFilters: { lastQueried: prevLastQueried } } = getState()
      dispatch(driversFilters.setLastQueried(lastQueried))
      if (!isEqual(lastQueried, prevLastQueried)) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  updateFilters: (filters) => {
    return (dispatch, getState) => {
      const { driversFilters: prevFilters } = getState()
      dispatch(driversFilters.setCompanies(filters.companies))
      dispatch(driversFilters.setDotNumbers(filters.dotNumbers))
      dispatch(driversFilters.setActive(filters.active))
      dispatch(driversFilters.setEmail(filters.email))
      dispatch(driversFilters.setStates(filters.states))
      dispatch(driversFilters.setLastConsented(filters.lastConsented))
      dispatch(driversFilters.setPendingConsent(filters.pendingConsent))
      dispatch(driversFilters.setLastNotified(filters.lastNotified))
      dispatch(driversFilters.setPendingQuery(filters.pendingQuery))
      dispatch(driversFilters.setLastQueried(filters.lastQueried))
      dispatch(driversFilters.setQueryTypes(filters.queryTypes))
      dispatch(driversFilters.setQueryStatuses(filters.queryStatuses))
      dispatch(driversFilters.setLastQueriedNE(filters.lastQueriedNE))
      dispatch(driversFilters.setLastQueriedBetween(filters.lastQueriedBetween))
      dispatch(driversFilters.setLastQueriedFrom(filters.lastQueriedFrom))
      dispatch(driversFilters.setLastQueriedTo(filters.lastQueriedTo))

      if (!isEqual(
        filters,
        pick(prevFilters, [
          'companies', 'dotNumbers', 'active', 'email', 'states',
          'lastConsented', 'pendingConsent', 'lastNotified',
          'pendingQuery', 'lastQueried','queryTypes','queryStatuses','lastQueriedFrom','lastQueriedTo',
          'lastQueriedNE','lastQueriedBetween'
        ])
      )) {
        dispatch(driversFilters.setPage(1))
        dispatch(drivers.reloadDrivers())
      }
    }
  },

  clearFilters: () => {
    return (dispatch) => {
      dispatch(driversFilters.updateFilters({
        companies: [],
        dotNumbers: [],
        active: undefined,
        email: undefined,
        states: [],
        lastConsented: undefined,
        pendingConsent: undefined,
        lastNotified: undefined,
        pendingQuery: undefined,
        lastQueried: undefined,
        queryTypes: [],
        queryStatuses: [],
        lastQueriedFrom: undefined,
        lastQueriedTo: undefined,
        lastQueriedNE: undefined,
        lastQueriedBetween: undefined
      }))
    }
  }
}

export { driversFilters }
