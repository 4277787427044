import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import Select from 'react-select'
import { selectCustomStyles } from '../common/selectCustomStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

class EditAccount extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    legalEntityName: '',
    driversLicenseNumber: '',
    state: '',
    dotNumber: ''
  }

  componentDidMount () {
    this.props.setAccountEdited()
    this.props.setError()

    const { account: { driver, company, dotNumber } } = this.props
    this.setState({
      firstName: driver.firstName,
      lastName: driver.lastName,
      driversLicenseNumber: driver.driversLicenseNumber,
      state: driver.state,
      dotNumber: dotNumber.name,
      email: driver.email,
      dob: moment.utc(driver.dob).format('YYYY-MM-DD'),
      legalEntityName: company.name
    })
  }

  componentDidUpdate () {
    const { account: { accountEdited } } = this.props
    if (accountEdited) {
      this.props.done()
    }
  }

  _renderButtonContents () {
    const { account: { loading } } = this.props
    if (!loading) { return 'Save' }
    return (
      <React.Fragment>
        <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Saving
      </React.Fragment>
    )
  }

  _renderError  () {
    const { account: { error } } = this.props
    if (!error) { return }
    return (
      <Row>
        <Col className='alert alert-danger'>
          {error}
        </Col>
      </Row>
    )
  }

  render () {
    const { account: { loading, driver, company, dotNumber }, done } = this.props
    if (!driver || !company || !dotNumber) { return }

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Edit account
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            <Row className='pb-1 align-items-center'>
              <Col xs={4} className='text-right'>First name</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 align-items-center'>
              <Col xs={4} className='text-right'>Last name</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 align-items-center'>
              <Col xs={4} className='text-right'>Drivers license number</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={this.state.driversLicenseNumber}
                  onChange={(e) => this.setState({ driversLicenseNumber: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 align-items-center'>
              <Col xs={4} className='text-right'>State</Col>
              <Col xs={8} md={6}>
                <Select
                  styles={selectCustomStyles}
                  isClearable={false}
                  isSearchable={true}
                  options={selectors.states.getAll().map((s) => ({ value: s, label: s }))}
                  value={{ value: this.state.state, label: this.state.state }}
                  onChange={(v) => this.setState({ state: v.value })}
                ></Select>
              </Col>
            </Row>
            <Row className='pb-1 align-items-center'>
              <Col xs={4} className='text-right'>DOT number</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={this.state.dotNumber}
                  onChange={(e) => this.setState({ dotNumber: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 align-items-center'>
              <Col xs={4} className='text-right'>Email address</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 align-items-center'>
              <Col xs={4} className='text-right'>Date of birth</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='date'
                  placeholder='1980-01-30'
                  value={this.state.dob}
                  onChange={(e) => this.setState({ dob: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 align-items-center'>
              <Col xs={4} className='text-right'>Legal entity name</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={this.state.legalEntityName}
                  onChange={(e) => this.setState({ legalEntityName: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => done()}
          >Cancel</Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={loading}
            className='btn-wide'
            onClick={() => this.props.updateAccount(this.state)}
          >{this._renderButtonContents()}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { account } = state
  return { account }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.account },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount)

EditAccount.propTypes = {
  done: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  updateAccount: PropTypes.func.isRequired,
  setAccountEdited: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
