const createAccount = {
  getSubscriptionPrice: (state) => {
    return state.createAccount.options.prices.subscription
  },

  getFullQueryPrice: (state) => {
    return state.createAccount.options.prices.fullQuery
  },

  getInitialTestPrice: (state) => {
    return state.createAccount.options.prices.initialTest
  },

  getTotalPrice: (state) => {
    const { createAccount: {
      options: { prices: { subscription, fullQuery: fullQueryAmount, initialTest: initialTestAmount } },
      serviceQuestions: { fullQuery, initialTest }
    } } = state
    let total = subscription
    if (fullQuery) { total += fullQueryAmount }
    if (initialTest) { total += initialTestAmount }
    return total
  }
}

export { createAccount }