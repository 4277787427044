
import React, { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { displayDate, displayQueryType, displayQueryStatus } from '../../../utils'

import {
  Button,
  Dropdown,
  Image
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEye, faEllipsisH, faExclamation, faCheck, faUserCheck, faFileImport, faCopy } from '@fortawesome/free-solid-svg-icons'
import addConsentIcon from '../../../images/addConsent.svg'
import addLimitedQueryIcon from '../../../images/addLimitedQuery.svg'
import addFullQueryIcon from '../../../images/addFullQuery.svg'
import styles from './driversTableCustomCells.module.scss'

// Display a checkbox
export const CheckboxCell = memo(({ value, checked, indeterminate, onChange }) => {
  return <input
    type='checkbox'
    checked={checked}
    ref={(el) => {
      if (!el) { return }
      if (indeterminate !== undefined) { el.indeterminate = indeterminate }
      if (checked !== undefined) { el.checked = checked }
    }}
    onChange={(e) => onChange(e.target.checked, value)}
  ></input>
})
CheckboxCell.displayName = 'CheckboxCell'
CheckboxCell.propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func
}

// Display a boolean variable
export const BooleanCell = memo(({ value, type }) => {
  if (!value) { return '' }
  return type === 'check' ? '✓' : 'Y'
})
BooleanCell.displayName = 'BooleanCell'
BooleanCell.propTypes = {
  value: PropTypes.bool,
  type: PropTypes.string
}

// Display a Date in the format M/D/YYYY, in local time
export const DateCell = memo(({ value, className = '', title }) => {
  if (!value) return ''
  return <span className={className} title={title}>{displayDate(value, true)}</span>
})
DateCell.displayName = 'DateCell'
DateCell.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string
}

// Display a DOT number
export const DotCell = memo(({ value, dotMap }) => {
  return dotMap[value].name
})
DotCell.displayName = 'DotCell'
DotCell.propTypes = {
  value: PropTypes.string.isRequired,
  dotMap: PropTypes.object.isRequired
}

// Display a Company
export const CompanyCell = memo(({ value, dotMap, companyMap }) => {
  return companyMap[dotMap[value].company].name
})
CompanyCell.displayName = 'CompanyCell'
CompanyCell.propTypes = {
  value: PropTypes.string.isRequired,
  dotMap: PropTypes.object.isRequired,
  companyMap: PropTypes.object.isRequired
}

const additionalPropsForExpDate = (expireDate) => {
  if (!expireDate || !moment.utc(expireDate).isValid()) { return {} }
  const now = moment.utc()
  const almostExpireDate = moment.utc(expireDate).subtract(1, 'month')

  if (now.isAfter(expireDate)) {
    return {
      className: 'border border-danger p-1 rounded',
      title: `Expired ${now.diff(expireDate, 'days')} days ago`
    }
  }
  if (now.isAfter(almostExpireDate)) {
    return {
      className: 'border border-warning p-1 rounded',
      title: `Expires in ${now.diff(expireDate, 'days')} days`
    }
  }
  return {}
}

export const QueryTypeCell = memo(({ queryStatus }) => {
  if (!queryStatus) { return '' }
  return <span className='text-secondary'>{displayQueryType(queryStatus.queryType)}</span>
})
QueryTypeCell.displayName = 'QueryType'
QueryTypeCell.propTypes = {
  queryStatus: PropTypes.object
}

export const QueryStatusCell = memo(({ queryStatus }) => {
  if (!queryStatus) { return '' }
  return <span className='text-secondary'>{displayQueryStatus(queryStatus.status)}</span>
})
QueryStatusCell.displayName = 'QueryStatus'
QueryStatusCell.propTypes = {
  queryStatus: PropTypes.object
}

export const StatusDateCell = memo(({ queryStatus }) => {
  if (!queryStatus) { return '' }
  const statusDate = queryStatus
  return <DateCell value={statusDate} {...displayDate(statusDate, true)} />
})
StatusDateCell.displayName = 'StatusDate'
StatusDateCell.propTypes = {
  queryStatus: PropTypes.object
}

export const ExpirationCell = memo(({ queryStatus }) => {
  if (!queryStatus || !queryStatus.lastCompleted) { return '' }
  const expireDate = moment(queryStatus.lastCompleted).add(1, 'year')
  return <DateCell value={expireDate} {...additionalPropsForExpDate(expireDate)} />
})
ExpirationCell.displayName = 'Expiration'
ExpirationCell.propTypes = {
  queryStatus: PropTypes.object
}

// Display a menu of actions
export const ActionsCell = memo(({ id, email, pendingConsent, consentCompleted, pendingLimitedQuery, pendingFullQuery, onAction, isConcordeAdmin }) => {
  const pendingQuery = pendingLimitedQuery || pendingFullQuery
  return (
    <React.Fragment>
      <Button
        variant='light'
        size='sm'
        title='Edit'
        className='mr-1 action-button text-secondary'
        onClick={() => onAction('edit', id)}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </Button>
      <Button
        variant='light'
        size='sm'
        title='View'
        className='mr-1 action-button text-secondary'
        onClick={() => onAction('view', id)}
      >
        <FontAwesomeIcon icon={faEye} />
      </Button>
      <Dropdown
        className='mr-1'
      >
        <Dropdown.Toggle
          variant='light'
          size='sm'
          title='More actions'
          className='action-button text-secondary hide-after-content'
        ><FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          {(!isConcordeAdmin || !pendingQuery || (pendingLimitedQuery && pendingConsent)) ? '' : (
            <React.Fragment>
              <Dropdown.Divider />
              <Dropdown.Item
                as='button'
                onSelect={() => onAction('performQuery', id)}
                key='1'
                className={`btn btn-secondary text-secondary ${styles.menuItem}`}
              ><FontAwesomeIcon icon={faUserCheck} className={`mr-2 ${styles.menuItemIcon}`} />Perform query
              </Dropdown.Item>
            </React.Fragment>
          )}
          {pendingConsent ? '' : (
            <React.Fragment>
              <Dropdown.Divider />
              <Dropdown.Item
                as='button'
                onSelect={() => onAction('createConsentRequest', id)}
                key='2'
                className={`btn btn-secondary text-secondary ${styles.menuItem}`}
              ><Image className={`mr-2 text-secondary ${styles.menuItemIcon}`} src={addConsentIcon}></Image>Create new consent request
              </Dropdown.Item>
            </React.Fragment>
          )}
          {(!email || !pendingConsent) ? '' : (
            <React.Fragment>
              <Dropdown.Divider />
              <Dropdown.Item
                as='button'
                onSelect={() => onAction('sendNotification', id)}
                key='3'
                className={`btn btn-secondary text-secondary ${styles.menuItem}`}
              ><FontAwesomeIcon icon={faExclamation} className={`mr-2 ${styles.menuItemIcon}`} />Send reminder notification
              </Dropdown.Item>
            </React.Fragment>
          )}
          {(!consentCompleted || pendingQuery) ? '' : (
            <React.Fragment>
              <Dropdown.Divider />
              <Dropdown.Item
                as='button'
                onSelect={() => onAction('createLimitedQueryRequest', id)}
                key='4'
                className={`btn btn-secondary text-secondary ${styles.menuItem}`}
              ><Image className={`mr-2 ${styles.menuItemIcon}`} src={addLimitedQueryIcon}></Image>Create new limited query request
              </Dropdown.Item>
            </React.Fragment>
          )}
          {pendingQuery ? '' : (
            <React.Fragment>
              <Dropdown.Divider />
              <Dropdown.Item
                as='button'
                onSelect={() => onAction('createFullQueryRequest', id)}
                key='5'
                className={`btn btn-secondary text-secondary ${styles.menuItem}`}
              ><Image className={`mr-2 ${styles.menuItemIcon}`} src={addFullQueryIcon}></Image>Create new full query request
              </Dropdown.Item>
            </React.Fragment>
          )}
          {(isConcordeAdmin || !pendingConsent) ? '' : (
            <React.Fragment>
              <Dropdown.Divider />
              <Dropdown.Item
                as='button'
                onSelect={() => onAction('performConsent', id)}
                key='6'
                className={`btn btn-secondary text-secondary ${styles.menuItem}`}
              ><FontAwesomeIcon icon={faCheck} className={`mr-2 ${styles.menuItemIcon}`} />Perform consent
              </Dropdown.Item>
            </React.Fragment>
          )}
          {(
            <React.Fragment>
              <Dropdown.Divider />
              <Dropdown.Item
                as='button'
                onSelect={() => onAction('uploadDriverDocument', id)}
                key='6'
                className={`btn btn-secondary text-secondary ${styles.menuItem}`}
              ><FontAwesomeIcon icon={faFileImport} className={`mr-2 ${styles.menuItemIcon}`} />Upload driver document
              </Dropdown.Item>
            </React.Fragment>
          )}
          <React.Fragment>
            <Dropdown.Divider />
            <Dropdown.Item
              as={'button'}
              onSelect={() => onAction('viewDocuments', id)}
              key={'7'}
              className={`btn btn-secondary text-secondary ${styles.menuItem}`}
            ><FontAwesomeIcon icon={faCopy} className={`mr-2 ${styles.menuITemIcon}`} />View driver documents
            </Dropdown.Item>
          </React.Fragment>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  )
})
ActionsCell.displayName = 'ActionsCell'
ActionsCell.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.bool.isRequired,
  pendingConsent: PropTypes.bool,
  consentCompleted: PropTypes.bool,
  pendingLimitedQuery: PropTypes.bool,
  pendingFullQuery: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  isConcordeAdmin: PropTypes.bool.isRequired
}
