import { isEmpty, sortBy, reverse, keyBy } from 'lodash'

const driver = {
  getCompletedConsents: (state) => {
    const { driver: { driver } } = state
    if (!driver || isEmpty(driver.consents)) { return [] }
    return driver.consents.filter((c) => c.status === 'complete')
  },

  getDriverDocuments: (state) => {
    const { driver: { driver } } = state
    if (!driver || isEmpty(driver.documents)) { return []}
    return driver.documents
  },

  getSortedQueriesAndConsents: (state) => {
    const { driver: { driver } } = state
    if (!driver) { return [] }
    const { consents = [], queries = [] } = driver
    return reverse(sortBy([
      ...consents.map((o) => Object.assign(o, { type: 'consent' })),
      ...queries.map((o) => Object.assign(o, { type: 'query' }))
    ], 'updatedAt'))
  },

  getDriverMap: (state) => {
    const { drivers: { drivers } } = state
    return keyBy(drivers || {}, '_id')
  }
}

export { driver }