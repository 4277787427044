import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fullName } from '../../utils'

import {
  Button,
  Container,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import ModalConfirmation from '../common/modalConfirmation'

import * as actions from '../../actions'

class CreateUser extends Component {
  state = {
    showCreateUserSuccess: null,
    firstName: '',
    lastName: '',
    email: ''
  }

  componentDidMount () {
    this.props.setUser()
    this.props.setError()
  }

  componentDidUpdate () {
    const { user: { user } } = this.props
    if (user && !this.state.showCreateUserSuccess) {
      this.setState({ showCreateUserSuccess: true })
    }
  }

  _renderButtonContents () {
    const { user: { loading } } = this.props
    if (!loading) { return 'Create' }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Saving
    </React.Fragment>
  }

  _renderError () {
    const { user: { error } } = this.props
    if (!error) { return '' }
    return <Row>
      <Col className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  _renderCreateUserSuccess () {
    const { showCreateUserSuccess } = this.state
    if (!showCreateUserSuccess) { return }

    const { user: { user }, company: { company: { name: companyName } }, done } = this.props

    return <ModalConfirmation
      show={true}
      header='User was created'
      text={`User ${fullName(user)} was successfully created for company ${companyName}. The user has been sent a welcome email.`}
      showAccept={false}
      onCancel={() => done()}
      cancelText='Done'
    ></ModalConfirmation>
  }

  _renderCreateUser () {
    const { showCreateUserSuccess, firstName, lastName, email } = this.state
    if (showCreateUserSuccess) { return }

    const { user: { loading }, company: { company: { _id: companyId, name } }, done } = this.props
    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            {`Create new user for ${name}`}
          </Modal.Title>
          <Button
            size='sm'
            variant='outline-secondary'
            title='Close'
            className='ml-2'
            onClick={() => done()}
          ><FontAwesomeIcon icon={faTimes}/>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>First name</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Last name</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Email address</Col>
              <Col xs={8} md={6}>
                <Form.Control
                  type='text'
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                ></Form.Control>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => done()}
          >Cancel</Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={loading}
            className='btn-wide'
            onClick={() => this.props.createUser(companyId, this.state)}
          >{this._renderButtonContents()}</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render () {
    return <React.Fragment>
      {this._renderCreateUser()}
      {this._renderCreateUserSuccess()}
    </React.Fragment>
  }
}

const mapStateToProps = (state) => {
  const { user, company } = state
  return { user, company }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.user },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)

CreateUser.propTypes = {
  done: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
