import { actionTypes } from '../actions/actionTypes'

const initialState = {
  user: null,
  error: null,
  forgotSent: null,
  passwordReset: null,
  loading: null,
  token: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.AUTH.SET_USER:
    return { ...state, user: action.value }
  case actionTypes.AUTH.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.AUTH.SET_FORGOT_SENT:
    return { ...state, forgotSent: action.value }
  case actionTypes.AUTH.SET_PASSWORD_RESET:
    return { ...state, passwordReset: action.value }
  case actionTypes.AUTH.SET_ERROR:
    return { ...state, error: action.value }
  case actionTypes.AUTH.SET_TOKEN:
    return { ...state, token: action.value }
  default:
    return state
  }
}