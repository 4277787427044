import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Document, Page, pdfjs } from 'react-pdf'
import moment from 'moment'
import { saveAs } from 'file-saver'

import {
  Button,
  Col,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons'
import styles from './pdfDocument.module.scss'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

class PdfDocument extends Component {
  state = {
    pdfSource: null,
    numPages: 1,
    currentPage: 1
  }

  componentDidMount () {
    const { consent, pdf } = this.props
    if (!consent || !pdf) { return }
    this.setState({ pdfSource: `data:application/pdf;base64,${pdf}` })
  }

  _handleDocumentLoadSuccess (document) {
    const { numPages } = document
    this.setState({ numPages })
  }

  _handleDownloadButtonClick () {
    const { consent: { completed } } = this.props
    const { pdfSource } = this.state
    const fileName = `consent-${moment.utc(completed).local().format('YYYYMMDDHHmmss')}.pdf`
    saveAs(pdfSource, fileName)
  }

  _renderLoaderSpinner () {
    return (
      <React.Fragment>
        <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Loading
      </React.Fragment>
    )
  }

  _renderPaginationButtons () {
    const { numPages, currentPage } = this.state
    if (!numPages || numPages === 1) { return '' }
    return (
      <React.Fragment>
        <span>{`Page ${currentPage} of ${numPages}`}</span>
        <Button
          size={'sm'}
          className='ml-2'
          disabled={currentPage === 1}
          onClick={() => this.setState({ currentPage: currentPage - 1 })}
        >
          <FontAwesomeIcon icon={faArrowLeft}/>
        </Button>
        <Button
          size={'sm'}
          className='ml-2'
          disabled={currentPage === numPages}
          onClick={() => this.setState({ currentPage: currentPage + 1 })}
        >
          <FontAwesomeIcon icon={faArrowRight}/>
        </Button>
      </React.Fragment>
    )
  }

  render () {
    const { consent } = this.props
    const { pdfSource, currentPage } = this.state
    if (!consent || !pdfSource) { return '' }

    return (
      <React.Fragment>
        <Row className='mx-2 justify-content-center'>
          <Col xs={12} md={10} xl={8} className='d-flex align-items-center justify-content-end'>
            {this._renderPaginationButtons()}
            {this.props.emailButton}
            <Button
              size={'sm'}
              className='ml-2'
              onClick={() => this._handleDownloadButtonClick()}
            >
              <FontAwesomeIcon icon={faDownload}/>
            </Button>
          </Col>
        </Row>
        <Row className='mt-2 justify-content-center'>
          <Col xs={12} md={10} xl={8} className='mb-5'>
            <div className={styles.pdfContainer}>
              <Document
                className={styles.pdfDocument}
                file={pdfSource}
                loading={this._renderLoaderSpinner()}
                onLoadSuccess={(document) => this._handleDocumentLoadSuccess(document)}
              >
                <Page
                  className={styles.pdfPage}
                  renderTextLayer={false}
                  renderInteractiveForms={false}
                  width={1024}
                  pageNumber={currentPage}
                />
              </Document>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default PdfDocument

PdfDocument.propTypes = {
  consent: PropTypes.object.isRequired,
  pdf: PropTypes.string.isRequired,
  emailButton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]).isRequired
}
