import handlebars from 'handlebars/dist/cjs/handlebars'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { format } from 'd3-format'

export const alertNotImplementedYet = () => {
  alert('This feature is not implemented yet')
}

export const isProductionEnvironment = () => {
  return /production/.test(process.env.NODE_ENV)
}

export const isTestEnvironment = () => {
  return /test/.test(process.env.NODE_ENV)
}

// Returns the rendered consent content
export const renderConsentContent = (consentContent, consent) => {
  try {
    const template = handlebars.compile(consentContent.content)
    const date = moment().format('DD MMMM YYYY')
    return template({ date, ...consent })
  } catch (err) {
    return err.toString()
  }
}

// Create session cookie
export const setCookie = (key, value) => {
  document.cookie = `${key}=${value};path=/;expires=;`
}

// Return session cookie by key
export const getCookie = key => {
  let decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')
  for (let cookie of cookies) {
    const currentCookie = cookie.split('=')
    if (currentCookie[0].trim() === key) {
      return currentCookie[1]
    }
  }
  return null
}

// Delete session cookie
export const deleteCookie = key => {
  document.cookie = `${key}=;path=/;expires=${moment.utc(new Date()).format('ddd, DD-MMM-YYYY h:mm:ss a')};`
}

/**
 * Formats a server date into a date string suitable for displaying in the UI
 * @param {string} dateStr the server date string
 * @param {boolean} convertToLocal if true, converts the date to local time, otherwise leaves in UTC. Defaults to `false`.
 * @returns {string}
 */
export const displayDate = (dateStr, convertToLocal = false) => {
  if (!dateStr) { return '' }
  const date = convertToLocal ? moment.utc(dateStr).local() : moment.utc(dateStr)
  return date.format('M/D/YYYY')
}

/**
 * Formats a server date into a date and time string suitable for displaying in the UI
 * @param {string} dateStr the server date string
 * @param {boolean} convertToLocal if true, converts the date to local time, otherwise leaves in UTC. Defaults to `false`.
 * @returns {string}
 */
export const displayDateTime = (dateStr, convertToLocal = false) => {
  if (!dateStr) { return '' }
  const date = convertToLocal ? moment.utc(dateStr).local() : moment.utc(dateStr)
  return date.format('M/D/YYYY h:mm a')
}

export const fullName = ({ firstName, lastName, email } = {}) => {
  let strs = []
  if (firstName) { strs.push(firstName) }
  if (lastName) { strs.push(lastName) }
  if (!isEmpty(strs)) { return strs.join(' ') }
  return email
}

/**
 * Returns a human-readable label for the language abbreviation
 */
export const getLabelForLanguage = (language) => {
  switch (language) {
  case 'en': default:
    return 'English'
  case 'es':
    return 'Español'
  case 'fr-ca':
    return 'Français Canadien'
  }
}

/**
 * Returns true when the user has currently selected text
 * see https://developer.mozilla.org/en-US/docs/Web/API/Window/getSelection
 */
export const hasSelectedText = () => {
  if (!getSelection) { return false }
  const selection = getSelection()
  return selection && selection.toString().length > 0
}

/**
 * Returns a string representation of an integer number, suitable for displaying in the UI
 */
export const displayNumber = (num) => {
  return format(',.0f')(num)
}

/**
 * Returns a $ string representation of an integer cents number, suitable for displaying in the UI
 */
export const displayCurrency = (num) => {
  return format('$.0f')(num / 100)
}

export const displayQueryType = (queryType) => {
  if(!queryType) return ''
  const displayType = queryType.replace(/^\w/, (chr) => chr.toUpperCase())
  return displayType
}

export const displayQueryStatus = (queryStatus) => {
  if(!queryStatus) return ''
  if(queryStatus === 'none') return ''
  const displayStatus = queryStatus
    .replace(/^\w/, (chr) => chr.toUpperCase())
    .replace(/\/\w/, (chr) => chr.toUpperCase())
  return displayStatus
}