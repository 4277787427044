import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Col } from 'react-bootstrap'
import ModalBackdropForm from './common/modalBackdropForm'

class NotFound extends Component {
  render () {
    return (
      <ModalBackdropForm>
        <Col xs={12} className='text-center mb-3'>
            404: Not found
        </Col>
        <Col xs={12} className='text-center'>
          <Link to={'/'}>Go home</Link>
        </Col>
      </ModalBackdropForm>
    )
  }
}

export default NotFound
