const driverDocumentTypes = {
  getAll: () => {
    return [
      'Full Consent',
      'Limited Consent',
      'Source Documentation',
      'Violation Documentation',
      'Other'
    ]
  }
}

export { driverDocumentTypes }