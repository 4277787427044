import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import moment from 'moment'
import jwtDecode from 'jwt-decode'
import { getCookie, deleteCookie } from '../../utils'

import * as actions from '../../actions'

const getTokenFromAuthCookie = () => {
  return getCookie(process.env.REACT_APP_AUTH_COOKIE_NAME)
}

const getUserFromToken = (token) => {
  try {
    return jwtDecode(token)
  } catch (err) {
    return null
  }
}

const isAuthExpired = (user) => {
  try {
    const { exp } = user
    const expiresAt = moment.utc(exp * 1000)
    return expiresAt.isBefore(moment.utc())
  } catch (err) {
    return true
  }
}

class AuthenticatedRoute extends Component {
  render () {
    let { auth: { user } } = this.props

    // if the user is not found in the props, attempt to load from the auth cookie
    if (!user) {
      const token = getTokenFromAuthCookie()
      user = getUserFromToken(token)
      if (user) {
        this.props.setToken(token)
        this.props.setUser(user)
      }
    }

    // if the jwt is expired, remove from props and the auth cookie
    if (user && isAuthExpired(user)) {
      user = undefined
      deleteCookie(process.env.REACT_APP_AUTH_COOKIE_NAME)
      this.props.setToken()
      this.props.setUser()
    }

    if (user) { return this.props.children }
    return <Redirect to={{ pathname: '/login' }}/>
  }
}

const mapStateToProps = (state) => {
  const { router, auth } = state
  return { router, auth }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.auth },
    dispatch
  )
}

AuthenticatedRoute.propTypes = {
  children: PropTypes.element.isRequired,
  router: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute)
