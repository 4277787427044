import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import { displayNumber } from '../../../utils'
import ModalConfirmation from '../modalConfirmation'

import * as actions from '../../../actions'

class SendNotifications extends Component {
  state = {
    dryRun: true
  }

  componentDidMount () {
    this.props.sendNotifications(true)
  }

  _renderDryRun () {
    const { driversBulkActions: { loading, error, sentNotifications } } = this.props

    if (loading || !sentNotifications) {
      return <ModalConfirmation
        show={true}
        header='Send reminder notifications'
        text='Preparing to send notifications ...'
        loading={loading}
        error={error}
        showAccept={false}
        onCancel={() => this.props.done()}
      />
    }
    if (sentNotifications && sentNotifications.emailed) {
      const { count } = sentNotifications.emailed
      if (count === 0) {
        return <ModalConfirmation
          show={true}
          header='Send reminder notifications'
          text='There are no drivers in your selection who can be sent a notification.'
          loading={loading}
          error={error}
          showAccept={false}
          cancelText='Done'
          onCancel={() => this.props.done()}
        />
      }
      return <ModalConfirmation
        show={true}
        header='Send reminder notifications'
        text={`Notifications will be emailed to ${displayNumber(count)} drivers. Do you want to proceed?`}
        loading={loading}
        error={error}
        onAccept={() => this.setState({ dryRun: false }, () => this.props.sendNotifications(false)) }
        onCancel={() => this.props.done()}
      />
    }
    return ''
  }

  _renderConfirmedRun () {
    const { driversBulkActions: { loading, error, sentNotifications } } = this.props

    let text = ''
    if (loading || !sentNotifications) {
      text = 'Sending notifications...'
    } else if (sentNotifications && sentNotifications.emailed) {
      text = `Notifications have been emailed to ${displayNumber(sentNotifications.emailed.count)} drivers.`
    }

    return <ModalConfirmation
      show={true}
      header='Send reminder notifications'
      text={text}
      loading={loading}
      error={error}
      showAccept={false}
      cancelText='Done'
      onCancel={() => this.props.done()}
    />
  }

  render () {
    const { dryRun } = this.state
    return dryRun ? this._renderDryRun() : this._renderConfirmedRun()
  }
}

const mapStateToProps = (state) => {
  const { driversBulkActions } = state
  return { driversBulkActions }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driversBulkActions },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SendNotifications)

SendNotifications.propTypes = {
  done: PropTypes.func.isRequired,
  driversBulkActions: PropTypes.object.isRequired,
  sendNotifications: PropTypes.func.isRequired
}
