import { actionTypes } from '../actions/actionTypes'

const initialState = {
  consent: null,
  consentContent: null,
  exists: null,
  loading: false,
  validationError: null,
  completionError: null,
  emailError: null,
  emailSuccess: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.DRIVER_CONSENT.SET_CONSENT:
    return { ...state, ...action.value }
  case actionTypes.DRIVER_CONSENT.SET_EXISTS:
    return { ...state, exists: action.value }
  case actionTypes.DRIVER_CONSENT.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.DRIVER_CONSENT.SET_VALIDATION_ERROR:
    return { ...state, validationError: action.value }
  case actionTypes.DRIVER_CONSENT.SET_COMPLETION_ERROR:
    return { ...state, completionError: action.value }
  case actionTypes.DRIVER_CONSENT.SET_EMAIL_ERROR:
    return { ...state, emailError: action.value }
  case actionTypes.DRIVER_CONSENT.SET_EMAIL_SUCCESS:
    return { ...state, emailSuccess: action.value }
  default:
    return state
  }
}