//import moment from 'moment'
import { isEmpty, isEqual, omit } from 'lodash'
import dotNumbersFiltersReducer from '../reducers/dotNumbersFilters'

const dotNumbersFilters = {
  /**
   * Returns the number of dotNumbers  who are selected
   */
  getNumberOfSelectedDOTNumbers: (state) => {
    const { dotNumbersFilters: { selectedDOTNumbers } } = state
    if (!selectedDOTNumbers) { return 0 }
    return selectedDOTNumbers.size
  },

  /**
   * Returns true when the selected drivers contains all the drivers
   */
  areAllDOTNumbersSelected: (state) => {
    const { dotNumbers: { dotNumbers } } = state
    if (!dotNumbers) { return false }
    return dotNumbersFilters.getNumberOfSelectedDOTNumbers(state) === dotNumbers.length
  },

  /**
   * Returns true when at least one driver is selected
   */
  areSomeDOTNumbersSelected: (state) => {
    return dotNumbersFilters.getNumberOfSelectedDOTNumbers(state) > 0
  },

  /**
   * Returns true when there are no currently active filters (including search)
   */
  areFiltersEmpty: (state) => {
    const { dotNumbersFilters: currentFilters } = state
    const initialFilters = dotNumbersFiltersReducer(undefined, { type: undefined })
    return isEqual(
      omit(currentFilters, ['sort', 'page', 'selectedDOTNumbers']),
      omit(initialFilters, ['sort', 'page', 'selectedDOTNumbers'])
    )
  },

  /**
    * Returns the filter parameters to use in API calls, given the current state of the dotNumbersFilters
    */
  getFilterParams: (state, includeDOTNumberIds) => {
    const { dotNumbersFilters: {
      page,
      search,
      sort,
      companies,
      selectedDOTNumbers
    } } = state

    const params = { page }
    if (search) { params.search = search }
    if (sort) { params.sort = sort }
    if (companies && !isEmpty(companies)) { params.companies = companies }
    //if (dotNumbers && !isEmpty(dotNumbers)) { params.dotNumbers = dotNumbers }

    if (includeDOTNumberIds && selectedDOTNumbers && selectedDOTNumbers.size > 0) {
      params.dotNumbersIds = Array.from(selectedDOTNumbers)
    }
    Object.assign(params, {})
    return params
  }

}

export { dotNumbersFilters }