import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  Dropdown,
  Image,
  Nav
} from 'react-bootstrap'
import styles from './navHeader.module.scss'
import logo from '../../../images/drivers-clearinghouse-logo-small-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import * as actions from '../../../actions'

class NavHeader extends Component {

  state = {
    showContactForm: null
  }

  _currentLocationConfig () {
    const { router: { location: { pathname } } } = this.props

    const config = {
      home: {
        name: 'Home',
        showDriverRostersLink: true,
        showCompaniesLink:true,
        showDOTRosterLink:true,
        showTasksErrorsLink:true
      },
      driverRosters: {
        name: 'Driver roster',
        showHomeLink: true,
        showCompaniesLink:true,
        showDOTRosterLink:true,
        showTasksErrorsLink:true
      },
      companies: {
        name: 'Companies',
        showHomeLink: true,
        showDriverRostersLink: true,
        showDOTRosterLink:true,
        showTasksErrorsLink:true
      },
      dotRosters: {
        name: 'DOT roster',
        showHomeLink: true,
        showDriverRostersLink: true,
        showCompaniesLink:true,
        showTasksErrorsLink:true
      },
      tasksErrors: {
        name: 'Tasks/Errors',
        showHomeLink: true,
        showDriverRostersLink: true,
        showCompaniesLink:true,
        showDOTRosterLink:true
      }

    }

    let current
    if (/home/.test(pathname)) { current = config.home }
    if (/driver-roster/.test(pathname)) { current = config.driverRosters }
    if (/companies/.test(pathname)) { current = config.companies }
    if (/dot-roster/.test(pathname)) { current = config.dotRosters }
    if (/tasks-errors/.test(pathname)) { current = config.tasksErrors }
    Object.assign(config, { current })

    return config
  }

  _renderViewSelect () {
    const config = this._currentLocationConfig()

    return (
      <Dropdown className='ml-3'>
        <Dropdown.Toggle
          className={`${styles.menuButton} px-3`}
          variant='outline-light'
          size='sm'
          id='viewSelect'
        >{config.current.name}<FontAwesomeIcon icon={faChevronDown} className='ml-3'/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {!config.current.showHomeLink ? '' :
            <Dropdown.Item
              as='button'
              key='1'
              id='navHomeButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToConcordeAdminHome()}
            >{config.home.name}</Dropdown.Item>
          }
          {!config.current.showDriverRostersLink ? '' :
            <Dropdown.Item
              as='button'
              key='2'
              id='navDriversTableButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToConcordeAdminDriversTable()}
            >{config.driverRosters.name}</Dropdown.Item>
          }
          {!config.current.showCompaniesLink ? '' :
            <Dropdown.Item
              as='button'
              key='3'
              id='navCompaniesTableButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToConcordeAdminCompanies()}
            >{config.companies.name}</Dropdown.Item>
          }
          {!config.current.showDOTRosterLink ? '' :
            <Dropdown.Item
              as='button'
              key='3'
              id='navDotRostersButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToConcordeAdminDOTRoster()}
            >{config.dotRosters.name}</Dropdown.Item>
          }
          {!config.current.showTasksErrorsLink ? '' :
            <Dropdown.Item
              as='button'
              key='3'
              id='navDotRostersButton'
              className='btn btn-secondary'
              onClick={() => this.props.navigateToConcordeAdminTasksErrors()}
            >{config.tasksErrors.name}</Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  render () {
    const { auth: { user } } = this.props
    if (!user) { return '' }
    const { firstName, lastName } = user

    return (
      <Nav className={`${styles.nav} nav d-flex justify-content-between align-items-center`}>
        <div className='d-flex align-items-center'>
          <Link to='/concorde-admin/home'>
            <Image className={`${styles.navImage} ml-3`} src={logo} alt='logo'/>
          </Link>
          {this._renderViewSelect()}
        </div>
        <div className='d-flex align-items-center'>
          <span
            className={`${styles.userName} text-light mr-4`}
          >{`${firstName} ${lastName}`}</span>
          <Dropdown className='mr-3'>
            <Dropdown.Toggle
              className={styles.menuButton}
              variant='outline-light'
              size='sm'
              id='navSelect'
            ><FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item
                as='button'
                onSelect={() => this.props.logout()}
                key='1'
                className='btn btn-secondary'
              >Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Nav>
    )
  }
}

const mapStateToProps = (state) => {
  const { router, auth } = state
  return { router, auth }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.auth, ...actions.navigation },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader)

NavHeader.propTypes = {
  router: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  navigateToConcordeAdminHome: PropTypes.func.isRequired,
  navigateToConcordeAdminDriversTable: PropTypes.func.isRequired,
  navigateToConcordeAdminDOTRoster:PropTypes.func.isRequired,
  navigateToConcordeAdminCompanies:PropTypes.func.isRequired,
  navigateToConcordeAdminTasksErrors:PropTypes.func.isRequired
}
