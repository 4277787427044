import { actionTypes } from './actionTypes'
import { sendEmail } from '../services/apiService'

const contact = {
  setError: (error) => ({
    type: actionTypes.CONTACT.SET_ERROR,
    value: error
  }),

  setLoading: (loading) => ({
    type: actionTypes.CONTACT.SET_LOADING,
    value: loading
  }),

  setSent: (sent) => ({
    type: actionTypes.CONTACT.SET_SENT,
    value: sent
  }),

  sendEmail: (message) => {
    return async (dispatch) => {
      dispatch(contact.setLoading(true))
      dispatch(contact.setError())
      const data = await sendEmail(message)
      if (data && data.sent) {
        dispatch(contact.setSent(true))
      } else if (data && data.error) {
        dispatch(contact.setError(data.error))
      }
      dispatch(contact.setLoading(false))
    }
  }
}

export { contact }
