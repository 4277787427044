import { actionTypes } from '../actions/actionTypes'

const initialState = {
  drivers: null,
  pages: {},
  loading: null,
  error: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.DRIVERS.SET_DRIVERS:
    return { ...state, ...action.value }
  case actionTypes.DRIVERS.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.DRIVERS.SET_ERROR:
    return { ...state, error: action.value }
  default:
    return state
  }
}