import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { head } from 'lodash'

import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../../images/drivers-clearinghouse-logo-small.png'

import * as actions from '../../../actions'
import * as selectors from '../../../selectors'
import ConcordeAdminRosterTemplate from './files/DriverRostersTemplateConcorde.xlsx'
import CompanyAdminRosterTemplate from './files/DriverRostersTemplate.xlsx'

class UploadRosters extends Component {
  state = {
    files: []
  }

  componentDidMount () {
    this.props.setUploaded()
    this.props.setError()
  }

  _renderButtonContents () {
    const { rosters: { loading } } = this.props
    if (!loading) { return 'Upload' }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Uploading
    </React.Fragment>
  }

  _renderError () {
    const { rosters: { error } } = this.props
    if (!error) { return '' }
    return <Row>
      <Col xs={12} className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  _renderInstructions () {
    const { rosters: { uploaded } } = this.props
    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
    if (uploaded) { return '' }
    return (
      <Row>
        <Col xs={12}>
          <p>Please make sure the file contains columns for: driver first name, last name, drivers license number, state, date of birth, {(isConcordeAdmin) ? 'company name, ' : ''}DOT number, and email address (if available).</p>
          <p><a href={isConcordeAdmin ? ConcordeAdminRosterTemplate : CompanyAdminRosterTemplate} className='' download='RosterUploadTemplate.xlsx'>Download an example template here.</a></p>
        </Col>
      </Row>
    )

  }

  _renderDropzone () {
    const { rosters: { uploaded } } = this.props
    if (uploaded) { return '' }

    const { files } = this.state
    let text = <span>Drag and drop an <strong>Excel</strong> file here or click to select a file.</span>
    if (files.length === 1) {
      text = <span>
        File to be uploaded: <strong>{files[0].name}</strong><br></br>
        Click upload to continue, or choose a different file.
      </span>
    }

    return (
      <Row>
        <Col xs={12} className='d-flex justify-content-center align-items-center'>
          <Dropzone
            multiple={false}
            onDrop={(files) => this.setState({ files })}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps({ className: `dropzone ${isDragActive ? 'active' : ''} p-3 d-flex justify-content-center align-items-center` })} style={{ width: '100%' }}>
                <input {...getInputProps()} />
                {text}
              </div>
            )}
          </Dropzone>
        </Col>
      </Row>
    )
  }

  _renderUploaded () {
    const { rosters: { uploaded } } = this.props
    if (!uploaded) { return '' }
    let errorInfoText = ''
    if (uploaded.rowsFailed > 0) {
      uploaded.errorInfo.forEach((error) => {
        errorInfoText = `${errorInfoText} - ${error.errorMessage} (Row ${error.recordNumber}) <br>`
      })
    }
    return <React.Fragment>{uploaded.rowsInserted === 0 ? '' :
      <Row>
        <Col xs={12} className='alert alert-success'>
          Successfully inserted {uploaded.rowsInserted} {uploaded.rowsInserted === 1 ? 'driver.' : 'drivers.'}
        </Col>
      </Row>}
    {uploaded.rowsFailed === 0 ? '' :
      <Row>
        <Col xs={12} className='alert alert-danger'>
          {uploaded.rowsFailed === 0 ? '' : `${uploaded.rowsFailed} ${uploaded.rowsFailed === 1 ? 'driver was' : 'drivers were' } not inserted.  Error Information is below.`}
          <br></br>
          <div dangerouslySetInnerHTML={{ __html:  errorInfoText }} />
        </Col>
      </Row>}
    </React.Fragment>
  }

  render () {
    const { rosters: { loading, uploaded }, done } = this.props
    const { files } = this.state

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Upload driver roster
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            {this._renderInstructions()}
            {this._renderDropzone()}
            {this._renderUploaded()}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className={uploaded ? 'btn-wide' : 'btn-semi-wide'}
            onClick={() => done()}
          >{uploaded ? 'Done' : 'Cancel'}</Button>
          {uploaded ? '' :
            <Button
              variant='primary'
              size={'sm'}
              disabled={loading || files.length !== 1}
              className='btn-wide'
              onClick={() => this.props.upload(head(this.state.files))}
            >{this._renderButtonContents()}</Button>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, rosters } = state
  return { auth, rosters }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.rosters },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadRosters)

UploadRosters.propTypes = {
  done: PropTypes.func.isRequired,
  rosters: PropTypes.object.isRequired,
  setUploaded: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired
}
