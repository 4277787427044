import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import { fullName } from '../../../utils'
import ModalConfirmation from '../modalConfirmation'

import * as actions from '../../../actions'

class SendNotification extends Component {
  componentDidMount () {
    this.props.setSentNotification()
    this.props.setError()
  }

  componentDidUpdate () {
    const { driver: { sentNotification } } = this.props
    if (sentNotification) {
      this.props.done()
    }
  }

  render () {
    const { driver: { driver, loading, error } } = this.props
    if (!driver) { return '' }

    return <ModalConfirmation
      show={true}
      header='Send reminder notification'
      text={`Send a reminder notification email to ${fullName(driver)} at ${driver.email}?`}
      onAccept={() => this.props.sendNotification(driver._id)}
      onCancel={() => this.props.done()}
      loading={loading}
      error={error}
    />
  }
}

const mapStateToProps = (state) => {
  const { driver } = state
  return { driver }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SendNotification)

SendNotification.propTypes = {
  done: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  setSentNotification: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
