import { actionTypes } from './actionTypes'
import * as companiesActions from '../actions/companies'
import {
  requestUpdateCompany,
  requestGetCompany
} from '../services/apiService'

const company = {
  setCompany: (value) => ({
    type: actionTypes.COMPANY.SET_COMPANY,
    value
  }),

  setEdited: (value) => ({
    type: actionTypes.COMPANY.SET_EDITED,
    value
  }),

  setError: (value) => ({
    type: actionTypes.COMPANY.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.COMPANY.SET_LOADING,
    value
  }),

  updateCompany: (companyId, params) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(company.setEdited())
      dispatch(company.setLoading(true))
      dispatch(company.setError())
      const data = await requestUpdateCompany(token, companyId, params)
      if (data && data.company) {
        dispatch(company.setEdited(true))
        dispatch(companiesActions.companies.updateWithCompany(data.company))
      } else if (data && data.error) {
        dispatch(company.setError(data.error))
      }
      dispatch(company.setLoading(false))
    }
  },

  getCompany: (companyId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(company.setLoading(true))
      dispatch(company.setError())
      const data = await requestGetCompany(token, companyId)
      if (data && data.company) {
        dispatch(company.setCompany(data.company))
      } else if (data && data.error) {
        dispatch(company.setError(data.error))
      }
      dispatch(company.setLoading(false))
    }
  }
}

export { company }
