import { actionTypes } from '../actions/actionTypes'

const initialState = {
  page: 1,
  search: undefined,
  sort: undefined,
  companies: [],
  dotNumbers: [],
  selectedDOTNumbers: new Set()
}

export default (state = initialState, action) => {
  console.log('DotNumbersFilters:' + action.type)
  switch(action.type){
  case actionTypes.DOT_NUMBERS_FILTERS.SET_PAGE:
    return { ...state, page: action.value }
  case actionTypes.DOT_NUMBERS_FILTERS.SET_SEARCH:
    return { ...state, search: action.value }
  case actionTypes.DOT_NUMBERS_FILTERS.SET_SORT:
    return { ...state, sort: action.value }
  case actionTypes.DOT_NUMBERS_FILTERS.SET_COMPANIES:
    return { ...state, companies: action.value }
  case actionTypes.DOT_NUMBERS_FILTERS.SET_DOT_NUMBERS:
    return { ...state, dotNumbers: action.value }
  case actionTypes.DOT_NUMBERS_FILTERS.SET_SELECTED_DOTNUMBERS:
    return { ...state, selectedDOTNumbers: action.value }
  case actionTypes.DOT_NUMBERS_FILTERS.CLEAR:
    return { ...state, ...initialState }
  // when drivers are set, clear out the selected drivers
  case actionTypes.DOT_NUMBERS_FILTERS.SET_DOTNUMBERS:
    return { ...state, selectedDOTNumbers: new Set() }
  default:
    return state
  }
}