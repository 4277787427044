import moment from 'moment'
import { isEmpty, isEqual, omit } from 'lodash'
import driversFiltersReducer from '../reducers/driversFilters'

const driversFilters = {
  /**
   * Returns the number of drivers who are selected
   */
  getNumberOfSelectedDrivers: (state) => {
    const { driversFilters: { selectedDrivers } } = state
    if (!selectedDrivers) { return 0 }
    return selectedDrivers.size
  },

  /**
   * Returns true when the selected drivers contains all the drivers
   */
  areAllDriversSelected: (state) => {
    const { drivers: { drivers } } = state
    if (!drivers) { return false }
    return driversFilters.getNumberOfSelectedDrivers(state) === drivers.length
  },

  /**
   * Returns true when at least one driver is selected
   */
  areSomeDriversSelected: (state) => {
    return driversFilters.getNumberOfSelectedDrivers(state) > 0
  },

  /**
   * Returns true when there are no currently active filters (including search)
   */
  areFiltersEmpty: (state) => {
    const { driversFilters: currentFilters } = state
    const initialFilters = driversFiltersReducer(undefined, { type: undefined })
    return isEqual(
      omit(currentFilters, ['sort', 'page', 'selectedDrivers']),
      omit(initialFilters, ['sort', 'page', 'selectedDrivers'])
    )
  },

  /**
   * Returns the filter parameters to use in API calls, given the current state of the driversFilters
   */
  getFilterParams: (state, includeDriverIds) => {
    const { driversFilters: {
      page,
      search,
      sort,
      companies,
      dotNumbers,
      active,
      email,
      states,
      lastConsented,
      pendingConsent,
      lastNotified,
      pendingQuery,
      lastQueried,
      selectedDrivers,
      queryTypes,
      queryStatuses,
      lastQueriedFrom,
      lastQueriedTo,
      lastQueriedNE,
      lastQueriedBetween
    } } = state

    const params = { page }
    if (search) { params.search = search }
    if (sort) { params.sort = sort }
    if (companies && !isEmpty(companies)) { params.companies = companies }
    if (dotNumbers && !isEmpty(dotNumbers)) { params.dotNumbers = dotNumbers }
    if (states && !isEmpty(states)) { params.states = states }
    if (queryTypes && !isEmpty(queryTypes)) { params.queryTypes = queryTypes }
    if (queryStatuses && !isEmpty(queryStatuses)) { params.queryStatuses = queryStatuses }
    if (lastConsented) {
      params.lastConsented = moment(lastConsented, 'YYYY-MM-DD').utc().format('YYYY-MM-DD')
    } else if (lastConsented === false) {
      params.lastConsented = false
    }
    if (lastNotified) {
      params.lastNotified = moment(lastNotified, 'YYYY-MM-DD').utc().format('YYYY-MM-DD')
    } else if (lastNotified === false) {
      params.lastNotified = false
    }

    if (lastQueried) {
      params.lastQueried = moment(lastQueried, 'YYYY-MM-DD').utc().format('YYYY-MM-DD')
    } else if (lastQueried === false) {
      params.lastQueried = false
    }

    if(lastQueriedNE) {
      params.lastQueriedNE = lastQueriedNE
      params.lastQueriedBetween = false
      params.lastQueriedFrom = false
      params.lastQueriedTo = false
    } else if (lastQueriedBetween) {
      params.lastQueriedBetween = lastQueriedBetween
      params.lastQueriedNE = false
      params.lastQueriedFrom = moment(lastQueriedFrom, 'YYYY-MM-DD').utc().format('YYYY-MM-DD')
      params.lastQueriedTo = moment(lastQueriedTo, 'YYYY-MM-DD').utc().format('YYYY-MM-DD')
    }

    if (includeDriverIds && selectedDrivers && selectedDrivers.size > 0) {
      params.driverIds = Array.from(selectedDrivers)
    }
    Object.assign(params, { active, email, pendingConsent, pendingQuery })
    return params
  }
}

export { driversFilters }