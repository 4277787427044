import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'

import {
  Button,
  Container,
  Col,
  Row,
  Spinner
} from 'react-bootstrap'
import NavHeader from './common/navHeader'
import ModalConfirmation from '../common/modalConfirmation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import PdfDocument from '../common/pdfDocument'

import * as actions from '../../actions'

class ConsentPrint extends Component {
  state = {
    showEmailConfirmationModal: false
  }

  componentDidMount () {
    const { driverConsent: { consent, exists, pdf } } = this.props

    // if the consent or pdf is not present, navigate to the validation view
    if (!consent || !exists || !pdf) {
      const { match: { params: { uuid } } } = this.props
      this.props.navigateToDriverConsentValidate({ uuid })
    }
  }

  _handleEmailButtonClick () {
    this.props.setEmailError()
    this.props.setEmailSuccess()
    this.setState({ showEmailConfirmationModal: true })
  }

  _handleEmailAcceptClick () {
    this.setState({ showEmailConfirmationModal: false })
    const { driverConsent: { consent: { uuid, driver: { dob, driversLicenseNumber } } } } = this.props
    this.props.emailDriverConsent({
      uuid,
      dob,
      driversLicenseNumber
    })
  }

  _handleEmailCancelClick () {
    this.setState({ showEmailConfirmationModal: false })
  }

  _renderLoader () {
    return (
      <Row className='mx-2 justify-content-center'>
        <Col xs={12} md={10} xl={8} className='p-3 my-4 d-flex justify-content-center'>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Loading
        </Col>
      </Row>
    )
  }

  _renderEmailSuccess () {
    const { driverConsent: { emailSuccess } } = this.props
    if (!emailSuccess) { return '' }
    return (
      <Row className='mx-2 justify-content-center'>
        <Col xs={12} md={10} xl={8} className='p-3 my-4 alert alert-success'>
        A copy of the consent has been emailed.
        </Col>
      </Row>
    )
  }

  _renderEmailError () {
    const { driverConsent: { emailError } } = this.props
    if (!emailError) { return '' }
    return (
      <Row className='mx-2 justify-content-center'>
        <Col xs={12} md={10} xl={8} className='p-3 my-4 alert alert-danger'>
          {`There was an error sending the email: ${emailError}`}
        </Col>
      </Row>
    )
  }

  render () {
    const { driverConsent: { loading, consent, pdf } } = this.props
    if (!consent || !pdf) { return '' }

    const { driver: { email, dotNumber: { company: { name: companyName } } }, completed } = consent

    return (
      <React.Fragment>
        <NavHeader/>
        <ModalConfirmation
          show={this.state.showEmailConfirmationModal}
          header='Send email'
          text={`Would you like to send a copy of this consent form to ${email}?`}
          onAccept={() => this._handleEmailAcceptClick()}
          onCancel={() => this._handleEmailCancelClick()}
        />
        <Container className='mb-5'>
          <Row className='mx-2 justify-content-center'>
            <Col xs={12} md={10} xl={8} className='p-3 my-4 alert alert-secondary'>
              <p>
                <span>You consented to this request from </span>
                <strong>{companyName} </strong>
                <span>on {moment.utc(completed).local().format('DD MMMM YYYY')}.</span>
                <br/><br/><strong>Please download or email yourself this completed form for your records.</strong>
              </p>
            </Col>
          </Row>
          { loading ? this._renderLoader() : ''}
          {this._renderEmailSuccess()}
          {this._renderEmailError()}
          <PdfDocument
            consent={consent}
            pdf={pdf}
            emailButton={!email ? '' :
              <Button
                size={'sm'}
                className='ml-5'
                onClick={() => this._handleEmailButtonClick()}
              ><FontAwesomeIcon icon={faEnvelope}/>
              </Button>
            }
          />
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { driverConsent } = state
  const { match } = ownProps
  return { match, driverConsent }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.navigation, ...actions.driverConsent },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentPrint)

ConsentPrint.propTypes = {
  match: PropTypes.object.isRequired,
  driverConsent: PropTypes.object.isRequired,
  navigateToDriverConsentValidate: PropTypes.func.isRequired,
  setEmailError: PropTypes.func.isRequired,
  setEmailSuccess: PropTypes.func.isRequired,
  emailDriverConsent: PropTypes.func.isRequired
}
