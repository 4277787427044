import { clone } from 'lodash'

const drivers = {
  updateDriversWithDriver: (drivers, driver) => {
    if (!drivers || !driver) { return drivers }
    const ind = drivers.findIndex((d) => d._id === driver._id)
    if (ind < 0) { return drivers }
    const newDrivers = clone(drivers)
    newDrivers[ind] = driver
    return newDrivers
  },

  updateDriversWithDrivers: (oldDrivers, updatedDrivers) => {
    if (!oldDrivers || !updatedDrivers)
      return oldDrivers
    const newDrivers = clone(oldDrivers)
    updatedDrivers.forEach(updated => {
      const ind = oldDrivers.findIndex((d) => d._id === updated._id)
      if (ind >= 0) newDrivers[ind] = updated
    })
    return newDrivers
  },

  prependDriver: (drivers, driver) => {
    if (!drivers || !driver) { return drivers }
    const newDrivers = clone(drivers)
    newDrivers.unshift(driver)
    return newDrivers
  }
}

export { drivers }