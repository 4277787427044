import jwt_decode from 'jwt-decode'
import { actionTypes } from './actionTypes'
import { requestAuthUser, requestForgot, requestReset } from '../services/apiService'
import { setCookie, deleteCookie } from '../utils'
import { navigation } from './navigation'
import { drivers } from './drivers'
import { dotNumbers } from './dotNumbers'
import { consentContent } from './consentContent'
import { companies } from './companies'

const auth = {
  setUser: (user) => ({
    type: actionTypes.AUTH.SET_USER,
    value: user
  }),

  setError: (error) => ({
    type: actionTypes.AUTH.SET_ERROR,
    value: error
  }),

  setForgotSent: (forgotSent) => ({
    type: actionTypes.AUTH.SET_FORGOT_SENT,
    value: forgotSent
  }),

  setPasswordReset: (passwordReset) => ({
    type: actionTypes.AUTH.SET_PASSWORD_RESET,
    value: passwordReset
  }),

  setLoading: (loading) => ({
    type: actionTypes.AUTH.SET_LOADING,
    value: loading
  }),

  setToken: (token) => ({
    type: actionTypes.AUTH.SET_TOKEN,
    value: token
  }),

  login: (params) => {
    return async (dispatch) => {
      dispatch(auth.setLoading(true))
      dispatch(auth.setError())
      const data = await requestAuthUser(params)
      if(data && data.token){
        setCookie(process.env.REACT_APP_AUTH_COOKIE_NAME, data.token)
        dispatch(auth.setToken(data.token))
        const user = jwt_decode(data.token)
        dispatch(auth.setUser(user))
        if (user && user.isConcordeAdmin) {
          dispatch(navigation.navigateToConcordeAdminHome())
        } else if (user && user.isOwnerOperator) {
          dispatch(navigation.navigateToOwnerOperatorHome())
        } else {
          dispatch(navigation.navigateToCompanyAdminHome())
        }
      } else if (data && data.error){
        dispatch(auth.setError(data.error))
      }
      dispatch(auth.setLoading(false))
    }
  },

  forgot: (params) => {
    return async (dispatch) => {
      dispatch(auth.setLoading(true))
      dispatch(auth.setForgotSent())
      dispatch(auth.setError())
      const data = await requestForgot(params)
      if (data && data.sent) {
        dispatch(auth.setForgotSent(true))
      } else if (data && data.error) {
        dispatch(auth.setError(data.error))
      }
      dispatch(auth.setLoading(false))
    }
  },

  reset: (params) => {
    return async (dispatch) => {
      dispatch(auth.setLoading(true))
      dispatch(auth.setPasswordReset())
      dispatch(auth.setError())
      const data = await requestReset(params)
      if (data && data.success) {
        dispatch(auth.setPasswordReset(true))
      } else if (data && data.error) {
        dispatch(auth.setError(data.error))
      }
      dispatch(auth.setLoading(false))
    }
  },

  logout: () => {
    return async (dispatch) => {
      dispatch(drivers.clear())
      dispatch(dotNumbers.clear())
      dispatch(consentContent.clear())
      dispatch(companies.clear())
      deleteCookie(process.env.REACT_APP_AUTH_COOKIE_NAME)
      dispatch(auth.setUser())
    }
  }
}

export { auth }
