import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getLabelForLanguage } from '../../utils'

import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner
} from 'react-bootstrap'
import NavHeader from './common/navHeader'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

class ConsentReview extends Component {
  state = {
    selectedContent: 0,
    accepted: false,
    htmlContent: null
  }

  componentDidMount () {
    const { driverConsent: { consent, exists, pdf } } = this.props
    const { match: { params: { uuid } } } = this.props

    // if the consent is not present, navigate to the validation view
    if (!consent || !exists) {
      this.props.navigateToDriverConsentValidate({ uuid })
      return
    }

    // if the consent and pdf are present, navigate to the print view
    if (consent && pdf) {
      this.props.navigateToDriverConsentPrint({ uuid })
      return
    }

    this._handleLanguageSelect(this.state.selectedContent)
  }

  _renderMessage = () => {
    const { driverConsent: { completionError } } = this.props
    if (!completionError) { return '' }
    return (
      <Row>
        <Col xs={12} md={6} className='mt-5 alert alert-danger'>
          {`There was a problem accepting the consent: ${completionError}. Please reload the page and try again.`}
        </Col>
      </Row>
    )
  }

  _handleButtonClick () {
    const { driverConsent: { consent: { uuid, driver: { dob, driversLicenseNumber } } } } = this.props
    this.props.completeDriverConsent({
      uuid,
      dob,
      driversLicenseNumber,
      ...this.state
    })
  }

  _handleLanguageSelect (key) {
    const content = selectors.driverConsent.getRenderedConsentContent(this.props)
    const htmlContent = content[key]
    this.setState({ selectedContent: key, htmlContent })
  }

  _renderButtonContents = () => {
    const { driverConsent: { loading } } = this.props

    if (loading) {
      return (
        <React.Fragment>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Loading
        </React.Fragment>
      )
    }
    return 'Continue'
  }

  _renderLanguageSelect () {
    const supportedLanguages = selectors.driverConsent.getSupportedLanguages(this.props)
    if (supportedLanguages.length <= 1) { return '' }

    const { selectedContent } = this.state

    return (
      <Dropdown onSelect={(key) => this._handleLanguageSelect(key)}>
        <Dropdown.Toggle
          variant='outline-secondary'
          size='sm'
          id='languageSelect'
        >
          Choose language
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          {supportedLanguages.map((language, index) =>
            <Dropdown.Item
              key={index}
              eventKey={index}
              active={new RegExp(index).test(selectedContent)}
            >{getLabelForLanguage(language)}</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  _renderConsentContent () {
    const { htmlContent } = this.state
    if (!htmlContent) { return '' }
    return (<div dangerouslySetInnerHTML={{ __html: htmlContent }}/>)
  }

  render () {
    const { driverConsent: { consent, loading } } = this.props
    if (!consent) { return '' }

    return (
      <React.Fragment>
        <NavHeader/>
        <Container className='mb-5 mt-2'>
          <Row className='mb-4'>
            <Col xs={12} className='d-flex justify-content-end'>
              {this._renderLanguageSelect()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this._renderConsentContent()}
            </Col>
          </Row>
          {this._renderMessage()}
          <Row className='mt-5 mb-3'>
            <Col xs={12} md={6}>
              <Form.Check
                name='consent'
                id='consentCheck'
                label='I consent to this request.'
                selected={this.state.accepted}
                onChange={() => this.setState({ accepted: !this.state.accepted  })}
              />
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col xs={12} md={6} className='mb-5'>
              <Button
                variant='primary'
                block
                type='submit'
                disabled={loading || !this.state.accepted}
                onClick={() => this._handleButtonClick()}
              >{this._renderButtonContents()}</Button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { driverConsent } = state
  const { match } = ownProps
  return { match, driverConsent }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { ...actions.navigation, ...actions.driverConsent },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentReview)

ConsentReview.propTypes = {
  match: PropTypes.object.isRequired,
  driverConsent: PropTypes.object.isRequired,
  navigateToDriverConsentValidate: PropTypes.func.isRequired,
  navigateToDriverConsentPrint: PropTypes.func.isRequired,
  completeDriverConsent: PropTypes.func.isRequired
}
