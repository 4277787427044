import { actionTypes } from './actionTypes'
import { requestConsentContent } from '../services/apiService'

const consentContent = {
  setConsentContent: (value) => ({
    type: actionTypes.CONSENT_CONTENT.SET_CONSENT_CONTENT,
    value
  }),

  setError: (value) => ({
    type: actionTypes.CONSENT_CONTENT.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.CONSENT_CONTENT.SET_LOADING,
    value
  }),

  loadConsentContent: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(consentContent.setLoading(true))
      dispatch(consentContent.setError())
      const data = await requestConsentContent(token)
      if(data && data.consentContent){
        dispatch(consentContent.setConsentContent(data))
      } else if (data && data.error) {
        dispatch(consentContent.setError(data.error))
      }
      dispatch(consentContent.setLoading(false))
    }
  },

  clear: () => {
    return (dispatch) => {
      dispatch(consentContent.setConsentContent({ consentContent: null }))
    }
  }
}

export { consentContent }
