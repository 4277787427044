import { keyBy, isEmpty } from 'lodash'

const companies = {
  getCompanyMap: (state) => {
    const { companies: { companies } } = state
    return keyBy(companies || {}, '_id')
  },

  getCompanyIdForDriver: (state) => {
    const { driver: { driver }, dotNumbers: { dotNumbers } } = state
    if (isEmpty(dotNumbers) || !driver || !driver.dotNumber) { return }
    const dotNumber = dotNumbers.find((d) => driver.dotNumber === d._id)
    if (dotNumber) { return dotNumber.company }
  }

}

export { companies }