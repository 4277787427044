import { actionTypes } from './actionTypes'
import * as driversActions from './drivers'
import {
  requestCreateConsents,
  requestSendNotifications,
  requestCreateQueries,
  requestUpdateQueryStatuses,
  requestCreateDriverExport,
  requestProcessFMCSAList
} from '../services/apiService'
import * as selectors from '../selectors'

const driversBulkActions = {
  setDrivers: (drivers) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_DRIVERS,
    value: drivers
  }),

  setCreatedConsents: (value) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_CREATED_CONSENTS,
    value
  }),

  setSentNotifications: (value) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_SENT_NOTIFICATIONS,
    value
  }),

  setCreatedQueries: (value) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_CREATED_QUERIES,
    value
  }),

  setUpdatedQueryStatuses: (value) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_UPDATED_QUERY_STATUSES,
    value
  }),

  setCreateDriverExport: (value) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_CREATED_DRIVER_EXPORT,
    value
  }),

  setError: (error) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_ERROR,
    value: error
  }),

  setLoading: (loading) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_LOADING,
    value: loading
  }),

  setProcessedFMCSAList: (value) => ({
    type: actionTypes.DRIVERS_BULK_ACTIONS.SET_PROCESSED_FMCSA_LIST,
    value
  }),

  createConsents: (dryRun = true) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      const params = {
        ...selectors.driversFilters.getFilterParams(getState(), true),
        dryRun
      }

      dispatch(driversBulkActions.setLoading(true))
      dispatch(driversBulkActions.setError())
      dispatch(driversBulkActions.setCreatedConsents())
      const data = await requestCreateConsents(token, params)
      if (data && data.result) {
        dispatch(driversBulkActions.setCreatedConsents(data.result))
      } else if (data && data.error) {
        dispatch(driversBulkActions.setError(data.error))
      }
      dispatch(driversBulkActions.setLoading(false))
    }
  },

  sendNotifications: (dryRun = true) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      const params = {
        ...selectors.driversFilters.getFilterParams(getState(), true),
        dryRun
      }

      dispatch(driversBulkActions.setLoading(true))
      dispatch(driversBulkActions.setError())
      dispatch(driversBulkActions.setSentNotifications())
      const data = await requestSendNotifications(token, params)
      if (data && data.result) {
        dispatch(driversBulkActions.setSentNotifications(data.result))
      } else if (data && data.error) {
        dispatch(driversBulkActions.setError(data.error))
      }
      dispatch(driversBulkActions.setLoading(false))
    }
  },

  createDriverExport: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      const params = {
        ...selectors.driversFilters.getFilterParams(getState(), true)
      }

      dispatch(driversBulkActions.setLoading(true))
      dispatch(driversBulkActions.setError())
      dispatch(driversBulkActions.setCreateDriverExport())
      const data = await requestCreateDriverExport(token, params)
      if (data && data.result) {
        dispatch(driversBulkActions.setCreateDriverExport(data.result))
      } else if (data && data.error) {
        dispatch(driversBulkActions.setError(data.error))
      }
      dispatch(driversBulkActions.setLoading(false))
    }
  },


  createQueries: (queryType, dryRun = true) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      const params = {
        ...selectors.driversFilters.getFilterParams(getState(), true),
        queryType,
        dryRun
      }

      dispatch(driversBulkActions.setLoading(true))
      dispatch(driversBulkActions.setError())
      dispatch(driversBulkActions.setCreatedQueries())
      const data = await requestCreateQueries(token, params)
      if (data && data.result) {
        dispatch(driversBulkActions.setCreatedQueries(data.result))
      } else if (data && data.error) {
        dispatch(driversBulkActions.setError(data.error))
      }
      dispatch(driversBulkActions.setLoading(false))
    }
  },

  updateQueryStatuses: (queryStatus) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      const params = {
        ...selectors.driversFilters.getFilterParams(getState(), true),
        queryStatus
      }
      dispatch(driversBulkActions.setLoading(true))
      dispatch(driversBulkActions.setError())
      dispatch(driversBulkActions.setUpdatedQueryStatuses())
      const data = await requestUpdateQueryStatuses(token, params)
      if (data && data.result) {
        dispatch(driversBulkActions.setUpdatedQueryStatuses(data.result))
        dispatch(driversActions.drivers.updateWithDrivers())
      } else if (data && data.error) {
        dispatch(driversBulkActions.setError(data.error))
      }
      dispatch(driversActions.drivers.debouncedReloadDrivers())
      dispatch(driversBulkActions.setLoading(false))
    }
  },

  processFMCSAList: (file) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(driversBulkActions.setLoading(true))
      dispatch(driversBulkActions.setError())
      const data = await requestProcessFMCSAList(token, file)
      if (data && data.processedFMCSAList) {
        dispatch(driversBulkActions.setProcessedFMCSAList(data))
      } else if (data && data.error) {
        dispatch(driversBulkActions.setError(data.error))
      }
      dispatch(driversBulkActions.setLoading(false))
    }
  }
}

export { driversBulkActions }
