import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row
} from 'react-bootstrap'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

class CreateDOTNumbers extends Component {
  state = {
    checked: false,
    DOTNumbers: this.props.DOTNumbers
  }

  _handleAddDOT (DOTNumbers) {
    DOTNumbers.push(null)
    this.setState({ DOTNumbers: DOTNumbers })
  }

  _handleRemoveDOT (DOTNumbers, index) {
    DOTNumbers.splice(index, 1)
    this.setState({ DOTNumbers: DOTNumbers })
  }

  _handleChangeDOT (DOTNumbers, index, event) {
    DOTNumbers[index] = event.target.value
    this.setState({ DOTNumbers: DOTNumbers })
  }

  render () {
    const { hide, next, back } = this.props
    return (
      <Modal
        show={true}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo' />
            Add DOT number(s)
          </Modal.Title>
          <Modal.Title>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => hide()}
            ><FontAwesomeIcon icon={faTimes} />
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this.state.DOTNumbers.map((dot, index) => {
              return <Row className='pb-1 mx-3 align-items-center'
                key={index}
                style={{ marginBottom: '5px' }}>
                <Col xs={3} md={4}
                  className='text-right' style={{ fontWeight: 'bold' }}>{index + 1}:</Col>
                <Col xs={6} md={4}>
                  <Form.Control
                    type='text'
                    value={this.state.DOTNumbers[index] || ''}
                    onChange={(event) => this._handleChangeDOT(this.state.DOTNumbers, index, event)}
                  ></Form.Control>
                </Col>
                <Col xs={3} md={4}>
                  <Button
                    variant='danger'
                    size={'sm'}
                    disabled={this.state.DOTNumbers.length < 2}
                    onClick={() => this._handleRemoveDOT(this.state.DOTNumbers, index)}>
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </Button>
                </Col>
              </Row>
            })}
            <Row className='pb-1 mx-3 align-items-center'
              style={{ margin: '10px 0' }}>
              <Col xs={3} md={4}></Col>
              <Col xs={6} md={4}></Col>
              <Col xs={3} md={4}>
                <Button
                  variant='primary'
                  size={'sm'}
                  onClick={() => this._handleAddDOT(this.state.DOTNumbers, this.state.current)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => hide()}
          >Cancel</Button>
          <Button
            variant='outline-secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => back(this.state.DOTNumbers)}
          >Back</Button>
          <Button
            variant='primary'
            size={'sm'}
            className='btn-wide'
            disabled={this.state.DOTNumbers.length < 1 || this.state.DOTNumbers[0] === null}
            onClick={() => next(this.state.DOTNumbers)}
          >Next</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect()(CreateDOTNumbers)

CreateDOTNumbers.propTypes = {
  hide: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  DOTNumbers: PropTypes.array
}
