import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { pick } from 'lodash'
import { displayQueryStatus } from '../../../utils'

import {
  Col,
  Form,
  Row
} from 'react-bootstrap'
import Select from 'react-select'
import { selectCustomStyles } from '../selectCustomStyles'
import DatePicker from 'react-datepicker'

import * as selectors from '../../../selectors'

class DriverFields extends Component {
  state = {
    emid: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    driversLicenseNumber: '',
    state: '',
    dotNumber: '',
    active: null,
    email: '',
    dob: '',
    note: '',
    externalViewNote: null,
    queryStatus: null
  }

  componentDidMount () {
    const { driver, dotNumbers } = this.props
    if (dotNumbers && dotNumbers.length > 0) {
      this.setState({ dotNumber: dotNumbers[0]._id })
    }
    if (driver) {
      const params = pick(driver, [
        'emid',
        'firstName',
        'lastName',
        'phoneNumber',
        'driversLicenseNumber',
        'state',
        'dotNumber',
        'active',
        'email',
        'note',
        'externalViewNote',
        'queryStatus'
      ])
      Object.assign(params, { dob:  moment.tz(moment.utc(driver.dob).format('YYYY-MM-DD'), moment.tz.guess()).toDate() })
      this.setState(params)
    }
  }

  componentDidUpdate () {
    // because the set of DOT numbers can change (if a Concorde Admin selects a new company),
    // ensure that a valid dotNumber is selected
    const { dotNumbers } = this.props
    const { dotNumber } = this.state
    if (!dotNumbers.map((d) => d._id).includes(dotNumber)) {
      this._handleStateUpdate({ dotNumber: dotNumbers[0]._id })
    }
  }

  _handleStateUpdate (newState) {
    this.setState(newState, () => this.props.onUpdate(this.state))
  }

  _handleQueryStatusUpdate (newStatus) {
    const queryStatus = JSON.parse(JSON.stringify(this.state.queryStatus))
    const newState = { queryStatus: Object.assign(queryStatus, newStatus) }
    this._handleStateUpdate(newState)
  }

  render () {
    const { dotNumbers, isConcordeAdmin } = this.props
    if (!dotNumbers) { return }

    const {
      emid,
      firstName,
      lastName,
      phoneNumber,
      email,
      dob,
      driversLicenseNumber,
      state,
      dotNumber,
      active,
      note,
      externalNoteView,
      queryStatus
    }
      = this.state

    const queryStatuses = [
      'none',
      'awaiting',
      'pending',
      'complete-notprohibited',
      'incomplete/cancelled',
      'driverprohibited',
      'recordsfound-fullqueryrequested',
      'driver-notverified',
      'query balance- 0'
    ]

    return (
      <React.Fragment>
        {isConcordeAdmin && <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>Employment ID</Col>
          <Col xs={8} md={6}>
            <Form.Control
              type='text'
              value={emid}
              onChange={(e) => this._handleStateUpdate({ emid: e.target.value })}
            ></Form.Control>
          </Col>
        </Row>}
        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>First name</Col>
          <Col xs={8} md={6}>
            <Form.Control
              type='text'
              value={firstName}
              onChange={(e) => this._handleStateUpdate({ firstName: e.target.value })}
            ></Form.Control>
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>Last name</Col>
          <Col xs={8} md={6}>
            <Form.Control
              type='text'
              value={lastName}
              onChange={(e) => this._handleStateUpdate({ lastName: e.target.value })}
            ></Form.Control>
          </Col>
        </Row>

        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>Phone number</Col>
          <Col xs={8} md={6}>
            <Form.Control
              type='text'
              value={phoneNumber}
              onChange={(e) => this._handleStateUpdate({ phoneNumber: e.target.value })}
            ></Form.Control>
          </Col>
        </Row>


        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>Drivers license number</Col>
          <Col xs={8} md={6}>
            <Form.Control
              type='text'
              value={driversLicenseNumber}
              onChange={(e) => this._handleStateUpdate({ driversLicenseNumber: e.target.value })}
            ></Form.Control>
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>State</Col>
          <Col xs={8} md={6}>
            <Select
              styles={selectCustomStyles}
              isClearable={false}
              isSearchable={true}
              options={selectors.states.getAll().map((s) => ({ value: s, label: s }))}
              value={{ value: state, label: state }}
              onChange={(v) => this._handleStateUpdate({ state: v.value })}
            ></Select>
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>DOT number</Col>
          <Col xs={8} md={6}>
            <Form.Control
              as='select'
              size='sm'
              value={dotNumber}
              onChange={(e) => this._handleStateUpdate({ dotNumber: e.target.value })}
            >
              {dotNumbers.map((dotNumber) => (
                <option
                  key={dotNumber._id}
                  value={dotNumber._id}
                >{dotNumber.name}</option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>Active</Col>
          <Col xs={8} md={6}>
            <Form.Check
              inline
              label='Yes'
              type='checkbox'
              id='inline-checkbox-active-yes'
              checked={!!active}
              onChange={(e) => this._handleStateUpdate({ active: e.target.checked ? true : false })}
              className='mr-3'
            />
            <Form.Check
              inline
              label='No'
              type='checkbox'
              id='inline-checkbox-active-no'
              checked={!active}
              onChange={(e) => this._handleStateUpdate({ active: e.target.checked ? false : true })}
            />
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>Email address</Col>
          <Col xs={8} md={6}>
            <Form.Control
              type='text'
              value={email || ''}
              onChange={(e) => this._handleStateUpdate({ email: e.target.value })}
            ></Form.Control>
          </Col>
        </Row>
        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>Date of birth</Col>
          <Col xs={8} md={6}>
            <DatePicker
              dateFormat='M/d/yyyy'
              selected={dob}
              onChange={(date) => this._handleStateUpdate({ dob: date })}
              dropdownMode='scroll'
            />
          </Col>
        </Row>

        {(isConcordeAdmin || externalNoteView) &&

          <Row className='pb-1 mx-3 align-items-center'>
            <Col xs={4} className='text-right'>Note</Col>
            <Col xs={12} md={6}>
              <Form.Control
                as='textarea'
                disabled={!isConcordeAdmin}
                rows={3}
                value={note || ''}
                onChange={(e) => this._handleStateUpdate({ note: e.target.value })}
              ></Form.Control>
            </Col>

            {isConcordeAdmin && <Col xs={4} className='text-right'>External View Note</Col>}
            {isConcordeAdmin &&
              <Col xs={8} md={6}>
                <Form.Check
                  inline
                  label='Yes'
                  type='checkbox'
                  id='inline-checkbox-external-view-note-yes'
                  checked={!!externalNoteView}
                  onChange={(e) => this._handleStateUpdate({ externalNoteView: e.target.checked ? true : false })}
                  className='mr-3'
                />
                <Form.Check
                  inline
                  label='No'
                  type='checkbox'
                  id='inline-checkbox-external-view-note-no'
                  checked={!externalNoteView}
                  onChange={(e) => this._handleStateUpdate({ externalNoteView: e.target.checked ? false : true })}
                />
              </Col>
            }
          </Row>
        }
        {
          isConcordeAdmin && queryStatus &&
          <Row className='pb-1 mx-3 align-items-center'>
            <Col xs={4} className='text-right'>Query status</Col>
            <Col xs={8} md={6}>
              <Select
                styles={selectCustomStyles}
                isSearchable={false}
                options={queryStatuses.map((status) => ({ value: status, label: displayQueryStatus(status) }))}
                value={{ value: queryStatus.status, label: displayQueryStatus(queryStatus.status) }}
                onChange={(e) => this._handleQueryStatusUpdate({ status: e.value, statusDate: moment.utc().local().toDate() })}
              ></Select>
            </Col>
          </Row>
        }
        {
          isConcordeAdmin && queryStatus &&
          <Row className='pb-1 mx-3 align-items-center'>
            <Col xs={4} className='text-right'>Status date</Col>
            <Col xs={8} md={6}>
              <DatePicker
                dateFormat='M/d/yyyy'
                selected={moment.utc(queryStatus.statusDate).local().toDate()}
                onChange={(date) => this._handleQueryStatusUpdate({ statusDate: date })}
                dropdownMode='scroll'
              />
            </Col>
          </Row>
        }
      </React.Fragment>
    )
  }
}

export default DriverFields

DriverFields.propTypes = {
  driver: PropTypes.object,
  dotNumbers: PropTypes.array,
  onUpdate: PropTypes.func.isRequired,
  isConcordeAdmin: PropTypes.bool.isRequired
}