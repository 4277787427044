import React, { Component } from 'react'

import { Col  } from 'react-bootstrap'
import ModalBackdropForm from './common/modalBackdropForm'
import dompurify from 'dompurify'

const terms = `<div class="info_level_1">
<p>Concorde, Inc. d.b.a. Driversclearinghouse.com</p>
<p>Effective Date: 2.4.2020</p>
<p>IMPORTANT NOTICE: THESE TERMS OF SERVICE CONTAIN A BINDING ARBITRATION PROVISION AND CLASS ACTION WAIVER. IT AFFECTS YOUR LEGAL RIGHTS UNLESS YOU OPT OUT, AS DETAILED IN THE ARBITRATION AND CLASS ACTION WAIVER SECTION BELOW. PLEASE READ CAREFULLY.</p>
<p>Concorde, Inc. (also referred to as “Concorde,” “we,” “us,” or “our”), D.B.A Driversclearinghouse.com provides the content and services available on the Concorde websites, including driversclearinhouse.com and concorde2000.com, as may be modified, relocated and/or redirected from time to time (collectively, the “Site”). Except as expressly provided herein, these Terms of Service (“Terms”) contain the terms and conditions that govern your access to and use of the services, including those available on and through the Site, including but not limited to: obtaining, delivering, and managing background reports, physical exams, drug and alcohol tests, driver qualification files, and related documentation; facilitating the report and queries of substance abuse violations to the government drug and alcohol clearinghouse; obtaining status information regarding background reports, physical exams, and drug and alcohol tests; Concorde’s processes for generating background reports and resolving potential inaccuracies; requesting a copy of your consumer file; any disputes relating to your background check; and/or facilitating flu shots (collectively, the “Services”).</p>
<p>These Terms apply to any and all users of the Site and Services, including but not limited to individuals, small and large businesses, and nonprofit organizations, who utilize the Services (“Customers,” “you,” or “your”), as well as all others who access the Services, including but not limited to website visitors and users of affiliate websites which allow access to Concorde Services (“users,” “you,” or “your”) including each user’s heirs, assigns and successors.  If you use the Site or Services on behalf of a business entity, you represent and warrant that you have the authority to bind that entity, your acceptance of the Terms will be deemed an acceptance by that entity, and “you” and “your” herein shall refer to that entity.</p>
<p>You must be 18 years old or the age of majority in your jurisdiction in order to use the Services on our Site.  This Site is not directed to children under 13 years old.</p>
<p>By accessing the Site or using the Services, you agree to be bound by these Terms, <strong>including the Arbitration Agreement below,</strong> and our Privacy Policy, as may be updated from time to time, (“Privacy Policy”), which is incorporated into these Terms by reference.  Except as specified in the “Opt-Out” provision of the Arbitration Agreement below, IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT USE OR ACCESS OUR SITE.</p>
<p>To the extent permitted and except where prohibited by applicable law, these Terms include:</p>
<ul>
    <li>Your agreement that either you or Concorde may compel binding and final arbitration for most types of disputes (Section XVII).</li>
    <li>Your agreement that the Services are provided "as is" and without warranty (Section XV).</li>
    <li>Your agreement to release Concorde from liability based on claims relating to the Services (Section XV-XVII).</li>
    <li>Your agreement to indemnify Concorde from certain claims (Section XIV).</li>
    <li>Your consent to any modifications or amendments to these Terms (Section I).</li>
</ul>
<ol class="info_list">
    <li>
        <span><strong>Changes to the Terms</strong></span>
        <p>We reserve the right, in our sole discretion, to change and revise these Terms at any time by posting the changes on the Site.   Any changes are effective immediately upon posting to the Site. The most current version will be available on our website, and the Effective Date of the current version is at the top of this page.   By continuing to access or use the Services after those revisions become effective, you agree to be bound by the revised Terms, except as provided in the Arbitration Agreement in Section XVII below.</p>
        <p>The parties agree that any changes  or revisions to the Arbitration Agreement must be in writing and the parties agree that Concorde may change or revise the Arbitration Agreement and you accept the changes or revisions by agreeing to any updated Terms; however the changed or revised Arbitration Agreement will not apply to any Claims already asserted by either party. Except for the Arbitration Agreement in Section XVII below, which survives the termination of these Terms, Concorde may, with or without prior notice, terminate any of the rights granted by these Terms.  We will provide you with any additional notices or choices with respect to such changes as required by applicable law.</p>
    </li>
    <li>
        <span><strong>Reports, Tests, and Exams</strong></span>
        <p>As part of the Services, Concorde prepares consumer reports or investigative consumer reports, as those terms are defined in the Fair Credit Reporting Act as amended (“FCRA”) or applicable state law and other information transmitted by Concorde to the Customer pertaining to an individual consumer (collectively referred to herein as “Screening Reports”).   Concorde prepares Screening Reports in its role as a service provider for Customers and not in the role of an employer, provider of credit, underwriter of insurance, or other user of that information (“User”).  Therefore, Concorde does not take adverse actions on the basis of Screening Reports against individuals who are the subject of this information.  Individuals should contact the User, and not Concorde, about the reasons for any adverse action taken by the User on the basis of Screening Reports.</p>
        <ol>
            <li>
                <span><strong>Users of Screening Reports</strong></span>
                <p>User will obtain and use Screening Reports (i) only after completing a Credentialing Application with, and End User Certification to, Concorde, (ii) solely for the permissible purpose(s) designated by the User in the Credentialing Application, and (iii) ONLY in accordance with the requirements in the End User Certification.</p>
                <p>Unless otherwise provided in the Services Agreement executed between the User and Concorde, Users have the responsibility of obtaining all authorizations from individuals, and providing all disclosures to individuals, imposed on Users by applicable law with respect to obtaining and using Screening Reports.  Such authorizations include, without limitation, the authorizations required by FCRA, the Federal Motor Carrier Safety Administration, and state law. Such notifications include, without limitation: the disclosure, pre-adverse action letter, and final adverse action letter required by FCRA and equivalent state laws; drug and alcohol testing policies; and state and local notices such as the California Consumer Privacy Act Notice at Collection, San Francisco Fair Chance Ordinance notice, and the City of Boulder drug testing ordinance.</p>
                <p>Users agree that they will share Screening Reports only as required by law or to the individual who is the subject of the Screening Report, unless permitted in writing by Concorde.</p>
                <p>Users represent and warrant that they will comply with all obligations imposed on Users by applicable law with respect to Screening Reports.  Users of consumer reports or investigative consumer reports further agree that they have reviewed and fully understand the following statutory notice:</p>
                <ul>
                    <li>Notice to Users of Consumer Reports (16 C.F.R. Part 601) located at  <a href="url">https://www.ecfr.gov/cgi-bin/text-idx?SID=15991599362a722d9d72ead05daefe38&mc=true&node=ap12.8.1022.0000_0nbspnbspnbsp.n&rgn=div9</a></li>
                </ul>
            </li>
            <li>
                <span><strong>Subjects of Consumer Reports and Investigative Consumer Reports (Consumers)</strong></span>
                <p>In the Services, you may authorize Concorde to prepare background reports about you (also known as consumer reports or investigative consumer reports).  If you provide such authorization, you understand and agree that 1) the background report may contain information about your character, general reputation, personal characteristics, and/or mode of living, which can involve personal interviews with sources such as your neighbors, friends, or associates; 2) reports may include checks regarding your criminal history, credit history, eviction records, social security trace, motor vehicle records (“driving records”), drug screening, verification of your education or employment history or other background checks; and 3) any law enforcement agency, administrator, state or federal agency, institution, school or university (public or private), information service bureau, employer, or insurance company may furnish any and all background information requested by Concorde.</p>
                <p>The Services may allow you to request a copy of your background check or background reports about you. By requesting a copy, you authorize Concorde and its contractors/providers to use the information you provide to us for purposes of conducting background checks and producing reports for you. You agree that Concorde has no obligation to monitor or edit the information you submit to us and that you are solely responsible for the completeness and accuracy thereof.</p>
                <p>By authorizing the preparation of a background check about you, you further acknowledge receipt of, and certify that you have reviewed and fully understand, the following two statutory notices:</p>
                <ul>
                    <li>Summary of Your Rights Under the Fair Credit Reporting Act (16 C.F.R. Part 601) located at  <a href="url">https://files.consumerfinance.gov/f/documents/bcfp_consumer-rights-summary_2018-09.docx</a></li>
                    <li>Summary of Consumer Identity Theft Rights is at:  <a href="url">https://files.consumerfinance.gov/f/documents/bcfp_consumer-identity-theft-rights-summary_2018-09.docx</a></li>
                </ul>
                <p>Nothing in these Terms shall affect your rights: a) to access certain information in your file by contacting us, or b) to notify us and request that we investigate information in your consumer report or investigative consumer report that you believe is inaccurate or incomplete, as provided in FCRA and applicable law.</p>
                <p>To request that Concorde fulfill your rights pursuant to FCRA and applicable law, contact Concorde’s General Counsel, 800-662-1676 (Toll-Free Number), 701 Market Street, Suite 3400 Philadelphia, PA 19106. Concorde may request verification of your identity and contact information before fulfilling your request.  It is your sole responsibility to provide Concorde with an accurate and secure means to communicate with you (“Means of Communication”). Concorde disclaims any liability for the compromise, corruption, or loss of information received by, or for the malfunction of, your Means of Communication.</p>
            </li>
        </ol>
    </li>
    <li>
        <span><strong>Accuracy of Submitted Information</strong></span>
        <p>You represent and warrant that any information you provide about yourself, the organization you represent, or any other individual will be accurate, including any information you may submit for Screening Reports conducted about or requested by you.</p>
    </li>
    <li>
        <span><strong>Your Warranty of Compliance with Applicable Law</strong></span>
        <p>By accessing the Site or using the Services, you represent and warrant you shall comply with all applicable federal, state, and local law, including but not limited to FCRA, the Equal Employment Opportunity Act, the Americans with Disabilities Act, and the federal Privacy Act.</p>
    </li>
    <li>
        <span><strong>Privacy Policy</strong></span>
        <p>Information that you provide to Concorde through the Site, including information about yourself and any individual, is subject to our Privacy Policy.</p>
    </li>
    <li>
        <span><strong>Services Agreement</strong></span>
        <p>To the extent these Terms conflict with the Services Agreement you executed with Concorde, the terms of the Services Agreement govern, except that the terms of the Arbitration Agreement in Section 17 of these Terms prevail over the terms of the Services Agreement.</p>
    </li>
    <li>
        <span><strong>Your Account</strong></span>
        <p>You may need to register an account with Concorde in order to utilize the Services. You are responsible for maintaining the confidentiality of your account and login credentials and for restricting access to your computer.  If there has been unauthorized use of your login credentials or account, please notify us immediately. You are responsible for providing and maintaining current, complete, accurate and truthful information on your account. You will provide us with such proof of identity as we may reasonably request from time to time. You are solely and fully responsible for all activities that occur using your login credentials or account. Concorde has no control over the use of any user's account by the user or third parties and expressly disclaims any liability resulting from such use. We reserve the right to refuse service and/or terminate accounts without prior notice if these Terms are violated or if we decide, in our sole discretion, that it would be in our best interest to do so.</p>
    </li>
    <li>
        <span><strong>Emails</strong></span>
        <p>Concorde may send you confirmation and other transactional emails regarding the Services. Concorde may also send you emails about products or services that we think might interest you ("Marketing Emails").  You can unsubscribe from Marketing Emails at any time by clicking unsubscribe in Concorde’s email communications or contacting a Concorde Representative at 800-662-1676 (Toll Free) or by contacting <a href="mailto:PrivacyOfficer@Concorde2000.com">PrivacyOfficer@Concorde2000.com</a></p>
    </li>
    <li>
        <span><strong>Intellectual Property and Limited License</strong></span>
        <p>Concorde and/or its licensors are and shall continue to be the sole and exclusive owner of all right, title and interest in and to all intellectual property rights in the Site and Services. These rights include database rights, patents, copyrights, trademarks (whether registered or unregistered), trade dresses, trade secrets, design rights (whether registered or unregistered), know-how, mask works, moral rights, and all similar rights that may exist now or later in any jurisdiction, including without limitation any applications and registrations for the foregoing, and the Concorde name, logos, designs, domain names, graphics, icons, scripts, service marks, features, functions, text, graphics, button icons, scripts, service marks, images, software, data compilations and other distinctive brand features, and the compilation and organization thereof (collectively, “Intellectual Property Rights”). All such Intellectual Property Rights are protected by United States and international laws, including laws governing copyrights and trademarks.</p>
        <p>Concorde grants you a worldwide, limited, revocable, non-exclusive license to access and use the Site and Services as they are provided to you by Concorde for employment purposes only (the “License”).  The License is non-transferable and non-assignable, and you shall not grant to any third party any right, permission, license or sublicense with respect to any of the rights granted hereunder without Concorde’s prior written permission, which it may withhold in its sole discretion.  Except as permitted under the License, you may not use, copy, make derivative works of, distribute, publish, display, perform, sell, make, or access the Site or Services.</p>
        <p>Violation of any provision of this License may result in immediate termination of the License, in Concorde’s sole discretion.</p>
    </li>
    <li>
        <span><strong>Use Restrictions</strong></span>
        <p>You represent and warrant that you will not do, attempt to do, or cause any third party to do or attempt to do any of the following in connection with your use of the Site or Services:</p>
        <ul class="ul_close">
            <li>use the Site or Services for unauthorized purposes (e.g. to request an illegal screening or an unauthorized background check);</li>
            <li>use the Site or Services for the benefit of any third party without Concorde’s prior written permission;</li>
            <li>(i) copy, distribute, rent, lease, lend, sublicense, or transfer the Services, or make the Services available to any third party, including your affiliates, parents or subsidiaries, without Concorde’s express prior written consent; (ii) modify, decompile, reverse engineer, or disassemble the Site or Services or otherwise attempt to discover any underlying source code, ideas, algorithms, file formats or programming interfaces; (iii) mirror or archive any part of the Site or Services without Concorde’s written permission; (iv) modify, remove, or obscure any copyright, trademark, patent, or other notices or legends that appear on the Services; or (v) use the Services to develop a competitive product offering;</li>
            <li>use any meta tags, “hidden text,” agents, robots, scripts, spiders, crawlers or other tools or means, whether manual or automated, to collect, scrape, index, mine, republish, redistribute, transmit, sell, license, download, access or manage the Services, Site (except caching or as necessary to view the Site), or the personal information of others without Concorde’s prior written permission or authorization;</li>
            <li>access the Site or Services for improper, illegal, or unauthorized purposes, including, but not limited to, in violation of FCRA, Civil Rights Act, Equal Employment Opportunity Act, fair chance or ban-the-box laws, or drug screening laws;</li>
            <li>misrepresent yourself, your identity, or information about you;</li>
            <li>take any action that (i) may unreasonably encumber the Site or Services’ infrastructure; (ii) bypasses measures that are used to prevent or restrict access to the Site or Services; (iii) circumvents, disables, or otherwise interferes with security features of the Site or Services; (iv) distribute, transmit, upload, post, e-mail, share, distribute, reproduce, or otherwise make available any software viruses, malware, program, code, file, or other technology or material intended to interrupt, disrupt, alter, destroy, or limit any part of the Site or Services, or that may harm users; (v) upload files to the Site that contain viruses, Trojans, corrupted files, or any other similar software that may damage the operation of another's computer; or (vi) use the Site or Services in a way that violates any of the Intellectual Property Rights, or other rights of any third party, including privacy or publicity rights, or take any action that would jeopardize or impair Concorde’s rights as owner of the Intellectual Property Rights or the legality and/or enforceability of the Intellectual Property Rights, including challenging or opposing Concorde’s ownership in the Intellectual Property Rights;</li>
            <li>use the Site or Services for any reason not explicitly authorized by these Terms;</li>
            <li>frame or utilize framing techniques to enclose the Site or any portion thereof;</li>
            <li>intentionally violate any applicable local, state, national, or international law; and/or</li>
            <li>attempt to indirectly undertake any of the foregoing.</li>
        </ul>
        <p>Notice of Penalty under FCRA: FCRA PROVIDES THAT ANY PERSON WHO KNOWINGLY AND WILLFULLY OBTAINS INFORMATION ON A CONSUMER FROM A CONSUMER REPORTING AGENCY UNDER FALSE PRETENSES SHALL BE FINED UNDER TITLE 18 OF THE UNITED STATES CODE OR IMPRISONED NOT MORE THAN TWO YEARS, OR BOTH.</p>
    </li>
    <li>
        <span><strong>Feedback</strong></span>
        <p>By sending us any feedback, comments, questions, or suggestions concerning Concorde or our Services (collectively, “Feedback”), you represent and warrant (a) that you have the right to disclose the Feedback, (b) that the Feedback does not violate the rights of any other person or entity, and (c) that your Feedback does not contain the confidential or proprietary information of any third party or parties. By sending us any Feedback, you further (i) agree that we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (ii) acknowledge that we may have something similar to the Feedback already under consideration or in development, (iii) grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide license to use, make, modify, prepare derivative works, publish, distribute, and sublicense the Feedback through multiple levels, and (iv) irrevocably waive, and cause to be waived, against Concorde and its users any claims and assertions of any moral rights contained in such Feedback. This Feedback Section shall survive any termination of the Services.</p>
    </li>
    <li>
        <span><strong>Fees</strong></span>
        <p>In connection with your use of the Services, Concorde may charge certain fees (“Fees”) such as when you order a background report.  You agree to pay all applicable Fees based on the terms then in effect.  Once Services have been initiated, Fees are nonrefundable.   Payment processing services provided by our third-party payment processor are subject to its terms.  All inquiries regarding Services and delivery of reports, including timing or delays, should be made to Concorde at <a href="mailto:PrivacyOfficer@Concorde2000.com">PrivacyOfficer@Concorde2000.com</a>.</p>
    </li>
    <li>
        <span><strong>Accuracy of Information; No Legal Advice</strong></span>
        <p>We attempt to be accurate when describing our Services on the Site; however, we do not warrant that the content available on the Site is accurate, complete, reliable, current, or error-free.  This Site may contain typographical errors or inaccuracies and may not be complete or current. We therefore reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information at any time without prior notice. </p>
        <p>The Site and Services may offer information, guidance, forms, materials, and/or other content (including sample documents) for informational purposes, which are not intended to, and shall not constitute, legal or professional advice, either express or implied. You agree not to rely on Concorde for, nor shall Concorde render, legal or professional advice. You acknowledge and agree that you are solely responsible for your decisions and that you will consult with your own legal counsel (at your discretion) regarding all legal matters, including but not limited to your legal obligations with respect to your procurement and use of the Services and Screening Reports.</p>
    </li>
    <li>
        <span><strong>Indemnification</strong></span>
        <p>You agree to defend, indemnify, and hold Concorde and its subsidiaries, affiliates, partners, licensors, directors, officers, employees, and agents (the “Indemnified Parties”) harmless for any damages, losses, judgments, costs, monetary penalties, expenses, reasonable attorneys’ fees, claims, investigations, and actions arising out of or relating to: (a) your use of the Site or Services in violation of any law, rule, regulation; (b) your breach of any terms, conditions, covenants, representations, or warranties of these Terms; (c) your failure as User to comply with any law applicable to a User of Screening Reports; (d) the inaccuracy of any information you provide to Concorde; or (e) your willful or malicious conduct relating to any violation described in this Section.  Concorde reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to your indemnification. You will not, in any event, settle any such claim or matter without the prior written consent of Concorde.</p>
    </li>
    <li>
        <span><strong>Warranties and Disclaimers</strong></span>
        <p>Each party represents and warrants to the other party that: (i) it has the full power and authority to enter into the terms and conditions of these Terms; and (ii) the Terms constitute a legal, valid and binding obligation.</p>
        <p>YOU ACKNOWLEDGE THAT THE SITE AND THE SERVICES ARE PRESENTED ON AN “AS IS” AND “AS AVAILABLE” BASIS.  CONCORDE DISCLAIMS REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR PARTICULAR PURPOSE, OR IMPLIED WARRANTIES ARISING FROM THE COURSE OF DEALING OR A COURSE OF PERFORMANCE IN CONNECTION WITH THESE SITE TERMS OR THE SITE OR ITS CONTENT OR SERVICES, INCLUDING BUT NOT LIMITED TO THE ACCURACY, VALIDITY, OR COMPLETENESS OF ANY INFORMATION PROVIDED THROUGH THE SITE OR SERVICES, THAT THE SITE OR SERVICES WILL MEET YOUR NEEDS, OR WILL BE PROVIDED ON AN UNINTERRUPTED BASIS. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE AND SERVICES IS AT YOUR SOLE RISK.</p>
    </li>
    <li>
        <span><strong>Limitation of Liability</strong></span>
        <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, CONCORDE AND ITS SUBSIDIARIES, AFFILIATES, PARTNERS, LICENSORS, DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS WILL NOT BE RESPONSIBLE OR LIABLE IN CONTRACT, WARRANTY, OR TORT (INCLUDING NEGLIGENCE) FOR ANY (a) INTERRUPTION OF THE SITE OR SERVICES; (b) ACCESS DELAYS OR ACCESS INTERRUPTIONS TO THE SITE; (c) DATA NON-DELIVERY, MISDELIVERY, CORRUPTION, DESTRUCTION, OR OTHER MODIFICATION; (d) LOSS OR DAMAGES OF ANY SORT INCURRED AS A RESULT OF DEALINGS WITH OR THE PRESENCE OF OFF-WEBSITE LINKS ON THE SITE; (e) COMPUTER VIRUSES, SYSTEM FAILURES, OR MALFUNCTIONS WHICH MAY OCCUR IN CONNECTION WITH YOUR USE OF THE SITE OR SERVICES; (f) ANY INACCURACIES, ERRORS, OR OMISSIONS IN INFORMATION PROVIDED THROUGH THE SITE OR SERVICES, OR (g) EVENTS BEYOND OUR REASONABLE CONTROL.</p>
        <p>FURTHER, CONCORDE WILL NOT BE LIABLE IN CONTRACT, WARRANTY, OR TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY FOR ANY INDIRECT, PUNITIVE, SPECIAL, RELIANCE, INCIDENTAL, CONSEQUENTIAL, OR SIMILAR DAMAGES OF ANY KIND (INCLUDING LOSS OF REVENUE OR PROFITS) ARISING OUT OF OR RELATING TO THESE TERMS, THE SITE OR YOUR USE THEREOF, INCLUDING THE USE OR INABILITY TO USE THE SITE OR SERVICES, OR FOR ANY INFORMATION OBTAINED FROM OR THROUGH THE SITE OR SERVICES, EVEN IF CONCORDE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF CONCORDE EXCEED THE AMOUNT PAID TO CONCORDE UNDER THESE TERMS DURING THE TWELVE MONTH PERIOD IMMEDIATELY PRECEDING THE INITIATION OF ANY CLAIM FOR DAMAGES.</p>
        <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you. If any portion of this Section is held to be invalid, the invalidity of such portion shall not affect the validity of the remaining portions of the applicable Sections.</p>
    </li>
    <li>
        <span><strong>Arbitration and Class Action Waiver (“Arbitration Agreement”)</strong></span>
        <p>PLEASE READ THIS SECTION CAREFULLY — IT AFFECTS YOUR LEGAL RIGHTS AND GOVERNS HOW YOU AND CONCORDE CAN BRING CLAIMS AGAINST EACH OTHER.  THIS SECTION WILL, WITH LIMITED EXCEPTION, REQUIRE YOU AND CONCORDE TO SUBMIT CLAIMS AGAINST EACH OTHER TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS, GROUP OR REPRESENTATIVE ACTION IN COURT.</p>
        <ol>
            <li>
                <span><strong>Agreement to Arbitrate.</strong></span>
                <p>In exchange for the mutual promises to arbitrate claims, you and Concorde mutually agree to give up our rights to resolve disagreements in a court of law by a judge or jury, and, as described below, agree to binding and final arbitration pursuant to the Federal Arbitration Act, 9 U.S.C. § 1, et seq. </p>
                <p>You and Concorde agree that this Arbitration Agreement is governed by the Federal Arbitration Act, and shall survive even after these Terms or any Services terminate.</p>
            </li>
            <li>
                <span><strong>Claims Covered by Arbitration Agreement.</strong></span>
                <p>This Arbitration Agreement is intended to be as broad as legally permissible, and, other than the exceptions below, you and Concorde agree that any disagreement, claim, or controversy, past, present, or future, based on any legal theory whatsoever, including negligence, breach of contract, tort, fraud, misrepresentation, the common law, or any statute, regulation or ordinance, and including without limitation, arising out of or relating in any way to these Terms (including its enforcement, breach, performance, interpretation, validity, or termination), or Your access to and/or use of the Services, or the provision of content, services, and/or technology on or through the Services (hereinafter, “Claims”), shall be resolved by final and binding arbitration. </p>
                <p><u>Claims not Covered by Arbitration Agreement and Limitations to how this Arbitration Agreement Applies.</u> Claims that controlling federal statutes state cannot be arbitrated or subject to a pre-dispute arbitration agreement are not covered by this Arbitration Agreement.</p>
                <p>Either party has the right to file an individual action in small claims court demanding $10,000 or less that is within the jurisdiction of the small claims court.  The defendant or counterclaim defendant in such a small claims court action may not elect to have the claim resolved by binding arbitration. If your jurisdiction permits small claims court judgments to be appealed to a court of general jurisdiction for a trial de novo, we agree that any such appeal shall be resolved in arbitration in accordance with this Arbitration Agreement instead of in that court of general jurisdiction.  This Arbitration Agreement does not cover criminal proceedings initiated by you or Concorde, and initiating or participating in criminal proceedings shall not be a waiver of any right to arbitrate under this Arbitration Agreement.</p>
                <p>Additionally, either party may apply to a court of competent jurisdiction for temporary or preliminary injunctive relief in connection with an arbitrable controversy, including without limitation, any dispute arising out of or related to, actual or threatened infringement, misappropriation, or violation of a party’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights. The court to which the application is made is authorized to consider the merits of the arbitrable controversy to the extent it deems necessary in making its ruling, but only to the extent permitted by applicable law. All determinations of final relief, however, will be decided in arbitration.</p>
            </li>
            <li>
                <span><strong>Delegation to Arbitrator.</strong></span>
                <p>Except with respect to the Class Action Waiver below, the arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to the validity, applicability, enforceability, unconscionability, or waiver of this Arbitration Agreement, including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable.</p>
            </li>
            <li>
                <span><strong>Class Action Waiver.</strong></span>
                <p>You and Concorde agree to bring and resolve any Claims only on an individual basis.  YOU AND CONCORDE WAIVE ANY RIGHT FOR ANY DISPUTE TO BE BROUGHT, HEARD, DECIDED OR ARBITRATED AS A CLASS, REPRESENTATIVE,  AND/OR COLLECTIVE ACTION AND THE ARBITRATOR WILL HAVE NO AUTHORITY TO HEAR OR PRESIDE OVER ANY SUCH CLAIM(hereinafter, “Class Action Waiver”).  Further, the arbitrator may not consolidate more than one party’s claims and may not preside over any class, consolidated, or representative proceeding, unless You and Concorde agree otherwise in writing.</p>
                <p>If there is a final determination that the Class Action Waiver is unenforceable as to certain claims brought on a class, collective, and/or representative basis, then those claims shall be severed from any remaining claims and may proceed in court, but the Class Action Waiver shall be enforced in arbitration on an individual basis as to any remaining claims to the fullest extent possible. The arbitrator is nevertheless without authority to preside over a class, collective and/or representative action.</p>
                <p>Notwithstanding any other provision of this Arbitration Agreement or the applicable American Arbitration Association Rules (referenced below), disagreements about the scope, applicability, enforceability, revocability or validity of this Class Action Waiver may be resolved only by a civil court of competent jurisdiction and not by an arbitrator.</p>
            </li>
            <li>
                <span><strong>Procedures and Costs.</strong></span>
                <p>You and Concorde agree that the arbitration shall be administered by the American Arbitration Association (“AAA”) before a single arbitrator. The arbitrator will apply the terms of this Arbitration Agreement and the applicable AAA rules, which are available at www.adr.org or by calling 1–800–778–7879. However, if there is any conflict between this Arbitration Agreement and the applicable AAA Rules, the parties agree this Arbitration Agreement shall govern.</p>
                <ul>
                    <li>
                        <p>If you are an individual person, the arbitrator shall apply the AAA Consumer Arbitration Rules.  If you are not an individual person, but are an entity or company, the arbitrator shall apply the AAA Commercial Arbitration Rules.</p>
                        <p>If you are an individual person and bring a claim solely for monetary relief of $10,000 or less:   Concorde will agree to pay for any filing, administrative, or hearing fees charged by the AAA.</p>
                    </li>
                    <li>
                        <p>If you are an individual person and bring a claim for monetary relief exceeding $10,000:  The AAA Consumer Arbitration Rules will govern payment of administrative or hearing fees charged by the AAA, including limiting your filing fee to $200.  In addition, fee waivers or other forms of cost relief at the arbitrator’s discretion may be available.</p>
                        <p>If you are not an individual person:  The AAA Commercial Arbitration Rules will govern payment of administrative or hearing fees charged by the AAA.</p>
                    </li>
                </ul>
                <p>If the arbitrator finds that either party’s claim is frivolous or brought for an improper purpose, the arbitrator may award sanctions as allowed by applicable law.</p>
                <span><u>Selection of Arbitrator and Procedures.</u></span>
                <p>For claims seeking relief valued at $75,000.00 or less (in both your and Concorde’s assessment), excluding attorney’s fees and costs, the AAA shall appoint the Arbitrator in accordance with its rules and procedures.  For all claims seeking relief above $75,000.00 in value (in either your or Concorde’s assessment), excluding attorney’s fees and costs, unless prohibited by the AAA (in which case the AAA’s rules and procedures for arbitrator selection shall apply), the arbitrator shall be selected as follows: The AAA shall give each party a list of five (5) arbitrators drawn from its roster of arbitrators.  Each party shall have ten (10) calendar days from the receipt of the list to strike all names on the list it deems unacceptable.  If only one common name remains on the lists of all parties, that individual shall be designated as the arbitrator.  If more than one common name remains on the lists of both parties, the parties shall strike names alternately from the list of common names until only one remains.  The party who did not initiate arbitration shall strike first.  If no common name remains on the lists of all parties, the AAA shall furnish an additional list of five (5) arbitrators from which the parties shall strike alternately, with the party who initiated arbitration striking first, until only one name remains.  That person shall be designated as the arbitrator.</p>
                <p>The arbitrator shall have the power to decide any motions, including dispositive or summary judgment motions, brought by any party to the arbitration.  The arbitrator may grant any remedy, relief, or outcome that the parties could have received in court to resolve the party’s individual claim, including awards of attorney’s fees and costs, in accordance with the law or laws that apply to the Claim.   The arbitrator shall provide in writing to the parties the basis for any award or decision. Judgment upon any award rendered in such arbitration will be binding and may be entered in any court with proper jurisdiction.</p>
            </li>
            <li>
                <span><strong>Severability.</strong></span>
                <p>Except as stated in the Class Action Waiver above, if any clause within this arbitration agreement is found to be illegal or unenforceable, that specific clause will be severed from this arbitration agreement, and the remainder of the arbitration agreement will be given full force and effect.</p>
            </li>
            <li>
                <span><strong>Opt-out.</strong></span>
                <p>If you are an individual person, you have the right to opt-out and not be bound by this Arbitration Agreement by sending written notice to Concorde—clearly indicating your intent to opt out of this arbitration agreement and including the name, phone number, and email address associated with your account—via email PrivacyOfficer@Concorde2000.com or U.S. Mail, 701 Market St., Suite 3400 Philadelphia, PA 19106. In order to be effective, your opt-out notice must be sent within 30 days of your agreement to these Terms.</p>
                <p>If you do not opt-out of this Arbitration Agreement within the 30-day period, you and Concorde shall be bound by the terms of this Arbitration Agreement in full.  If you opt-out of this arbitration agreement within the 30-day period, it will not affect any other, previous, or future Arbitration Agreements that you may have with Concorde.</p>
            </li>
            <li>
                <span><strong>Pre-Arbitration Notification and Negotiation Process.</strong></span>
                <p>Prior to initiating an arbitration, you and Concorde each agree to first attempt to negotiate an informal resolution of any Claims.   This pre-arbitration negotiation shall be initiated by providing written notice to the other party—including a brief written statement describing the name, address, and contact information of the notifying party, the facts giving rise to the Claim, and the relief requested.   You must send such written notice to Concorde via U.S. Mail to Attn: Concorde Inc. - Legal Department, 701 Market Street, Suite 3400 Philadelphia, PA 19106; Concorde will send such written notice to the email address you have provided to Concorde.</p>
                <p>During this pre-arbitration negotiation, all offers, promises, conduct and statements, whether oral or written, made in the course of the negotiation by any of the parties, their agents, employees, and attorneys are confidential, privileged and inadmissible for any purpose, including as evidence of liability, in arbitration or other proceeding involving the parties.</p>
                <p>After a good faith effort to negotiate, if you or Concorde believe a Claim cannot be resolved informally, the party intending to pursue arbitration agrees to notify the other party via email prior to initiating the arbitration.  In order to initiate arbitration, a claim must be filed with the AAA and the written Demand for Arbitration (available at www.adr.org) must be provided to the other party, as specified in the applicable AAA Rules.</p>
            </li>
        </ol>
    </li>
    <li>
        <span><strong>Third-Party Links</strong></span>
        <p>The Services may contain links to third-party websites and other resources that are not owned or controlled by Concorde.   These links do not imply any endorsement by Concorde or the assumption of any responsibility for any such third-party sites, information, materials, products, or services.  If you access a third-party website or resource from the Services, you do so at your own risk, and you understand that these Terms and Concorde’s Privacy Policy do not apply to your use of such links.  Any contract entered with, and any damages caused by, a third party will be between you and that third party.</p>
        <p>You expressly relieve Concorde from any and all liability arising from your use of any third-party website, service, or content. You acknowledge and agree that Concorde is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources.   You should carefully review the privacy policies and terms and conditions of the third party websites you visit.</p>
    </li>
    <li>
        <span><strong>Term and Termination; Survival.</strong></span>
        <ol>
            <li>
                <span><strong>Term.</strong></span>
                <p>These Terms shall continue in full force and effect until such time as they are terminated by you or by us.</p>
            </li>
            <li>
                <span><strong>Termination by Concorde.</strong></span>
                <p>Concorde may terminate these Terms or terminate or suspend your right to use the Site or Services at any time for any or no reason by providing you with notice of such termination to the Means of Communication you have provided us.  Termination will be effective immediately upon such notice. Concorde will refund in full any payments for Services that have not been delivered.</p>
            </li>
            <li>
                <span><strong>Termination by You.</strong></span>
                <p>You may terminate these Terms by completely and permanently ceasing to use the Site and Services, provided that you do not owe Fees for any Services. If you attempt to terminate these Terms while there are Services outstanding, your termination of the Agreement will automatically cancel those Services.</p>
            </li>
            <li>
                <span><strong>Survival.</strong></span>
                <p>All provisions that should by their nature survive the expiration or termination of these Terms, including without limitation, Sections II, IV, X, XII, XV-XX, shall so survive.</p>
            </li>
        </ol>
    </li>
    <li>
        <span><strong>General</strong></span>
        <ol>
            <li>
                <span><strong>Governing Law; Jurisdiction</strong></span>
                <p>Except for the Arbitration Agreement, which is governed by the Federal Arbitration Act, these Terms are governed by the laws of the Commonwealth of Pennsylvania without regard to choice of law rules or principles.   For all disputes not subject to arbitration, you agree to submit to the personal and exclusive jurisdiction and venue of the courts located within Philadelphia, Pennsylvania.</p>
            </li>
            <li>
                <span><strong>Assignment</strong></span>
                <p>You may not assign any of your rights or obligations under these Terms without the prior written consent of Concorde. Subject to the preceding sentence, these Terms inure to the benefit of and are binding on the parties’ permitted assignees, transferees and successors. Any attempted assignment in violation of this clause is void.</p>
            </li>
            <li>
                <span><strong>Integration</strong></span>
                <p>You acknowledge and agree that these Terms constitute the parties’ complete and exclusive agreement concerning the use of the Site and Services, and, subject to Section 6 (Services Agreement), supersede and govern any and all prior or contemporaneous proposals, agreements, or other communications relating to the Site and Services.</p>
            </li>
            <li>
                <span><strong>Force Majeure</strong></span>
                <p>Concorde will not be responsible for any failure or delay in its performance under these Terms due to causes beyond its reasonable control, including, but not limited to, labor disputes, strikes, lockouts, internet or telecommunications failures, shortages of or inability to obtain labor, energy, or supplies, war, terrorism, riot, acts of God or governmental action, acts by hackers or other malicious third parties and problems with the Internet generally, and such performance shall be excused to the extent that it is prevented or delayed by reason of any of the foregoing.</p>
            </li>
            <li>
                <span><strong>Miscellaneous</strong></span>
                <p>The parties are independent contractors.   Nothing contained in these Terms shall be construed as creating any employment, agency, partnership, franchise, joint venture, or other form of joint enterprise or authority to bind the other party. There are no third-party beneficiaries to these Terms. If any provision is found unenforceable, it and any related provisions will be interpreted to best accomplish the unenforceable provisions essential purpose. Any waiver of a provision of these Terms will only be valid if provided in writing and applies only to the specific occurrence so waived. Failure to enforce any provision will not constitute a waiver. Nothing in these Terms will limit a party’s ability to seek equitable relief. Section headings are not to be used in the interpretation hereof.</p>
            </li>
            <li>
                <span><strong>Notices and Consent to Receive Notices Electronically</strong></span>
                <p>You consent to receive any agreements, notices, disclosures, and other communications (collectively, “Notices”) to which these Terms refer from us electronically including without limitation by e-mail or by posting notices on this Site.  You agree that all Notices that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
            </li>
        </ol>
    </li>
</ol>
<p>Unless otherwise specified in these Terms, all Notices under these Terms will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or email; or the day after it is sent, if sent for next day delivery by recognized overnight delivery service.</p>
<p>Written notice may be sent via first-class mail to Attn: Concorde Inc. - Legal Dept. 701 Market Street, Suite 3400 Philadelphia, PA 19106. </p>
<p class="info_version">4823-2462-1985.6 097391.1004</p>
</div>`

class TermsOfUse extends Component {
  render () {
    return (
      <ModalBackdropForm sm={12} md={11} lg={10} displayTermsOfUseLink={false} displayPrivacyPolicyLink={false}>
        <Col xs={12} className='text-center mb-3'>
          <h3>Terms of Use</h3>
        </Col>
        <Col xs={12}><div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(terms) }} />
        </Col>
      </ModalBackdropForm>
    )
  }
}

export default TermsOfUse
