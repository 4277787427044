const languages = {
  getAll: () => {
    return [...languageOptions]
  }
}

export { languages }

const languageOptions = [
  { display: 'English', value: 'en' },
  { display: 'French', value: 'fr-ca' },
  { display: 'Spanish', value: 'es' }
]