import { push } from 'connected-react-router'

const navigation = {
  navigateToDriverConsentValidate: ({ uuid }) => {
    return (dispatch) => {
      const route = `/driver-consent/${uuid}`
      dispatch(push(route))
    }
  },

  navigateToDriverConsentReview: ({ uuid }) => {
    return (dispatch) => {
      const route = `/driver-consent/${uuid}/review`
      dispatch(push(route))
    }
  },

  navigateToDriverConsentPrint: ({ uuid }) => {
    return (dispatch) => {
      const route = `/driver-consent/${uuid}/print`
      dispatch(push(route))
    }
  },

  navigateToContact: () => {
    return (dispatch) => {
      const route = '/contact'
      dispatch(push(route))
    }
  },

  navigateToLogin: () => {
    return (dispatch) => {
      const route = '/login'
      dispatch(push(route))
    }
  },

  navigateToForgotPassword: () => {
    return (dispatch) => {
      const route = '/forgot-password'
      dispatch(push(route))
    }
  },

  navigateToPasswordReset: (passwordResetToken) => {
    return (dispatch) => {
      const route = `/password-reset/${passwordResetToken}`
      dispatch(push(route))
    }
  },

  navigateToCompanyAdminHome: () => {
    return (dispatch) => {
      const route = '/company-admin/home'
      dispatch(push(route))
    }
  },

  navigateToCompanyAdminDriversTable: () => {
    return (dispatch) => {
      const route = '/company-admin/driver-roster'
      dispatch(push(route))
    }
  },

  navigateToCompanyAdminConsentContent: () => {
    return (dispatch) => {
      const route = '/company-admin/consent-content'
      dispatch(push(route))
    }
  },

  navigateToConcordeAdminHome: () => {
    return (dispatch) => {
      const route = '/concorde-admin/home'
      dispatch(push(route))
    }
  },

  navigateToOwnerOperatorCreateAccountUserInfo: () => {
    return (dispatch) => {
      const route = '/owner-operator/create-account/user-info'
      dispatch(push(route))
    }
  },

  navigateToOwnerOperatorCreateAccountServiceQuestions: () => {
    return (dispatch) => {
      const route = '/owner-operator/create-account/service-questions'
      dispatch(push(route))
    }
  },

  navigateToOwnerOperatorCreateAccountSignConsent: () => {
    return (dispatch) => {
      const route = '/owner-operator/create-account/sign-consent'
      dispatch(push(route))
    }
  },

  navigateToOwnerOperatorCreateAccountPayment: () => {
    return (dispatch) => {
      const route = '/owner-operator/create-account/payment'
      dispatch(push(route))
    }
  },

  navigateToOwnerOperatorHome: () => {
    return (dispatch) => {
      const route = '/owner-operator/account'
      dispatch(push(route))
    }
  },

  navigateToCompanyAdminDOTRoster: () => {
    return (dispatch) => {
      const route = '/company-admin/dot-roster'
      dispatch(push(route))
    }
  },

  navigateToConcordeAdminDOTRoster: () => {
    return (dispatch) => {
      const route = '/concorde-admin/dot-roster'
      dispatch(push(route))
    }
  },
  navigateToConcordeAdminCompanies: () => {
    return (dispatch) => {
      const route = '/concorde-admin/companies'
      dispatch(push(route))
    }
  },
  navigateToConcordeAdminDriversTable: () => {
    return (dispatch) => {
      const route = '/concorde-admin/driver-roster'
      dispatch(push(route))
    }
  },
  navigateToConcordeAdminTasksErrors:() => {
    return (dispatch) => {
      const route = '/concorde-admin/tasks-errors'
      dispatch(push(route))
    }
  }
}

export { navigation }
