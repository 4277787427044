import { actionTypes } from './actionTypes'
import { requestCreateUser } from '../services/apiService'
import * as companiesActions from './companies'

const user = {
  setUser: (value) => ({
    type: actionTypes.USER.SET_USER,
    value
  }),

  setError: (value) => ({
    type: actionTypes.USER.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.USER.SET_LOADING,
    value
  }),

  createUser: (companyId, params) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(user.setLoading(true))
      dispatch(user.setError())
      const data = await requestCreateUser(token, companyId, params)
      if (data && data.user) {
        dispatch(user.setUser(data.user))
        if (data.company) {
          dispatch(companiesActions.companies.updateWithCompany(data.company))
        }
      } else if (data && data.error) {
        dispatch(user.setError(data.error))
      }
      dispatch(user.setLoading(false))
    }
  }
}

export { user }
