import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import { isEqual, cloneDeep } from 'lodash'
import { hasSelectedText, displayNumber } from '../../../utils'

import ReactTable from 'react-table'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEllipsisH, faArrowLeft, faArrowRight, faSync, faExclamation, faUserPlus, faFileUpload, faFileExport, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import CreateConsent from './createConsent'
import CreateConsents from './createConsents'
import SendNotification from './sendNotification'
import SendNotifications from './sendNotifications'
import CreateQuery from './createQuery'
import CreateQueries from './createQueries'
import PerformQuery from '../../concordeAdmin/performQuery'
import UpdateQueryStatuses from '../../concordeAdmin/updateQueryStatus'
import PerformConsent from './performConsent'
import ViewDriver from './viewDriver'
import EditDriverCompanyAdmin from '../../companyAdmin/editDriver'
import EditDriverConcordeAdmin from '../../concordeAdmin/editDriver'
import ViewDocuments from './viewDocuments'
import CreateDriverCompanyAdmin from '../../companyAdmin/createDriver'
import CreateDriverConcordeAdmin from '../../concordeAdmin/createDriver'
import UploadRosters from './uploadRosters'
import GeneratePDFExcel from './generatePDFExcel'
import UploadDriverDocument from './uploadDriverDocument'
import DriversTableFilters from './driversTableFilters'
import ExportDriverRoster from './exportDriverRoster'
import FilterBadges from './filterBadges'
import {
  CheckboxCell,
  BooleanCell,
  DateCell,
  DotCell,
  CompanyCell,
  QueryTypeCell,
  QueryStatusCell,
  StatusDateCell,
  ExpirationCell,
  ActionsCell
} from './driversTableCustomCells'
import addConsentIcon from '../../../images/addConsent.svg'
import addLimitedQueryIcon from '../../../images/addLimitedQuery.svg'
import addFullQueryIcon from '../../../images/addFullQuery.svg'
import styles from './driversTable.module.scss'
import * as selectors from '../../../selectors'
import * as transformations from '../../../transformations'
import * as actions from '../../../actions'

class DriversTable extends Component {
  state = {
    searchString: '',
    sorted: [],
    showFilters: false,
    showCreateConsent: false,
    showSendNotification: false,
    showCreateQuery: false,
    showPerformQuery: false,
    showUpdateQueryStatus: false,
    showPerformConsent: false,
    showViewDriver: false,
    showViewDocuments: false,
    showEditDriver: false,
    showCreateDriver: false,
    showUploadRosters: false,
    showGeneratePDFExcel: false,
    showExportDriverRoster: false,
    showCreateConsents: false,
    showSendNotifications: false,
    showCreateQueries: false,
    showUploadDriverDocument: false
  }

  constructor () {
    super()
    this.handleDriverAction = this._handleDriverAction.bind(this)
    this.handleAllDriversCheck = this._handleAllDriversCheck.bind(this)
    this.handleDriverCheck = this._handleDriverCheck.bind(this)
  }

  componentDidMount () {
    this.props.reloadDrivers()
    this.props.loadDotNumbers()
    if (selectors.auth.isConcordeAdmin(this.props)) {
      this.props.loadCompanies()
    }
  }

  _handleSearchChange (searchString = '') {
    this.setState({ searchString })
    this.props.updateSearch(searchString)
  }

  _handleTableOnFetchData ({ sorted = [] }) {
    if (!isEqual(this.state.sorted, sorted)) {
      //if (!_.isEqual(this.state.sorted, sorted)) {
      this.setState({ sorted })
      this.props.updateSort(sorted)
    }
  }

  _handlePageChange (newPage = '') {
    const { drivers: { pages: { pages } } } = this.props
    if (isNaN(Number(newPage))) { return }
    let page = Number(newPage)
    if (page < 1) { page = 1 }
    if (page > pages) { page = pages }
    this.props.updatePage(page)
  }

  _handleDriverAction (type, driverId) {
    const driver = this.props.drivers.drivers.find((d) => d._id === driverId)
    this.props.setDriver(driver)
    if (!driver) { return }

    switch (type) {
    case 'createConsentRequest':
      this.setState({ showCreateConsent: true })
      return
    case 'sendNotification':
      this.setState({ showSendNotification: true })
      return
    case 'createLimitedQueryRequest':
      this.setState({ showCreateQuery: true, queryType: 'limited' })
      return
    case 'createFullQueryRequest':
      this.setState({ showCreateQuery: true, queryType: 'full' })
      return
    case 'performConsent':
      this.setState({ showPerformConsent: true })
      return
    case 'performQuery':
      this.setState({ showPerformQuery: true })
      return
    case 'view':
      this.setState({ showViewDriver: true })
      return
    case 'edit':
      this.setState({ showEditDriver: true })
      return
    case 'uploadDriverDocument':
      this.setState({ showUploadDriverDocument: true })
      return
    case 'viewDocuments':
      this.setState({ showViewDocuments: true })
      return
    default: return
    }
  }

  _handleDriverCheckToggle (driverId) {
    const selectedDrivers = transformations.driversFilters
      .updateSelectedDriversWithToggledDriver(this.props.driversFilters.selectedDrivers, driverId)
    this.props.setSelectedDrivers(selectedDrivers)
  }

  _handleDriverCheck (checked, driverId) {
    const selectedDrivers = transformations.driversFilters
      .updateSelectedDriversWithDriver(this.props.driversFilters.selectedDrivers, driverId, checked)
    this.props.setSelectedDrivers(selectedDrivers)
  }


  _handleAllDriversCheck (checked) {
    const selectedDrivers = transformations.driversFilters
      .replaceAllSelectedDrivers(checked ? this.props.drivers.drivers : null)
    this.props.setSelectedDrivers(selectedDrivers)
  }


  _renderTopControls () {
    const { drivers: { loading } } = this.props

    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
    const areFiltersEmpty = selectors.driversFilters.areFiltersEmpty(this.props)
    const areSomeDriversSelected = selectors.driversFilters.areSomeDriversSelected(this.props)
    const labelStr = areSomeDriversSelected ? 'currently selected' : (areFiltersEmpty ? 'all' : 'currently filtered')

    return (
      <Row className='flex-shrink-0 mx-3 mt-2 mb-1' noGutters>
        <Col xs={4} md={3} lg={2}>
          <Form.Group>
            <Form.Label>Search</Form.Label>
            <InputGroup size='sm'>
              <Form.Control
                type='text'
                value={this.state.searchString}
                onChange={(e) => this._handleSearchChange(e.target.value)}
              ></Form.Control>
              <InputGroup.Append>
                <Button
                  variant='outline-secondary'
                  title='Clear'
                  disabled={!this.state.searchString}
                  onClick={() => this._handleSearchChange()}
                ><FontAwesomeIcon icon={faTimes} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={{ span: 4, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 8, offset: 1 }}>
          <Form.Group>
            <Form.Label>Filters</Form.Label>
            <InputGroup className='flex-nowrap align-items-center'>
              <Button
                variant='outline-primary'
                size='sm'
                className='mr-2 align-self-start'
                onClick={() => this.setState({ showFilters: true })}
              >Edit</Button>
              <Button
                variant='outline-secondary'
                size='sm'
                className='mr-2 align-self-start'
                onClick={() => this.props.clearFilters()}
              >Clear</Button>
              <div className='d-flex flex-row align-items-center flex-wrap'>
                <FilterBadges />
              </div>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={3} md={2} lg={1}>
          <Form.Group className='d-flex flex-column align-items-end'>
            <Form.Label>Actions</Form.Label>
            <InputGroup className='d-flex justify-content-end'>
              <Button
                variant='outline-primary'
                size='sm'
                className='mr-2'
                disabled={loading}
                title='Reload data'
                onClick={() => this.props.reloadDrivers()}
              ><FontAwesomeIcon icon={faSync} /></Button>
              <Dropdown>
                <Dropdown.Toggle
                  variant='outline-primary'
                  size='sm'
                  title='More actions'
                  className='hide-after-content'
                ><FontAwesomeIcon icon={faEllipsisH} />
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight>
                  <Dropdown.Item
                    as='button'
                    key='1'
                    className='btn btn-secondary text-secondary'
                    onClick={() => this.setState({ showCreateDriver: true })}
                  ><FontAwesomeIcon icon={faUserPlus} className={`mr-2 ${styles.menuItemIcon}`} />Create a new driver
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    key='2'
                    className='btn btn-secondary text-secondary'
                    onClick={() => this.setState({ showUploadRosters: true })}
                  ><FontAwesomeIcon icon={faFileUpload} className={`mr-2 ${styles.menuItemIcon}`} />Upload driver roster
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    key='7'
                    className='btn btn-secondary text-secondary'
                    onClick={() => this.setState({ showExportDriverRoster: true })}
                  ><FontAwesomeIcon icon={faFileExport} className={`mr-2 ${styles.menuItemIcon}`} />{!areSomeDriversSelected ? 'Export driver roster' : 'Export selected drivers'}
                  </Dropdown.Item>
                  {isConcordeAdmin ? '' :
                    <React.Fragment>
                      <Dropdown.Item
                        as='button'
                        key='3'
                        className='btn btn-secondary text-secondary'
                        onClick={() => this.setState({ showCreateConsents: true })}
                      ><Image className={`mr-2 ${styles.menuItemIcon}`} src={addConsentIcon}></Image>{`Create new consent requests for ${labelStr} drivers`}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as='button'
                        key='4'
                        className='btn btn-secondary text-secondary'
                        onClick={() => this.setState({ showCreateQueries: true, queryType: 'limited' })}
                      ><Image className={`mr-2 ${styles.menuItemIcon}`} src={addLimitedQueryIcon}></Image>{`Create new limited query requests for ${labelStr} drivers`}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as='button'
                        key='5'
                        className='btn btn-secondary text-secondary'
                        onClick={() => this.setState({ showCreateQueries: true, queryType: 'full' })}
                      ><Image className={`mr-2 ${styles.menuItemIcon}`} src={addFullQueryIcon}></Image>{`Create new full query requests for ${labelStr} drivers`}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as='button'
                        key='6'
                        className='btn btn-secondary text-secondary'
                        onClick={() => this.setState({ showSendNotifications: true })}
                      ><FontAwesomeIcon icon={faExclamation} className={`mr-2 ${styles.menuItemIcon}`} />{`Send reminder notifications to ${labelStr} drivers`}
                      </Dropdown.Item>
                    </React.Fragment>
                  }
                  {
                    (!isConcordeAdmin || !selectors.driversFilters.areSomeDriversSelected(this.props)) ? '' :
                      <React.Fragment>
                        <Dropdown.Item
                          as='button'
                          key='2'
                          className='btn btn-secondary text-secondary'
                          onClick={() => this.setState({ showUpdateQueryStatus: true })}
                        ><Image className={`mr-2 ${styles.menuItemIcon}`}></Image>
                          {'Change query status for selected drivers'}
                        </Dropdown.Item>
                      </React.Fragment>
                  }
                  {!isConcordeAdmin ? '' :
                    <React.Fragment>
                      <Dropdown.Item
                        as='button'
                        key='7'
                        className='btn btn-secondary text-secondary'
                        onClick={() => this.setState({ showGeneratePDFExcel: true })}
                      ><FontAwesomeIcon icon={faFilePdf} className={`mr-2 ${styles.menuItemIcon}`} />{'Generate PDFs from FMCSA spreadsheet'}
                      </Dropdown.Item>
                    </React.Fragment>
                  }
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    )
  }

  _renderTableError () {
    const { drivers: { error } } = this.props
    return (
      <div className='d-flex justify-content-around align-items-center'>
        {`There was a problem loading the data: ${error}`}
      </div>
    )
  }

  _renderTableLoading () {
    return (
      <div className='d-flex justify-content-around align-items-center'>
        <div>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Loading drivers
        </div>
      </div>
    )
  }

  _renderTable () {
    const {
      companies: { companies },
      drivers: { drivers, pages: { pageSize }, loading, error },
      dotNumbers: { dotNumbers },
      driversFilters: { selectedDrivers }
    } = this.props

    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
    const queryParams = new URLSearchParams(window.location.search)
    let filtered = cloneDeep(drivers)

    if (error) { return this._renderTableError() }
    if (!drivers || !dotNumbers || (isConcordeAdmin && !companies)) { return this._renderTableLoading() }

    if (isConcordeAdmin && !this.companyMap) {
      this.companyMap = selectors.companies.getCompanyMap(this.props)
    }
    if (!this.dotMap) {
      this.dotMap = selectors.dotNumbers.getDotNumberMap(this.props)
    }

    if (queryParams) {

      if (queryParams.has('dotNumber')) {
        let urlDots = queryParams.get('dotNumber').split(',')
        filtered = filtered.filter((x) => urlDots.includes(x.dotNumber))
      }

      if (queryParams.has('queryType')) {
        let urlTypes = queryParams.get('queryType').split(',')
        filtered = filtered.filter((x) => urlTypes.includes(x.queryStatus.queryType))
      }
      if (queryParams.has('queryStatus')) {
        let urlStatus = queryParams.get('queryStatus').split(',')
        filtered = filtered.filter((x) => urlStatus.includes(x.queryStatus.status))
      }

    }

    return (
      <ReactTable
        className='flex-grow mx-3'
        columns={[
          {
            Header: () => <CheckboxCell
              checked={drivers.length > 0 && selectors.driversFilters.areAllDriversSelected(this.props)}
              indeterminate={selectors.driversFilters.areSomeDriversSelected(this.props) && !selectors.driversFilters.areAllDriversSelected(this.props)}
              onChange={this.handleAllDriversCheck}
            />,
            Cell: (row) => <CheckboxCell
              checked={selectedDrivers.has(row.value)}
              value={row.value}
              onChange={this.handleDriverCheck}
            />,
            accessor: '_id',
            headerClassName: 'centered',
            className: 'centered',
            sortable: false,
            resizable: false,
            width: 30
          },
          { Header: 'First name', accessor: 'firstName', headerClassName: 'left' },
          { Header: 'Last name', accessor: 'lastName', headerClassName: 'left' },
          { Header: 'DL number', accessor: 'driversLicenseNumber', headerClassName: 'left', minWidth: 150 },
          (!isConcordeAdmin ? { show: false } :
            {
              Header: 'Company',
              id: 'company',
              Cell: (row) => <CompanyCell value={row.value} dotMap={this.dotMap} companyMap={this.companyMap} />,
              accessor: 'dotNumber',
              headerClassName: 'left',
              minWidth: 100
            })
          ,
          {
            Header: 'DOT',
            Cell: (row) => <DotCell value={row.value} dotMap={this.dotMap} />,
            accessor: 'dotNumber',
            headerClassName: 'left'
          },
          {
            Header: 'Active',
            accessor: 'active',
            Cell: (row) => <BooleanCell value={row.value} type='check' />,
            className: 'centered',
            minWidth: 50
          },
          {
            Header: 'Email',
            accessor: 'email',
            minWidth: 150,
            headerClassName: 'left'
          },
          {
            Header: 'Query type',
            id: 'queryStatus.queryType',
            accessor: 'queryStatus',
            Cell: (row) => <QueryTypeCell queryStatus={row.value} />,
            headerClassName: 'left',
            minWidth: 110,
            style: { 'whiteSpace': 'unset' }
          },
          {
            Header: 'Query status',
            id: 'queryStatus.status',
            accessor: 'queryStatus',
            Cell: (row) => <QueryStatusCell queryStatus={row.value} />,
            headerClassName: 'left',
            minWidth: 110,
            style: { 'whiteSpace': 'unset' }
          },
          {
            Header: 'Status date',
            id: 'queryStatus.statusDate',
            accessor: 'queryStatus.statusDate',
            Cell: (row) => <StatusDateCell queryStatus={row.value} />,
            headerClassName: 'left',
            minWidth: 110,
            sortable: true
          },
          {
            Header: 'Expiration',
            id: 'expiration',
            sortable: true,
            accessor: 'queryStatus',
            Cell: (row) => <ExpirationCell queryStatus={row.value} />,
            headerClassName: 'left',
            minWidth: 110
          },
          {
            Header: 'Awaiting consent',
            accessor: 'consentStatus.pending',
            Cell: (row) => <BooleanCell value={row.value} />,
            className: 'centered'
          },
          {
            Header: 'Last notified',
            accessor: 'consentStatus.lastNotified',
            Cell: (row) => <DateCell value={row.value} />,
            headerClassName: 'left'
          },
          {
            Header: 'Actions',
            id: 'actions',
            sortable: false,
            accessor: (d) => ({
              id: d._id,
              email: !!d.email,
              pendingConsent: !!(d.consentStatus && d.consentStatus.pending),
              consentCompleted: !!(d.consentStatus && d.consentStatus.lastCompleted),
              pendingLimitedQuery: !!(d.queryStatus && d.queryStatus.status === 'pending' && d.queryStatus.queryType === 'limited'),
              pendingFullQuery: !!(d.queryStatus && d.queryStatus.status === 'pending' && d.queryStatus.queryType === 'full'),
              onAction: this.handleDriverAction,
              isConcordeAdmin
            }),
            Cell: (row) => <ActionsCell {...row.value} />,
            className: 'centered no-padding allow-overflow actions'
          }
        ]}
        data={!filtered ? drivers : filtered}
        loading={loading}
        loadingText='Loading'
        manual
        minRows={5}
        pageSize={pageSize}
        showPaginationBottom={false}
        getTrProps={(state, rowInfo = {}) => {
          const props = { className: '' }
          // visually indicate recently updated drivers
          const { original: { _id, updatedAt } = {} } = rowInfo
          if (updatedAt && moment.utc(updatedAt).isAfter(moment.utc().subtract(5, 's'))) {
            props.className += ' recently-updated'
          }
          // visually indicate selected rows
          if (_id && this.props.driversFilters.selectedDrivers.has(_id)) {
            props.className += ' bg-light2'
          }
          return props
        }}
        getTdProps={(state, rowInfo = {}, column) => {
          const props = {}
          // toggle the driver's selected state on click
          const { original: { _id } = {} } = rowInfo
          if (_id) {
            props.onClick = () => {
              if ((column && column.id === 'actions') || hasSelectedText()) { return }
              this._handleDriverCheckToggle(_id)
              return false
            }
          }
          return props
        }}
        onFetchData={(state) => this._handleTableOnFetchData(state)}
      ></ReactTable>
    )
  }

  _renderCreateConsent () {
    if (!this.state.showCreateConsent) { return '' }
    return <CreateConsent
      done={() => this.setState({ showCreateConsent: false })}
    />
  }

  _renderSendNotification () {
    if (!this.state.showSendNotification) { return '' }
    return <SendNotification
      done={() => this.setState({ showSendNotification: false })}
    />
  }

  _renderCreateQuery () {
    const { showCreateQuery, queryType } = this.state
    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
    if (!showCreateQuery) { return '' }
    return <CreateQuery
      queryType={queryType}
      isConcordeAdmin={isConcordeAdmin}
      done={() => this.setState({ showCreateQuery: false })}
    />
  }

  _renderPerformQuery () {
    const { showPerformQuery } = this.state
    if (!showPerformQuery) { return '' }
    return <PerformQuery
      done={() => this.setState({ showPerformQuery: false })}
    />
  }

  _renderUpdateQueryStatuses () {
    const { showUpdateQueryStatus } = this.state
    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
    if (!showUpdateQueryStatus || !isConcordeAdmin) { return '' }
    return <UpdateQueryStatuses
      done={() => this.setState({ showUpdateQueryStatus: false, queryStatus: 'none' })}
    />
  }

  _renderPerformConsent () {
    if (!this.state.showPerformConsent) { return '' }
    return <PerformConsent
      done={() => this.setState({ showPerformConsent: false })}
    />
  }

  _renderViewDriver () {
    if (!this.state.showViewDriver) { return '' }
    return <ViewDriver
      done={({ showEditDriver } = { showEditDriver: false }) =>
        this.setState({
          showViewDriver: false,
          showEditDriver
        })
      }
    />
  }

  _renderEditDriver () {
    if (!this.state.showEditDriver) { return '' }
    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)

    if (isConcordeAdmin) {
      return <EditDriverConcordeAdmin
        done={({ showViewDriver } = { showViewDriver: false }) =>
          this.setState({
            showEditDriver: false,
            showViewDriver
          })}
      />
    }
    return <EditDriverCompanyAdmin
      done={({ showViewDriver } = { showViewDriver: false }) =>
        this.setState({
          showEditDriver: false,
          showViewDriver
        })}
    />
  }

  _renderViewDocuments () {
    if (!this.state.showViewDocuments) { return '' }
    return <ViewDocuments done={() => this.setState({ showViewDocuments: false })} />
  }
  _renderCreateDriver () {
    if (!this.state.showCreateDriver) { return '' }
    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)

    if (isConcordeAdmin) {
      return <CreateDriverConcordeAdmin
        done={() => this.setState({ showCreateDriver: false })}
      />
    }
    return <CreateDriverCompanyAdmin
      done={() => this.setState({ showCreateDriver: false })}
    />
  }

  _renderUploadDriverDocument () {
    if (!this.state.showUploadDriverDocument) { return '' }
    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
    return <UploadDriverDocument
      isConcordeAdmin={isConcordeAdmin}
      done={() => this.setState({ showUploadDriverDocument: false })}
    />
  }

  _renderExportDriverRoster () {
    if (!this.state.showExportDriverRoster) { return '' }
    if (!this.dotMap) {
      this.dotMap = selectors.dotNumbers.getDotNumberMap(this.props)
    }
    // const {
    //   driversFilters: { selectedDrivers: { size: selectedDriversCount } }
    // } = this.props
    return <ExportDriverRoster
      done={() => this.setState({ showExportDriverRoster: false })}
      dotMap={this.dotMap}
      companyMap={this.companyMap}
    />
  }


  _renderUploadRosters () {
    if (!this.state.showUploadRosters) { return '' }
    return <UploadRosters
      done={() => this.setState({ showUploadRosters: false })}
    />
  }

  _renderGeneratePDFExcel () {
    if (!this.state.showGeneratePDFExcel) { return '' }
    return <GeneratePDFExcel
      done={() => this.setState({ showGeneratePDFExcel: false })}
    />
  }

  _renderCreateConsents () {
    if (!this.state.showCreateConsents) { return '' }
    return <CreateConsents
      done={() => this.setState({ showCreateConsents: false })}
    />
  }

  _renderSendNotifications () {
    if (!this.state.showSendNotifications) { return '' }
    return <SendNotifications
      done={() => this.setState({ showSendNotifications: false })}
    />
  }

  _renderCreateQueries () {
    const { showCreateQueries, queryType } = this.state
    if (!showCreateQueries) { return '' }
    return <CreateQueries
      queryType={queryType}
      done={() => this.setState({ showCreateQueries: false })}
    />
  }


  _renderBottomControls () {
    const {
      drivers: { pages: { pageSize, pages, total, hasNextPage, hasPrevPage } },
      driversFilters: { page, selectedDrivers: { size: selectedDriversCount } }
    } = this.props
    if (!total) { return '' }

    let message = `Showing ${displayNumber(pageSize)} drivers out of ${displayNumber(total)}`
    if (selectedDriversCount > 0) {
      message += `. (${displayNumber(selectedDriversCount)} selected)`
    }

    return (
      <Row className='my-2 d-flex justify-content-between align-items-center' noGutters>
        <div className='ml-3'>{message}</div>
        <div className='mr-3 d-flex align-items-center'>
          <Button
            variant='outline-secondary'
            size='sm'
            title='Prev'
            className='mr-2'
            disabled={!hasPrevPage}
            onClick={() => this._handlePageChange(page - 1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='mr-2'>Page</span>
          <Form.Control
            size='sm'
            type='number'
            className='mr-2'
            value={page}
            min={1}
            max={pages}
            onChange={(e) => this._handlePageChange(e.target.value)}
          />
          <span className='mr-2 flex-shrink-0'>{`out of ${displayNumber(pages)}`}</span>
          <Button
            variant='outline-secondary'
            size='sm'
            title='Next'
            disabled={!hasNextPage}
            onClick={() => this._handlePageChange(page + 1)}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      </Row>
    )
  }

  _renderFilters () {
    if (!this.state.showFilters) { return '' }
    return <DriversTableFilters
      done={() => this.setState({ showFilters: false })}
    />
  }

  render () {
    return (
      <React.Fragment>
        {this._renderCreateConsent()}
        {this._renderSendNotification()}
        {this._renderCreateQuery()}
        {this._renderPerformQuery()}
        {this._renderUpdateQueryStatuses()}
        {this._renderPerformConsent()}
        {this._renderViewDriver()}
        {this._renderViewDocuments()}
        {this._renderEditDriver()}
        {this._renderCreateDriver()}
        {this._renderUploadDriverDocument()}
        {this._renderExportDriverRoster()}
        {this._renderUploadRosters()}
        {this._renderGeneratePDFExcel()}
        {this._renderCreateConsents()}
        {this._renderSendNotifications()}
        {this._renderCreateQueries()}
        {this._renderFilters()}
        {this._renderTopControls()}
        {this._renderTable()}
        {this._renderBottomControls()}

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, companies, dotNumbers, drivers, driversFilters } = state
  return { auth, companies, dotNumbers, drivers, driversFilters }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.dotNumbers, ...actions.companies, ...actions.drivers, ...actions.driversFilters, ...actions.driver },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DriversTable)

DriversTable.propTypes = {
  drivers: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  driversFilters: PropTypes.object.isRequired,
  updateSearch: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  loadDotNumbers: PropTypes.func.isRequired,
  loadCompanies: PropTypes.func.isRequired,
  setSelectedDrivers: PropTypes.func.isRequired,
  reloadDrivers: PropTypes.func.isRequired,
  setDriver: PropTypes.func.isRequired
}