import { actionTypes } from '../actions/actionTypes'

const initialState = {
  error: null,
  loading: null,
  sent: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.CONTACT.SET_ERROR:
    return { ...state, error: action.value }
  case actionTypes.CONTACT.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.CONTACT.SET_SENT:
    return { ...state, sent: action.value }
  default:
    return state
  }
}