import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import { displayNumber } from '../../../utils'
import ModalConfirmation from '../modalConfirmation'

import * as actions from '../../../actions'

class CreateQueries extends Component {
  state = {
    dryRun: true
  }

  componentDidMount () {
    this.props.createQueries(this.props.queryType, true)
  }

  _renderDryRun () {
    const { driversBulkActions: { loading, error, createdQueries }, queryType } = this.props

    if (loading || !createdQueries) {
      return <ModalConfirmation
        show={true}
        header={`Create new ${queryType} query requests`}
        text='Preparing to create query requests...'
        loading={loading}
        error={error}
        showAccept={false}
        onCancel={() => this.props.done()}
      />
    }
    if (createdQueries && createdQueries.created) {
      const { count } = createdQueries.created
      if (count === 0) {
        return <ModalConfirmation
          show={true}
          header={`Create new ${queryType} query requests`}
          text={`There are no drivers in your selection for whom a ${queryType} query request can be created.`}
          loading={loading}
          error={error}
          showAccept={false}
          cancelText='Done'
          onCancel={() => this.props.done()}
        />
      }
      return <ModalConfirmation
        show={true}
        header={`Create new ${queryType} query requests`}
        text={`New ${queryType} query requests will be created for ${displayNumber(count)} drivers. \
          Do you want to proceed?`}
        loading={loading}
        error={error}
        onAccept={() => this.setState({ dryRun: false }, () => this.props.createQueries(queryType, false)) }
        onCancel={() => this.props.done()}
      />
    }
    return ''
  }

  _renderConfirmedRun () {
    const { driversBulkActions: { loading, error, createdQueries }, queryType } = this.props

    let text = ''
    if (loading || !createdQueries) {
      text = 'Creating queries...'
    } else if (createdQueries && createdQueries.created) {
      text = `New ${queryType} queries have been created for ${displayNumber(createdQueries.created.count)} drivers.`
    }

    return <ModalConfirmation
      show={true}
      header={`Create new ${queryType} query requests`}
      text={text}
      loading={loading}
      error={error}
      showAccept={false}
      cancelText='Done'
      onCancel={() => this.props.done()}
    />
  }

  render () {
    const { dryRun } = this.state
    return dryRun ? this._renderDryRun() : this._renderConfirmedRun()
  }
}

const mapStateToProps = (state) => {
  const { driversBulkActions } = state
  return { driversBulkActions }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driversBulkActions },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQueries)

CreateQueries.propTypes = {
  queryType: PropTypes.string.isRequired,
  done: PropTypes.func.isRequired,
  driversBulkActions: PropTypes.object.isRequired,
  createQueries: PropTypes.func.isRequired
}
