
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Button
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEye, faScroll } from '@fortawesome/free-solid-svg-icons'

// Display a Company
export const CompanyCell = memo(({ value, companyMap }) => {
  return companyMap[value].name
})

CompanyCell.displayName = 'CompanyCell'
CompanyCell.propTypes = {
  value: PropTypes.string.isRequired,
  companyMap: PropTypes.object.isRequired
}


// Display a checkbox
export const CheckboxCell = memo(({ value, checked, indeterminate, onChange }) => {
  return <input
    type='checkbox'
    checked={checked}
    ref={(el) => {
      if (!el) { return }
      if (indeterminate !== undefined) { el.indeterminate = indeterminate }
      if (checked !== undefined) { el.checked = checked }
    }}
    onChange={(e) => onChange(e.target.checked, value)}
  ></input>
})
CheckboxCell.displayName = 'CheckboxCell'
CheckboxCell.propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func
}

// Display a menu of actions
export const ActionsCell = memo(({ id, onAction, isConcordeAdmin }) => {
  //const pendingQuery = pendingLimitedQuery || pendingFullQuery
  const link = isConcordeAdmin? 'concorde': 'company'
  return (
    <React.Fragment>
      <Button
        variant='light'
        size='sm'
        title='Edit'
        className='mr-1 action-button text-secondary'
        onClick={() => onAction('edit', id)}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </Button>
      <Button
        variant='light'
        size='sm'
        title='View'
        className='mr-1 action-button text-secondary'
        onClick={() => onAction('view', id)}
      >
        <FontAwesomeIcon icon={faEye} />
      </Button>
      <Link className='no-decoration' to= {`/${link}-admin/driver-roster?dotNumber=${id}`}>
        <Button
          variant='light'
          size='sm'
          title='Driver roster'
          className='mr-1 action-button text-secondary'
          //onClick={() => onAction('roster', id)}
        >
          <FontAwesomeIcon icon={faScroll} />
        </Button>
      </Link>

    </React.Fragment>
  )
})
ActionsCell.displayName = 'ActionsCell'
ActionsCell.propTypes = {
  id: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  isConcordeAdmin: PropTypes.bool.isRequired
}
