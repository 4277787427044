export const selectCustomStyles = {
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : base.color,
    backgroundColor: state.isSelected ? '#40bf8a' : (state.isFocused ? '#a0dfc5' : base.color),
    '&:hover': {
      backgroundColor: '#a0dfc5'
    }
  }),
  control: (base, state) => ({
    ...base,
    borderRadius: '.15rem',
    borderColor: state.isFocused ? '#a0dfc5' : '#ced4da',
    '&:hover': {
      borderColor: state.isFocused ? '#a0dfc5' : '#ced4da'
    },
    boxShadow: 'none',
    height: '34px'
  })
}
