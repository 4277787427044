import 'typeface-open-sans'
import 'typeface-inconsolata'
import 'typeface-montserrat'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import './index.scss'
import 'jquery'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { store, history } from './store'

import App from './App'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App/>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )
})