import { actionTypes } from './actionTypes'
import { requestDrivers } from '../services/apiService'
import * as transformations from '../transformations'
import * as selectors from '../selectors'


const drivers = {
  setDrivers: (drivers) => ({
    type: actionTypes.DRIVERS.SET_DRIVERS,
    value: drivers
  }),

  setError: (error) => ({
    type: actionTypes.DRIVERS.SET_ERROR,
    value: error
  }),

  setLoading: (loading) => ({
    type: actionTypes.DRIVERS.SET_LOADING,
    value: loading
  }),

  reloadDrivers: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      const params = selectors.driversFilters.getFilterParams(getState())

      dispatch(drivers.setLoading(true))
      dispatch(drivers.setError())
      const data = await requestDrivers(token, params)
      if(data && data.drivers){
        dispatch(drivers.setDrivers(data))
      } else if (data && data.error){
        dispatch(drivers.setError(data.error))
      }
      dispatch(drivers.setLoading(false))
    }
  },

  debouncedReloadDrivers: () => {
    const thunk = (dispatch) => {
      dispatch(drivers.reloadDrivers())
    }
    thunk.meta = {
      debounce: {
        time: 500,
        key: 'DEBOUNCED_RELOAD_DRIVERS'
      }
    }
    return thunk
  },

  clear: () => {
    return (dispatch) => {
      dispatch(drivers.setDrivers({ drivers: null, pages: {} }))
    }
  },

  updateWithDriver: (driver) => {
    return (dispatch, getState) => {
      if (!driver) { return }
      const { drivers: { drivers: existingDrivers } } = getState()
      const newDrivers = transformations.drivers.updateDriversWithDriver(existingDrivers, driver)
      dispatch(drivers.setDrivers({ drivers: newDrivers }))
    }
  },

  updateWithDrivers: (drivers) => {
    return (dispatch, getState) => {
      if (!drivers) { return }
      const { drivers: { drivers: existingDrivers } } = getState()
      const newDrivers = transformations.drivers.updateDriversWithDrivers(existingDrivers, drivers)
      dispatch(drivers.setDrivers({ drivers: newDrivers }))
    }
  },

  prependDriver: (driver) => {
    return (dispatch, getState) => {
      if (!driver) { return }
      const { drivers: { drivers: existingDrivers } } = getState()
      const newDrivers = transformations.drivers.prependDriver(existingDrivers, driver)
      dispatch(drivers.setDrivers({ drivers: newDrivers }))
    }
  }
}

export { drivers }
