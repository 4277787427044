import { actionTypes } from '../actions/actionTypes'

const initialState = {
  dotNumbers: null,
  pages: {},
  loading: null,
  error: null
}

export default (state = initialState, action) => {
  switch(action.type){
  case actionTypes.DOT_NUMBERS.SET_DOT_NUMBERS:
    return { ...state, ...action.value }
  case actionTypes.DOT_NUMBERS.SET_LOADING:
    return { ...state, loading: action.value }
  case actionTypes.DOT_NUMBERS.SET_ERROR:
    return { ...state, error: action.value }
  default:
    console.log('DOT Numbers default action type:' + action.type)
    return state
  }
}