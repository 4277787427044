import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'

import { fullName } from '../../../utils'
import ModalConfirmation from '../modalConfirmation'

import * as actions from '../../../actions'

class CreateQuery extends Component {
  componentDidMount () {
    this.props.setCreatedQuery()
    this.props.setError()
  }

  componentDidUpdate () {
    const { driver: { createdQuery } } = this.props
    if (createdQuery) {
      this.props.done()
    }
  }

  render () {
    const { driver: { driver, loading, error }, queryType, isConcordeAdmin } = this.props
    if (!driver) { return '' }
    return <ModalConfirmation
      show={true}
      header={`Create a new ${queryType} query request`}
      text={`Create a new ${queryType} query request for ${fullName(driver)}?`}
      onAccept={() => this.props.createQuery(driver._id, queryType, isConcordeAdmin )}
      onCancel={() => this.props.done()}
      loading={loading}
      error={error}
    />
  }
}

const mapStateToProps = (state) => {
  const { driver } = state
  return { driver }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuery)

CreateQuery.propTypes = {
  queryType: PropTypes.string.isRequired,
  done: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  createQuery: PropTypes.func.isRequired,
  setCreatedQuery: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isConcordeAdmin: PropTypes.bool
}