import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import ReactTable from 'react-table'
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faTimes, faEllipsisH, faSync, faUserPlus, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import FilterBadges from './filterBadges'
import { CheckboxCell, CompanyCell, ActionsCell } from './dotsTableCustomCells'
import styles from './dotsTable.module.scss'
import * as selectors from '../../../selectors'
import * as actions from '../../../actions'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { alertNotImplementedYet ,hasSelectedText, displayNumber } from '../../../utils'
import DOTSTableFilters from './dotsTableFilters'

class DOTSTable extends Component {

    state = {
      searchString: '',
      sorted: [],
      showFilters: false,
      showCreateDOT: false,
      showSendNotification: false,
      showViewDOT: false,
      showEditDOT: false,
      showUploadDOTS:false
    }


    constructor () {
      super()
      this.handleDOTNumberAction = this._handleDOTNumberAction.bind(this)
      this.handleAllDOTNumbersCheck = this._handleAllDOTNumbersCheck.bind(this)
      this.handleDOTNumberCheck = this._handleDOTNumberCheck.bind(this)
    }

    componentDidMount () {
      this.props.reloadDOTNumbers()
      if (selectors.auth.isConcordeAdmin(this.props)) {
        this.props.loadCompanies()
      }
    }


    _handleDOTNumberAction (type, dotNumberId) {
      //alertNotImplementedYet()
      const dotNumber = this.props.dotNumbers.dotNumbers.find((d) => d._id === dotNumberId)
      //this.props.setDOTNumber(dotNumber)
      if (!dotNumber) {return}

      switch (type) {
      case 'view':
        //this.setState({ showViewDOT: true })
        alertNotImplementedYet()
        return
      case 'edit':
        //this.setState({ showEditDOT: true })
        alertNotImplementedYet()
        return
      default: return
      }

    }


    _handleSearchChange (searchString = '') {
      this.setState({ searchString })
      this.props.updateSearch(searchString)
    }


    _handleTableOnFetchData ({ sorted = [] }) {
      //alertNotImplementedYet()
      console.log('dotNumbers._handleTableOnFetchData')
      if (!isEqual(this.state.sorted, sorted)) {
        this.setState({ sorted })
        this.props.updateSort(sorted)
      }
    }


    _handleDOTNumberCheckToggle (dotNumberId) {
      alertNotImplementedYet()
      console.log(dotNumberId)
      return ''
      //console.log('/////////////////////////////_handleDOTNumberCheckToggle')
      //const selectedDOTNumbers = transformations.dotNumbersFilters
      //  .updateSelectedDOTNumberWithToggledDOTNumber (this.props.dotNumbersFilters.selectedDOTNumbers, dotNumberId)
      //this.props.setSelectedDOTNumbers(selectedDOTNumbers)
    }

    _handleDOTNumberCheck (checked, dotNumberId) {
      alertNotImplementedYet()
      console.log(checked + ' ' + dotNumberId)
      return ''
      //console.log('///////////////// checked=' + checked + ' dotNumberId=' + dotNumberId)
      //const selectedDOTNumbers = transformations.dotNumbersFilters
      //  .updateSelectedDOTNumbersWithDOTNumber(this.props.dotNumbersFilters.selectedDOTNumbers, dotNumberId, checked)

      //  console.log('///////////////// selectedDOTNumbers: ' + selectedDOTNumbers.length)
      //this.props.setSelectedDOTNumbers(selectedDOTNumbers)
    }

    _handleAllDOTNumbersCheck (checked) {
      alertNotImplementedYet()
      console.log(checked)
      return ''

      //console.log('/////////////////////////////_handleAllDOTNumbersCheck')
      //const selectedDOTNumbers = transformations.dotNumbersFilters
      //  .replaceAllSelectedDOTNumbers(checked ? this.props.dotNumbers.dotNumbers : null)
      //this.props.setSelectedDOTNumbers(selectedDOTNumbers)
    }


    _renderTable () {

      const {
        companies : { companies },
        dotNumbers: { dotNumbers , pages: { pageSize } ,  loading, error } ,
        dotNumbersFilters: { selectedDOTNumbers }
      } = this.props

      const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)

      if (error) { return this._renderTableError() }
      if ( !dotNumbers || (isConcordeAdmin && !companies) ){ return this._renderTableLoading() }

      if (isConcordeAdmin && !this.companyMap) {
        this.companyMap = selectors.companies.getCompanyMap(this.props)
      }

      return (

        <ReactTable
          className='flex-grow mx-3'
          columns={[


            {
              Header: () => <CheckboxCell
                checked={dotNumbers.length > 0 && selectors.dotNumbersFilters.areAllDOTNumbersSelected(this.props)}
                indeterminate={selectors.dotNumbersFilters.areSomeDOTNumbersSelected(this.props) && !selectors.dotNumbersFilters.areAllDOTNumbersSelected(this.props)}
                onChange={this._handleAllDOTNumbersCheck}
              />,
              Cell: (row) => <CheckboxCell
                checked={selectedDOTNumbers.has(row.value)}
                value={row.value}
                onChange={this.handleDOTNumberCheck}
              />,
              accessor: '_id',
              headerClassName: 'centered',
              className: 'centered',
              sortable: false,
              resizable: false,
              width: 30
            },


            (!isConcordeAdmin ? { show: false } :
              {
                Header: 'Company',
                id: 'company',
                Cell: (row) => <CompanyCell value={row.value} companyMap={this.companyMap}/> ,
                accessor: 'company',
                headerClassName: 'left',
                minWidth: 150
              })
            ,

            { Header: 'DOT Number', accessor:'name', headerClassName: 'left' },

            {
              Header: 'Actions',
              id: 'actions',
              sortable: false,
              accessor: (d) => ({
                id: d._id,
                onAction: this.handleDOTNumberAction,
                isConcordeAdmin
              }),
              Cell: (row) => <ActionsCell {...row.value} />,
              className: 'centered no-padding allow-overflow actions'
            }

          ]}
          data={dotNumbers}
          loading={loading}
          loadingText='Loading'
          manual
          minRows={5}
          pageSize={pageSize}
          showPaginationBottom={false}


          getTdProps={(state, rowInfo = {}, column) => {
            const props = { className: '' }
            // toggle the DOT Number's selected state on click
            const { original: { _id } = {} } = rowInfo
            if (_id) {
              props.onClick = () => {
                if ((column && column.id === 'actions') || hasSelectedText()) { return }
                this._handleDOTNumberCheckToggle(_id)
                return false
              }
            }
            return props
          }}

          onFetchData={(state) => this._handleTableOnFetchData(state)}
        >
        </ReactTable>
      )
    }

    _renderBottomControls () {

      const {
        dotNumbers:{ pages : { pageSize, pages, total, hasNextPage, hasPrevPage } },
        dotNumbersFilters: { page, selectedDOTNumbers: { size: selectedDOTNumbersCount } }
      } = this.props

      //if (!total) { return '' }

      let message = `Showing ${displayNumber(pageSize)} DOT Numbers out of ${displayNumber(total)}`
      if (selectedDOTNumbersCount > 0) {
        message += `. (${displayNumber(selectedDOTNumbersCount)} selected)`
      }

      return (
        <Row className='my-2 d-flex justify-content-between align-items-center' noGutters>
          <div className='ml-3'>{message}</div>
          <div className='mr-3 d-flex align-items-center'>
            <Button
              variant='outline-secondary'
              size='sm'
              title='Prev'
              className='mr-2'
              disabled={!hasPrevPage}
              onClick={() => this._handlePageChange(page - 1)}
            >
              <FontAwesomeIcon icon={faArrowLeft}/>
            </Button>
            <span className='mr-2'>Page</span>
            <Form.Control
              size='sm'
              type='number'
              className='mr-2'
              value={page}
              min={1}
              max={pages}
              onChange={(e) => this._handlePageChange(e.target.value)}
            />
            <span className='mr-2 flex-shrink-0'>{`out of ${displayNumber(pages)}`}</span>
            <Button
              variant='outline-secondary'
              size='sm'
              title='Next'
              disabled={!hasNextPage}
              onClick={() => this._handlePageChange(page + 1)}
            >
              <FontAwesomeIcon icon={faArrowRight}/>
            </Button>
          </div>
        </Row>
      )

      //return (<div>Bottom controls</div>)
    }

    _handlePageChange (newPage = '') {
      console.log('#########################_handlePageChange1')
      const { dotNumbers: { pages: { pages } } } = this.props
      console.log('_handlePageChange2')
      if (isNaN(Number(newPage))) { return }
      console.log('_handlePageChange3')
      let page = Number(newPage)
      if (page < 1) { page = 1 }
      if (page > pages) { page = pages }
      this.props.updatePage(page)
    }


    _renderCreateDOT () {
      if (!this.state.showCreateDOT) { return '' }
      //const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)

      //if (isConcordeAdmin) {
      //    return <CreateDOTConcordeAdmin
      //      done={() => this.setState({ showCreateDOT: false })}
      //    />
      //  }
      //  return <CreateDOTCompanyAdmin
      //    done={() => this.setState({ showCreateDOT: false })}
      //  />

    }


    _renderUploadRosters () {
      //if (!this.state.showUploadDOTS) { return '' }
      //return <UploadRosters
      //  done={() => this.setState({ showUploadRosters: false })}
      ///>
    }


    _renderTableLoading () {
      return (
        <div className='d-flex justify-content-around align-items-center'>
          <div>
            <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
              Loading DOT Numbers
          </div>
        </div>
      )
    }


    _renderTableError () {
      const { dotNumbers: { error } } = this.props
      return (
        <div className='d-flex justify-content-around align-items-center'>
          {`There was a problem loading the data: ${error}`}
        </div>
      )
    }

    _renderTopControls () {

      const { dotNumbers: { loading } } = this.props
      const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
      //const areFiltersEmpty = selectors.dotNumbersFilters.areFiltersEmpty(this.props)
      //const areSomeDOTNumbersSelected = selectors.dotNumbersFilters.areSomeDOTNumbersSelected(this.props)
      //const labelStr = areSomeDOTNumbersSelectedsSelected ? 'currently selected' : (areFiltersEmpty ? 'all' : 'currently filtered')

      return (
        <Row className='flex-shrink-0 mx-3 mt-2 mb-1' noGutters>
          <Col xs={4} md={3} lg={2}>
            <Form.Group>
              <Form.Label>Search</Form.Label>
              <InputGroup size='sm'>
                <Form.Control
                  type='text'
                  value={this.state.searchString}
                  onChange={(e) => this._handleSearchChange(e.target.value)}
                ></Form.Control>
                <InputGroup.Append>
                  <Button
                    variant='outline-secondary'
                    title='Clear'
                    disabled={!this.state.searchString}
                    onClick={() => this._handleSearchChange()}
                  ><FontAwesomeIcon icon={faTimes}/>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col xs={{ span: 4, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 8, offset: 1 }}>
            <Form.Group>
              <Form.Label>Filters</Form.Label>
              <InputGroup className='flex-nowrap align-items-center'>
                <Button
                  variant='outline-primary'
                  size='sm'
                  className='mr-2 align-self-start'
                  onClick={() => this.setState({ showFilters: true })}
                >Edit</Button>
                <Button
                  variant='outline-secondary'
                  size='sm'
                  className='mr-2 align-self-start'
                  onClick={() => this.props.clearFilters()}
                >Clear</Button>
                <div className='d-flex flex-row align-items-center flex-wrap'>
                  <FilterBadges/>
                </div>
              </InputGroup>
            </Form.Group>
          </Col>



          <Col xs={3} md={2} lg={1}>
            <Form.Group className='d-flex flex-column align-items-end'>
              <Form.Label>Actions</Form.Label>
              <InputGroup className='d-flex justify-content-end'>
                <Button
                  variant='outline-primary'
                  size='sm'
                  className='mr-2'
                  disabled={loading}
                  title='Reload data'
                  onClick={() => this.props.reloadDOTNumbers() }
                  //onClick={() => alertNotImplementedYet()}
                ><FontAwesomeIcon icon={faSync}/>
                </Button>


                <Dropdown>

                  <Dropdown.Toggle
                    variant='outline-primary'
                    size='sm'
                    title='More actions'
                    className='hide-after-content'>
                    <FontAwesomeIcon icon={faEllipsisH}/>
                  </Dropdown.Toggle>

                  <Dropdown.Menu alignRight>

                    <Dropdown.Item
                      as='button'
                      key='1'
                      className='btn btn-secondary text-secondary'
                      //onClick={() => this.setState({ showCreateDOT: true })}>
                      onClick={() => alertNotImplementedYet()}>
                      <FontAwesomeIcon icon={faUserPlus} className={`mr-2 ${styles.menuItemIcon}`}/>Create a new DOT
                    </Dropdown.Item>

                    {isConcordeAdmin ? '' :
                      <React.Fragment>
                        <Dropdown.Item
                          as='button'
                          key='2'
                          className='btn btn-secondary text-secondary'
                          //onClick={() => this.setState({ showUploadRosters: true })}>
                          onClick={() => alertNotImplementedYet()}>
                          <FontAwesomeIcon icon={faFileUpload} className={`mr-2 ${styles.menuItemIcon}`}/>Upload DOT roster
                        </Dropdown.Item>
                      </React.Fragment>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      )
    }

    _renderFilters () {
      if (!this.state.showFilters) { return '' }
      return <DOTSTableFilters
        done={() => this.setState({ showFilters: false })}
      />
    }


    render ()    {
      return (
        <React.Fragment>

          {this._renderFilters()}
          {this._renderTopControls()}
          {this._renderTable()}
          {this._renderBottomControls()}
        </React.Fragment>
      )
    }
}

const mapStateToProps = (state) => {
  const { auth, dotNumbers, companies , dotNumbersFilters } = state
  return { auth, dotNumbers, companies, dotNumbersFilters }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {  ...actions.companies, ...actions.dotNumbers, ...actions.dotNumbersFilters, ...actions.dotNumber },
    dispatch
  )
}

export default connect(mapStateToProps,mapDispatchToProps)(DOTSTable)

DOTSTable.propTypes = {
  dotNumbers: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  loadDotNumbers :PropTypes.func.isRequired,
  loadCompanies: PropTypes.func.isRequired ,
  setDOTNumbers: PropTypes.func.isRequired   ,
  setSelectedDOTNumbers: PropTypes.func.isRequired,
  updateSearch: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  dotNumbersFilters:PropTypes.object.isRequired,
  setDOTNumber : PropTypes.func.isRequired,
  reloadDOTNumbers: PropTypes.func.isRequired
}
