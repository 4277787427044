const queries = {
  getTypes: () => {
    return [...types.sort()]
  },
  getStatuses: () => {
    return [...status.sort()]
  }
}

export { queries }
const types = ['limited', 'full', 'limited → full', 'pre-employment']
const status = ['none', 'awaiting', 'pending', 'complete', 'complete-notprohibited', 'incomplete/cancelled', 'violation/other', 'driverprohibited','driver-notverified','query balance- 0']