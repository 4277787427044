import { actionTypes } from './actionTypes'
import {
  requestCompanies,
  requestCreateCompany
} from '../services/apiService'
import * as transformations from '../transformations'

const companies = {
  setCompanies: (value) => ({
    type: actionTypes.COMPANIES.SET_COMPANIES,
    value
  }),

  setError: (value) => ({
    type: actionTypes.COMPANIES.SET_ERROR,
    value
  }),

  setLoading: (value) => ({
    type: actionTypes.COMPANIES.SET_LOADING,
    value
  }),

  loadCompanies: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      dispatch(companies.setLoading(true))
      dispatch(companies.setError())
      const data = await requestCompanies(token)
      if(data && data.companies){
        dispatch(companies.setCompanies(data))
      } else if (data && data.error) {
        dispatch(companies.setError(data.error))
      }
      dispatch(companies.setLoading(false))
    }
  },

  createCompany: (company, consentContents) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }

      company.consentContents = consentContents
      const data = await requestCreateCompany(token, company)
      if(data.error)
        dispatch(companies.setError(data.error))
      else {
        dispatch(companies.setLoading(true))
        dispatch(companies.setError())
        const data = await requestCompanies(token)
        if(data && data.companies){
          dispatch(companies.setCompanies(data))
        } else if (data && data.error) {
          dispatch(companies.setError(data.error))
        }
        dispatch(companies.setLoading(false))
      }
    }
  },

  updateWithCompany: (company) => {
    return (dispatch, getState) => {
      if (!company) { return }
      const { companies: { companies: existingCompanies } } = getState()
      const newCompanies = transformations.companies.updateCompaniesWithCompany(existingCompanies, company)
      dispatch(companies.setCompanies({ companies: newCompanies }))
    }
  },

  clear: () => {
    return (dispatch) => {
      dispatch(companies.setCompanies({ companies: null }))
    }
  }
}

export { companies }
