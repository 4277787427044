import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ReactTable from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCopy, faFile, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

import { DateCell } from './driversTableCustomCells'
import { fullName } from '../../../utils'
import { driverDocumentTypes } from '../../../selectors/driverDocumentTypes'

import {
  Card,
  Button,
  Col,
  Container,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'

import PdfDocument from '../pdfDocument'

import * as actions from '../../../actions'
import styles from './driversTableCustomCells.module.scss'

class ViewDocuments extends Component {

  constructor (props) {
    super(props)
    this.state = {
      selectedDocument: null,
      showConfirmDelete: false,
      selectedDocumentType: null
    }
  }

  componentDidMount () {
    const { driver: { driver } } = this.props
    if (!driver) {
      return
    }
    this.props.getDriver(driver._id)
    this.props.getDocumentList(driver._id)
  }

  _renderDocumentList () {
    const { driver: { driver }, driverDocuments } = this.props
    if (!driver) {
      return ''
    }
    console.log (` ffffffffff ${ JSON.stringify( driverDocuments )} `)
    const data = this._initializeDocumentTableData(driverDocuments)
    const maxRows = 10
    console.log('Document List Rendered - Array Length:  ' + data.length)
    console.log(data)
    let defaultPageSize = data.length <= maxRows ? data.length : maxRows
    if (defaultPageSize === 0) {
      defaultPageSize = 2
    }
    return (
      <ReactTable
        className='-highlight'
        columns={[
          {
            Header: 'Document Type',
            accessor: 'documentType',
            className: 'left',
            headerClassName: 'left'
          },
          {
            Header: 'Upload Date',
            accessor: 'createdAt',
            Cell: (row) => {
              if (isNaN(Date.parse(row.value)) === true)
              {
                return row.value
              }
              else {
                return <DateCell value={row.value}/>
              }
            },
            className: 'left',
            headerClassName: 'left'
          },
          {
            Header: 'Actions',
            id: 'sub_col_actions',
            accessor: (d) => ({
              id: d._id,
              createdAt: d.createdAt,
              serverFileName: d.serverFileName,
              documentType: d.documentType
            }),
            Cell: (row) => {
              if (isNaN(Date.parse(row.value.createdAt)) === true) {
                return ''
              }
              return (
                <React.Fragment>
                  <Button
                    variant='light'
                    size='sm'
                    title='View Document'
                    className = 'mr-1 action-button text-secondary'
                    onClick = {() => {
                      this.setState({
                        selectedDocument: row.value.id
                      }, () => this.props.getDocument(driver._id, row.value.serverFileName))
                    }}
                  >
                    <FontAwesomeIcon icon={faEye}/>
                  </Button>
                  <Button
                    variant='light'
                    size='sm'
                    title='Delete Document'
                    className = 'mr-1 action-button text-secondary'
                    onClick = {() => {
                      this.setState({
                        selectedDocument: row.value.id,
                        showConfirmDelete: true,
                        selectedDocumentType: row.value.documentType
                      }, () => this.props.setDocument())
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash}/>
                  </Button>
                </React.Fragment>
              )
            },
            className: 'left',
            headerClassName: 'left'
          }
        ]}
        data={data}
        pageSize={defaultPageSize}
        showPagination={data.length > defaultPageSize}
        noDataText={
          driverDocuments.loading
            ? 'Loading document list . . .'
            : 'This driver has no uploaded documents'
        }
        getTrProps={
          (state, rowInfo) => {
            if (!rowInfo) return {}
            if (!rowInfo.original) return {}
            return {
              className: this.state.selectedDocument === rowInfo.original._id ? 'bg-light2' : ''
            }
          }
        }
      />
    )
  }

  _initializeDocumentTableData (driverDocuments) {
    if (driverDocuments.loading) {
      return []
    }
    var data = driverDocuments.documentList || []
    driverDocumentTypes.getAll().forEach((type) => {
      var found = data.some((document) => document['documentType'] === type)

      if (found === false) {
        data.push({
          _id: 'Document Not Present',
          documentType: type,
          createdAt: 'Document Not Present'
        })
      }
    })

    data.sort((a, b) => {
      if (a.documentType === b.documentType) {
        return (a.createdAt < b.createdAt) ? 1 : -1
      }
      return (a.documentType > b.documentType) ? 1 : -1
    })

    return data
  }

  _renderDocument () {
    const { driver: { driver }, driverDocuments } = this.props
    if (driverDocuments.loadingDocument) {
      return <Col xs={12} className='d-flex justify-content-around'>
        <div>
          <Spinner animation='border' size='sm' className='mr-2'/>
          Loading
        </div>
      </Col>
    }
    if (!driverDocuments.document) {
      if (this.state.showConfirmDelete) {
        return <Container>
          <Card>
            <Card.Body className='text-center'>
              This action cannot be undone.
              <br></br>
              Are you sure you want to delete this document?
              <br></br>
              <Button
                variant='secondary'
                size='sm'
                className='btn-semi-wide ml-2'
                onClick={() => this.setState({
                  showConfirmDelete: false,
                  selectedDocument: null,
                  selectedDocumentType: null
                }, () => this.props.setDocument())}
              >Cancel
              </Button>
              <Button
                variant='primary'
                size='sm'
                disabled={driverDocuments.loadingDocument}
                className='btn-semi-wide ml-2'
                onClick={() => this.setState({
                  showConfirmDelete: false
                }, () => {
                  this.props.deleteDocument(driver._id, this.state.selectedDocument, this.state.selectedDocumentType)
                  this.setState({
                    selctedDocument: null
                  })})}
              >Delete
              </Button>
            </Card.Body>
          </Card>
        </Container>
      }
      return <Container>
        <Card>
          <Card.Body className='d-flex'>
            <FontAwesomeIcon
              icon={faFile}
              className={'fa-9x ml-auto mr-auto text-muted'}
              style={{ opacity: 0.2 }}
            />
          </Card.Body>
        </Card>
      </Container>
    }
    return <Container>
      <PdfDocument
        consent={{}}
        pdf={driverDocuments.document}
        emailButton=''
      />
    </Container>
  }

  render () {
    const { driver: { driver }, done } = this.props

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <FontAwesomeIcon icon={faCopy} className={`mr-2 ${styles.menuITemIcon}`}/>
            {fullName(driver)} - Documents
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderDocumentList()}
            <hr className='mb-3'/>
            <Row>
              {this._renderDocument()}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-wide'
            onClick={() => done()}
          >Done</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { driver, driverDocuments } = state
  return { driver, driverDocuments }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver, ...actions.driverDocuments },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocuments)

ViewDocuments.propTypes = {
  done: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  driverDocuments: PropTypes.object.isRequired,
  getDriver: PropTypes.func.isRequired,
  getDocumentList: PropTypes.func.isRequired,
  getDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setDocument: PropTypes.func.isRequired
}
