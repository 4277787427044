import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Container,
  Col,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import Select from 'react-select'
import { selectCustomStyles } from '../common/selectCustomStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/drivers-clearinghouse-logo-small.png'
import DriverFields from '../common/driversTable/driverFields'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

class CreateDriver extends Component {
  state = {
    company: null,
    driverFields: null
  }

  componentDidMount () {
    this.props.setCreated()
    this.props.setError()
    const { companies: { companies } } = this.props
    if (companies && companies.length > 0) {
      this.setState({ company: companies[0]._id })
    }
  }

  componentDidUpdate () {
    const { driver: { created } } = this.props
    if (created) {
      this.props.done()
    }
  }

  _renderButtonContents () {
    const { driver: { loading } } = this.props
    if (!loading) { return 'Create' }
    return <React.Fragment>
      <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
        Saving
    </React.Fragment>
  }

  _renderError  () {
    const { driver: { error } } = this.props
    if (!error) { return '' }
    return <Row>
      <Col className='alert alert-danger'>
        {error}
      </Col>
    </Row>
  }

  render () {
    const { driver: { loading }, companies: { companies }, dotNumbers: { dotNumbers }, done } = this.props
    const { company } = this.state
    if (!companies || !dotNumbers || !company) { return '' }
    const companyMap = selectors.companies.getCompanyMap(this.props)
    const dotNumbersForCompany = dotNumbers.filter((d) => d.company === company)

    return (
      <Modal
        show={true}
        onHide={() => done()}
        animation={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Image className='mr-3' src={logo} height={26} width={39} alt='logo'/>
            Create new driver
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            ><FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            <Row className='pb-1 mx-3 align-items-center'>
              <Col xs={4} className='text-right'>Company</Col>
              <Col xs={8} md={6}>
                <Select
                  styles={selectCustomStyles}
                  isSearchable={true}
                  options={companies.map((d) => ({ value: d._id, label: d.name }))}
                  value={{ value: company, label: companyMap[company].name }}
                  onChange={(v) => this.setState({ company: v.value })}
                  filterOption={(option, inputValue) => (option.label.toString().match(inputValue) || []).length > 0}
                ></Select>
              </Col>
            </Row>
            <DriverFields
              dotNumbers={dotNumbersForCompany}
              onUpdate={(state) => this.setState({ driverFields: state })}
              isConcordeAdmin={true}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => done()}
          >Cancel</Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={loading}
            className='btn-wide'
            onClick={() => this.props.createDriver(this.state.driverFields)}
          >{this._renderButtonContents()}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { companies, dotNumbers, driver } = state
  return { companies, dotNumbers, driver }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.driver },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDriver)

CreateDriver.propTypes = {
  done: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  driver: PropTypes.object.isRequired,
  createDriver: PropTypes.func.isRequired,
  setCreated: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
