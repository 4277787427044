import { actionTypes } from './actionTypes'
import {
  requestGetDocumentList,
  requestGetDocument,
  requestDeleteDocument,
  requestGetDocumentListSparcMissing
} from '../services/apiService'

const driverDocuments = {
  setDocumentList: (documentList) => ({
    type: actionTypes.DRIVER_DOCUMENTS.SET_DOCUMENT_LIST,
    value: documentList
  }),

  setDocument: (document) => ({
    type: actionTypes.DRIVER_DOCUMENTS.SET_DOCUMENT,
    value: document
  }),

  setLoading: (loading) => ({
    type: actionTypes.DRIVER_DOCUMENTS.SET_LOADING,
    value: loading
  }),

  setLoadingDocument: (loadingDocument) => ({
    type: actionTypes.DRIVER_DOCUMENTS.SET_LOADING_DOCUMENT,
    value: loadingDocument
  }),

  setError: (value) => ({
    type: actionTypes.DRIVER_DOCUMENTS.SET_ERROR,
    value
  }),

  getDocumentList: (driverId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driverDocuments.setDocument())
      dispatch(driverDocuments.setLoading(true))
      dispatch(driverDocuments.setError())
      const data = await requestGetDocumentList(token, driverId)
      if (data && data.documentList) {
        dispatch(driverDocuments.setDocumentList(data.documentList))
      } else if (data && data.error) {
        dispatch(driverDocuments.setError(data.error))
      }
      dispatch(driverDocuments.setLoading(false))
    }
  },

  getDocumentListSparcMissing: () => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driverDocuments.setDocument())
      dispatch(driverDocuments.setDocumentList())
      dispatch(driverDocuments.setLoading(true))
      dispatch(driverDocuments.setError())
      const data = await requestGetDocumentListSparcMissing(token)
      if (data && data.documentList) {
        dispatch(driverDocuments.setDocumentList(data.documentList))
      } else if (data && data.error) {
        dispatch(driverDocuments.setError(data.error))
      }
      dispatch(driverDocuments.setLoading(false))
    }
  },

  getDocument: (driverId, documentId) => {
    return async (dispatch, getState) => {
      const { auth: { token } } = getState()
      if (!token) { return }
      dispatch(driverDocuments.setLoadingDocument(true))
      dispatch(driverDocuments.setError())
      const data = await requestGetDocument(token, driverId, documentId)
      if (data && data.document) {
        dispatch(driverDocuments.setDocument(data.document))
      } else if (data && data.error) {
        dispatch(driverDocuments.setError(data.error))
      }
      dispatch(driverDocuments.setLoadingDocument(false))
    }
  },

  deleteDocument: (driverId, documentId) => {
    return async (dispatch, getState) => {
      const { auth: { token, user } } = getState()
      let userName = ` ${user.firstName} ${user.lastName} `
      if (!token) { return }
      dispatch(driverDocuments.setLoadingDocument(true))
      dispatch(driverDocuments.setLoading(true))
      dispatch(driverDocuments.setError())
      const data = await requestDeleteDocument(token, driverId, documentId, userName)
      if (data && data.documentList) {
        dispatch(driverDocuments.setDocumentList(data.documentList))
      } else if (data && data.error) {
        dispatch(driverDocuments.setError(data.error))
      }
      dispatch(driverDocuments.setLoadingDocument(false))
      dispatch(driverDocuments.setLoading(false))
    }
  }
}

export { driverDocuments }