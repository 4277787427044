import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createBrowserHistory } from 'history'
import createDebounce from 'redux-debounced'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import { isProductionEnvironment } from './utils'

const history = createBrowserHistory()

const middlewares = [
  createDebounce(),
  thunkMiddleware,
  routerMiddleware(history)
]

if (!isProductionEnvironment()) {
  middlewares.push(createLogger())
}

const initialState = {}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  createRootReducer(history),
  initialState,
  composeEnhancers(
    applyMiddleware(...middlewares)
  )
)

export {
  history,
  store
}
