import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// import moment from 'moment'
import { omit, groupBy, isEmpty } from 'lodash'

import {
  Button,
  Col,
  //Form,
  Image,
  Modal,
  Row
} from 'react-bootstrap'
import Select from 'react-select'

import { selectCustomStyles } from '../selectCustomStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../../images/drivers-clearinghouse-logo-small.png'

import * as actions from '../../../actions'
import * as selectors from '../../../selectors'

class DOTSTableFilters extends Component {
  state = {};

  componentDidMount () {
    this.setState(this.props.dotNumbersFilters)
    //const { dotNumbersFilters } = this.props
  }


  // the options to display in the DOT number select
  _dotNumberOptions () {
    const {
      dotNumbers: { dotNumbers },
      companies: { companies: allCompanies }
    } = this.props
    const { companies } = this.state
    const companyMap = selectors.companies.getCompanyMap(this.props)

    // for concorde admins who are viewing a list of companies, group the dot numbers by company
    if (!isEmpty(allCompanies)) {
      return Object.entries(groupBy(dotNumbers, 'company'))
        .filter(
          ([companyId]) => isEmpty(companies) || companies.includes(companyId)
        )
        .map(([companyId, dotNumbers]) => ({
          label: companyMap[companyId].name,
          options: dotNumbers.map(d => ({ value: d._id, label: d.name }))
        }))
    }
    // otherwise, don't worry about grouping them
    return dotNumbers.map(d => ({ value: d._id, label: d.name }))
  }

  _companyOptions () {
    const {
      dotNumbers: { dotNumbers },
      companies: { companies: allCompanies }
    } = this.props
    const { companies } = this.state
    const companyMap = selectors.companies.getCompanyMap(this.props)

    // for concorde admins who are viewing a list of companies, group the dot numbers by company
    if (!isEmpty(allCompanies)) {
      return Object.entries(groupBy(dotNumbers, 'company'))
        .filter(
          ([companyId]) => isEmpty(companies) || companies.includes(companyId)
        )
        .map(([companyId, dotNumbers]) => ({
          label: companyMap[companyId].name,
          options: dotNumbers.map(d => ({ value: d._id, label: d.name }))
        }))
    }
  }

  _renderFilters () {
    const {
      dotNumbers: { dotNumbers: allDotNumbers },
      companies: { companies: allCompanies }
    } = this.props
    if (!allDotNumbers) {
      return
    }

    const isConcordeAdmin = selectors.auth.isConcordeAdmin(this.props)
    const companyMap = selectors.companies.getCompanyMap(this.props)

    const { companies } = this.state

    return (

      <React.Fragment>
        {!isConcordeAdmin ? (
          ''
        ) : (
          <Row className='pb-3 mx-3 border-bottom align-items-center'>
            <Col
              xs={4}
              className='d-flex justify-content-end'
              style={{ height: '200px' }}
            >
                Companies
            </Col>
            <Col xs={8} md={6} style={{ height: '200px' }}>
              <Select
                styles={selectCustomStyles}
                isClearable={true}
                isSearchable={true}
                isMulti={true}
                options={allCompanies.map(d => ({
                  value: d._id,
                  label: d.name
                }))}
                value={(companies || []).map(d => ({
                  value: d,
                  label: companyMap[d].name
                }))}
                onChange={value =>
                  this.setState({
                    companies: (value || []).map(v => v.value),
                    dotNumbers: []
                  })
                }
                filterOption={(option, inputValue) =>
                  (option.label.toString().toUpperCase().match(inputValue.toUpperCase()) || []).length > 0
                }
              ></Select>
            </Col>
          </Row>
        )}

        {/* <Row className='py-3 mx-3 border-bottom align-items-center'>
            <Col xs={4} className='d-flex justify-content-end'>DOT numbers</Col>
            <Col xs={8} md={6}>
              <Select
                styles={selectCustomStyles}
                isClearable={true}
                isSearchable={true}
                isMulti={true}
                options={this._dotNumberOptions()}
                value={(dotNumbers || []).map((d) => ({ value: d, label: dotNumberMap[d].name }))}
                onChange={(value) => this.setState({ dotNumbers: (value || []).map((v) => v.value) })}
                filterOption={(option, inputValue) => (option.label.toString().match(inputValue) || []).length > 0}
              ></Select>
            </Col>
          </Row> */}
      </React.Fragment>
    )
  }

  render () {
    return (
      <Modal
        show={true}
        size='lg'
        onHide={() => this.props.done()}
        animation={false}
        centered
        scrollable={false}
      >
        <Modal.Header className='shadow-sm'>
          <Modal.Title>
            <Image
              className='mr-3'
              src={logo}
              height={26}
              width={39}
              alt='logo'
            />
            Edit filters
          </Modal.Title>
          <Button
            size='sm'
            variant='outline-secondary'
            title='Close'
            onClick={() => this.props.done()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Modal.Header>
        <Modal.Body>{this._renderFilters()}</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => this.props.done()}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            size={'sm'}
            className='btn-wide'
            onClick={() => {
              this.props.updateFilters(omit(this.state, []))
              this.props.done()
            }}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  //Jerry
}

const mapStateToProps = state => {
  const { auth, companies, dotNumbers, dotNumbersFilters } = state
  return { auth, companies, dotNumbers, dotNumbersFilters }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...actions.dotNumbersFilters }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DOTSTableFilters)

DOTSTableFilters.propTypes = {
  done: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
  dotNumbers: PropTypes.object.isRequired,
  dotNumbersFilters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired
}
