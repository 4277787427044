import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { isEmpty } from 'lodash'
import { displayNumber } from '../../utils'

import {
  Button,
  Col,
  Container,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEye, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import NavHeader from './common/navHeader'
import ViewCompany from './viewCompany'
import EditCompany from './editCompany'
import CreateUser from './createUser'
import CreateCompany from './createCompany'
import CreateCompanyUser from './createCompanyUser'
import CreateCompanyError from './createCompanyError'
import CreateDOTNumbers from './createDOTNumbers'
import CreateConsentContents from './createConsentContents'

import * as actions from '../../actions'
import { Link } from 'react-router-dom'

class Companies extends Component {
  state = {
    showAddUser: null,
    showViewCompany: null,
    showEditCompany: null,
    showCreateCompany: false,
    showCreateCompanyError: false,
    showCreateUser: false,
    showCreateUserError: false,
    showCreateDOTNumbers: false,
    showCreateDOTNumbersError: false,
    showCreateConsentContents: false,
    companyName: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    isConcordeAdmin: false,
    DOTNumbers: [null],
    consentContents: [null],
    errorTitle: null,
    errorMessage: null
  }

  _handleCompanyAction () {
    this.setState({ showCreateCompany: true })
  }

  componentDidMount () {
    this.props.setCompanies()
    this.props.loadCompanies()
  }

  _renderLoading () {
    const { companies: { loading } } = this.props
    if (!loading) { return '' }
    return (
      <Row>
        <Col xs={12} className='justify-content-center'>
          <Spinner animation='border' size='sm' className='align-middle mr-2'></Spinner>
          Loading
        </Col>
      </Row>
    )
  }

  _renderError () {
    const { companies: { error } } = this.props
    if (!error) { return '' }
    return (
      <Row>
        <Col xs={12} className='alert alert-danger'>
          {error}
        </Col>
      </Row>
    )
  }

  _renderViewCompany () {
    const { showViewCompany } = this.state
    if (!showViewCompany) { return }
    return <ViewCompany
      done={({ showEditCompany } = { showEditCompany: false }) =>
        this.setState({ showViewCompany: false, showEditCompany })
      }
    />
  }

  _renderEditCompany () {
    const { showEditCompany } = this.state
    if (!showEditCompany) { return }
    return <EditCompany
      done={({ showViewCompany } = { showViewCompany: false }) =>
        this.setState({ showEditCompany: false, showViewCompany })
      }
    />
  }

  _renderCreateUser () {
    const { showAddUser } = this.state
    if (!showAddUser) { return }
    return <CreateUser
      done={() => this.setState({ showAddUser: false })}
    />
  }

  _renderCompanies () {
    const { companies: { companies } } = this.props
    if (isEmpty(companies)) { return '' }
    return (
      <React.Fragment>
        <Row className='mb-3'>
          <Col xs={12}>
            <h3>Companies</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Drivers</th>
                  <th>Pending<br />Full Queries</th>
                  <th>Awaiting<br />Full Queries</th>
                  <th>Complete<br />Not Prohibited</th>
                  <th>Complete<br />Prohibited</th>
                  <th>Driver<br />Not Verified</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((c) => this._renderCompany(c))}
                {this._renderCountTotals(companies)}
              </tbody>
            </Table>
          </Col>
          <Col xs={12}>
            <div className='d-flex justify-content-end'>
              <Button
                variant='primary'
                onClick={() => this._handleCompanyAction()}
              >Create new company</Button>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  _renderCountTotals (companies) {

    let totals = {
      driverCount: 0,
      pendingFullQueryCount: 0,
      awaitingFullQueryCount: 0,
      completeNotProhibited: 0,
      completeProhibited: 0,
      driverNotVerified: 0
    }

    if (!isEmpty(companies)) {
      companies.forEach(company => {
        totals.driverCount += company.driverCount
        totals.pendingFullQueryCount += company.pendingFullQueryCount
        totals.awaitingFullQueryCount += company.awaitingFullQueryCount
        totals.completeNotProhibited += company.completeNotProhibited
        totals.completeProhibited += company.completeProhibited
        totals.driverNotVerified += company.driverNotVerified
      })
    }

    return (
      <tr>
        <td></td> {/* empty column */}
        <td><b>{displayNumber(totals.driverCount)}</b></td>
        <td><b>{displayNumber(totals.pendingFullQueryCount)} {` (${this._processPercent(totals.driverCount, totals.pendingFullQueryCount)})`}</b></td>
        <td><b>{displayNumber(totals.awaitingFullQueryCount)}{` (${this._processPercent(totals.driverCount, totals.awaitingFullQueryCount)})`}</b></td>
        <td><b>{displayNumber(totals.completeNotProhibited)}{` (${this._processPercent(totals.driverCount, totals.completeNotProhibited)})`}</b></td>
        <td><b>{displayNumber(totals.completeProhibited)}{` (${this._processPercent(totals.driverCount, totals.completeProhibited)})`}</b></td>
        <td><b>{displayNumber(totals.driverNotVerified)}{` (${this._processPercent(totals.driverCount, totals.driverNotVerified)})`}</b></td>
      </tr>
    )
  }

  _processPercent (dividend, divisor) {
    if (dividend === 0 || divisor === 0) return '0%'
    return Number(divisor / dividend).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })
  }

  _renderCompany (company) {
    const { _id, name, dotNumbers, driverCount = 0, pendingFullQueryCount, awaitingFullQueryCount, completeNotProhibited, completeProhibited, driverNotVerified } = company
    const dots = (dotNumbers || []).reduce((dots, dot) => {
      if (dot) {
        dots.push(dot._id)
      }
      return dots
    }, [])

    return (
      <tr key={_id}>
        <td>{name}</td>
        <td>{displayNumber(driverCount)}</td>
        <td>
          <Link className='no-decoration'
            to={`/concorde-admin/driver-roster?dotNumber=${dots.join(',')}&queryType=full&queryStatus=pending`} >
            {displayNumber(pendingFullQueryCount)}
          </Link>
        </td>
        <td>
          <Link className='no-decoration'
            to={`/concorde-admin/driver-roster?dotNumber=${dots.join(',')}&queryType=full&queryStatus=awaiting`} >
            {displayNumber(awaitingFullQueryCount)}
          </Link>
        </td>
        <td>
          <Link className='no-decoration'
            to={`/concorde-admin/driver-roster?dotNumber=${dots.join(',')}&queryType=full&queryStatus=complete,complete-notprohibited`} >
            {displayNumber(completeNotProhibited)}
          </Link>
        </td>
        <td>
          <Link className='no-decoration'
            to={`/concorde-admin/driver-roster?dotNumber=${dots.join(',')}&queryType=full&queryStatus=driverprohibited`} >
            {displayNumber(completeProhibited)}
          </Link>
        </td>
        <td>
          <Link className='no-decoration'
            to={`/concorde-admin/driver-roster?dotNumber=${dots.join(',')}&queryType=full&queryStatus=driver-notverified`} >
            {displayNumber(driverNotVerified)}
          </Link>
        </td>
        <td>
          <Button
            variant='light'
            size='sm'
            title='View'
            className='mr-1 action-button text-secondary'
            onClick={() => {
              this.props.setCompany(company)
              this.setState({ showViewCompany: true })
            }}
          ><FontAwesomeIcon icon={faEye} />
          </Button>
          <Button
            variant='light'
            size='sm'
            title='Edit'
            className='mr-1 action-button text-secondary'
            onClick={() => {
              this.props.setCompany(company)
              this.setState({ showEditCompany: true })
            }}
          ><FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            variant='light'
            size='sm'
            title='Add user'
            className='mr-1 action-button text-secondary'
            onClick={() => {
              this.props.setCompany(company)
              this.setState({ showAddUser: true })
            }}
          ><FontAwesomeIcon icon={faUserPlus} />
          </Button>
        </td>
      </tr>
    )
  }

  _renderCreateCompany () {
    if (!this.state.showCreateCompany) { return '' }
    return <CreateCompany
      hide={() =>
        this.setState({
          showCreateCompany: false,
          companyName: null,
          firstName: null,
          lastName: null,
          email: null,
          isConcordeAdmin: null,
          DOTNumbers: [null],
          consentContents: [null],
          errorTitle: null,
          errorMessage: null
        })
      }
      next={(companyName) => {
        const compArray = this.props.companies.companies
        if (compArray.map(c => c.name).includes(companyName))
          this.setState({
            showCreateCompanyError: true,
            errorTitle: 'Cannot add company',
            errorMessage: 'A company with this name already exists!'
          })
        else
          this.setState({
            showCreateCompany: false,
            showCreateDOTNumbers: true,
            companyName: companyName,
            errorTitle: null,
            errorMessage: null
          })
      }}
      companyName={this.state.companyName}
    />
  }

  _renderCreateCompanyUser () {
    if (!this.state.showCreateUser) { return '' }
    return <CreateCompanyUser
      hide={() =>
        this.setState({
          showCreateUser: false,
          companyName: null,
          firstName: null,
          lastName: null,
          email: null,
          isConcordeAdmin: null,
          DOTNumbers: [null],
          consentContents: [null],
          errorTitle: null,
          errorMessage: null
        })
      }
      next={(firstName,
        lastName,
        email,
        password,
        confirmPassword,
        isConcordeAdmin
      ) => {
        if (password !== confirmPassword)
          this.setState({
            showCreateCompanyError: true,
            errorTitle: 'Cannot add user',
            errorMessage: 'Password and confirmation must match!'
          })
        else
          this.setState({
            showCreateUser: false,
            showCreateDOTNumbers: true,
            firstName: firstName,
            lastName: lastName,
            password: password,
            email: email,
            isConcordeAdmin: isConcordeAdmin,
            errorTitle: null,
            errorMessage: null
          })
      }}
      back={(firstName, lastName, email, isConcordeAdmin) =>
        this.setState({
          showCreateCompany: true,
          showCreateUser: false,
          firstName: firstName,
          lastName: lastName,
          password: null,
          email: email,
          isConcordeAdmin: isConcordeAdmin,
          errorTitle: null,
          errorMessage: null
        })
      }
      companyName={this.state.companyName}
      firstName={this.state.firstName}
      lastName={this.state.lastName}
      email={this.state.email}
      isConcordeAdmin={this.state.isConcordeAdmin}
    />
  }

  _hasDuplicates (array) {
    return (new Set(array)).size !== array.length
  }

  _renderCreateDOTNumbers () {
    if (!this.state.showCreateDOTNumbers) { return '' }
    const DOTRegex = new RegExp('^[a-zA-Z0-9]*$')
    return <CreateDOTNumbers
      hide={() => {
        this.setState({
          showCreateDOTNumbers: false,
          companyName: null,
          firstName: null,
          lastName: null,
          email: null,
          password: null,
          isConcordeAdmin: null,
          DOTNumbers: [null],
          consentContents: [null],
          errorTitle: null,
          errorMessage: null
        })
      }}
      next={(DOTNumbers) => {
        if (!DOTNumbers.reduce((current, next) =>
          current && DOTRegex.test(next), true)) {
          this.setState({
            showCreateCompanyError: true,
            errorTitle: 'Cannot add DOT numbers',
            errorMessage: 'DOT numbers must be alphanumeric!'
          })
        }
        else if (this._hasDuplicates(DOTNumbers)) {
          this.setState({
            showCreateCompanyError: true,
            errorTitle: 'Cannot add DOT numbers',
            errorMessage: 'DOT numbers must be unique!'
          })
        } else {
          this.setState({
            showCreateDOTNumbers: false,
            showCreateConsentContents: true,
            firstName: null,
            lastName: null,
            email: null,
            password: null,
            isConcordeAdmin: null,
            DOTNumbers: DOTNumbers,
            errorTitle: null,
            errorMessage: null
          })
        }
      }}
      back={(DOTNumbers) =>
        this.setState({
          showCreateCompany: true,
          showCreateDOTNumbers: false,
          password: null,
          DOTNumbers: DOTNumbers,
          errorTitle: null,
          errorMessage: null
        })
      }
      DOTNumbers={this.state.DOTNumbers}
    />
  }

  _hasConsentDuplicates (consentContents) {
    return consentContents.some((consent) => {
      return consentContents.filter((c) => {
        return c.language === consent.language && c.dotNumber === consent.dotNumber
      }).length > 1
    })
  }

  _renderCreateConsentContents () {
    if (!this.state.showCreateConsentContents) { return '' }
    return <CreateConsentContents
      hide={() => {
        this.setState({
          showCreateConsentContents: false,
          companyName: null,
          firstName: null,
          lastName: null,
          email: null,
          password: null,
          isConcordeAdmin: null,
          DOTNumbers: [null],
          consentContents: [null],
          errorTitle: null,
          errorMessage: null
        })
      }}
      done={(consentContents) => {
        if (this._hasConsentDuplicates(consentContents)) {
          this.setState({
            showCreateCompanyError: true,
            errorTitle: 'Cannot add consent contents',
            errorMessage: 'Content language/DOT options must be unique!'
          })
        }
        else {
          this.props.createCompany(this.state, consentContents)
          this.setState({
            showCreateConsentContents: false,
            companyName: null,
            firstName: null,
            lastName: null,
            email: null,
            password: null,
            isConcordeAdmin: null,
            DOTNumbers: [null],
            consentContents: [null],
            errorTitle: null,
            errorMessage: null
          })
        }
      }}
      back={(consentContents) =>
        this.setState({
          showCreateDOTNumbers: true,
          showCreateConsentContents: false,
          consentContents: consentContents,
          password: null,
          errorTitle: null,
          errorMessage: null
        })
      }
      DOTNumbers={this.state.DOTNumbers}
      consentContents={this.state.consentContents}
    />
  }

  _renderCreateCompanyError () {
    if (!this.state.showCreateCompanyError) { return '' }
    return <CreateCompanyError
      hide={() =>
        this.setState({
          showCreateCompanyError: false
        })
      }
      title={this.state.errorTitle}
      message={this.state.errorMessage}
    />
  }

  render () {
    return (
      <div className='d-flex flex-column w-100 h-100'>
        <NavHeader />
        <Container className='d-flex flex-grow-1 flex-column align-items-center justify-content-center'>
          {this._renderEditCompany()}
          {this._renderViewCompany()}
          {this._renderCreateUser()}
          {this._renderLoading()}
          {this._renderError()}
          {this._renderCompanies()}
          {this._renderCreateCompany()}
          {this._renderCreateCompanyUser()}
          {this._renderCreateDOTNumbers()}
          {this._renderCreateConsentContents()}
          {this._renderCreateCompanyError()}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { companies } = state
  return { companies }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions.companies, ...actions.company },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies)

Companies.propTypes = {
  companies: PropTypes.object.isRequired,
  setCompanies: PropTypes.func.isRequired,
  loadCompanies: PropTypes.func.isRequired,
  setCompany: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired
}