import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Button,
  Col,
  Form,
  Container,
  Image,
  Modal,
  Row
} from 'react-bootstrap'
import logo from '../../../images/drivers-clearinghouse-logo-small.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import * as actions from '../../../actions'
import XLSX from 'xlsx'
import moment from 'moment-timezone'


const convertDate = (date, dateFormat = 'MM/DD/YYYY') => (
  date ? moment.tz(moment.utc(date).format('YYYY-MM-DD'), moment.tz.guess()).format(dateFormat) : ''
)

class ExportDriverRoster extends Component {
  state = {
    fileName: '',
    error: false
  };

  componentDidMount () {
    this.props.createDriverExport()
  }

  createFile () {
    if (!this.state.fileName) {
      this.setState({ error: true })
      return ''
    }
    const { driversBulkActions: { createdDriverExport } } = this.props
    let fileData = this._convertDataFile(createdDriverExport)

    if (fileData && fileData.length > 0) {
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(fileData)
      worksheet['!cols'] = this._formatColumnWidth(fileData)
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        'Exported Drivers'
      )
      XLSX.writeFile(workbook, this.state.fileName + '.xlsx', { bookType: 'xlsx' })
      this.props.done()
    }

  }

  _formatColumnWidth (fileData) {
    const columnWidths = []
    for (const property in fileData[0]) {
      columnWidths.push({ wch: Math.max(property ? property.toString().length + 3 : 0, ...fileData.map(row => row[property] ? row[property].toString().length + 3 : 0)) })
    }
    return columnWidths
  }

  _convertDataFile (createdDriverExport) {

    var arrReturn = []
    createdDriverExport.forEach(function (item) {
      arrReturn.push({
        'Last Name': item.lastName,
        'First Name': item.firstName,
        'DOB': convertDate(item.dob),
        'DL#': item.driversLicenseNumber,
        'Country': '',
        'State': item.state,
        'Query Type': ''
      })
    }
    )

    return arrReturn
  }

  _renderError () {
    if (!this.state.error) { return '' }
    return <Row>
      <Col className='alert alert-danger'>
        {'File name is required.'}
      </Col>
    </Row>
  }

  _renderCreateFileName () {
    const { fileName } = this.state

    return (
      <React.Fragment>
        <Row className='pb-1 mx-3 align-items-center'>
          <Col xs={4} className='text-right'>
            File Name
          </Col>
          <Col xs={8} md={6}>
            <Form.Control
              type='text'
              placeholder='Enter file name.'
              value={fileName}
              onChange={e => this.setState({ fileName: e.target.value })}
            ></Form.Control>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  render () {

    const { done } = this.props

    return (
      <Modal show={true} onHide={() => done()} animation={false} centered>
        <Modal.Header>
          <Modal.Title>
            <Image
              className='mr-3'
              src={logo}
              height={26}
              width={39}
              alt='logo'
            />
            Export driver roster
          </Modal.Title>
          <div>
            <Button
              size='sm'
              variant='outline-secondary'
              title='Close'
              className='ml-2'
              onClick={() => done()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {this._renderError()}
            {this._renderCreateFileName()}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            size={'sm'}
            className='btn-semi-wide'
            onClick={() => done()}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            size={'sm'}
            disabled={!this.state.fileName}
            className='btn-wide'
            onClick={() => { this.createFile() }}
          >
            Create File
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  const { driversBulkActions } = state
  return { driversBulkActions }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions.driversBulkActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportDriverRoster)

ExportDriverRoster.propTypes = {
  done: PropTypes.func.isRequired,
  driversBulkActions: PropTypes.object.isRequired,
  createDriverExport: PropTypes.func.isRequired,
  dotMap: PropTypes.object.isRequired,
  companyMap: PropTypes.object.isRequired
}
